import { Box, Flex, Switch } from '@chakra-ui/react'
import { Dropdown, type DropdownOption } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'
import { useSaturationCurveShowValuesPerDayAtom } from '../SaturationCurves'
import { ChartPointsItem, ChartPointsList } from '../shared/ChartPoints'

import {
  actualSpendColor,
  optimalSpendColor,
  saturationSpendColor,
} from './SingleChannelGraph'
import {
  SingleChannelControlActionEnum,
  type SingleChannelControlAction,
  type SingleChannelControlState,
} from './state'

interface SingleChannelControlPanelProps {
  dispatch: React.Dispatch<SingleChannelControlAction>
  state: SingleChannelControlState
  selectedCurveData: SaturationCurveData[number]
  channels: DropdownOption[]
}

export const SingleChannelControlPanel: React.FC<
  SingleChannelControlPanelProps
> = ({ state, dispatch, selectedCurveData, channels }) => {
  const [trackEvent] = useTrackEvent()
  const [showValuesPerDay, setShowValuesPerDay] =
    useSaturationCurveShowValuesPerDayAtom()

  return (
    <Flex justifyContent="space-between" gap={2} position="relative">
      <Box>
        <Flex gap={1} alignItems="center" mb={4}>
          <Typography
            fontSize="xs"
            color="gray.700"
            fontWeight={500}
            lineHeight={4}
          >
            Channel:
          </Typography>
          <Dropdown
            callback={(channel) => {
              trackEvent({
                eventName: 'Optimizations Saturation Curve Channel Changed',
                eventProperties: {
                  oldChannel: state.channel,
                  newChannel: String(channel.id),
                },
              })
              dispatch({
                type: SingleChannelControlActionEnum.CHANGE_CHANNEL,
                payload: {
                  channel: String(channel.id),
                },
              })
            }}
            size="xs"
            variant="solid"
            value={state.channel}
            options={channels}
          />
        </Flex>
        <Flex alignItems="center" gap={2} justifyContent="space-between">
          <Typography
            fontSize="xs"
            color="gray.900"
            fontWeight={400}
            lineHeight={4}
          >
            Show values per day
          </Typography>
          <Switch
            size="sm"
            isChecked={showValuesPerDay}
            onChange={() => {
              trackEvent({
                eventName:
                  'Optimizations Saturation Curve Values Per Day Toggled',
                eventProperties: { showValuesPerDay: !showValuesPerDay },
              })
              setShowValuesPerDay((c) => !c)
            }}
          />
        </Flex>
      </Box>
      <ChartPointsList>
        <ChartPointsItem color={optimalSpendColor} label="Proposed spend" />
        <ChartPointsItem
          color={saturationSpendColor}
          label="Saturation spend"
        />
        {selectedCurveData.actualPoint.length > 0 && (
          <ChartPointsItem color={actualSpendColor} label="Actual spend" />
        )}
      </ChartPointsList>
    </Flex>
  )
}
