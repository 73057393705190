import { getDateString } from '../../utils'

/**
 * @example
 * const string = getCompareDateString(new Date(2018, 2, 1), new Date(2018, 9, 15))
 * console.log(string) // "01 mar - 15 oct, 2018"
 */
export function getCompareDateString(
  startDate: Date | null,
  endDate?: Date | null,
): string {
  if (!startDate) {
    return ''
  }

  if (!endDate) {
    return getDateString(startDate).toLocaleLowerCase()
  }

  const isInSameYear =
    startDate && endDate && startDate.getFullYear() === endDate.getFullYear()

  if (isInSameYear) {
    return `${getDateString(startDate, 'dd MMM')} - ${getDateString(
      endDate,
    )}`.toLocaleLowerCase()
  }

  return `${getDateString(startDate)} - ${getDateString(
    endDate,
  )}`.toLocaleLowerCase()
}
