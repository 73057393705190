import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const EDIT_SEGMENTATION_SET_MUTATION = graphql(/* GraphQL */ `
  mutation EditSegmentationSet($id: ID!, $params: EditSegmentationSetInput!) {
    editSegmentationSet(id: $id, params: $params) {
      ...SegmentationSetFields
    }
  }
`)

export const useEditSegmentationSet = () => {
  const [editSegmentationSet] = useMutation(EDIT_SEGMENTATION_SET_MUTATION)

  return editSegmentationSet
}
