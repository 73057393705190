import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type FC, useRef, useState } from 'react'
import { useRevokeOrganizationInvitation } from './graphql/useRevokeOrganizationInvitation'
import type { Invitations } from './types'

type Invite = NonNullable<Invitations['data']>[number]

type RevokeInvitationButtonProps = {
  invite: Invite
  invitations: Invitations
}

export const RevokeInvitationButton: FC<RevokeInvitationButtonProps> = ({
  invite,
  invitations,
}) => {
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [revokeOrganizationInvitation] = useRevokeOrganizationInvitation()

  const cancelRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const revokeText = 'Revoke invitation'

  const revokeInvitation = async () => {
    try {
      setIsLoading(true)

      await revokeOrganizationInvitation({
        variables: { params: { invitationId: invite.id } },
      })

      await invitations?.revalidate?.()
      toast({
        status: 'success',
        description: 'Successfully revoked invite',
        position: 'bottom',
        isClosable: true,
      })
      trackEvent({
        eventName: 'Team Invite Revoked',
        eventProperties: {
          memberEmail: invite.emailAddress,
          memberRole: invite.role,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not revoke invite',
        position: 'bottom',
        isClosable: true,
      })
    }

    setIsLoading(false)
    onClose()
  }

  return (
    <>
      <Button variant="outline" colorScheme="red" size="xs" onClick={onOpen}>
        {revokeText}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight={500}>
              {revokeText}
            </AlertDialogHeader>

            <AlertDialogBody>
              The invite will be permanently revoked.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                isLoading={isLoading}
                onClick={revokeInvitation}
              >
                Revoke
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
