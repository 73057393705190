import { type DropdownOption } from 'components/Dropdown'
import { startOfDay } from 'date-fns'
import { type MarketingCostDimension } from './types'

export const MARKETING_COST_DIMENSION = {
  COUNTRY: 'country',
  CHANNEL: 'channel',
  CHANNEL_GROUP: 'channelGroup',
  CAMPAIGN: 'campaign',
} as const

export const normalizedMarketingCostDimensions: Record<
  MarketingCostDimension,
  DropdownOption<MarketingCostDimension>
> = {
  country: { id: MARKETING_COST_DIMENSION.COUNTRY, name: 'Country' },
  channel: { id: MARKETING_COST_DIMENSION.CHANNEL, name: 'Channel' },
  channelGroup: {
    id: MARKETING_COST_DIMENSION.CHANNEL_GROUP,
    name: 'Channel group',
  },
  campaign: { id: MARKETING_COST_DIMENSION.CAMPAIGN, name: 'Campaign' },
}

export const dateFormat = 'yyyy-MM-dd'
export const startOfToday = startOfDay(new Date())

export const TAXONOMY_DATA_TYPES = {
  PER_ORDER: 'perOrder',
  FIXED: 'fixed',
  PER_VISIT: 'perVisit',
  REVENUE_SHARE: 'revenueShare',
} as const

export const costTypes: DropdownOption[] = [
  { id: TAXONOMY_DATA_TYPES.PER_ORDER, name: 'Per order' },
  { id: TAXONOMY_DATA_TYPES.FIXED, name: 'Fixed cost per day' },
  { id: TAXONOMY_DATA_TYPES.PER_VISIT, name: 'Per visit' },
  { id: TAXONOMY_DATA_TYPES.REVENUE_SHARE, name: 'Revenue share' },
]
