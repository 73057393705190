import {
  type DatePreset,
  getCompareDatePresets,
  getDatePresets,
} from 'constants/getDatePresets'
import { Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { getDateString } from 'components/Datepicker/utils'
import { Dropdown } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { DateSelectorButton } from './DateSelectorButton'
import type { Props } from './types'

export const RangeDatepickerHeader = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
  dynamicDate,
  compareDynamicDate,
  shouldSetCompareDate,
  dynamicDateOptions,
  compareDynamicDateOptions,
  onPresetClick,
  onComparePresetClick,
  resetDateRange,
  resetEndDate,
  resetCompareDateRange,
  resetCompareEndDate,
}: Props) => {
  const presets = getDatePresets(dynamicDateOptions)
  const selectedPreset = presets.find((preset) => preset.id === dynamicDate)
  const comparePresets = getCompareDatePresets(
    compareDynamicDateOptions,
    startDate,
    endDate,
  )
  const selectedComparePreset = comparePresets.find(
    (preset) => preset.id === compareDynamicDate,
  )

  const onSelectPresetCallback = ({ id, value }: DatePreset) =>
    onPresetClick(id, value)

  const onSelectComparePresetCallback = ({ id, value }: DatePreset) =>
    onComparePresetClick(id, value)

  return (
    <Flex
      w="100%"
      p={4}
      bg="grey.50"
      border="1px solid"
      borderColor="grey.200"
      borderRadius={3}
    >
      <Flex
        flexDir="column"
        borderRight="1px solid"
        borderColor="grey.200"
        w="50%"
        pr={5}
      >
        <Typography>Main date range</Typography>
        <Flex mt={4} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <DateSelectorButton
              text={getDateString(startDate)}
              type="main"
              isActive={!shouldSetCompareDate && (!startDate || !!endDate)}
              onClick={resetDateRange}
            />
            <Typography mx={1}>-</Typography>
            <DateSelectorButton
              text={getDateString(endDate)}
              type="main"
              isActive={!!startDate && !endDate}
              onClick={resetEndDate}
            />
          </Flex>
          <ButtonIcon
            variant="ghost"
            size="sm"
            name="CloseIcon"
            onClick={resetDateRange}
            title="Reset date range"
          />
        </Flex>
        <Dropdown
          options={presets}
          value={selectedPreset?.id}
          callback={onSelectPresetCallback}
          placeholder="Custom range"
          containerProps={{ mt: 4 }}
          buttonProps={{ bg: 'white' }}
        />
      </Flex>
      <Flex flexDir="column" pl={5} w="50%">
        <Typography>Comparison range</Typography>
        <Flex mt={4} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <DateSelectorButton
              text={getDateString(compareStartDate)}
              type="compare"
              isActive={shouldSetCompareDate && !compareStartDate}
              onClick={resetCompareDateRange}
            />
            <Typography mx={1}>-</Typography>
            <DateSelectorButton
              text={getDateString(compareEndDate)}
              type="compare"
              isActive={
                shouldSetCompareDate && !!compareStartDate && !compareEndDate
              }
              onClick={resetCompareEndDate}
            />
          </Flex>
          <ButtonIcon
            variant="ghost"
            size="sm"
            name="CloseIcon"
            title="Reset comparison range"
            onClick={resetCompareDateRange}
          />
        </Flex>
        <Dropdown
          options={comparePresets}
          value={selectedComparePreset?.id}
          callback={onSelectComparePresetCallback}
          placeholder="Custom range"
          containerProps={{ mt: 4 }}
          buttonProps={{ bg: 'white' }}
        />
      </Flex>
    </Flex>
  )
}
