import { type TableCellProps } from '@chakra-ui/react'
import { OPTIMIZATION_METRIC } from 'features/optimizations/consts'

export const derivedMetrics = [
  OPTIMIZATION_METRIC.EPROAS,
  OPTIMIZATION_METRIC.EPROAS_OPTIMAL,
  OPTIMIZATION_METRIC.ROAS_AD,
  OPTIMIZATION_METRIC.ROAS_AD_OPTIMAL,
] as const

export const totalKey = 'total'

export const tdStyles: TableCellProps = {
  borderBottomWidth: 1,
  borderColor: 'gray.200',
  borderStyle: 'solid',
  verticalAlign: 'top',
  py: 2,
  pr: 4,
  pl: 1.5,
}
