import { Navigate, type RouteObject } from 'react-router-dom'
import { GEO_LIFT_PAGES, GEO_LIFT_ROUTE_FRAGMENTS } from './routePages'
import { ExperimentsView } from './views/ExperimentsView/ExperimentsView'
import { GuideView } from './views/GuideView/GuideView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={GEO_LIFT_PAGES.GUIDE} />,
  },
  {
    path: GEO_LIFT_ROUTE_FRAGMENTS.EXPERIMENTS,
    element: <ExperimentsView />,
  },
  {
    path: GEO_LIFT_ROUTE_FRAGMENTS.GUIDE,
    element: <GuideView />,
  },
]
