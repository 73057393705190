import { Box, Divider, Flex, type FlexProps } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { AdditionalInfoSection } from './AdditionalInfoSection/AdditionalInfoSection'
import { CompareUnitSection } from './CompareUnitSection'
import { DimensionsSection } from './DimensionsSection'
import { LabelsSection } from './LabelsSection'
import { MetricsSection } from './MetricsSection/MetricsSection'
import { VisibilitySection } from './VisibilitySection'

const sectionStyles: FlexProps = {
  direction: 'column',
  gap: 4,
  p: 4,
  pb: 6,
}

export const ReportDetailsPanel = () => {
  return (
    <Box
      flexShrink={0}
      w="300px"
      borderLeft="solid 1px"
      borderLeftColor="gray.200"
      h="full"
      overflowY="auto"
    >
      <Flex {...sectionStyles}>
        <Typography fontSize="md" color="gray.800" lineHeight={6}>
          Report details
        </Typography>

        <DimensionsSection />
        <MetricsSection />
        <CompareUnitSection />
      </Flex>

      <Divider />

      <Flex {...sectionStyles}>
        <VisibilitySection />
        <LabelsSection />
      </Flex>

      <Divider />

      <AdditionalInfoSection />
    </Box>
  )
}
