import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import type { DateRange } from 'constants/types'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  Box,
  Portal,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { endOfDay } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import '../datepicker.scss'
import { RangeDatepickerWrapper } from './RangeDatepickerWrapper'
import type { DatepickerProps } from './types'

export const RangeDatepicker = ({
  dynamicDateOptions,
  compareDynamicDateOptions,
  compareDateRange,
  compareDynamicDate,
  dateRange,
  dynamicDate,
  popupButton,
  onApply,
}: DatepickerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [draftDateRange, setDraftDateRange] = useState<DateRange>(dateRange)
  const [draftCompareDateRange, setDraftCompareDateRange] =
    useState<DateRange>(compareDateRange)
  const [draftDynamicDate, setDraftDynamicDate] = useState<string | undefined>(
    dynamicDate,
  )
  const [draftCompareDynamicDate, setDraftCompareDynamicDate] = useState<
    string | undefined
  >(compareDynamicDate)
  const popoverContentRef = useRef<HTMLDivElement>(null)

  const resetRanges = () => {
    setDraftDateRange(dateRange)
    setDraftCompareDateRange(compareDateRange)
    setDraftDynamicDate(dynamicDate)
    setDraftCompareDynamicDate(compareDynamicDate)
    onClose()
  }

  useEffect(() => {
    setDraftDateRange(dateRange)
    setDraftCompareDateRange(compareDateRange)
    setDraftDynamicDate(dynamicDate)
    setDraftCompareDynamicDate(compareDynamicDate)
  }, [dateRange, compareDateRange, dynamicDate, compareDynamicDate])

  const onApplyCallback = () => {
    const [fromDate, toDate] = draftDateRange
    const [fromCompareDate, toCompareDate] = draftCompareDateRange
    let defaultToDate = toDate
    let defaultCompareToDate = toCompareDate

    if (fromDate && !toDate) {
      defaultToDate = endOfDay(fromDate)
    }
    if (fromCompareDate && !toCompareDate) {
      defaultCompareToDate = endOfDay(fromCompareDate)
    }
    const newDateRange: DateRange = [fromDate, defaultToDate]
    const newCompareDateRange: DateRange = [
      fromCompareDate,
      defaultCompareToDate,
    ]

    onApply({
      dateRange: newDateRange,
      compareDateRange: newCompareDateRange,
      dynamicDate: draftDynamicDate || DYNAMIC_DATE_ID.CUSTOM_RANGE,
      compareDynamicDate:
        draftCompareDynamicDate || COMPARE_DYNAMIC_DATE_ID.CUSTOM_RANGE,
    })

    onClose()
  }

  const handleClose = () => {
    draftDateRange[0] === null ? resetRanges() : onApplyCallback()
    onClose()
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      placement="left"
      trigger="click"
      strategy="fixed"
    >
      <PopoverTrigger>
        <Box>{popupButton}</Box>
      </PopoverTrigger>

      <Portal>
        <MenuOverlay
          isOpen={isOpen}
          onClick={(e) => {
            if (!popoverContentRef.current?.contains(e.target as HTMLElement)) {
              handleClose()
            }
          }}
        />
        <PopoverContent minW="630px" ref={popoverContentRef}>
          <PopoverBody>
            <RangeDatepickerWrapper
              dynamicDateOptions={dynamicDateOptions}
              compareDynamicDateOptions={compareDynamicDateOptions}
              compareDateRange={draftCompareDateRange}
              compareDynamicDate={draftCompareDynamicDate}
              dateRange={draftDateRange}
              dynamicDate={draftDynamicDate}
              setCompareDateRange={setDraftCompareDateRange}
              setCompareDynamicDate={setDraftCompareDynamicDate}
              setDateRange={setDraftDateRange}
              setDynamicDate={setDraftDynamicDate}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
