import { type ButtonProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'

interface Props extends ButtonProps {
  isAllSelected?: boolean
  toggleAllSelected?: () => void
}

export const SelectDeselectButton = ({
  isAllSelected,
  toggleAllSelected,
  ...rest
}: Props) => {
  const label = isAllSelected ? 'Deselect all' : 'Select all'

  return (
    <Button
      type="button"
      flexShrink={0}
      variant="link"
      size="sm"
      onClick={toggleAllSelected}
      {...rest}
    >
      {label}
    </Button>
  )
}
