import { Divider, Flex } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { type SetProfileImageParams } from '@clerk/types'
import { ImagePicker } from 'components/ImagePicker/ImagePicker'
import { Typography } from 'components/Typography'

import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { Information } from './Information'

export const ProfileView: React.FC = () => {
  const { user } = useUser()
  const [trackEvent] = useTrackEvent()

  if (!user) return null

  const setImage = (image: SetProfileImageParams) => {
    trackEvent({
      eventName: 'Profile Image Changed',
      eventProperties: {},
    })

    return user.setProfileImage(image)
  }

  return (
    <Flex direction="column" w="full" alignItems="flex-start" p={6}>
      <Typography fontSize="xl" lineHeight="28px" mb={6}>
        Profile
      </Typography>

      <ImagePicker
        imageUrl={user.imageUrl}
        setImage={setImage}
        hasImage={user.hasImage}
        title="Photo"
        description="Upload a profile picture."
        buttonText="Upload photo"
        roundedImage
        removeTitle="Remove photo"
        removeDescription="The photo will be deleted permanently"
        toastUploadedMessage="Photo uploaded"
        toastRemovedMessage="Photo removed"
      />

      <Divider my={10} />

      <Information user={user} />
    </Flex>
  )
}
