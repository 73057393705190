import { type MutationFunctionOptions, useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import type {
  CreateConversationMessageInput,
  CreateConversationMessageMutation,
} from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'

const CREATE_CONVERSATION_MESSAGE = graphql(/* GraphQL */ `
  mutation CreateConversationMessage(
    $payload: CreateConversationMessageInput!
  ) {
    createConversationMessage(params: $payload) {
      ...ConversationMessageFields
    }
  }
`)

export const useCreateConversationMessage = () => {
  const [mutation, state] = useMutation(CREATE_CONVERSATION_MESSAGE)
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const createConversationMessage = async ({
    payload,
    options,
  }: {
    payload: CreateConversationMessageInput
    options?: MutationFunctionOptions<CreateConversationMessageMutation>
  }) => {
    return mutation({
      ...options,
      variables: {
        payload,
      },
      onCompleted: (data) => {
        trackEvent({
          eventName: 'Copilot Question Asked',
          eventProperties: {
            question: payload.text,
            conversationId: payload.id,
          },
        })

        options?.onCompleted?.(data)
      },
      onError: (error) => {
        toast({
          status: 'error',
          description: 'Could not send message. Please try again',
        })

        options?.onError?.(error)
      },
    })
  }

  return [createConversationMessage, state] as const
}
