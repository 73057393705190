import { Box, Divider, Flex } from '@chakra-ui/react'
import { Dropdown, type DropdownOption } from 'components/Dropdown'
import {
  type ChartSerie,
  type ChartSorting,
  ChartSortOrder,
} from 'graphql/reports/types'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import type React from 'react'
import { type UpdateChartOptions } from './ChartSettingsPanel'

interface ChartSortingOptionProps {
  onChange: (args: UpdateChartOptions) => void
  series: ChartSerie[]
  chartSorting: ChartSorting
  selectedXAxis: DropdownOption
}

const ALPHABETICAL_SORT_OPTIONS = [
  { id: ChartSortOrder.ASC, name: 'A → Z' },
  { id: ChartSortOrder.DESC, name: 'Z → A' },
] satisfies DropdownOption[]

const NUMERIC_SORT_OPTIONS = [
  { id: ChartSortOrder.DESC, name: '9 → 1' },
  { id: ChartSortOrder.ASC, name: '1 → 9' },
] satisfies DropdownOption[]

export const ChartSortingOption: React.FC<ChartSortingOptionProps> = ({
  onChange,
  series,
  chartSorting,
  selectedXAxis,
}) => {
  const normalizedMetrics = useNormalizedMetrics()

  return (
    <Box>
      <Flex gap={2} alignItems="flex-end">
        <Dropdown
          containerProps={{ flex: 1, minW: '0px' }}
          value={chartSorting.key}
          callback={(newSort) => {
            onChange({
              newChartSorting: {
                key: String(newSort.id),
                order: newSort.sortOrder,
              },
            })
          }}
          options={[
            // The extra sortOrder prop here is as we want to change the order
            // when swapping between metrics and dimensions
            { ...selectedXAxis, sortOrder: ChartSortOrder.ASC },
            ...series.map((item) => ({
              id: item.key,
              name: normalizedMetrics[item.key]?.label ?? '',
              sortOrder: ChartSortOrder.DESC,
            })),
          ]}
          label="X-axis sort order"
          size="sm"
        />
        <Dropdown
          value={chartSorting.order}
          callback={(newSort) => {
            onChange({
              newChartSorting: {
                key: chartSorting?.key,
                order: newSort.id,
              },
            })
          }}
          options={
            chartSorting?.key === selectedXAxis?.id
              ? ALPHABETICAL_SORT_OPTIONS
              : NUMERIC_SORT_OPTIONS
          }
          listProps={{
            minW: '0px',
          }}
          size="sm"
        />
      </Flex>
      <Divider mt={4} />
    </Box>
  )
}
