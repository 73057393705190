import { Grid } from '@chakra-ui/react'
import {
  type CompareValueType,
  type MetricOptimizationTableColumn,
  type OptimizationTableColumn,
} from 'features/optimizations/consts'
import {
  type ContributionData,
  type SummaryData,
} from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type OptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { getChannelId } from 'features/optimizations/utils/transformChannel'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { TableBodyGroup } from './TableBodyGroup'
import { TableHeader } from './TableHeader'
import { TableSummary } from './TableSummary'
import { getAggregatedData } from './utils'

type MergedData = {
  contribution: ContributionData
  summary: SummaryData
}
interface Props {
  data: MergedData | undefined
  optimizationConfig: OptimizationConfig | undefined
  compareDateLabel: string
  compareValueType: CompareValueType
  columns: OptimizationTableColumn[]
}
const emptyData: MergedData = { contribution: [], summary: [] }
const excludedSummaryRows = ['marketing']

export const ScenarioTable = ({
  data = emptyData,
  optimizationConfig,
  compareDateLabel,
  compareValueType,
  columns,
}: Props) => {
  const { currency } = useMerchantInfo()

  const aggregatedData = useMemo(
    () =>
      getAggregatedData({
        contribution: data.contribution,
        columns: columns.filter(
          ({ isMetric }) => isMetric,
        ) as MetricOptimizationTableColumn[],
      }),
    [data.contribution, columns],
  )

  return (
    <Grid
      templateColumns={`repeat(${columns.length + 1},auto)`} // columns + 1 for Funnel column
      overflowX="auto"
    >
      <TableHeader
        compareDateLabel={compareDateLabel}
        currency={currency}
        columns={columns}
      />
      {data.contribution?.map((row) => {
        const id = getChannelId(row.channel)

        return (
          <TableBodyGroup
            columns={columns}
            key={id}
            contribution={row}
            compareValueType={compareValueType}
          />
        )
      })}
      <TableSummary
        columns={
          columns.filter(
            ({ isMetric }) => isMetric,
          ) as MetricOptimizationTableColumn[]
        }
        aggregatedData={aggregatedData}
        summaryData={data.summary.filter(
          ({ id }) => !excludedSummaryRows.includes(String(id)), // We don't want to show all rows. For example, marketing since it is already counted in aggregatedData
        )}
        summaries={optimizationConfig?.summaries}
        compareValueType={compareValueType}
      />
    </Grid>
  )
}
