import { Flex, Skeleton } from '@chakra-ui/react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Tag } from 'components/Tag/Tag'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useForm, useWatch } from 'react-hook-form'
import { formatMetric } from 'utils/numberFormats'
import { z } from 'zod'
import { useUpdateReturnThreshold } from './graphql/useUpdateReturnThreshold'
import { ReturnThresholdChart } from './ReturnThresholdChart'

const MAX_RETURN_THRESHOLD = 1000

const validationSchema = z.object({
  returnThreshold: z
    .string()
    .optional()
    .refine(
      (val) => !val || (Number(val) >= 0 && Number(val) < MAX_RETURN_THRESHOLD),
      (val) => ({
        message:
          Number(val) < 0
            ? 'Value must be greater than or equal to 0'
            : `Value must be less than ${MAX_RETURN_THRESHOLD}`,
      }),
    ),
})

interface FormState {
  returnThreshold: string | undefined
}

const defaultPercentageValue = '0%'
const getDisplayPercentageValue = (
  returnThreshold: string | undefined,
  returnThresholdCurve: number[][],
): string => {
  if (!returnThresholdCurve.length || !returnThreshold) {
    return defaultPercentageValue
  }

  const numberReturnThreshold = Number(returnThreshold)

  const [firstPoint] = returnThresholdCurve[0]
  const [lastPoint, lastValue] =
    returnThresholdCurve[returnThresholdCurve.length - 1]

  if (numberReturnThreshold < firstPoint) {
    return defaultPercentageValue
  }
  if (numberReturnThreshold > lastPoint) {
    return formatMetric(METRIC_FORMAT.PERCENT, lastValue / 100)
  }

  // Find the closest point less than or equal to the returnThreshold
  let previousValue = defaultPercentageValue

  for (const [point, value] of returnThresholdCurve) {
    if (point > numberReturnThreshold) {
      break
    }
    previousValue = formatMetric(METRIC_FORMAT.PERCENT, value / 100)
  }

  return previousValue
}

const emptyArray: number[][] = []

export const ReturnThresholdContent = ({
  returnThreshold,
  returnThresholdCurve = emptyArray,
  isLoading,
}: {
  returnThreshold: number | null | undefined
  returnThresholdCurve: number[][] | undefined
  isLoading: boolean
}) => {
  const defaultReturnThreshold = returnThreshold?.toString() ?? undefined
  const methods = useForm<FormState>({
    defaultValues: {
      returnThreshold: defaultReturnThreshold,
    },
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
    mode: 'onChange',
  })
  const [updateReturnThreshold] = useUpdateReturnThreshold()

  const {
    formState: { errors },
    control,
  } = methods
  const handleSubmit = (data: FormState) => {
    updateReturnThreshold({
      returnThreshold: data.returnThreshold
        ? Number(data.returnThreshold)
        : null,
    })
  }
  const formReturnThreshold = useWatch({ name: 'returnThreshold', control }) // We need to use watch so that component re-renders when target changes

  return (
    <>
      <Flex w="100%" maxW={600} h={360}>
        <Skeleton isLoaded={!isLoading} w="100%" h="100%">
          <ReturnThresholdChart
            data={returnThresholdCurve}
            returnThreshold={Number(formReturnThreshold)}
          />
        </Skeleton>
      </Flex>
      <Form<FormState> methods={methods} onSubmit={handleSubmit}>
        <Flex gap={4} alignItems="flex-start" p={4}>
          <Flex direction="column" gap={2}>
            <Skeleton isLoaded={!isLoading}>
              <Input
                helperText="days"
                placeholder="30"
                label="Returns threshold"
                type="number"
                min={0}
                max={MAX_RETURN_THRESHOLD}
                step={1}
                onKeyDown={(e) => {
                  // Prevent decimal and exponential input
                  if (['.', ',', 'e', '+'].includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                name="returnThreshold"
                error={errors.returnThreshold}
                minW={120}
              />
            </Skeleton>
            <Skeleton isLoaded={!isLoading}>
              <Tag
                variant="solid"
                size="lg"
                label={`${getDisplayPercentageValue(
                  formReturnThreshold,
                  returnThresholdCurve,
                )} of returns`}
                minW="full"
                colorScheme="purple"
              />
            </Skeleton>
          </Flex>
          <Skeleton isLoaded={!isLoading} w="full">
            <Button
              variant="outline"
              colorScheme="grey"
              mt={6}
              onClick={() => methods.setValue('returnThreshold', undefined)}
            >
              Clear
            </Button>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} w="full">
            <Button
              type="submit"
              isDisabled={defaultReturnThreshold === formReturnThreshold}
              mt={6}
            >
              Save
            </Button>
          </Skeleton>
        </Flex>
      </Form>
    </>
  )
}
