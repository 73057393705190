import { Flex } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import { Typography } from 'components/Typography'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import type React from 'react'

export const OrganizationLegend: React.FC = () => {
  const { organization } = useOrganization()

  if (!organization) return null

  return (
    <Flex shrink={0} gap={1.5} alignItems="center">
      <ClerkImage imageUrl={organization.imageUrl} type="organisation" />
      <Typography fontSize="sm" fontWeight={500} color="gray.800">
        {organization.name}
      </Typography>
    </Flex>
  )
}
