import { MenuItem, useDisclosure } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import type React from 'react'
import { DeleteReportModal } from './DeleteReportModal'

interface Props {
  reportId: string | undefined
}
export const DeleteReportMenuItem: React.FC<Props> = ({ reportId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={() => {
          onOpen()
        }}
        closeOnSelect={false}
      >
        Delete
      </MenuItem>
      <DeleteReportModal
        isOpen={isOpen}
        reportId={reportId}
        onClose={onClose}
      />
    </>
  )
}
