import {
  Menu,
  IconButton,
  MenuButton,
  MenuList,
  Portal,
  MenuDivider,
  type PlacementWithLogical,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationSet } from 'features/productFeed/graphql/fragments'
import type React from 'react'
import { CopyCSVItem } from './CopyCSVItem'
import { DeleteSegmentationSetModal } from './DeleteSegmentationSetModal'
import { EditSegmentationSetModal } from './EditSegmentationModal'

interface SegmentationSetContextMenuProps {
  segmentationSet: SegmentationSet
  placement?: PlacementWithLogical
  onDelete?: () => void
}

export const SegmentationSetContextMenu: React.FC<
  SegmentationSetContextMenuProps
> = ({ segmentationSet, placement, onDelete }) => {
  return (
    <Menu
      isLazy={false}
      size="sm"
      closeOnSelect={false}
      placement={placement || 'bottom-end'}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            onClick={
              (e) => e.stopPropagation() // For example when context menu is used in report list, it will stop the report from being selected on click
            }
            as={IconButton}
            size="sm"
            aria-label="Options"
            border="none"
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="Actions"
                size="small"
              />
            }
            variant="ghost"
          />
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              <EditSegmentationSetModal
                currentSegmentationSet={segmentationSet}
              />

              <CopyCSVItem segmentationSet={segmentationSet} />

              <MenuDivider />

              <DeleteSegmentationSetModal
                segmentationSet={segmentationSet}
                onDelete={onDelete}
              />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
