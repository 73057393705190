import { type DateRange } from 'constants/types'
import { MenuButton } from '@chakra-ui/react'
import { Button, type ButtonProps } from 'components/buttons/Button'
import { type DropdownOption } from 'components/Dropdown'
import { type BaseFilterProps } from 'components/Filters/types'
import { type Metric } from 'graphql/statistics/useMetrics'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type ReactElement, useState } from 'react'
import { DimensionMetricAutocomplete } from './DimensionMetricAutocomplete'
import { FilterPopupPicker } from './FilterPopupPicker'

const buttonStyles: ButtonProps = {
  variant: 'outline',
  colorScheme: 'gray',
  size: 'sm',
  px: 2,
  leadingIcon: { name: 'FilterIcon', size: 'small' },
}

interface AddFilterButtonProps
  extends Pick<BaseFilterProps, 'addFilter' | 'removeFilter' | 'filters'> {
  dateRange: DateRange
  customMenuButton?: ReactElement
  validDimensionIds?: string[]
  validMetricIds?: string[]
  metrics: Metric[]
  showMetricsFirst?: boolean
  skipDimensionGroupValidation?: boolean
}

export const AddFilterButton = ({
  filters,
  addFilter,
  removeFilter,
  dateRange,
  customMenuButton,
  validDimensionIds,
  validMetricIds,
  metrics,
  showMetricsFirst,
  skipDimensionGroupValidation,
}: AddFilterButtonProps) => {
  const [selectedFilter, setSelectedFilter] = useState<
    DropdownOption | undefined
  >()
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId: String(selectedFilter?.id ?? ''),
    dateRange,
    skipStaticValues: true,
  })

  if (!selectedFilter) {
    return (
      <DimensionMetricAutocomplete
        matchWidth={false}
        metrics={metrics}
        showMetricsFirst={showMetricsFirst}
        validDimensionIds={validDimensionIds}
        validMetricIds={validMetricIds}
        skipDimensionGroupValidation={skipDimensionGroupValidation}
        setSelected={(item) => {
          setSelectedFilter(item)
        }}
        customMenuButton={
          customMenuButton ?? (
            <MenuButton as={Button} {...buttonStyles}>
              Filters
            </MenuButton>
          )
        }
      />
    )
  }

  return (
    <FilterPopupPicker
      isOpen={true}
      onClose={() => {
        setSelectedFilter(undefined)
      }}
      filters={filters}
      options={uniqueDimensionValues}
      isLoading={query.loading}
      removeFilter={removeFilter}
      addFilter={addFilter}
      filterLabel={selectedFilter.name}
      filterId={String(selectedFilter.id)}
      filterIndex={filters[selectedFilter.id]?.length ?? 0} // The new filter index will be the current filter length for that key
      popupButton={
        customMenuButton ?? (
          <MenuButton as={Button} {...buttonStyles}>
            Filters
          </MenuButton>
        )
      }
    />
  )
}
