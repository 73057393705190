import { progressAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(() => ({
  label: {
    textAlign: 'left',
    paddingLeft: 1,
  },
  filledTrack: {
    borderRadius: '4px',
  },
  track: {
    borderRadius: '4px',
    backgroundColor: 'gray.100',
  },
}))

const sizes = {
  xl: definePartsStyle({
    track: { h: '5' },
  }),
}

export const progressTheme = defineMultiStyleConfig({
  sizes,
  baseStyle: baseStyle,
  defaultProps: {
    size: 'xl',
  },
})
