import type { DateRange, ISODateRange, TimestampRange } from 'constants/types'
import { format, addDays } from 'date-fns'

import { DATE_FORMAT } from './consts'

export const getDates = (startDate: Date, endDate: Date) => {
  const dateArray: Date[] = []
  let currentDate = startDate

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate))
    currentDate = addDays(currentDate, 1)
  }

  return dateArray
}

export const getDateString = (date: Date | null, dateFormat?: string) =>
  date ? format(date, dateFormat || DATE_FORMAT) : ''

type NonNullableRange<T> = T extends [infer U, infer V]
  ? [NonNullable<U>, NonNullable<V>]
  : never

type Range = DateRange | TimestampRange | ISODateRange
export const getIsPopulatedDateRange = (
  dateRange: Range,
): dateRange is NonNullableRange<Range> => {
  return !!dateRange[0] && !!dateRange[1]
}
