import {
  Alert as AlertBase,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  useToast,
} from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { Icon, type IconName } from 'components/Icon/Icon'
import { useState, type FC } from 'react'
import { DarkModeHandler } from 'ui/DarkModeHandler'
import type { AlertProps } from './types'

export const Alert: FC<AlertProps> = ({
  content,
  title,
  status = 'info',
  closable = true,
  action,
  toastId,
  variant,
  colorMode,
  multiline = false,
  onClose,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const toast = useToast()

  const hasTitleAndContent = title && content

  if (!isOpen && !toastId) return null

  const iconNames: Record<typeof status, IconName> = {
    info: 'InformationCircleIcon',
    success: 'CheckCircleIcon',
    warning: 'ExclamationTriangleIcon',
    error: 'ExclamationCircleIcon',
  }

  const iconName = iconNames[status]

  return (
    <DarkModeHandler colorMode={colorMode}>
      <AlertBase status={status} variant={variant} {...rest}>
        {/* height and width are set to 6 to match the large icon size */}
        <AlertIcon mr={3} height={6} width={6}>
          <Icon name={iconName} />
        </AlertIcon>
        <Flex
          flex={1}
          justifyContent="space-between"
          mt="2px"
          mr={closable ? 8 : 0}
          w={multiline ? 'min-content' : undefined}
        >
          <Flex flexDir={hasTitleAndContent ? 'column' : undefined} gap="6px">
            {title && (
              <AlertTitle lineHeight={5} fontSize={15} fontWeight={500}>
                {title}
              </AlertTitle>
            )}
            {content && <AlertDescription>{content}</AlertDescription>}
          </Flex>
          {action && (
            <Button
              onClick={action.onClick}
              variant="link"
              alignSelf="flex-start"
            >
              {action.label}
            </Button>
          )}
        </Flex>
        {closable && (
          <Flex>
            <ButtonIcon
              title="Close alert"
              name="CloseIcon"
              size="xs"
              variant="ghost"
              colorScheme="grey"
              onClick={() => {
                toastId ? toast.close(toastId) : setIsOpen(false)

                onClose?.()
              }}
              alignSelf="flex-start"
              _hover={{ bg: 'transparent' }}
            />
          </Flex>
        )}
      </AlertBase>
    </DarkModeHandler>
  )
}
