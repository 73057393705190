import {
  IconButton as ChakraIconButton,
  forwardRef,
  type IconButtonProps as ChakraIconButtonProps,
  type As,
} from '@chakra-ui/react'
import { Icon, type IconName, type IconProps } from 'components/Icon/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { type TooltipProps } from 'components/Tooltip/types'
import { type ReactElement } from 'react'

export type ButtonSize = Extract<
  ChakraIconButtonProps['size'],
  'lg' | 'md' | 'sm' | 'xs'
>

type ButtonIconOption =
  | { customIcon?: never; name: IconName }
  | { customIcon: ReactElement; name?: never } // meant for passing in org logos for example

export type ButtonIconProps = Omit<
  ChakraIconButtonProps,
  'name' | 'size' | 'aria-label'
> & {
  iconProps?: Partial<IconProps>
  size?: ButtonSize
  isSelected?: boolean
  title: string
  tooltipProps?: Omit<TooltipProps, 'children'>
} & ButtonIconOption

const buttonSizes: Record<ButtonSize, number> = {
  lg: 12,
  md: 10,
  sm: 8,
  xs: 6,
}

const iconSizes: Record<ButtonSize, number> = {
  lg: 16,
  md: 16,
  sm: 16,
  xs: 16,
}

export const ButtonIcon = forwardRef<ButtonIconProps, As>(
  (
    {
      colorScheme = 'grey',
      iconProps,
      name,
      isSelected,
      size = 'md',
      variant = 'solid',
      tooltipProps,
      title,
      onClick,
      customIcon,
      ...rest
    },
    ref,
  ) => {
    const buttonSize = buttonSizes[size]

    return (
      <Tooltip size="sm" label={title} {...tooltipProps}>
        <ChakraIconButton
          icon={
            customIcon ? (
              customIcon
            ) : (
              <Icon name={name} customSize={iconSizes[size]} {...iconProps} />
            )
          }
          variant={variant}
          colorScheme={colorScheme}
          ref={ref}
          size={size}
          aria-label={title}
          data-hover={isSelected ? true : undefined} // sending false still enables the hover state
          maxW={buttonSize}
          minW={buttonSize}
          maxH={buttonSize}
          minH={buttonSize}
          onClick={onClick}
          flexShrink={0}
          {...rest}
        />
      </Tooltip>
    )
  },
)
