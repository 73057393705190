import { type Row } from '@tanstack/react-table'
import { Icon } from 'components/Icon/Icon'
import { useMemo } from 'react'
import { getErrors } from '../ManualInput/utils/getErrors'
import { type ManualInputColumns } from '../types'

type Props = {
  row: Row<ManualInputColumns>
}

export const ErrorIconCell = ({ row }: Props) => {
  const isExpanded = row.getIsExpanded()

  // only iterate when there are errors, and break out of the loop if an error is found
  const errorExists = useMemo(() => {
    const shouldDisplayError = !isExpanded

    if (!shouldDisplayError) {
      return false
    }

    const errorIds = getErrors?.(row, true) ?? []
    const errorsExists = errorIds.length > 0

    return errorsExists
  }, [isExpanded, row])

  if (!errorExists) return null

  return <Icon name="ExclamationCircleIcon" color="red.500" size="small" />
}
