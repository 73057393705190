import { Box } from '@chakra-ui/react'
import type React from 'react'
import { SegmentationHeader } from './components/SegmentationHeader'
import { SegmentationList } from './components/SegmentationList'

export const SegmentationSetsView: React.FC = () => {
  return (
    <Box>
      <SegmentationHeader />

      <SegmentationList />
    </Box>
  )
}
