import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ProductLabel } from './fragments'

export const PRODUCT_LABELS_QUERY = graphql(/* GraphQL */ `
  query ProductLabels {
    viewer {
      id
      merchant {
        id
        productLabels {
          ...ProductLabelFields
        }
      }
    }
  }
`)

export const useProductLabels = () => {
  const { data, ...query } = useQuery(PRODUCT_LABELS_QUERY)

  return {
    query,
    productLabels: (data?.viewer?.merchant.productLabels ??
      []) as ProductLabel[],
  }
}
