import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import type { FC } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirmHandler: () => void
}

export const ConfirmationModal: FC<Props> = ({
  isOpen,
  onClose,
  onConfirmHandler,
}) => {
  const onSave = () => {
    onConfirmHandler()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save new cost values</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          New cost values will permanently replace previous cost values when
          saved. This action cannot be undone.
          <br />
          <br />
          Note that rows containing empty values will not be saved.
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
