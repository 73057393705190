import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import type React from 'react'

export const BotIcon: React.FC = () => {
  return (
    <Flex
      bg="purple.500"
      flexShrink={0}
      w={8}
      h={8}
      justifyContent="center"
      alignItems="center"
    >
      <Icon name="RobotIcon" color="white" />
    </Flex>
  )
}
