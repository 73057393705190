import { type UseDisclosureProps, useDisclosure } from '@chakra-ui/react'

export type DefaultDisclosure = Pick<
  ReturnType<typeof useDisclosure>,
  'isOpen' | 'onOpen' | 'onClose'
>

// Helper util for handling a disclosure that could optionally be controlled by the parent
export const useDefaultDisclosure = (
  disclosure: DefaultDisclosure | undefined,
  props?: UseDisclosureProps,
) => {
  const defaultDisclosure = useDisclosure(props)

  return disclosure ?? defaultDisclosure
}
