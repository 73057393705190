import { Box, Flex, Image, useToast } from '@chakra-ui/react'
import type { SetProfileImageParams } from '@clerk/types'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { type ChangeEvent } from 'react'
import {
  RemoveImageModal,
  type RemoveImageModalProps,
} from './RemoveImageModal'

interface ImagePickerProps extends Omit<RemoveImageModalProps, 'onRemove'> {
  title: string
  description: string
  roundedImage?: boolean
  imageUrl: string
  setImage: (params: SetProfileImageParams) => Promise<unknown>
  hasImage: boolean
  buttonText: string
  toastUploadedMessage: string
  toastRemovedMessage: string
}

export const ImagePicker: React.FC<ImagePickerProps> = ({
  title,
  description,
  roundedImage,
  imageUrl,
  setImage,
  hasImage,
  buttonText,
  removeDescription,
  removeTitle,
  toastUploadedMessage,
  toastRemovedMessage,
}) => {
  const toast = useToast()

  const uploadPhotoChangeHandler = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0]

    if (file) {
      await setImage({ file })
      toast({
        title: toastUploadedMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
      event.target.value = ''
    }
  }

  const removePhotoHandler = async () => {
    if (hasImage) {
      await setImage({ file: null })
      toast({
        title: toastRemovedMessage,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  return (
    <Box>
      <Box mb={4}>
        <Typography fontSize="md" lineHeight={6} color="gray.900" mb={2}>
          {title}
        </Typography>
        <Typography fontSize="sm" lineHeight={5} color="gray.800">
          {description}
        </Typography>
      </Box>
      <Flex alignItems="center" gap={6}>
        <Image
          src={imageUrl || ''}
          alt={title}
          boxSize="96px"
          objectFit="contain"
          borderRadius={roundedImage ? 'full' : 4}
        />
        <Flex direction="column" alignItems="center" gap={2}>
          <Button
            variant="outline"
            size="sm"
            colorScheme="gray"
            as="label"
            htmlFor="fileInput"
            cursor="pointer"
            px={2}
          >
            {buttonText}
          </Button>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={uploadPhotoChangeHandler}
          />
          {hasImage && (
            <RemoveImageModal
              onRemove={removePhotoHandler}
              removeTitle={removeTitle}
              removeDescription={removeDescription}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
