import { z } from 'zod'

export enum GLOBAL_FILTER {
  STOREFRONT = 'merchantSite',
  CHANNEL = 'channel',
  MARKET = 'market',
  BRAND = 'brand',
  CATEGORY = 'category',
  GENDER = 'gender',
  WAREHOUSE = 'warehouse',
  FUNNEL_CAMPAIGN = 'funnelCampaign',
  MATERIAL = 'material',
  COLLECTION = 'collection',
  CUSTOM_ATTRIBUTE_1 = 'customAttribute1',
}

export type GlobalFilterId = GLOBAL_FILTER

export const GlobalFilterSchema = z.object({
  selected: z.array(z.string()).optional().default([]),
})

export type GlobalFilter = z.infer<typeof GlobalFilterSchema>

export const globalFilters: Record<GlobalFilterId, GlobalFilter> = {
  [GLOBAL_FILTER.STOREFRONT]: {
    selected: [],
  },
  [GLOBAL_FILTER.CHANNEL]: {
    selected: [],
  },
  [GLOBAL_FILTER.MARKET]: {
    selected: [],
  },
  [GLOBAL_FILTER.BRAND]: {
    selected: [],
  },
  [GLOBAL_FILTER.CATEGORY]: {
    selected: [],
  },
  [GLOBAL_FILTER.GENDER]: {
    selected: [],
  },
  [GLOBAL_FILTER.WAREHOUSE]: {
    selected: [],
  },
  [GLOBAL_FILTER.CUSTOM_ATTRIBUTE_1]: {
    selected: [],
  },
  [GLOBAL_FILTER.MATERIAL]: {
    selected: [],
  },
  [GLOBAL_FILTER.COLLECTION]: {
    selected: [],
  },
  [GLOBAL_FILTER.FUNNEL_CAMPAIGN]: {
    selected: [],
  },
}
