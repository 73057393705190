import { useTeamReports } from 'graphql/reports/useTeamReports'
import { useTeamById } from 'graphql/teams/useTeamById'
import { useParams } from 'react-router-dom'
import { NotFound } from 'shared/NotFound'
import {
  useNewReportState,
  useSetAnalyticsConfigAtom,
  useSetReportStateAtom,
} from '../hooks/useReportState'
import { ReportListTable } from './shared/ReportListTable'

export const TeamReportsView = () => {
  const { teamId = '' } = useParams()
  const { team, query: teamQuery } = useTeamById(teamId)
  const { reports, query } = useTeamReports(teamId)
  const isLoading = query.loading || teamQuery.loading
  const setReportState = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()
  const newReportState = useNewReportState()

  if (!teamQuery.loading && (!teamId || !team)) return <NotFound />

  return (
    <ReportListTable
      pageTitle={team?.name}
      reports={reports}
      isLoading={isLoading}
      key={teamId}
      addReportCallback={() => {
        if (!team) return

        setReportState({
          ...newReportState,
          visibility: [team],
        })
        setAnalyticsConfigAtom(undefined)
      }}
    />
  )
}
