import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'
import { focusAtom } from 'jotai-optics'
import { v4 as uuid } from 'uuid'
import {
  type Dashboard,
  DashboardsReducerActionEnum,
  type DashboardState,
  type DashboardsReducerAction,
  type DashboardLayout,
  type LayoutReducerAction,
  LayoutReducerActionEnum,
} from './types'

const DASHBOARD_STATE_KEY = 'dema-custom-dashboard-state'

const internalDashboardStateAtom = atomWithStorage<DashboardState>(
  DASHBOARD_STATE_KEY,
  {
    dashboards: [
      {
        id: uuid(),
        name: 'My first dashboard',
        layout: { order: [], rows: {} },
      },
    ],
  },
  undefined,
  { getOnInit: true }, // needed to make it get value on first render
)

const dashboardsStateFocus = focusAtom(internalDashboardStateAtom, (optic) =>
  optic.prop('dashboards'),
)

// Atom for setting selected dashboard id
export const selectedDashboardIdAtom = focusAtom(
  internalDashboardStateAtom,
  (optic) => optic.prop('selectedDashboardId'),
)
// Atom for getting the dashboards state and adding/removing dashboards
export const dashboardsState = atom(
  (get) => {
    return get(dashboardsStateFocus)
  },
  (get, set, action: DashboardsReducerAction) => {
    set(
      dashboardsStateFocus,
      dashboardsReducer(get(dashboardsStateFocus), action),
    )
  },
)

const dashboardsReducer = (
  prev: Dashboard[],
  action: DashboardsReducerAction,
): Dashboard[] => {
  if (action.type === DashboardsReducerActionEnum.CREATE)
    return [...prev, action.payload]
  if (action.type === DashboardsReducerActionEnum.DELETE)
    return prev.filter((d) => d.id !== action.payload.id)

  throw new Error('unknown action type')
}

// Atom for getting selected dashboard and updating it
export const selectedDashboardAtom = atom(
  (get) => {
    const { selectedDashboardId, dashboards } = get(internalDashboardStateAtom)

    return (dashboards.find((d) => d.id === selectedDashboardId) ??
      dashboards[0]) as Dashboard | undefined
  },
  (get, set, action: Partial<Dashboard> & { id: string }) => {
    const { dashboards } = get(internalDashboardStateAtom)

    const updatedDashboards = dashboards.map((dashboard) =>
      dashboard.id === action.id ? { ...dashboard, ...action } : dashboard,
    )

    set(dashboardsStateFocus, updatedDashboards)
  },
)

const selectedDashboardLayoutAtom = focusAtom(selectedDashboardAtom, (optic) =>
  optic.optional().prop('layout'),
)

export const selectedDashboardLayoutState = atom(
  (get) => {
    return get(selectedDashboardLayoutAtom)
  },
  (get, set, action: LayoutReducerAction) => {
    const selectedDashboardLayout = get(selectedDashboardLayoutAtom)

    if (!selectedDashboardLayout) {
      return
    }

    set(
      selectedDashboardLayoutAtom,
      layoutReducer(selectedDashboardLayout, action),
    )
  },
)

const layoutReducer = (
  prev: DashboardLayout,
  action: LayoutReducerAction,
): DashboardLayout => {
  if (action.type === LayoutReducerActionEnum.ADD_WIDGET) {
    const { rowId, widget } = action.payload

    if (!rowId) {
      // Add a new row if there is no rowId
      const newId = uuid()
      const newRow = {
        widgets: [widget],
        height: 300,
      }

      return {
        order: [...prev.order, newId],
        rows: {
          ...prev.rows,
          [newId]: newRow,
        },
      }
    }

    const newRows = {
      ...prev.rows,
      [rowId]: {
        ...prev.rows[rowId],
        widgets: [...prev.rows[rowId].widgets, widget],
      },
    }

    return { ...prev, rows: newRows }
  }
  if (action.type === LayoutReducerActionEnum.REMOVE_WIDGET) {
    const { rowId, widgetId } = action.payload
    const newWidgets = prev.rows[rowId].widgets.filter((w) => w.id !== widgetId)

    if (newWidgets.length === 0) {
      // Remove the row if there are no widgets
      const newRows = { ...prev.rows }

      delete newRows[rowId]

      return {
        order: prev.order.filter((r) => r !== rowId),
        rows: newRows,
      }
    }

    const newRows = {
      ...prev.rows,
      [rowId]: {
        ...prev.rows[rowId],
        widgets: newWidgets,
      },
    }

    return {
      ...prev,
      rows: newRows,
    }
  }

  throw new Error('unknown action type')
}
