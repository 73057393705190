import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ReportWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { transformReports } from './utils'

const TEAM_REPORTS_BY_ID_QUERY = graphql(/* GraphQL */ `
  query TeamReportsById($id: ID!) {
    viewer {
      id
      team(id: $id) {
        id
        reports {
          ...ReportWithOwnerFields
        }
      }
    }
  }
`)

const emptyArray: ReportWithOwnerFieldsFragment[] = []

export const useTeamReports = (teamId: string) => {
  const query = useQuery(TEAM_REPORTS_BY_ID_QUERY, {
    variables: {
      id: teamId,
    },
  })

  const reportsData = query.data?.viewer?.team?.reports ?? emptyArray

  const reports = useMemo(() => {
    return transformReports(reportsData)
  }, [reportsData])

  return { reports, query }
}
