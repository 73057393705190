import { Flex, type FlexProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export const CellWrapper = forwardRef(
  ({ children, sx, ...rest }: FlexProps, ref) => {
    return (
      <Flex
        px={2}
        py={1}
        sx={{
          '.ag-column-first &': {
            pl: 4,
          },
          '.ag-column-last &': {
            pr: 4,
          },
          ...sx,
        }}
        height="100%"
        width="100%"
        flexDir="column"
        {...rest}
        ref={ref}
      >
        {children}
      </Flex>
    )
  },
)

CellWrapper.displayName = 'CellWrapper'
