export const OPTIMIZATIONS_ROUTE_FRAGMENTS = {
  OPTIMIZATIONS: 'optimizations',
  MARKET: 'market',
  WEEKLY_ANALYSIS: 'weekly-analysis',
  YEARLY_ANALYSIS: 'yearly-analysis',
  PREDICTION: 'prediction',
} as const

const BASE = `/${OPTIMIZATIONS_ROUTE_FRAGMENTS.OPTIMIZATIONS}` as const

export const OPTIMIZATIONS_PAGES = {
  BASE: BASE,
  MARKET_WEEKLY_ANALYSIS: (market: string) =>
    `${BASE}/${market}/${OPTIMIZATIONS_ROUTE_FRAGMENTS.WEEKLY_ANALYSIS}` as const,
  MARKET_YEARLY_ANALYSIS: (market: string) =>
    `${BASE}/${market}/${OPTIMIZATIONS_ROUTE_FRAGMENTS.YEARLY_ANALYSIS}` as const,
  MARKET_PREDICTION: (market: string) =>
    `${BASE}/${market}/${OPTIMIZATIONS_ROUTE_FRAGMENTS.PREDICTION}` as const,
} as const
