import { type AttributionModel as GraphAttributionModel } from 'generated/graphql/graphql'
import { atom, useAtomValue } from 'jotai'

export type AttributionId = 'dema' | 'linear' | 'lastClick'

type AttributionModel = Omit<GraphAttributionModel, 'id'> & {
  id: AttributionId
}

export type NormalizedAttributionModels = Record<
  AttributionId,
  AttributionModel
>

export const DEFAULT_ATTRIBUTION: AttributionModel = {
  id: 'dema',
  label: 'Dema',
  description:
    "Dema's data-driven model uses a machine learning algorithm that customizes attribution based on customer behavior throughout the customer journey before the purchase.",
}

export const attributionModelsQueryAtom = atom<GraphAttributionModel[] | null>(
  null,
)

export const normalizedAttributionModelsAtom =
  atom<NormalizedAttributionModels>((get) => {
    const data = get(attributionModelsQueryAtom)

    return (data ?? []).reduce<NormalizedAttributionModels>((acc, model) => {
      const enhancedModel = {
        ...model,
        id: model.id as AttributionId,
      }

      acc[enhancedModel.id] = enhancedModel

      return acc
    }, {} as NormalizedAttributionModels)
  })

export const useNormalizedAttributionModels =
  (): NormalizedAttributionModels => {
    return useAtomValue(normalizedAttributionModelsAtom)
  }
