import { Flex } from '@chakra-ui/react'
import { Loader } from 'components/Loader/Loader'
import { Typography } from 'components/Typography'

export const LoadingScenario = () => {
  return (
    <Flex
      flexDirection="column"
      w="full"
      height="full"
      overflowY="auto"
      justifyContent="center"
      py={5}
      px={{ base: 10, lg: 120 }}
    >
      <Flex flexDirection="column" gap={4} maxW={330}>
        <Loader customSize={56} />
        <Flex flexDirection="column" w="full" gap={2}>
          <Typography fontSize="xl" fontWeight="light">
            Scenario is being created...
          </Typography>
          <Typography fontSize="sm">
            Please be patient while we are calculating the optimal budget
            allocation.
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}
