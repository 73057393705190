import { graphql } from 'generated/graphql'

export const IntegrationFieldsFragment = graphql(/* GraphQL */ `
  fragment IntegrationFields on Integration {
    id
    serviceId
    connected
    config
    costConfig
    __typename
  }
`)
