import { createStore } from 'jotai'

let store: ReturnType<typeof createStore> | undefined

export const getStore = () => {
  if (!store) {
    store = createStore()
  }

  return store
}
