import { Flex, VStack } from '@chakra-ui/react'
import type { UserResource } from '@clerk/types'
import { Typography } from 'components/Typography'
import { ChangeNameModal } from './ChangeNameModal'
import { PasswordResetModal } from './PasswordResetModal'

interface Props {
  user: UserResource
}

export const Information: React.FC<Props> = ({ user }) => (
  <Flex direction="column" gap={6}>
    <VStack spacing={2} align="start">
      <Typography fontWeight={600}>Name</Typography>
      <Typography fontSize="sm">{user.fullName}</Typography>
      <ChangeNameModal />
    </VStack>
    <VStack spacing={2} align="start">
      <Typography fontWeight={600}>Email</Typography>
      <Typography fontSize="sm">
        {user.primaryEmailAddress?.emailAddress}
      </Typography>
    </VStack>

    <VStack spacing={2} align="start">
      <Typography fontWeight={600}>Password</Typography>
      <PasswordResetModal />
    </VStack>
  </Flex>
)
