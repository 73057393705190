const grey = {
  50: '#FAFAF8',
  100: '#F4F4F1',
  200: '#E8E8E3',
  300: '#CFCFC9',
  400: '#B2B2AC',
  500: '#8F8F8A',
  600: '#757570',
  700: '#54544C',
  800: '#3c3c38',
  900: '#272725',
  950: '#171714',
} as const

const blue = {
  50: '#EDF5FF',
  100: '#D1E1FF',
  200: '#A4BEFF',
  300: '#7B9FFF',
  400: '#4E7FFF',
  500: '#1C58FD',
  600: '#0040EB',
  700: '#002FBD',
  800: '#00218F',
  900: '#001666',
} as const

const chartColors = {
  cyan: {
    50: '#effdff',
    100: '#d1f7ff',
    200: '#adeeff',
    300: '#85e5ff',
    400: '#57daff',
    500: '#28c9fa',
    600: '#12acdb',
    700: '#078fb8',
    800: '#006e8f',
    900: '#004e66',
  },
  purple: {
    50: '#f1efff',
    100: '#d9d1ff',
    200: '#beadff',
    300: '#9f85ff',
    400: '#7c57ff',
    500: '#5928fa',
    600: '#4112db',
    700: '#3007b8',
    800: '#21008f',
    900: '#180066',
  },
  magenta: {
    50: '#f9efff',
    100: '#f0d1ff',
    200: '#e7adff',
    300: '#dc85ff',
    400: '#d057ff',
    500: '#c228fa',
    600: '#a512db',
    700: '#8807b8',
    800: '#69008f',
    900: '#4b0066',
  },
  yellow: {
    50: '#fff7ed',
    100: '#ffefd1',
    200: '#ffe5a4',
    300: '#ffdb7b',
    400: '#ffce4e',
    500: '#fdc11c',
    600: '#ebab00',
    700: '#bd8e00',
    800: '#8f6e00',
    900: '#665000',
  },
} as const

export const chartColorKeys = Object.keys(
  chartColors,
) as (keyof typeof chartColors)[]

export const colorTheme = {
  dema: {
    50: '#FCFFEB',
    100: '#F8FFD1',
    200: '#F0FFA3',
    300: '#E6FF80',
    400: '#D8FF43',
    500: '#C2F806',
    600: '#9AC803',
    700: '#7AA200',
    800: '#5E7F00',
    900: '#2C3800',
  },
  primary: blue,
  blue,
  gray: grey,
  grey,
  green: {
    50: '#E9FEE1',
    100: '#D2FCC5',
    200: '#B9EFA9',
    300: '#94E37D',
    400: '#63D148',
    500: '#409F28',
    600: '#378224',
    700: '#277614',
    800: '#195C0A',
    900: '#0C3602',
  },
  orange: {
    50: '#FEEFE1',
    100: '#FDDFC4',
    200: '#FCC89C',
    300: '#F7AB66',
    400: '#FA8D2D',
    500: '#E96D02',
    600: '#B45100',
    700: '#8F4200',
    800: '#703400',
    900: '#472100',
  },
  red: {
    50: '#FEE2E7',
    100: '#FAC6D0',
    200: '#F4A4B1',
    300: '#EF7187',
    400: '#E43F59',
    500: '#CD1D35',
    600: '#A3142A',
    700: '#880C1C',
    800: '#680815',
    900: '#48050D',
  },
  ...chartColors,
  black: '#000000',
  white: '#ffffff',
} as const

type ChartColorTheme = keyof typeof chartColors
type ChartColorShadeValue = keyof (typeof colorTheme)[ChartColorTheme]

// We need to exclude the chart colors here as they have different shade values, so the types will be messed up
export type ColorValue = Exclude<keyof typeof colorTheme, ChartColorTheme>
type ShadeValue = keyof (typeof colorTheme)[ColorValue]
type ChartColorHexValue =
  (typeof colorTheme)[ChartColorTheme][ChartColorShadeValue]
export type HexColor =
  | (typeof colorTheme)[ColorValue][ShadeValue]
  | ChartColorHexValue

export type ColorType =
  // Gray color palette has different shade range
  | `${Exclude<ColorValue, 'grey' | 'gray' | ChartColorTheme>}.${ShadeValue}`
  | `grey.${keyof typeof grey}`
  | `gray.${keyof typeof grey}`
  | `${ChartColorTheme}.${ChartColorShadeValue}`
  | 'white'
  | 'black'
  | 'transparent'
  | 'inherit'

const additionalColors = ['white', 'black']

export const getColor = (
  colorType: ColorType | undefined,
): string | undefined => {
  if (!colorType) {
    return undefined
  }
  if (additionalColors.includes(colorType)) {
    return colorType
  }

  const [colorKey, shadeKey] = colorType.split('.') as [ColorValue, ShadeValue]
  const color = colorTheme[colorKey]

  if (color) {
    return color[shadeKey]
  }

  return undefined
}

export const colorToRgba = (color: ColorType, opacity: number) => {
  const hex = getColor(color)

  if (!hex) return undefined
  const hexWithoutHash = hex.substring(1)
  const [r, g, b] = [0, 2, 4].map((offset) =>
    parseInt(hexWithoutHash.substring(offset, offset + 2), 16),
  )

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

const colorTypeMap: Record<HexColor, ColorType> = Object.entries(
  colorTheme,
).reduce<Record<string, ColorType>>((acc, [colorKey, shades]) => {
  Object.entries(shades).map(([shadeKey, hex]) => {
    acc[hex] = `${colorKey}.${shadeKey}` as ColorType
  })

  return acc
}, {})

export const hexToColorType = (hexColor: HexColor) => colorTypeMap[hexColor]
