import { useMutation } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import { type CreateTeamInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { MERCHANT_TEAMS_QUERY } from 'graphql/teams/useMerchantTeams'
import { set } from 'lodash-es'
import { useNavigate } from 'react-router'

const CREATE_TEAM_MUTATION = graphql(/* GraphQL */ `
  mutation CreateTeamMutation($payload: CreateTeamInput!) {
    createTeam(params: $payload) {
      ...Teams_TeamFields
    }
  }
`)

type CreateTeamPayload = Omit<
  CreateTeamInput,
  'preferredDimensions' | 'preferredMetrics'
>

export const useCreateTeam = () => {
  const [mutation, state] = useMutation(CREATE_TEAM_MUTATION)
  const navigate = useNavigate()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const createTeam = async (payload: CreateTeamPayload) => {
    const newTeam = {
      ...payload,
      preferredMetrics: [],
      preferredDimensions: [],
    }

    return mutation({
      variables: {
        payload: newTeam,
      },
      optimisticResponse: {
        createTeam: {
          id: 'optimistic',
          __typename: 'Team',
          members: [],
          ...newTeam,
        },
      },
      update: (cache, { data }) => {
        if (!data) return

        cache.updateQuery({ query: MERCHANT_TEAMS_QUERY }, (queryData) => {
          if (!queryData?.viewer) return queryData

          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.merchant.teams', [
            ...queryData.viewer.merchant.teams,
            data.createTeam,
          ])

          return queryDataClone
        })
      },
      onCompleted: ({ createTeam }) => {
        navigate(`/settings/teams/${createTeam.id}/general`)
        trackEvent({
          eventName: 'Team Created',
          eventProperties: {
            teamId: createTeam.id,
            teamName: createTeam.name,
          },
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Could not create team. Please try again',
        })
      },
    })
  }

  return [createTeam, state] as const
}
