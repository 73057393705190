import { useOrganization } from '@clerk/clerk-react'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { useOrganisationMembers } from 'graphql/useOrganisationMembers'
import { MembersTable } from './MembersTable'

export const OrganisationMembersView = () => {
  // 500 is the maximum page size for the API on clerks side, anything above that will be treated as 10
  const { memberships, invitations } = useOrganization({
    memberships: { pageSize: 500 },
    invitations: { pageSize: 500 },
  })
  const { members } = useOrganisationMembers()

  return (
    <TitleLayoutView
      title="Members"
      description="Manage organisation members and their access"
    >
      <MembersTable
        members={members}
        memberships={memberships}
        invitations={invitations}
        isLoading={!members?.length} // the members array is only empty when its loading
      />
    </TitleLayoutView>
  )
}
