import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { type FC, type ReactElement, cloneElement } from 'react'

interface GenericPickerModalProps {
  modalButton: ReactElement
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  setToDefaultState?: () => void
  children: React.ReactNode
  initialFocusRef?: React.RefObject<HTMLElement> | undefined
}

export const GenericPickerModal: FC<GenericPickerModalProps> = ({
  modalButton,
  isOpen,
  onClose,
  onOpen,
  setToDefaultState,
  children,
  initialFocusRef,
}) => {
  return (
    <>
      {cloneElement(modalButton, {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
          modalButton?.props.onClick?.(e)
          setToDefaultState?.()
          onOpen()
        },
      })}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        returnFocusOnClose={false}
        size="sm"
        initialFocusRef={initialFocusRef}
      >
        <ModalOverlay />
        <ModalContent>{children}</ModalContent>
      </Modal>
    </>
  )
}
