import {
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { CopyCSVLinkModal } from 'features/productFeed/shared/CopyCSVLinkModal'
import { EmptyState } from 'features/productFeed/shared/EmptyState'
import { useState } from 'react'
import { useSegmentationSets } from '../graphql/useSegmentationSets'
import { AddSegmentationSetModal } from './AddSegmentationSetModal'
import { SegmentRow } from './SegmentRow'

export const SegmentationList: React.FC = () => {
  const { segmentationSets, query } = useSegmentationSets()
  const [createdSegmentationSet, setCreatedSegmentationSet] = useState<
    { name: string; id: string } | undefined
  >(undefined)
  const csvLinkModalDisclosure = useDisclosure()

  if (query.loading) {
    return (
      <Table mt={6}>
        <Tbody>
          <SkeletonTableRows columns={3} rows={5} />
        </Tbody>
      </Table>
    )
  }

  if (segmentationSets.length === 0) {
    return (
      <EmptyState
        imageSrc="/images/Layers.svg"
        title="Product feeds"
        description="A product feed defines the rules for how your products should automatically be assigned to different product segments. You can create multiple sets to use for different product feeds (e.g. one product feed for Google, another for Facebook)."
        modalButton={
          <AddSegmentationSetModal
            modalButton={<Button size="sm">Create new product feed</Button>}
            onSegmentationSetCreated={(segmentation) => {
              setCreatedSegmentationSet(segmentation)
              csvLinkModalDisclosure.onOpen()
            }}
          />
        }
      />
    )
  }

  return (
    <>
      <Table mt={6}>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Last updated</Th>
            <Th>
              <Box srOnly>Actions</Box>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {segmentationSets.map((segmentationSet) => (
            <SegmentRow
              key={segmentationSet.id}
              segmentationSet={segmentationSet}
            />
          ))}
        </Tbody>
      </Table>

      <CopyCSVLinkModal
        isOpen={csvLinkModalDisclosure.isOpen}
        onClose={csvLinkModalDisclosure.onClose}
        segmentationSetName={createdSegmentationSet?.name ?? ''}
        segmentationSetId={createdSegmentationSet?.id ?? ''}
      />
    </>
  )
}
