import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type AnalyticsConfig } from './types'

export const ANALYTICS_CONFIG_BY_ID_QUERY = graphql(/* GraphQL */ `
  query AnalyticsConfigById($id: ID!) {
    viewer {
      id
      analyticsConfig(id: $id) {
        ...AnalyticsConfigFields
      }
    }
  }
`)

export const useAnalyticsConfigById = (configId: string | undefined | null) => {
  const query = useQuery(ANALYTICS_CONFIG_BY_ID_QUERY, {
    variables: {
      id: configId ?? '',
    },
    skip: !configId,
  })

  return {
    analyticsConfig: query.data?.viewer?.analyticsConfig as unknown as
      | AnalyticsConfig
      | undefined,
    query,
  }
}
