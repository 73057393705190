import { Flex } from '@chakra-ui/react'
import { type FC, type ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const ReportHeaderWrapper: FC<Props> = ({ children }) => {
  return (
    <Flex
      px={6}
      py={2}
      justifyContent="space-between"
      bg="white"
      borderBottom="solid 1px"
      borderBottomColor="gray.200"
      alignItems="baseline"
      gap={{ base: 10, sm: 20 }}
    >
      {children}
    </Flex>
  )
}
