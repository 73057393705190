import { type ColorMode } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { colorTheme } from 'ui/theme/colors'

interface Props {
  isDisabled?: boolean
  label: string
  colorMode?: ColorMode
  htmlFor?: string
}

const getLabelColor = (colorMode: ColorMode, isDisabled?: boolean) => {
  if (isDisabled) return colorTheme.grey[400]
  if (colorMode === 'dark') {
    return colorTheme.grey[100]
  }

  return colorTheme.grey[700]
}

export const Label = ({
  isDisabled,
  label,
  colorMode = 'light',
  htmlFor,
}: Props) => {
  const labelColor = getLabelColor(colorMode, isDisabled)

  return (
    <Typography
      as="label"
      fontSize="xs"
      mb={1}
      color={labelColor}
      htmlFor={htmlFor}
    >
      {label}
    </Typography>
  )
}
