import { Box, Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import {
  useAnalyticsTableState,
  useSetTableState,
} from 'features/reports/hooks/useAnalyticsTableState'
import { type ExtendedSortDirection } from 'graphql/reports/types'
import { forwardRef, useCallback } from 'react'
import { CellWrapper } from '../cell/CellWrapper'
import { type AnalyticsTableHeaderProps } from '../types'
import { HeaderMenuWrapper } from './HeaderMenuWrapper'
import { HeaderText } from './HeaderText'
import { HeaderTotalSection } from './HeaderTotalSection'

export const AnalyticsTableHeader = forwardRef(
  (
    {
      aggregatedData,
      column,
      api,
      metric,
      dimension,
      displayName,
      attributionLabel,
      isCompare,
      isLoading,
      compareUnit,
      progressSort,
    }: AnalyticsTableHeaderProps,
    ref,
  ) => {
    const columnId = column?.getColId()
    const state = useAnalyticsTableState()
    const sortValue =
      state.find(({ id, sort }) => !!sort && id === columnId)?.sort ?? null
    const { setTableSorting, setTableColumnPinning } = useSetTableState()

    const setSortOrder = useCallback(
      (sort: ExtendedSortDirection) => {
        setTableSorting(columnId, sort)
      },
      [columnId, setTableSorting],
    )

    const onHeaderClick = () => {
      switch (sortValue) {
        case 'ascCompare': {
          setSortOrder(null)
          break
        }

        case 'descCompare': {
          setSortOrder('ascCompare')
          break
        }

        default: {
          progressSort()
          const sort = column.getSort() ?? null

          setSortOrder(sort)
        }
      }
    }

    const toggleFreezeColumn = () => {
      const isPinned = column.isPinned()

      api.setColumnsPinned([columnId], isPinned ? null : 'left')

      setTableColumnPinning(columnId, !isPinned)
    }

    return (
      <CellWrapper gap={2} justifyContent="space-between" py={3} ref={ref}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={metric ? 'row-reverse' : 'row'}
          _hover={{
            '& .icon': {
              visibility: 'visible',
            },
          }}
        >
          <Box onClick={onHeaderClick} as="button">
            <HeaderText
              isMetric={!!metric}
              showIcon
              sortDirection={sortValue}
              description={dimension?.description || metric?.description}
            >
              {displayName}
            </HeaderText>
          </Box>
          <HeaderMenuWrapper
            setSortOrder={setSortOrder}
            columnId={columnId}
            displayName={displayName}
            isMetric={!!metric}
            isFrozen={column.isPinned()}
            toggleFreezeState={toggleFreezeColumn}
          />
        </Flex>
        <Flex flexDir="column" gap={1}>
          {attributionLabel ? (
            <Typography
              textAlign="end"
              noOfLines={1}
              color="grey.900"
              whiteSpace="nowrap"
              display="block"
              fontSize="xs"
            >
              {attributionLabel} model
            </Typography>
          ) : null}

          <HeaderTotalSection
            isLoading={isLoading}
            metric={metric}
            isCompare={isCompare}
            aggregatedData={aggregatedData}
            compareUnit={compareUnit}
          />
        </Flex>
      </CellWrapper>
    )
  },
)

AnalyticsTableHeader.displayName = 'AnalyticsTableHeader'
