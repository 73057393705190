import {
  Box,
  CardBody,
  CardHeader,
  Card as ChakraCard,
  Flex,
  forwardRef,
  Skeleton,
  type As,
} from '@chakra-ui/react'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { Typography } from 'components/Typography'
import { type ReportResult } from 'graphql/reports/types'
import { useReportById } from 'graphql/reports/useReportById'
import { type DashboardWidget } from '../../atoms/types'
import { DashboardWidgetChart } from '../DashboardWidgetChart'

type Props = { widget: DashboardWidget; height: number }

export const CustomDashboardWidget = forwardRef<Props, As>(
  ({ widget: { configId }, height }, ref) => {
    const { report, query } = useReportById(configId)

    return (
      <ChakraCard
        rounded="none"
        h={height}
        ref={ref}
        maxH={height}
        minH={height}
        p={4}
        gap={4}
        boxShadow="base"
        overflow="hidden"
      >
        {report?.name && (
          <CardHeader p={0}>
            <Flex justifyContent="space-between" alignItems="start">
              <Box>
                <BaseWidgetTitle title={report.name} />
              </Box>
            </Flex>
          </CardHeader>
        )}
        <CardBody as={Flex} p={0} alignItems="center">
          {query.loading && <Skeleton w="100%" h="100%" />}
          {!report && !query.loading && <WidgetErrorState />}
          {report && (
            <DashboardWidgetChart report={report as unknown as ReportResult} />
          )}
        </CardBody>
      </ChakraCard>
    )
  },
)

CustomDashboardWidget.displayName = 'CustomDashboardWidget'

export const BaseWidgetTitle = ({ title }: { title: string }) => {
  return (
    <Flex gap={2} alignItems="center" flexWrap="wrap" mb={2}>
      <Typography fontSize={'lg'} lineHeight={7} color="gray.900">
        {title}
      </Typography>
    </Flex>
  )
}
