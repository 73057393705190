import { Flex } from '@chakra-ui/react'
import { InfoTooltip } from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'
import {
  type ContributionData,
  modelPerformanceDefaultId,
} from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type FC } from 'react'

interface Props {
  channelContribution: ContributionData[number] | undefined
}

export const SpendAnalysisCell: FC<Props> = ({ channelContribution }) => {
  return (
    <>
      {channelContribution?.spendAnalysis && (
        <Flex gap={2}>
          <Typography
            fontSize="xs"
            color="grey.800"
            textAlign="start"
            lineHeight={4}
          >
            {channelContribution.spendAnalysis.name}
          </Typography>
          <InfoTooltip
            tooltipText={channelContribution.spendAnalysis.description}
          />
          {channelContribution.modelPerformance &&
            channelContribution.modelPerformance.id !==
              modelPerformanceDefaultId && (
              <InfoTooltip
                tooltipText={channelContribution.modelPerformance.description}
                iconProps={{ color: 'orange.500' }}
                iconName="ExclamationTriangleIcon"
              />
            )}
        </Flex>
      )}
    </>
  )
}
