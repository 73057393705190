import { Box } from '@chakra-ui/react'
import { Typography } from 'components/Typography'

export const EmptyTableInfo = () => {
  return (
    <Box
      px={10}
      py={6}
      pos="sticky"
      left={0}
      textAlign="center"
      _hover={{ bg: 'unset' }}
    >
      <Typography fontWeight={600}>No data available</Typography>
      <Typography>
        Try running a new query with other parameters or removing some filters
      </Typography>
    </Box>
  )
}
