import { type ConversationMessageFieldsFragment } from 'generated/graphql/graphql'
import { OPTIMISTIC_IDS } from '../consts'
import { ConversationFieldsFragment } from '../graphql/fragments'
import { type useCreateConversation } from '../graphql/useCreateConversation'
import { type useCreateConversationMessage } from '../graphql/useCreateConversationMessage'
import { CONVERSATION_BY_ID_QUERY } from '../graphql/useGetConversationById'

export const addOptimisticConversationToCache = async ({
  message,
  onSetMessage,
  onSetConversationId,
  createConversation,
  optimisticNewMessage,
  viewerId,
}: {
  conversationId: string | null
  message: string
  onSetMessage: (id: string) => void
  optimisticNewMessage: ConversationMessageFieldsFragment
  onSetConversationId: (id: string | null) => void
  createConversation: ReturnType<typeof useCreateConversation>[0]
  createConversationMessage: ReturnType<typeof useCreateConversationMessage>[0]
  viewerId: string
}) => {
  await createConversation({
    payload: {
      text: message,
    },
    options: {
      optimisticResponse: {
        createConversation: {
          __typename: 'Conversation' as const,
          id: OPTIMISTIC_IDS.CONVERSATION,
          title: 'New Conversation',
          messages: [optimisticNewMessage],
        },
      },
      update: (cache, { data }) => {
        if (data?.createConversation) {
          cache.writeFragment({
            id: cache.identify(data.createConversation),
            data: data.createConversation,
            fragment: ConversationFieldsFragment,
            fragmentName: 'ConversationFields',
          })

          cache.updateQuery(
            {
              query: CONVERSATION_BY_ID_QUERY,
              variables: { id: data.createConversation.id },
            },
            (queryData) => {
              return {
                ...queryData,
                viewer: {
                  id: viewerId,
                  ...queryData?.viewer,
                  conversation: data.createConversation,
                },
              }
            },
          )

          onSetConversationId(data.createConversation.id)
        }
      },
      onError: () => {
        onSetConversationId(null)

        onSetMessage(message)
      },
    },
  })
}
