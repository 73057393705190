import { Box, Divider, Skeleton } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import { ImagePicker } from 'components/ImagePicker/ImagePicker'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { ChangeName } from './ChangeName'

export const OrganisationGeneralView: React.FC = () => {
  const res = useOrganization()
  const [trackEvent] = useTrackEvent()

  if (!res.isLoaded || !res.organization)
    return (
      <Box p={6}>
        <Skeleton h={10} w={40} mb={2} />
        <Skeleton h={5} w={80} mb={6} />

        <Skeleton h={10} w={40} mb={2} />
        <Skeleton h={5} w={80} />
      </Box>
    )

  const { organization } = res

  return (
    <TitleLayoutView
      title="Organisation"
      description="Manage your organisation settings."
    >
      <ImagePicker
        imageUrl={organization.imageUrl}
        setImage={async (params) => {
          organization.setLogo(params)
          trackEvent({
            eventName: 'Organisation Logo Changed',
            eventProperties: {},
          })
        }}
        hasImage={organization.hasImage}
        title="Logo"
        description="Upload a logo for your organisation."
        buttonText="Upload logo"
        removeTitle="Remove logo"
        removeDescription="The logo will be deleted permanently"
        toastUploadedMessage="Logo uploaded"
        toastRemovedMessage="Logo removed"
      />

      <Divider my={10} />

      <ChangeName organization={organization} />
    </TitleLayoutView>
  )
}
