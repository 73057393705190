import {
  type COMPARE_DYNAMIC_DATE_ID,
  type DYNAMIC_DATE_ID,
} from 'constants/getDatePresets'
import { Skeleton } from '@chakra-ui/react'
import { getWeek } from 'date-fns'
import { type DateState } from 'features/reports/hooks/useDateState'
import { type ReportResult } from 'graphql/reports/types'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCBoost from 'highcharts/modules/boost'
import HCExporting from 'highcharts/modules/exporting'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { useMemo, useRef } from 'react'
import { useChartAnalyticsFromReport } from '../hooks/useChartAnalyticsFromReport'
import { useChartOptionsFromReport } from '../hooks/useChartOptionsFromReport'

HCExporting(Highcharts)
HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HCBoost(Highcharts)

Highcharts.dateFormats.W = function (timestamp) {
  return String(
    getWeek(new Date(timestamp), {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    }),
  )
}

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
})

type Props = {
  report: ReportResult
}
export const DashboardWidgetChart = ({ report }: Props) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null)

  const { chartData, loading } = useChartAnalyticsFromReport(report)

  const isLoading = loading

  const dateState: DateState = useMemo(
    () => ({
      dateRange: [
        new Date(report.analyticsConfig.startDate),
        new Date(report.analyticsConfig.endDate),
      ],
      compareDateRange: [
        report.analyticsConfig.compareStartDate
          ? new Date(report.analyticsConfig.compareStartDate)
          : null,
        report.analyticsConfig.compareEndDate
          ? new Date(report.analyticsConfig.compareEndDate)
          : null,
      ],
      isCompare:
        !!report.analyticsConfig.compareStartDate &&
        !!report.analyticsConfig.compareEndDate,
      dynamicDate: report.analyticsConfig.dynamicDate
        ? (report.analyticsConfig.dynamicDate as DYNAMIC_DATE_ID)
        : undefined,
      compareDynamicDate: report.analyticsConfig.compareDynamicDate
        ? (report.analyticsConfig.compareDynamicDate as COMPARE_DYNAMIC_DATE_ID)
        : undefined,
    }),
    [
      report.analyticsConfig.compareDynamicDate,
      report.analyticsConfig.compareEndDate,
      report.analyticsConfig.compareStartDate,
      report.analyticsConfig.dynamicDate,
      report.analyticsConfig.endDate,
      report.analyticsConfig.startDate,
    ],
  )

  const options = useChartOptionsFromReport(
    chartData,
    isLoading,
    report.analyticsConfig.chart,
    dateState,
  )

  if (isLoading) {
    return <Skeleton w="100%" h="100%" />
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
      containerProps={{ style: { width: '100%', height: '100%' } }}
    />
  )
}
