import { Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type React from 'react'

interface EmptyDataProps {
  label: string
}

export const EmptyData: React.FC<EmptyDataProps> = ({ label }) => {
  return (
    <Flex
      alignItems="center"
      py={16}
      flexDir="column"
      gap={10}
      justifyItems="center"
    >
      <Image src="/images/EmptyData.svg" alt="No integrations" boxSize={200} />
      <Typography>{label}</Typography>
    </Flex>
  )
}
