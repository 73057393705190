import { type QueryHookOptions } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type StatisticsQueryQuery,
  type StatisticsQueryQueryVariables,
} from 'generated/graphql/graphql'
import { useQueryWithRetry } from 'hooks/useQueryWithRetry'
import { keyBy } from 'lodash-es'
import { useMemo } from 'react'
import { type Statistic, type NormalizedStatistic } from './types'

const STATISTICS_QUERY = graphql(/* GraphQL */ `
  query StatisticsQuery($statisticsParams: StatisticsInput!) {
    viewer {
      id
      merchant {
        id
        statistics(params: $statisticsParams) {
          dimensions
          metrics
        }
      }
    }
  }
`)

const changeQueryName = (queryName: string): typeof STATISTICS_QUERY => {
  const tempDefinitions = [...STATISTICS_QUERY.definitions]

  if ('name' in tempDefinitions[0] && tempDefinitions[0].name) {
    tempDefinitions[0] = {
      ...tempDefinitions[0],
      name: { ...tempDefinitions[0].name, value: queryName },
    }
  }

  return { ...STATISTICS_QUERY, definitions: tempDefinitions }
}

const defaultStatsQueryName = 'StatsQuery'

export const useStatistics = (
  options: QueryHookOptions<
    StatisticsQueryQuery,
    StatisticsQueryQueryVariables
  >,
  queryName = '',
): {
  query: typeof query
  statistics: NormalizedStatistic[]
  queryName?: string
} => {
  const statsQuery = useMemo(
    () => changeQueryName(queryName + defaultStatsQueryName),
    [queryName],
  )

  const query = useQueryWithRetry(statsQuery, {
    ...options,
  })

  const statistics = useMemo<NormalizedStatistic[]>(
    () =>
      (query.data?.viewer?.merchant?.statistics ?? []).map(
        ({ dimensions, metrics }) => {
          return keyBy(
            (dimensions as Statistic[]).concat(metrics as Statistic[]),
            'id',
          )
        },
      ),
    [query.data],
  )

  return {
    query,
    statistics,
  }
}
