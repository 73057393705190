import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { useTeamById } from 'graphql/teams/useTeamById'
import { useParams } from 'react-router'
import { NotFound } from 'shared/NotFound'
import { DataView } from './DataView'

export const TeamDataView = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { team, query } = useTeamById(teamId ?? '')

  if (query.loading) {
    return (
      <Box p={6}>
        <Skeleton h={8} w={40} mb={2} />
        <Skeleton h={5} w={80} mb={8} />

        <Skeleton h={76} w={360} mb={4} />
        <Skeleton h={76} w={360} />
      </Box>
    )
  }

  if (query.error || !team) {
    return (
      <Flex alignItems="center" w="full">
        <NotFound />
      </Flex>
    )
  }

  // The key is needed to reset the state when swapping between teams
  return <DataView team={team} key={team.id} />
}
