import {
  type MetricFormat,
  type NormalizedStatistic,
} from 'graphql/statistics/types'
import { escape } from 'lodash-es'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { getXAxisDateFormat } from 'utils/chart/common'
import {
  SERIES_MAP_KEY,
  compareColor,
  isTimeUnit,
  normalizedTimeDimensions,
  seriesMap,
} from 'utils/chart/constants'
import {
  type GroupedChartData,
  type GroupedScatterChartData,
} from 'utils/chart/types'
import { formatMetric } from 'utils/numberFormats'
import { getChartOptionsForBarChart } from './barChart'
import {
  getChartOptionsForBaseChart,
  getGroupedChartDataForBaseChart,
} from './baseChart'
import {
  getChartOptionsForPieChart,
  getGroupedChartDataForPieChart,
} from './pieChart'
import {
  getChartOptionsForScatterChart,
  getGroupedChartDataForScatterChart,
} from './scatterChart'
import {
  type SeriesChartOptionsProps,
  type GroupedSeriesData,
  type GroupedSeriesDataProps,
} from './types'

export const tooltipFormatter = (
  xAxis: string,
  yAxis: string,
  xAxisLabel?: string,
  xAxisFormat?: MetricFormat,
  yAxisLabel?: string,
  yAxisFormat?: MetricFormat,
  colorLabel?: string,
  gColor?: string,
) =>
  function (this: Highcharts.TooltipFormatterContextObject) {
    let yValue: number | null | undefined | string = this.y

    if (yAxisFormat && this.y) {
      yValue = formatMetric(
        yAxisFormat === 'currency' ? 'integer' : yAxisFormat,
        this.y,
      )
    }
    let xValue: number | null | undefined | string = this.x

    if (xAxisFormat && this.x) {
      xValue = formatMetric(
        xAxisFormat === 'currency' ? 'integer' : xAxisFormat,
        Number(this.x),
      )
    }

    const isXAxisTimeUnit = isTimeUnit(xAxis)
    const escapedSeriesName = escape(this.series.name)
    const escapedPointName = escape(this.point.name)

    return (
      (colorLabel
        ? colorLabel + ':<br/>' + escapedSeriesName + '<br/><br/>'
        : compareColor.id === gColor
          ? compareColor.name + ':<br/>' + escapedSeriesName + '<br/><br/>'
          : '') +
      (isXAxisTimeUnit ? normalizedTimeDimensions[xAxis].name : xAxisLabel) +
      ':<br/>' +
      (isXAxisTimeUnit && this.x
        ? getXAxisDateFormat(xAxis, Number(this.x))
        : xAxisFormat
          ? xValue
          : escapedPointName) +
      '<br/><br/>' +
      yAxisLabel +
      ':<br/>' +
      yValue
    )
  }

export const getGroupLabel = (
  color: string | null,
  row: NormalizedStatistic,
) => {
  if (color) {
    if (color === compareColor.id) {
      return seriesMap[SERIES_MAP_KEY.ACTUAL]
    }

    return row[color].formattedValue
  }

  return 'no-color'
}

export const getGroupedSeriesData = ({
  series,
  ...rest
}: GroupedSeriesDataProps) => {
  return series.reduce<GroupedSeriesData>((acc, serie) => {
    const { key, type } = serie
    const props = { ...rest, serie }

    switch (type) {
      case CHART_TYPE_ID.SCATTER:
        acc[key] = getGroupedChartDataForScatterChart(props)
        break
      case CHART_TYPE_ID.PIE:
        acc[key] = getGroupedChartDataForPieChart(props)
        break
      default:
        acc[key] = getGroupedChartDataForBaseChart(props)
    }

    return acc
  }, {})
}

export const getChartOptions = ({
  series,
  groupedData,
  ...rest
}: SeriesChartOptionsProps): Highcharts.Options => {
  // Currently we don't support multiple metrics with scatter and pie chart so if we have any series with that type, we ignore the rest.
  const scatterSerie = series.find(({ type }) => type === CHART_TYPE_ID.SCATTER)

  if (scatterSerie) {
    return getChartOptionsForScatterChart({
      ...rest,
      groupedData: groupedData[scatterSerie.key] as GroupedScatterChartData,
      serie: scatterSerie,
    })
  }
  const pieSerie = series.find(({ type }) => type === CHART_TYPE_ID.PIE)

  if (pieSerie) {
    return getChartOptionsForPieChart({
      ...rest,
      groupedData: groupedData[pieSerie.key] as GroupedChartData,
      serie: pieSerie,
    })
  }
  const barSerie = series.find(
    ({ type }) =>
      type === CHART_TYPE_ID.BAR || type === CHART_TYPE_ID.STACKED_BAR,
  )

  if (barSerie) {
    return getChartOptionsForBarChart({
      ...rest,
      groupedData: groupedData[barSerie.key] as GroupedChartData,
      serie: barSerie,
    })
  }

  return getChartOptionsForBaseChart({
    ...rest,
    groupedData,
    series,
  })
}
