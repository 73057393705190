import { type HistoricalMissedPotentialAggregated } from 'generated/graphql/graphql'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type Options } from 'highcharts'
import { colorTheme, colorToRgba } from 'ui/theme/colors'
import { yAxisFormatter } from 'utils/chart/common'
import {
  CHART_PRIMARY_COLOR,
  CHART_PRIMARY_LIGHT_OFFSET_COLOR,
  staticChartOptions,
} from 'utils/chart/constants'
import { formatMetric } from 'utils/numberFormats'
import { getWaterfallTooltipFormatter } from './getWaterfallTooltipFormatter'

interface GetWaterfallChartProps {
  data: HistoricalMissedPotentialAggregated
  currency: string | undefined
}

export const getWaterfallChart = ({
  data,
  currency,
}: GetWaterfallChartProps): Options => {
  return {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      type: 'waterfall',
    },
    series: [
      {
        type: 'waterfall',
        upColor: colorTheme.green[300],
        color: colorTheme.red[300],
        borderWidth: 0,
        borderRadius: 0,
        minPointLength: 20, // for handling when value is so small
        lineColor: colorTheme.gray[400], // dotted lines between blocks
        stickyTracking: true,
        data: [
          {
            name: 'Actual profit',
            y: data.actualProfit,
            color: CHART_PRIMARY_LIGHT_OFFSET_COLOR,
            dataLabels: {
              format: formatMetric(
                METRIC_FORMAT.CURRENCY,
                data.actualProfit,
                currency,
              ),
            },
          },
          {
            name: 'Missed potential',
            y: data.missedProfit,
            // TODO change
            color: CHART_PRIMARY_COLOR,
            dataLabels: {
              style: {
                color: 'white',
              },
              format: `+ ${formatMetric(
                METRIC_FORMAT.CURRENCY,
                data.missedProfit,
                currency,
              )}`,
            },
          },
          {
            name: 'Predicted profit',
            y: data.optimalProfit,
            color: CHART_PRIMARY_LIGHT_OFFSET_COLOR,
            isSum: true,
            dataLabels: {
              format: formatMetric(
                METRIC_FORMAT.CURRENCY,
                data.optimalProfit,
                currency,
              ),
            },
          },
        ],

        dataLabels: {
          enabled: true,
          style: {
            fontSize: '14px',
            fontWeight: '600',
            color: colorTheme.gray[900],
            textOutline: 'none', // Highcharts adds a default white outline around text
          },
        },
        pointPadding: 0,
      },
    ],
    xAxis: {
      minPadding: 0,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: colorTheme.gray[600],
          fontSize: '10px',
        },
        autoRotationLimit: 300,
      },
      lineColor: colorTheme.gray[300],
      type: 'category',
      visible: true,
      id: 'channel',
      crosshair: {
        color: colorToRgba('gray.200', 0.35),
        dashStyle: 'Solid',
      },
    },
    yAxis: {
      title: {
        ...staticChartOptions.yAxis.title,
        margin: 16,
        style: {
          color: colorTheme.gray[700],
        },
        text: `Net gross profit 3 (${currency})`,
      },
      gridLineWidth: 1,
      gridZIndex: 2,
      gridLineColor: colorTheme.gray[200],
      labels: {
        style: {
          color: colorTheme.gray[600],
          fontSize: '10px',
        },
        formatter: yAxisFormatter(METRIC_FORMAT.CURRENCY),
      },
      visible: true,
      id: '1',
      opposite: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: getWaterfallTooltipFormatter({
        currency,
        yAxisLabel: 'Net gross profit 3',
      }),
      shared: true,
    },
  }
}
