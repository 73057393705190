import { Image } from '@chakra-ui/react'
import { useSignIn, useUser } from '@clerk/clerk-react'
import { isClerkAPIResponseError } from '@clerk/clerk-react/errors'
import { type OAuthStrategy } from '@clerk/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { ContainerWrapper } from 'features/auth/components/ContainerWrapper'
import { useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'

const validationSchema = z.object({
  email: z
    .string()
    .min(1, 'Email address is required')
    .email('Invalid email address'),
  password: z.string().min(1, 'Password is required'),
})

type ValidationSchema = z.infer<typeof validationSchema>

export const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false)

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
  })

  const {
    formState: { errors, isSubmitting },
    setError,
  } = methods
  const {
    isLoaded: isSignInLoaded,
    signIn,
    setActive: setActiveSession,
  } = useSignIn()
  const { isLoaded: isUserLoaded, isSignedIn } = useUser()

  if (!isSignInLoaded) return null

  const onSubmit: SubmitHandler<ValidationSchema> = async ({
    email,
    password,
  }) => {
    try {
      const result = await signIn.create({
        identifier: email,
        password,
      })

      if (result.status === 'complete') {
        await setActiveSession({ session: result.createdSessionId })
      }
    } catch (error) {
      isClerkAPIResponseError(error)
        ? captureException('Clerk API response error: ' + error)
        : captureException('Unknown sign in error: ' + error)
      setError('password', { message: 'Invalid email or password' })
    }
  }

  const signInWith = (strategy: OAuthStrategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: '/auth/sso-callback',
      redirectUrlComplete: '/auth/loading',
    })
  }

  return (
    <ContainerWrapper>
      <Form display="flex" methods={methods} onSubmit={onSubmit}>
        <Typography
          fontSize="3xl"
          fontWeight="600"
          lineHeight="36px"
          textAlign="center"
          mb={8}
          color="grey.100"
        >
          Log in
        </Typography>
        <Button
          onClick={() => signInWith('oauth_google')}
          variant="unstyled"
          py="3.5"
          px="5"
          display="flex"
          gap={4}
          justifyItems="center"
          w="full"
          border="solid 1px"
          borderColor="gray.400"
          h="unset"
          _hover={{
            opacity: 0.7,
          }}
        >
          <Image src="/images/Google.svg" alt="Google" objectFit="contain" />
          <Typography fontSize="sm" fontWeight="500" color="gray.200">
            Continue with Google
          </Typography>
        </Button>

        <Typography my={8} fontSize="xs" color="white" textAlign="center">
          or
        </Typography>

        <Input
          w="full"
          colorMode="dark"
          name="email"
          type="email"
          label="Email"
          wrapperProps={{ mb: 4 }}
          error={errors.email}
        />
        <Input
          w="full"
          colorMode="dark"
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          autoComplete="current-password"
          trailingIcon={{ name: 'VisibleIcon' }}
          onTrailingIconClick={() =>
            setShowPassword((showPassword) => !showPassword)
          }
          error={errors.password}
        />
        <Link to="/auth/forgot-password">
          <Button mt={4} variant="link" color="primary.300">
            Forgot your password?
          </Button>
        </Link>

        <Button
          mt={8}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          w="full"
          visibility={isUserLoaded && !isSignedIn ? 'visible' : 'hidden'}
        >
          Log in
        </Button>
      </Form>
    </ContainerWrapper>
  )
}
