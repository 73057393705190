import { useApolloClient, useQuery } from '@apollo/client'
import retry from 'p-retry'
import { useEffect, useMemo, useState } from 'react'

// Skip retries if polling is less than 10s
const fastPollingBreakpoint = 10000

export const useQueryWithRetry: typeof useQuery = (query, options) => {
  const client = useApolloClient()
  const queryResult = useQuery(query, options)
  const [isError, setIsError] = useState(false)
  const isFastPolling =
    !!options?.pollInterval && options.pollInterval < fastPollingBreakpoint
  const isRetrying = !!queryResult.error && !isError

  useEffect(() => {
    // Skip retry if polling is enabled
    if (queryResult.error && !isFastPolling) {
      const refetch = async () => {
        return client.refetchQueries({
          include: [query],
          onQueryUpdated: (updatedQuery) => {
            if (updatedQuery === queryResult.observable) {
              // retry only the query that is failing
              return !!updatedQuery.getLastError()
            }
          },
        })
      }

      retry(refetch, {
        retries: 5,
        randomize: true, // Mitigate thundering herd
        onFailedAttempt: (error) => {
          if (error.retriesLeft === 0) {
            setIsError(true)
          }
        },
      })
    }
  }, [client, queryResult.observable, queryResult.error, query, isFastPolling])

  // Overwrite apollo's states for retry.
  const retryQueryResult = useMemo(
    () => ({
      ...queryResult,
      // error is shown after all retries have failed
      error: isError ? queryResult.error : undefined,
      // loading is true while retrying
      loading: queryResult.loading || isRetrying,
    }),
    [queryResult, isError, isRetrying],
  )

  if (isFastPolling) {
    // Skip retry states if polling is enabled
    return queryResult
  }

  return retryQueryResult
}
