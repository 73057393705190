import { Box, GridItem, type TextProps } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type OptimizationTableColumn } from 'features/optimizations/consts'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { Fragment } from 'react/jsx-runtime'

const titleHeaderStyles: TextProps = {
  fontSize: 'sm',
  fontWeight: '600',
  lineHeight: 5,
  color: 'grey.900',
}

const subHeaderStyles: TextProps = {
  fontSize: 'xs',
  fontWeight: '600',
  lineHeight: 4,
  color: 'grey.600',
  textAlign: 'end',
}

interface Props {
  compareDateLabel: string
  currency: string | undefined
  columns: OptimizationTableColumn[]
}

export const TableHeader = ({ compareDateLabel, currency, columns }: Props) => {
  return (
    <>
      <GridItem rowSpan={2}>{/* Funnel column */}</GridItem>
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(({ label, format, includeActualValue, isMetric }) => (
          <GridItem
            key={label}
            colSpan={includeActualValue ? 2 : 1}
            py={1}
            pr={4}
            pl={1.5}
            mr={6}
          >
            <Typography
              {...titleHeaderStyles}
              textAlign={!isMetric ? 'start' : 'end'}
            >
              {label}
              {format === METRIC_FORMAT.CURRENCY ? ` (${currency})` : ''}
            </Typography>
          </GridItem>
        ))}
      {columns
        .filter(({ isHidden }) => !isHidden)
        .map(({ key, includeActualValue, isHighlighted, isMetric }) => {
          if (!isMetric) {
            return <GridItem key={key} />
          }

          return (
            <Fragment key={key}>
              {includeActualValue && (
                <GridItem pl={1.5} pr={2} py={2} borderBottom="none">
                  <Typography
                    {...subHeaderStyles}
                    color="grey.600"
                    whiteSpace="nowrap"
                  >
                    {compareDateLabel}
                  </Typography>
                </GridItem>
              )}
              <GridItem colSpan={1} borderBottom="none">
                <Box
                  mr={6}
                  pl={4}
                  pr={4}
                  py={2}
                  bg={isHighlighted ? 'gray.100' : undefined}
                >
                  <Typography
                    {...subHeaderStyles}
                    color={isHighlighted ? 'gray.700' : 'gray.600'}
                  >
                    {isHighlighted ? 'Proposed' : 'Predicted'}
                  </Typography>
                </Box>
              </GridItem>
            </Fragment>
          )
        })}
    </>
  )
}
