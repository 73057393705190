import { type ConversationMessageFieldsFragment } from 'generated/graphql/graphql'

export const OPTIMISTIC_IDS = {
  CONVERSATION: 'optimistic-conversation-id',
} as const

export const botWelcomeMessage: ConversationMessageFieldsFragment = {
  id: 'welcome-message',
  author: {
    id: 'copilot',
    name: 'Copilot',
    role: 'Copilot',
    __typename: 'Author' as const,
  },
  items: [
    {
      __typename: 'TextMessageItem' as const,
      id: 'welcome-message-item',
      text: 'Hi! How can I help you?',
    },
  ],
  __typename: 'Message' as const,
  createdAt: new Date().toISOString(),
}
