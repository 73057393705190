import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'

const RETURN_THRESHOLD_QUERY = graphql(/* GraphQL */ `
  query ReturnThreshold {
    viewer {
      id
      merchant {
        __typename
        id
        returnThreshold
        returnThresholdCurve
      }
    }
  }
`)

export const useReturnThreshold = () => {
  const { data, loading } = useQuery(RETURN_THRESHOLD_QUERY)

  const { returnThreshold, returnThresholdCurve } = data?.viewer?.merchant ?? {}

  return { returnThreshold, returnThresholdCurve, loading }
}
