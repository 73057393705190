import { Flex } from '@chakra-ui/react'
import { ClearAllFilterButton } from 'components/Filters/ClearAllFilterButton'
import useAddFilter from 'features/reports/hooks/filters/useAddFilter'
import {
  useFiltersState,
  useSetFiltersState,
} from 'features/reports/hooks/filters/useFiltersState'
import { useRemoveFilter } from 'features/reports/hooks/filters/useRemoveFilter'
import { useDateState } from 'features/reports/hooks/useDateState'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'
import { AddFilterButton } from './AddFilterButton'
import { DateRangePicker } from './DateRangePicker'
import { SelectedFilterChip } from './SelectedFilterChip/SelectedFilterChip'

export const ReportFiltersContainer = () => {
  const selectedFilterValues = useFiltersState()
  const setFilters = useSetFiltersState()
  const normalizedMetrics = useNormalizedMetrics()
  const { metrics } = useMetricsState()
  const { dateRange } = useDateState()
  const addFilter = useAddFilter()
  const removeFilter = useRemoveFilter()

  const metricGroups = useMemo(
    () => groupBy(metrics.map((metric) => metric.groupKey)),
    [metrics],
  )

  return (
    <Flex
      gap={2}
      justifyContent="space-between"
      flexDir="row-reverse"
      flexWrap="wrap"
      px={6}
      py={1.5}
      overflowX="auto"
    >
      <Flex justifyContent="end">
        <DateRangePicker />
      </Flex>
      <Flex
        gap={2}
        wrap="wrap"
        justifyContent="start"
        flex={1}
        alignItems="center"
        maxW="full"
      >
        {Object.entries(selectedFilterValues).map(([filterId, filters]) =>
          filters.map((filter, index) => (
            <SelectedFilterChip
              key={filterId + index}
              filter={filter}
              filterId={filterId}
              filterIndex={index}
              filters={selectedFilterValues}
              addFilter={addFilter}
              removeFilter={removeFilter}
              metricShouldShowAttribution={
                metricGroups[normalizedMetrics[filterId]?.groupKey]?.length > 1
              }
            />
          )),
        )}

        <AddFilterButton
          filters={selectedFilterValues}
          addFilter={addFilter}
          removeFilter={removeFilter}
          dateRange={dateRange}
          metrics={metrics}
        />

        {Object.keys(selectedFilterValues).length > 0 && (
          <ClearAllFilterButton onClick={() => setFilters({})} />
        )}
      </Flex>
    </Flex>
  )
}
