import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import {
  useSetAnalyticsConfigAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { useCallback } from 'react'

export const useSelectReport = () => {
  const setReportAtom = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()

  return useCallback(
    (report: ReportState) => {
      if (!report || !report.id) {
        return
      }

      setReportAtom(report)
      setAnalyticsConfigAtom(undefined)
    },
    [setReportAtom, setAnalyticsConfigAtom],
  )
}
