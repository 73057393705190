import { useEffect } from 'react'

// Page loader is defined in index.html. Changing the id here should be reflected in index.html.
// The reason for this implementation is to avoid the animation to reset when navigating between pages or when the component is rendered at different stages of the initialization flow
export const LoaderPage = () => {
  useEffect(() => {
    const pageLoader = document.getElementById('page-loader')

    if (pageLoader) {
      pageLoader.style.display = 'block'

      return () => {
        pageLoader.style.display = 'none'
      }
    }
  })

  return null
}
