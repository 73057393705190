import { ChartSortOrder } from 'graphql/reports/types'
import type Highcharts from 'highcharts'
import { difference } from 'lodash-es'
import { colorTheme } from 'ui/theme/colors'
import { chartTypes } from 'utils/chart/chartTypes'
import {
  getAxisType,
  getDateTimeLabelFormats,
  getXAxisLabel,
  getXAxisValue,
  sortByNumberOrString,
  xAxisFormatter,
  yAxisFormatter,
} from 'utils/chart/common'
import {
  AXIS_TYPE,
  SERIES_MAP_KEY,
  compareColor,
  isTimeUnit,
  normalizedTimeDimensions,
  seriesMap,
  staticChartOptions,
} from 'utils/chart/constants'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { v4 as uuid } from 'uuid'
import { getTooltipFormatter } from './ChartTooltip'
import { type BaseChartOptionsProps } from './types'

const maxNumberOfCategories = 1000

type TransformSeriesForChartProps = Omit<
  BaseChartOptionsProps,
  'dateState' | 'data' | 'timezone' | 'compareUnit'
>

const transformSerieForChart = ({
  groupedData,
  groupBy,
  serie: { type, key },
  normalizedMetrics,
  normalizedDimensions,
  xAxis,
  chartSorting,
}: TransformSeriesForChartProps) => {
  const xAxisType = AXIS_TYPE.CATEGORY
  const isTimeAxis = isTimeUnit(xAxis)

  const chartId = `${type}-${xAxis}-${key}-${groupBy}`
  const xAxisId = `${chartId}-xAxis`

  const groupedDataEntries = Object.entries(groupedData)

  let categories = Array.from(
    new Set(
      groupedDataEntries.flatMap(([, value]) => {
        const data = value ? Object.entries(value) : []

        return data.map(([key]) => String(key))
      }),
    ),
  )

  const hasDimensionGroup = !!groupBy && groupBy !== compareColor.id

  if (isTimeAxis) {
    // Sort by date ascending
    categories.sort((first, second) => sortByNumberOrString(second, first))
  } else if (
    hasDimensionGroup ||
    (chartSorting && normalizedDimensions[chartSorting.key])
  ) {
    const isMetricCompare =
      !!chartSorting && normalizedMetrics[chartSorting.key]

    const [, value] = groupedDataEntries[0]
    let data = value ? Object.entries(value) : []

    // Some categories may be missing, so find them and add a default 0
    const missingCategories = difference(
      categories,
      data.map(([key]) => key),
    )

    data = data.concat(missingCategories.map((key) => [key, 0]))

    if (isMetricCompare) {
      data.sort(([, first], [, second]) =>
        chartSorting.order === ChartSortOrder.ASC
          ? sortByNumberOrString(second ?? 0, first ?? 0)
          : sortByNumberOrString(first ?? 0, second ?? 0),
      )

      const xAxisGroupOrder = data.map(([key]) => key)

      categories.sort(
        (first, second) =>
          xAxisGroupOrder.indexOf(String(first)) -
          xAxisGroupOrder.indexOf(String(second)),
      )
    } else {
      // Sort alphabetically for dimensions
      categories.sort((first, second) =>
        chartSorting?.order === ChartSortOrder.ASC
          ? String(first).localeCompare(String(second))
          : String(second).localeCompare(String(first)),
      )
    }
  } else {
    // Metric sorting (no grouping)
    const [, value] = groupedDataEntries[0]
    let data = value ? Object.entries(value) : []

    // Some categories may be missing, so find them and add a default 0
    const missingCategories = difference(
      categories,
      data.map(([key]) => key),
    )

    data = data.concat(missingCategories.map((key) => [key, 0]))

    // Apply chartSorting logic for metrics
    if (chartSorting && normalizedMetrics[chartSorting.key]) {
      data.sort(([, first], [, second]) =>
        chartSorting.order === ChartSortOrder.ASC
          ? sortByNumberOrString(second ?? 0, first ?? 0)
          : sortByNumberOrString(first ?? 0, second ?? 0),
      )
    } else {
      // Default sorting if no chartSorting is provided or if it's not a metric
      data.sort(([, first], [, second]) =>
        sortByNumberOrString(first ?? 0, second ?? 0),
      )
    }

    categories = data.map(([key]) => key)
  }

  const transformedSerie = groupedDataEntries.map(
    ([groupName, value], index) => {
      const name = groupName
      let data = value ? Object.entries(value) : []
      // Some categories may be missing, so find them and add a default 0
      const missingCategories = difference(
        categories,
        data.map(([key]) => key),
      )

      data = data.concat(missingCategories.map((key) => [key, 0]))
      // Sort by values and get top categories
      data.sort(([first], [second]) => {
        if (!categories) return 0

        return categories.indexOf(first) - categories.indexOf(second)
      })

      const isCompareSeries =
        groupBy === compareColor.id &&
        groupName === seriesMap[SERIES_MAP_KEY.COMPARE]

      return {
        metadata: {
          isCompareSeries,
          metric: normalizedMetrics[key],
        },
        type: 'column', // Type 'bar' has problems with updating inverted field, this is an ugly fix for it
        stacking: chartTypes[type].stack ? 'normal' : undefined,
        data: data.map(([dataKey, value]) => [
          getXAxisValue(xAxisType, dataKey),
          value,
        ]),
        name,
        id: `${chartId}-${name}-${uuid()}`,
        color:
          staticChartOptions.colors[index % staticChartOptions.colors.length],
        xAxis: xAxisId,
        pointPadding: 0,
        groupPadding: 0.1,
        marker: {
          enabled: false,
        },
        legendSymbol: 'rectangle',
      }
    },
  )

  categories?.slice(0, maxNumberOfCategories)

  return { series: transformedSerie, categories }
}

export const getChartOptionsForBarChart = ({
  groupedData,
  groupBy,
  serie,
  normalizedDimensions,
  normalizedMetrics,
  xAxis,
  currency,
  dateState,
  data,
  timezone,
  compareUnit,
  chartSorting,
}: BaseChartOptionsProps) => {
  const { series: transformedSeries, categories } = transformSerieForChart({
    groupedData,
    groupBy,
    serie,
    normalizedDimensions,
    normalizedMetrics,
    xAxis,
    currency,
    chartSorting,
  })
  const { key, type } = serie
  const xAxisType = getAxisType(xAxis, !!normalizedMetrics[xAxis])
  const xAxisLabel = getXAxisLabel(
    xAxis,
    type,
    normalizedDimensions[xAxis],
    normalizedMetrics[xAxis],
    currency,
  )
  const isTimeAxis = isTimeUnit(xAxis)

  const chartId = `${type}-${xAxis}-${key}-${groupBy}`
  const xAxisId = `${chartId}-xAxis`

  return {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      inverted: true,
      zooming: { type: 'xy' } as Highcharts.ChartZoomingOptions,
    },
    xAxis: [
      {
        ...staticChartOptions.xAxis,
        type: xAxisType,
        dateTimeLabelFormats: isTimeAxis
          ? getDateTimeLabelFormats(xAxis)
          : undefined,
        categories,
        title: {
          ...staticChartOptions.xAxis.title,
          text: isTimeAxis ? normalizedTimeDimensions[xAxis].name : xAxisLabel,
        },
        visible: true,
        labels: {
          ...staticChartOptions.xAxis.labels,
          autoRotationLimit: 300,
          formatter: xAxisFormatter(xAxis, normalizedMetrics[xAxis]),
        },
        crosshair: {
          color: colorTheme.grey[100],
          dashStyle: 'Solid',
        },
        id: xAxisId,
      } as Highcharts.XAxisOptions,
    ],
    yAxis: {
      ...staticChartOptions.yAxis,
      title: {
        ...staticChartOptions.yAxis.title,
        text: formatMetricLabel(normalizedMetrics[key], currency),
      },
      labels: {
        ...staticChartOptions.yAxis.labels,
        formatter: yAxisFormatter(normalizedMetrics[key]?.format),
      },
      visible: true,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      formatter: getTooltipFormatter({
        xAxis,
        series: [serie],
        dateState,
        normalizedMetrics,
        currency,
        groupBy,
        data,
        timezone,
        compareUnit,
      }),
    } satisfies Highcharts.TooltipOptions,
    legend: {
      ...staticChartOptions.legend,
      enabled: !!groupBy && transformedSeries.length > 1,
    },
    series: transformedSeries as Highcharts.SeriesOptionsType[],
    boost: {
      ...staticChartOptions.boost,
      enabled: false,
    },
  }
}
