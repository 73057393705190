import { statAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(statAnatomy.keys)

const baseStyle = definePartsStyle({
  container: { p: 4, boxShadow: 'base', bg: 'white' },
  label: {
    color: 'gray.700',
    fontSize: 'xs',
    lineHeight: 4,
  },
  number: { color: 'gray.900', fontWeight: 600 },
  helpText: {
    fontSize: 'xs',
    lineHeight: 4,
    mb: 0,
    h: 'fit-content',
    px: 1.5,
    py: 0.5,
    width: 'fit-content',
    borderRadius: 'sm',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    opacity: 1, // Chakra ads 0.8 by default
  },
  icon: {}, // We are not using chakras own icon for this, we have our own icons instead
})

export const statTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    sm: {
      ...baseStyle,
      number: {
        fontSize: 'lg',
        lineHeight: 7,
      },
    },
    md: {
      ...baseStyle,
      number: {
        fontSize: { base: 'lg', lg: 'xl' },
        lineHeight: 7,
      },
    },
    lg: {
      ...baseStyle,
      number: {
        fontSize: { base: 'lg', lg: '3xl' },
        lineHeight: { base: 7, lg: 9 },
      },
    },
  },
  defaultProps: {
    size: 'md',
  },
})
