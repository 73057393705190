import { type IconName } from 'components/Icon/Icon'
import { colorTheme } from 'ui/theme/colors'

export const teamIconColors: string[] = [
  colorTheme.gray[600],
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[400],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[600],
  colorTheme.black,
]

export const teamIconNames: IconName[] = [
  'ArchiveIcon',
  'BarChartIcon',
  'CustomerSupportIcon',
  'DiamondIcon',
  'Percent1Icon',
  'DollarCoinIcon',
  'EarthIcon',
  'Flower1Icon',
  'Flower2Icon',
  'HeartIcon',
  'ChartPieIcon',
  'PiggyBankIcon',
  'ShippingBoxIcon',
  'ShippingTruckIcon',
  'ShoppingBagIcon',
  'StarIcon',
  'SuitcaseIcon',
  'TreeIcon',
  'UserMultipleCollaborateIcon',
  'WalletIcon',
  'LeafIcon',
  'SummitIcon',
]
