import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'
import { graphql } from 'generated/graphql'
import { type ChannelMapping } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { z } from 'zod'
import { MAPPINGS_VIEW_QUERY } from '../MappingsView'
import { MERCHANT_TOTAL_UNMAPPED_SESSIONS_QUERY } from '../UnhandledMappings/NoOfUnhandledMappings'

const UPDATE_CHANNEL_MAPPINGS_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateChannelMappings($params: UpdateChannelMappingsInput!) {
    updateChannelMappings(params: $params) {
      mappings
      channel
      channelGroup
    }
  }
`)

const atLeastOneDefined = (obj: Record<string, unknown>) =>
  Object.values(obj).some((v) => v !== undefined)

export const MAPPING_ACTION = {
  CREATE: 'CREATE',
  REMOVE: 'REMOVE',
} as const

const mappingsSchema = z
  .object({
    utmCampaign: z.string(),
    utmMedium: z.string(),
    utmSource: z.string(),
  })
  .partial()
  .refine(atLeastOneDefined, { message: 'Provide at least one mapping.' })

const paramsSchema = z.object({
  frontendId: z.string(),
  channel: z.string(),
  channelGroup: z.string(),
  mappings: mappingsSchema,
  action: z.nativeEnum(MAPPING_ACTION),
})

type UpdateChannelMappingsParams = z.infer<typeof paramsSchema>

type Mapping = {
  utm_campaign: string | undefined
  utm_medium: string | undefined
  utm_source: string | undefined
}

type UpdateChannelMappingsResponse = {
  updateChannelMappings: ChannelMapping & {
    mappings: [Mapping]
  }
}

const eventActions: Record<keyof typeof MAPPING_ACTION, 'Created' | 'Deleted'> =
  {
    [MAPPING_ACTION.CREATE]: 'Created',
    [MAPPING_ACTION.REMOVE]: 'Deleted',
  }

export const useUpdateChannelMappings = (): [
  typeof updateChannelMappings,
  typeof state,
] => {
  const [trackEvent] = useTrackEvent()
  const [mutation, state] = useMutation<UpdateChannelMappingsResponse>(
    UPDATE_CHANNEL_MAPPINGS_MUTATION,
  )

  const updateChannelMappings = (params: UpdateChannelMappingsParams) => {
    const parsedParams = paramsSchema.safeParse(params)

    if (!parsedParams.success) {
      captureException(
        `updateChannelMappings invalid request ${parsedParams.error}`,
      )

      return
    }

    const { frontendId, channel, channelGroup, mappings, action } =
      parsedParams.data

    return mutation({
      variables: {
        params: { frontendId, channel, channelGroup, mappings, action },
      },
      refetchQueries: [
        MAPPINGS_VIEW_QUERY,
        MERCHANT_TOTAL_UNMAPPED_SESSIONS_QUERY,
      ],
      onError: () => {
        captureException(`updateChannelMappings error: ${parsedParams.error}`)
      },
      onCompleted: (response) => {
        const data = response.updateChannelMappings
        const lastIndex = data.mappings.length - 1

        let mapping: Mapping = {
          utm_campaign: undefined,
          utm_medium: undefined,
          utm_source: undefined,
        }

        switch (action) {
          case MAPPING_ACTION.CREATE: {
            mapping = data.mappings[lastIndex]
            break
          }
          case MAPPING_ACTION.REMOVE: {
            const { utmCampaign, utmMedium, utmSource } = mappings

            mapping = {
              utm_campaign: utmCampaign,
              utm_medium: utmMedium,
              utm_source: utmSource,
            }
            break
          }
          default: {
            captureException(
              `Channel mapping: invalid action ${action} used while updating mapping`,
            )

            break
          }
        }

        const eventProperties = {
          utmSource: mapping.utm_source,
          utmMedium: mapping.utm_medium,
          utmCampaign: mapping.utm_campaign,
          channelGroup: data.channelGroup,
          channel: data.channel,
        }

        trackEvent({
          eventName: `Settings Channel Mapping ${eventActions[action]}`,
          eventProperties,
        })
      },
    })
  }

  return [updateChannelMappings, state]
}
