import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Portal,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { useSignOut } from 'hooks/useSignOut'
import { useMatch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { NavIconLink } from '../NavLinks'
import { AccountMenuItem } from './AccountMenuItem'
import { Featurebase } from './Featurebase'
import { SwitchOrganisationMenuItem } from './SwitchOrganisationMenuItem'

export const AccountMenu = () => {
  const { hide: hideIntercom } = useIntercom()
  const signOut = useSignOut()
  const { user } = useUser()
  const isGuestUser = useIsGuestUser()
  const match = useMatch({ path: '/profile/*' })

  if (!user) return null

  return (
    <Menu placement="bottom-end" gutter={-0.5} onOpen={hideIntercom}>
      {({ isOpen }) => (
        <>
          <MenuButton
            aria-label="User profile"
            as={NavIconLink}
            isSelected={!!match}
            iconName="UserIcon"
          />

          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              <AccountMenuItem
                name={user.primaryEmailAddress?.emailAddress ?? ''}
                iconName="UserCircleIcon"
                isDisabled={true}
                _disabled={{
                  pointerEvents: 'none',
                }}
              />

              <MenuDivider borderColor="gray.200" />

              <AccountMenuItem
                name="Profile"
                iconName="SlidersIcon"
                to="/settings/account/profile"
              />

              {!isGuestUser && (
                <AccountMenuItem
                  name="Organisation"
                  iconName="OfficeIcon"
                  to="/settings/organisation/members"
                />
              )}

              <Featurebase user={user} />

              <MenuDivider borderColor="gray.200" />

              <SwitchOrganisationMenuItem />

              <AccountMenuItem
                name="Log out"
                iconName="LogoutIcon"
                onClick={signOut}
              />
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
