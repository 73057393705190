import { useQuery } from '@apollo/client'
import { Divider, Flex } from '@chakra-ui/react'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import { graphql } from 'generated/graphql'
import { useEffect, useState } from 'react'
import { MerchantSiteSelector } from '../shared/MerchantSiteSelector'
import { ExistingMappings } from './ExistingMappings/ExistingMappings'
import { MappingsActionType, useMappingsAtom } from './shared/state'
import { UnhandledMappings } from './UnhandledMappings/UnhandledMappings'

export const MAPPINGS_VIEW_QUERY = graphql(/* GraphQL */ `
  query MappingsViewQuery($merchantSiteParams: MerchantSiteInput!) {
    viewer {
      id
      merchantSite(params: $merchantSiteParams) {
        frontendId
        ...MappableSessions_MerchantSite
        ...ChannelMappings_MerchantSite
      }
    }
  }
`)

const MAPPING_TAB = {
  UNHANDLED: 'Unhandled',
  EXISTING: 'Existing',
} as const

export const MAPPING_TABS = [
  { id: MAPPING_TAB.UNHANDLED, label: 'Unmapped traffic' },
  { id: MAPPING_TAB.EXISTING, label: 'Existing mappings' },
]

type MappingTab = (typeof MAPPING_TAB)[keyof typeof MAPPING_TAB]

export const MappingsView = () => {
  const [, setMappingsState] = useMappingsAtom()
  const [frontendId, setSelectedMerchantSite] = useState<string>()
  const [mappingTab, setMappingTab] = useState<MappingTab>(
    MAPPING_TAB.UNHANDLED,
  )
  const { data, loading } = useQuery(MAPPINGS_VIEW_QUERY, {
    skip: !frontendId,
    variables: { merchantSiteParams: { frontendId: frontendId ?? '' } },
  })

  const isLoading = !frontendId || loading

  useEffect(() => {
    return () => {
      setMappingsState({ type: MappingsActionType.ResetMappingsState })
    }
  }, [frontendId, setMappingsState])

  return (
    <TitleLayoutView
      title="Mapping"
      description="Manage how your web site traffic should be assigned to different channel groups and channels based on UTM parameters"
    >
      <Divider my={6} />
      <Flex justifyContent="space-between" gap={2}>
        <ToggleWithLabels
          items={MAPPING_TABS}
          selected={mappingTab}
          setSelected={setMappingTab}
        />
        <MerchantSiteSelector
          merchantSiteId={frontendId}
          setMerchantSiteId={setSelectedMerchantSite}
        />
      </Flex>
      <Flex flexDir="column" mt={6} gap={6} w="full" minW="400px">
        {mappingTab === MAPPING_TAB.UNHANDLED && (
          <UnhandledMappings
            merchantSite={data?.viewer?.merchantSite}
            isLoading={isLoading}
          />
        )}
        {mappingTab === MAPPING_TAB.EXISTING && (
          <ExistingMappings
            merchantSite={data?.viewer?.merchantSite}
            isLoading={isLoading}
          />
        )}
      </Flex>
    </TitleLayoutView>
  )
}
