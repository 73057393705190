import { Tbody, Td, Tr } from '@chakra-ui/react'
import {
  type ExpandedState,
  flexRender,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  type ColumnDef,
  type HeaderGroup,
  type Row,
} from '@tanstack/react-table'
import { Button } from 'components/buttons'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import {
  useEffect,
  useState,
  type FC,
  Fragment,
  type ReactElement,
} from 'react'
import { type ManualInputColumns } from '../types'
import { getErrors } from './utils/getErrors'
import { updateData, addRow, deleteRow, addSubRow } from './utils/updateData'

const cellStyles = {
  _first: { w: '400px' },
  _last: { w: '88px' },
}

const getIsLastChildRow = (
  row: Row<ManualInputColumns>,
  acc: boolean[] = [],
): boolean[] => {
  const parentRow = row.getParentRow()

  if (!parentRow || row.depth < 1) {
    return acc
  }

  acc.push((row.getParentRow()?.subRows.length ?? 0) - 1 === row.index)

  return getIsLastChildRow(parentRow, acc)
}

const getCurrentButtons = (
  row: Row<ManualInputColumns>,
  addRowButtons: ReactElement[],
  addRow?: (rowId: string) => void,
) => {
  let currentButtons: ReactElement[] = []
  const numberOfColumns = row.getVisibleCells().length
  const isLastChildRow =
    (row.getParentRow()?.subRows.length ?? 0) - 1 === row.index
  const isLastOfAllArr = getIsLastChildRow(row)
  const isLastOfAll = !isLastOfAllArr.some((isLast) => !isLast)

  if (isLastChildRow) {
    addRowButtons.push(
      <Tr key={row.id}>
        <Td {...cellStyles} colSpan={numberOfColumns}>
          <Button
            variant="ghost"
            size="sm"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            ml={`${row.depth * 1.5 + 1.5}rem`}
            onClick={() => addRow?.(row.id)}
          >
            Add row
          </Button>
        </Td>
      </Tr>,
    )
  }

  if (isLastChildRow && (row.subRows.length === 0 || !row.getIsExpanded())) {
    if (isLastOfAll) {
      currentButtons = [...addRowButtons]
    } else {
      let shouldSkip = false

      isLastOfAllArr.forEach((val) => {
        if (!val || shouldSkip) {
          shouldSkip = true

          return
        }

        currentButtons = currentButtons.concat(addRowButtons.splice(-1))
      })

      currentButtons.reverse()
    }
  }

  return currentButtons
}

type Props = {
  data: ManualInputColumns[]
  columns: ColumnDef<ManualInputColumns, unknown>[]
  isReadOnly: boolean
  setHeaderGroups: (headerGroups: HeaderGroup<ManualInputColumns>[]) => void
  setData: (data: ManualInputColumns[]) => void
}

export const ManualInputTableContent: FC<Props> = ({
  data,
  columns,
  isReadOnly,
  setHeaderGroups,
  setData,
}) => {
  const { currency } = useMerchantInfo()
  const normalizedDimensions = useNormalizedDimensions()
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    // Meta data is declared in tanstackTableMetaData.ts
    meta: {
      isReadOnly,
      normalizedDimensions,
      updateData: (rowId, values) =>
        updateData({ rowId, values, data, setData }),
      addRow: (rowId) => addRow(rowId, data, setData, columns, currency),
      addSubRow: (rowId) => addSubRow(rowId, data, setData, columns, currency),
      deleteRow: (rowId) => deleteRow({ rowId, data, setData }),
      getErrors: (firstRow, skipSelf) => getErrors(firstRow, skipSelf),
    },
  })
  const headerGroups = table.getHeaderGroups()
  const addRowButtons: ReactElement[] = []

  useEffect(
    () => setHeaderGroups(headerGroups),
    [headerGroups, setHeaderGroups],
  )

  return (
    <Tbody>
      {table.getRowModel().rows.map((row) => {
        const { isReadOnly, addRow } = table.options.meta || {}
        const isGroupRow = row.depth === 0
        const currentButtons =
          !isGroupRow && !isReadOnly
            ? getCurrentButtons(row, addRowButtons, addRow)
            : []

        return (
          <Fragment key={row.id}>
            <Tr>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  {...cellStyles}
                  h={isGroupRow ? 10 : 8}
                  verticalAlign="top"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
            {currentButtons.reverse().map((button) => button)}
          </Fragment>
        )
      })}
    </Tbody>
  )
}
