import { type ButtonProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type FC } from 'react'

type Props = {
  onClick: ButtonProps['onClick']
}

export const ClearAllFilterButton: FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="ghost" size="sm">
      Clear all
    </Button>
  )
}
