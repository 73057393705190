import { Flex } from '@chakra-ui/react'
import {
  InfoTooltip,
  type InfoTooltipProps,
} from 'components/Tooltip/InfoTooltip'
import { Typography } from 'components/Typography'
import { type FC } from 'react'

const ReportDetailsTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography fontSize="xs" lineHeight={4} fontWeight={500} color="gray.700">
    {children}
  </Typography>
)

interface Props {
  title: string
  infoDetails?: InfoTooltipProps
}

export const ReportDetailsHeader: FC<Props> = ({ title, infoDetails }) => (
  <Flex mb={1} gap={1}>
    <ReportDetailsTitle>{title}</ReportDetailsTitle>
    {infoDetails && (
      <InfoTooltip
        tooltipText={infoDetails.tooltipText}
        link={infoDetails.link}
        iconProps={{ color: 'gray.700' }}
      />
    )}
  </Flex>
)
