import { v4 as uuid } from 'uuid'
import { type RuleForm } from './types'

export const getEmptyRule = (): RuleForm => ({
  id: uuid(),
  description: '',
  filters: {},
  productLabel: null,
  expiresAt: null,
})
