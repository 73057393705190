import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { ToggleWithIcons } from 'components/ToggleWithIcons/ToggleWithIcons'
import { Typography } from 'components/Typography'
import { differenceInDays } from 'date-fns'
import { useHistoricalAnalysisDateAtom } from 'features/optimizations/atoms/dateAtom'
import { BaseWidget } from 'features/optimizations/components/BaseWidget'
import { SaturationCurves } from 'features/optimizations/components/SaturationCurves/SaturationCurves'
import { ScenarioTable } from 'features/optimizations/components/ScenarioTable/ScenarioTable'
import { SpendAnalysisCell } from 'features/optimizations/components/ScenarioTable/SpendAnalysisCell'
import {
  compareValuesTypes,
  CompareValueType,
  getOptimalOptimizationMetric,
  type OptimizationTableColumn,
  scenarioColumns,
  type OPTIMIZATION_METRIC,
} from 'features/optimizations/consts'
import {
  type ContributionData,
  type HistoricalAnalysis,
  type SummaryData,
} from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { useState, type FC } from 'react'
import { InfoAlert } from './components/InfoAlert/InfoAlert'
import { useHistoricalAnalysis } from './hooks/useHistoricalAnalysis'

const weeklyAnalysisColumns: OptimizationTableColumn[] = [
  ...scenarioColumns,
  {
    isMetric: false,
    key: 'spendAnalysis',
    label: 'Spend Analysis',
    CellComponent: SpendAnalysisCell,
  },
]

export const WeeklyAnalysisView: FC = () => {
  const [compareValueType, setCompareValueType] = useState(
    CompareValueType.Percentage,
  )
  const [dateState] = useHistoricalAnalysisDateAtom()
  const dateRange = dateState.value
  const dayDiff =
    differenceInDays(dateRange[1] as Date, dateRange[0] as Date) + 1
  const { historicalAnalysis, query } = useHistoricalAnalysis()
  const isLoading = query.loading
  const hasError = !!query.error

  const tableData = getTableData(historicalAnalysis)
  const { data: configData } = useOptimizationConfig()
  const { optimizationConfig } = configData?.viewer?.merchant ?? {}

  return (
    <Box p={6} pt={0}>
      <InfoAlert mb={6} />
      <Box mb={6}>
        <BaseWidget
          title="Channel analysis"
          rightElement={
            <Flex gap={2} alignItems="center">
              <Typography size="xs" fontWeight={600} color="gray.600">
                Comparison:
              </Typography>
              <ToggleWithIcons
                selected={compareValueType}
                setSelected={setCompareValueType}
                items={compareValuesTypes}
              />
            </Flex>
          }
        >
          {isLoading ? (
            <Skeleton h="548px" />
          ) : (
            <ScenarioTable
              data={tableData}
              optimizationConfig={optimizationConfig}
              compareDateLabel="Actual"
              compareValueType={compareValueType}
              columns={weeklyAnalysisColumns}
            />
          )}
        </BaseWidget>
      </Box>

      <SaturationCurves
        curveData={historicalAnalysis?.curve}
        dayDiff={dayDiff}
        hasError={hasError}
        isLoading={isLoading}
      />
    </Box>
  )
}

const getTableData = (data: HistoricalAnalysis | undefined) => {
  const result = {
    contribution: (data?.contribution ?? []) as ContributionData,
    summary: [] as SummaryData,
  }

  if (!data) {
    return result
  }

  const summaryKeys = Object.keys(data.summary[0]).filter((key) => key !== 'id')

  // Merge summaries
  for (const summaryRow of data.summary) {
    const enrichedSummary: Record<string, unknown> = {
      id: String(summaryRow.id),
    }

    summaryKeys.forEach((key) => {
      enrichedSummary[key] = summaryRow[key]
      enrichedSummary[
        getOptimalOptimizationMetric(key as OPTIMIZATION_METRIC)
      ] = summaryRow[key]
    })

    const dataIsEmpty = Object.entries(enrichedSummary)
      .filter(([key]) => key !== 'id')
      .every(([, value]) => !value)

    // Skip rows with no data
    if (!dataIsEmpty) {
      result.summary.push(enrichedSummary as SummaryData[number])
    }
  }

  return result
}
