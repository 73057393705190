import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type GetProductLabelSegmentationInput } from 'generated/graphql/graphql'

const PRODUCT_LABEL_SEGMENTATION_QUERY = graphql(/* GraphQL */ `
  query ProductLabelSegmentation($params: GetProductLabelSegmentationInput!) {
    viewer {
      id
      merchant {
        id
        productLabelSegmentation(params: $params)
      }
    }
  }
`)

export const useProductLabelSegmentation = ({
  params,
}: {
  params: GetProductLabelSegmentationInput
}) => {
  const { data, ...query } = useQuery(PRODUCT_LABEL_SEGMENTATION_QUERY, {
    variables: { params },
  })

  return {
    productLabelSegmentation: data?.viewer?.merchant
      .productLabelSegmentation as Record<
      string,
      {
        labelName: string
        labelId: string
        datalayerProductId: string
        productItemId: string
      }
    > | null,
    ...query,
  }
}
