import { useMemo } from 'react'

type MultiSelectLabelProps = {
  selectedIds: string[]
  normalizedOptions?: Record<string, { label: string }>
  defaultFirstLabel?: string
  defaultEmptyLabel?: string
}

export const useMultiSelectLabel = ({
  selectedIds,
  normalizedOptions,
  defaultFirstLabel,
  defaultEmptyLabel = 'None selected',
}: MultiSelectLabelProps) => {
  const label = useMemo(() => {
    const firstLabel =
      (normalizedOptions
        ? normalizedOptions[selectedIds[0]]?.label
        : defaultFirstLabel) ?? 'Unknown'

    const label =
      selectedIds.length === 0
        ? defaultEmptyLabel
        : selectedIds.length === 1
          ? firstLabel
          : firstLabel + ` and ${selectedIds.length - 1} more`

    return label
  }, [defaultFirstLabel, defaultEmptyLabel, normalizedOptions, selectedIds])

  return label
}
