type Service =
  | 'google'
  | 'facebook'
  | 'tiktok'
  | 'sftp'
  | 'centra'
  | 'shopify'
  | 'commercetools'

export type IntegrationType = 'Marketing' | 'Inventory' | 'Order'

export type RedirectIntegrationType =
  | 'api'
  | 'sftpModal'
  | 'centraModal'
  | 'shopifyModal'
  | 'commercetoolsModal'

export const INTEGRATION_TYPES = {
  MARKETING: 'Marketing',
  INVENTORY: 'Inventory',
  ORDER: 'Order',
} as const

export interface IntegrationConfig {
  channelGroup: string
  channel: string
  logo: string
  serviceId: Service
  title: string
  type: IntegrationType
  redirectIntegration: RedirectIntegrationType
}

export const availableIntegrations: Record<
  IntegrationType,
  IntegrationConfig[]
> = {
  [INTEGRATION_TYPES.MARKETING]: [
    {
      channel: 'google',
      channelGroup: 'sem',
      serviceId: 'google',
      logo: 'GoogleAds.svg',
      title: 'Google ads',
      type: INTEGRATION_TYPES.MARKETING,
      redirectIntegration: 'api',
    },
    {
      channel: 'facebook',
      channelGroup: 'social_ppc',
      serviceId: 'facebook',
      logo: 'Meta.svg',
      title: 'Meta ads',
      type: INTEGRATION_TYPES.MARKETING,
      redirectIntegration: 'api',
    },
    {
      channel: 'tiktok',
      channelGroup: 'social_ppc',
      serviceId: 'tiktok',
      logo: 'TikTok.svg',
      title: 'TikTok Ads',
      type: INTEGRATION_TYPES.MARKETING,
      redirectIntegration: 'api',
    },
  ],
  [INTEGRATION_TYPES.INVENTORY]: [
    {
      channel: 'sftp',
      channelGroup: 'social_ppc',
      serviceId: 'sftp',
      logo: 'sFTP.svg',
      title: 'sFTP',
      type: INTEGRATION_TYPES.INVENTORY,
      redirectIntegration: 'sftpModal',
    },
    {
      channel: 'centra',
      channelGroup: 'social_ppc',
      serviceId: 'centra',
      logo: 'Centra.svg',
      title: 'Centra',
      type: INTEGRATION_TYPES.INVENTORY,
      redirectIntegration: 'centraModal',
    },
    {
      channel: 'shopify',
      channelGroup: 'social_ppc',
      serviceId: 'shopify',
      logo: 'Shopify.svg',
      title: 'Shopify',
      type: INTEGRATION_TYPES.INVENTORY,
      redirectIntegration: 'shopifyModal',
    },
    {
      channel: 'commercetools',
      channelGroup: 'social_ppc',
      serviceId: 'commercetools',
      logo: 'Commercetools.svg',
      title: 'Commercetools',
      type: INTEGRATION_TYPES.INVENTORY,
      redirectIntegration: 'commercetoolsModal',
    },
  ],
  [INTEGRATION_TYPES.ORDER]: [
    {
      channel: 'sftp',
      channelGroup: 'social_ppc',
      serviceId: 'sftp',
      logo: 'sFTP.svg',
      title: 'sFTP',
      type: INTEGRATION_TYPES.ORDER,
      redirectIntegration: 'sftpModal',
    },
    {
      channel: 'centra',
      channelGroup: 'social_ppc',
      serviceId: 'centra',
      logo: 'Centra.svg',
      title: 'Centra',
      type: INTEGRATION_TYPES.ORDER,
      redirectIntegration: 'centraModal',
    },
    {
      channel: 'shopify',
      channelGroup: 'social_ppc',
      serviceId: 'shopify',
      logo: 'Shopify.svg',
      title: 'Shopify',
      type: INTEGRATION_TYPES.ORDER,
      redirectIntegration: 'shopifyModal',
    },
    {
      channel: 'commercetools',
      channelGroup: 'social_ppc',
      serviceId: 'commercetools',
      logo: 'Commercetools.svg',
      title: 'Commercetools',
      type: INTEGRATION_TYPES.ORDER,
      redirectIntegration: 'commercetoolsModal',
    },
  ],
}
