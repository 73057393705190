import { Box, Flex, Table, Tbody, Td, Tr } from '@chakra-ui/react'
import Input from 'components/Input/Input'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { useReportLabels } from 'graphql/reports/useReportLabels'
import { startTransition, useMemo, useState } from 'react'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { AddLabelModal } from './AddLabelModal'
import { DeleteLabelModal } from './DeleteLabelModal'
import { EditLabelModal } from './EditLabelModal'

export const OrganisationLabelsView = () => {
  const [searchInput, setSearchInput] = useState('')
  const { reportLabels, query: reportLabelsQuery } = useReportLabels({
    withReportCount: true,
  })

  const filteredReportLabels = useMemo(() => {
    return !searchInput
      ? reportLabels
      : reportLabels.filter((label) =>
          label.name.toLowerCase().includes(searchInput.toLowerCase()),
        )
  }, [reportLabels, searchInput])

  return (
    <TitleLayoutView
      title="Labels"
      description="Manage labels for your organisation."
      size="sm"
    >
      <Flex justifyContent="space-between" mt={10}>
        <Input
          maxW={180}
          size="sm"
          leadingIcon={{
            name: 'MagnifyingGlassIcon',
          }}
          placeholder="Search labels"
          onChange={(e) => {
            startTransition(() => setSearchInput(e.target.value))
          }}
        />
        <AddLabelModal />
      </Flex>
      <Table mt={6}>
        <Tbody>
          {reportLabelsQuery.loading ? (
            <SkeletonTableRows rows={5} columns={1} />
          ) : filteredReportLabels.length === 0 ? (
            <EmptyData label="You haven't added any label yet" />
          ) : (
            filteredReportLabels.map((label) => (
              <Tr display="flex" key={label.id}>
                <Td px={0} flexGrow={1} minW={0} alignContent="center">
                  <Flex alignItems="center" gap={2} px={4}>
                    <Box bg={label.color} w={2} h={2} flexShrink={0} />
                    <Typography
                      fontSize="xs"
                      fontWeight={500}
                      color="gray.800"
                      lineHeight={4}
                      isTruncated
                    >
                      {label.name}
                    </Typography>
                    <Typography
                      fontSize="xs"
                      color="gray.600"
                      lineHeight={4}
                      flexShrink={0}
                    >
                      •
                    </Typography>
                    <Typography
                      fontSize="xs"
                      color="gray.600"
                      lineHeight={4}
                      flexShrink={0}
                    >
                      {label.reportCount || 0} reports
                    </Typography>
                  </Flex>
                </Td>
                <Td px={0}>
                  <Flex>
                    <EditLabelModal currentLabel={label} />
                    <DeleteLabelModal label={label} />
                  </Flex>
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TitleLayoutView>
  )
}
