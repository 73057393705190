import { type ColorType } from 'ui/theme/colors'

export enum DiffStatus {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  NEUTRAL = 'neutral',
}

export const STATUS_COLORS = {
  [DiffStatus.INCREASE]: {
    bg: 'green.50',
    color: 'green.600',
  },
  [DiffStatus.DECREASE]: {
    bg: 'red.50',
    color: 'red.500',
  },
  [DiffStatus.NEUTRAL]: {
    bg: 'gray.200',
    color: 'gray.800',
  },
} satisfies Record<DiffStatus, { color: ColorType; bg: ColorType }>
