import { type ColumnDef } from '@tanstack/react-table'
import { MANUAL_INPUT_FORMAT } from '../../consts'
import { type ManualInputColumns, type ManualInputFormat } from '../../types'

export const getFallbackValueByFormat = (
  format?: ManualInputFormat,
  currency?: string,
): string => {
  switch (format) {
    case MANUAL_INPUT_FORMAT.CURRENCY:
      return currency ?? 'XXX'
    case MANUAL_INPUT_FORMAT.NUMBER:
    case MANUAL_INPUT_FORMAT.NUMBER_CURRENCY:
    case MANUAL_INPUT_FORMAT.PERCENTAGE:
      return '0'
    case MANUAL_INPUT_FORMAT.STRING:
    default:
      return ''
  }
}

export const getEmptyDataRow = (
  columns: ColumnDef<ManualInputColumns, unknown>[],
  currency?: string,
): ManualInputColumns => {
  return Object.values(columns).reduce<Record<string, string>>(
    (acc, column) => {
      if (!('accessorKey' in column)) return acc

      const value = getFallbackValueByFormat(column.meta?.format, currency)

      acc[column.accessorKey] = value

      return acc
    },
    {},
  )
}
