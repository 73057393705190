import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Divider,
} from '@chakra-ui/react'
import { Icon, type IconProps, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'

export type InfoTooltipProps = {
  tooltipText: string
  link?: string
  iconName?: IconName
  iconProps?: Omit<IconProps, 'name'>
}

export const InfoTooltip: FC<InfoTooltipProps> = ({
  tooltipText,
  iconName = 'InformationCircleIcon',
  iconProps,
}) => {
  return (
    <Popover isLazy trigger="hover" placement="bottom-start" gutter={1}>
      <PopoverTrigger>
        <Icon {...iconProps} name={iconName} size="small" />
      </PopoverTrigger>
      <PopoverContent p={0} maxW="188px">
        <Box p={4}>
          <Typography
            whiteSpace="normal"
            fontSize="xs"
            lineHeight={4}
            color="gray.700"
          >
            {tooltipText}
          </Typography>
        </Box>
        <Divider />
        {/* TODO add this once we have links */}
        {/*{link && <Box p={4} lineHeight={1}>
                  <Button
                    as={Link}
                    to={infoDetails.link}
                    target="_blank"
                    size="sm"
                    variant="link"
                    trailingIcon={{ name: 'LinkExternalIcon' }}
                  >
                    Read more
                  </Button>
                </Box> }*/}
      </PopoverContent>
    </Popover>
  )
}
