import { type Filter } from 'constants/types'
import { type DropdownOption } from 'components/Dropdown/types'
import { type AddFilterArgsMultiSelectSearch } from './AdvancedMultiSelectFilter'
import { type AddNumberFilterArgs } from './NumberFilter/NumberFilter'
import { type FilterPopoverProps } from './shared/FilterPopover'
import { type AddFilterArgsMultiSelect } from './SimpleMultiSelectFilter'

export enum FilterOperator {
  contains = 'contains',
  doesNotContain = 'doesNotContain',
  isAnyOf = 'isAnyOf',
  isNoneOf = 'isNoneOf',
  greaterThan = 'gt',
  lessThan = 'lt',
  between = 'between',
  topPercent = 'topPercent',
  bottomPercent = 'bottomPercent',
}

export type AddFilterArgsBase = {
  filterId: string
  filterIndex: number
  selectedFilterGroup: FilterOperator
}

export type BaseFilterProps = {
  filterLabel?: string
  filterId: string
  filters: Record<string, Filter[]>
  filterIndex: number
  defaultSelectedFilter?: FilterOperator
  options: DropdownOption[]
  selectedOptions?: string[] | string
  isLoading: boolean
  addFilter: (
    filter:
      | AddFilterArgsMultiSelectSearch
      | AddFilterArgsMultiSelect
      | AddNumberFilterArgs,
  ) => void
  removeFilter: ({
    filterId,
    filterIndex,
  }: {
    filterId: string
    filterIndex: number
  }) => void
} & Omit<FilterPopoverProps, 'children'>

export type FilterTupleWithNull = [number | null, number | null]
export type FilterTuple = [number, number]
