import {
  COMPARE_DYNAMIC_DATE_ID,
  DYNAMIC_DATE_ID,
  getCompareDatePreset,
  getDatePreset,
  getDatePresets,
} from 'constants/getDatePresets'
import { type DateRange } from 'constants/types'

export const getSimpleDynamicDatePreset = (
  urlDynamicDateId: string | null | undefined,
  dynamicDates: DYNAMIC_DATE_ID[],
) => {
  const dates = getDatePresets(dynamicDates)

  return dates.find((preset) => preset.id === urlDynamicDateId) || dates[0]
}

export const getDefaultDynamicDate = () => getDatePreset(DYNAMIC_DATE_ID.TODAY)

const defaultCompareDynamicDateId =
  COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING

export const getSimpleCompareDynamicDatePreset = (
  urlDynamicDateId: string | null | undefined,
  dateRange: DateRange,
) => {
  return getCompareDatePreset(
    (urlDynamicDateId as COMPARE_DYNAMIC_DATE_ID) ??
      defaultCompareDynamicDateId,
    dateRange[0],
    dateRange[1],
  )
}

export const getDefaultCompareDynamicDate = (dateRange: DateRange) => {
  const defaultCompareDynamicDateId =
    COMPARE_DYNAMIC_DATE_ID.PRECEDING_PERIOD_MATCHING

  return getCompareDatePreset(
    defaultCompareDynamicDateId,
    dateRange[0],
    dateRange[1],
  )
}
