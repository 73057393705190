import { useToast } from '@chakra-ui/react'
import { useSignIn } from '@clerk/clerk-react'
import { isClerkAPIResponseError } from '@clerk/clerk-react/errors'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { PinInput } from 'components/PinInput/PinInput'
import { Typography } from 'components/Typography'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

const validationSchema = z.object({
  code: z
    .string()
    .min(1, 'Code is required')
    .length(6, 'Code must be 6 digits long')
    .regex(/^\d+$/, 'Code must contain only digits'),
  password: z.string().min(1, 'Password is required'),
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = { colorMode: 'dark' | 'light' }

export const PasswordInputForm = ({ colorMode }: Props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const { isLoaded, signIn, setActive } = useSignIn()
  const toast = useToast()
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
  })
  const isDarkMode = colorMode === 'dark'
  const {
    formState: { errors, isSubmitting },
  } = methods

  async function onSubmit({ code, password }: ValidationSchema) {
    try {
      const result = await signIn?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })

      // When successful, user will be signed in automatically
      if (isLoaded && result?.status === 'complete') {
        setActive({ session: result.createdSessionId })
        setError('')
        toast({
          status: 'success',
          description: 'Your password has been updated successfully.',
          position: 'bottom',
        })
        // Check if 2FA is required (this should not happen for now, as 2FA is not used)
      } else if (result?.status === 'needs_second_factor') {
        setError('2FA is required')
      } else {
        setError('Unknown error')
      }
    } catch (err) {
      if (isClerkAPIResponseError(err)) {
        setError(err.errors[0].longMessage ?? 'Unknown error')
      }
    }
  }

  return (
    <>
      <Form display="flex" methods={methods} onSubmit={onSubmit}>
        <Typography
          fontSize="3xl"
          fontWeight="600"
          lineHeight="36px"
          textAlign="center"
          mb={2}
          color="grey.100"
        >
          New password
        </Typography>

        <Typography
          fontSize="sm"
          lineHeight="20px"
          textAlign="center"
          mb={8}
          color={isDarkMode ? 'grey.100' : 'grey.900'}
        >
          We sent a password reset code to your email. <br />
          Enter this code and your new password below.
        </Typography>

        <Typography
          fontSize="xs"
          color={isDarkMode ? 'grey.100' : 'grey.900'}
          mb={1}
        >
          Password reset code
        </Typography>
        <Controller
          name="code"
          control={methods.control}
          render={({ field }) => (
            <PinInput
              length={6}
              value={field.value}
              onChange={field.onChange}
              colorMode={colorMode}
              error={errors.code}
            />
          )}
        />
        <Input
          colorMode={colorMode}
          name="password"
          type={showPassword ? 'text' : 'password'}
          label="New password"
          trailingIcon={{ name: 'VisibleIcon' }}
          onTrailingIconClick={() =>
            setShowPassword((showPassword) => !showPassword)
          }
          wrapperProps={{ my: 4 }}
          error={errors.password}
        />
        <Button
          mt={2}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          minW="100%"
          isDisabled={!isLoaded}
        >
          Update password
        </Button>
        {error && (
          <Typography
            fontSize="xs"
            color={isDarkMode ? 'red.300' : 'red.500'}
            mt={1}
          >
            {error}
          </Typography>
        )}
      </Form>
    </>
  )
}
