import { Stack, useToast } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { Tag } from 'components/Tag/Tag'
import { Typography } from 'components/Typography'
import type { Channel, ChannelGroup } from 'generated/graphql/graphql'
import { useChannelGroups } from 'graphql/useChannelGroups'
import { useEffect, useState } from 'react'
import {
  MAPPING_ACTION,
  useUpdateChannelMappings,
} from '../graphql/useUpdateChannelMappings'
import { parameterTypes } from '../shared/consts'
import { MappingsActionType, useMappingsAtom } from '../shared/state'
import { getMappingsLabel } from '../shared/utils'

type MappingsPanelProps = {
  frontendId: string | undefined
}

export const CreateMappingPanel = ({ frontendId }: MappingsPanelProps) => {
  const [channelGroupIndex, setChannelGroupIndex] = useState(-1)
  const [channelIndex, setChannelIndex] = useState(-1)
  const [mappingsState, setMappingsState] = useMappingsAtom()
  const [updateChannelMappings, { loading }] = useUpdateChannelMappings()
  const { channelGroups } = useChannelGroups()
  const toast = useToast()
  const channelGroupsSorted = channelGroups
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))

  const isAnyParameterSelected = parameterTypes.some(
    ({ id }) => mappingsState[id] !== undefined,
  )

  const onSelectChannelGroup = (value: ChannelGroup) => {
    const newIndex = channelGroupsSorted.indexOf(value)

    setChannelGroupIndex(newIndex)
    setChannelIndex(-1)
  }

  const selectedChannelGroup =
    channelGroupIndex >= 0 ? channelGroupsSorted[channelGroupIndex] : undefined

  const channels =
    channelGroupIndex >= 0 && selectedChannelGroup
      ? selectedChannelGroup.channels
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
      : []

  const onSelectChannel = (value: Channel) => {
    const newIndex = channels.indexOf(value)

    setChannelIndex(newIndex)
  }

  const selectedChannel =
    channelIndex >= 0 && channels ? channels[channelIndex] : undefined

  const createMappingIsDisabled =
    channelGroupIndex < 0 || channelIndex < 0 || !isAnyParameterSelected

  const createMapping = async () => {
    if (!selectedChannelGroup || !selectedChannel || !frontendId) return

    const createMappingParams = {
      action: MAPPING_ACTION.CREATE,
      frontendId,
      channelGroup: selectedChannelGroup.id,
      channel: selectedChannel.id,
      mappings: mappingsState,
    }

    try {
      await updateChannelMappings(createMappingParams)

      toast({
        status: 'success',
        description: 'Mapping created',
        position: 'bottom',
        isClosable: true,
      })
      setChannelIndex(-1)
      setChannelGroupIndex(-1)
      setMappingsState({ type: MappingsActionType.ResetMappingsState })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Failed to create mapping',
        position: 'bottom',
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (!isAnyParameterSelected) {
      setChannelIndex(-1)
      setChannelGroupIndex(-1)
    }
  }, [isAnyParameterSelected])

  return (
    <Stack
      p={4}
      maxW="300px"
      minW={{ base: 'full', xl: '300px' }}
      boxShadow="md"
      spacing={8}
    >
      <Stack spacing={6}>
        <Typography fontSize="xl" lineHeight={7}>
          Create a new mapping
        </Typography>
        <Stack spacing={1}>
          <Typography fontSize="xs">Selected parameters</Typography>
          {isAnyParameterSelected ? (
            parameterTypes.map(({ id, label }) => {
              if (mappingsState[id] === undefined) return null

              return (
                <Tag
                  key={id}
                  size="lg"
                  variant="solid"
                  colorScheme="blackAlpha"
                  label={`${label}: ${getMappingsLabel(mappingsState[id])}`}
                  onClose={() =>
                    setMappingsState({
                      type: MappingsActionType.SetMappingTypeValue,
                      payload: {
                        type: id,
                        value: undefined,
                      },
                    })
                  }
                />
              )
            })
          ) : (
            <Typography fontSize="sm">No parameters selected</Typography>
          )}
        </Stack>
      </Stack>
      <Stack
        spacing={4}
        display="flex"
        flexDir={{ base: 'column', md: 'row', xl: 'column' }}
        minW="full"
      >
        <Dropdown
          options={channelGroupsSorted}
          callback={onSelectChannelGroup}
          value={selectedChannelGroup?.id}
          label="Channel group"
          containerProps={{ w: 'full' }}
        />
        <Dropdown
          options={channels}
          callback={onSelectChannel}
          placeholder={
            channelGroupIndex < 0 ? 'Select channel group first' : undefined
          }
          isDisabled={channelGroupIndex < 0}
          value={selectedChannel?.id}
          label="Channel"
          containerProps={{ w: 'full' }}
        />
      </Stack>
      <Button
        isLoading={loading}
        isDisabled={createMappingIsDisabled}
        onClick={createMapping}
      >
        Create mapping
      </Button>
    </Stack>
  )
}
