import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { ReportHeaderWrapper } from 'features/reports/components/ReportHeader/ReportHeaderWrapper'
import { ReportNameDescription } from 'features/reports/components/ReportHeader/ReportNameDescription'
import { ReportPanelControl } from 'features/reports/components/ReportHeader/ReportPanelControl'
import {
  reportDescriptionAtom,
  reportNameAtom,
} from 'features/reports/hooks/useReportState'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { CreateNewReportButton } from './CreateNewReportButton'

const reportNameSchema = z.object({
  reportName: z.string().min(2),
  reportDescription: z.string().optional(),
})

export const NewReportHeader = () => {
  const [reportName, setReportName] = useAtom(reportNameAtom)
  const [reportDescription, setReportDescription] = useAtom(
    reportDescriptionAtom,
  )

  const isValidReportNaming = reportNameSchema.safeParse({
    reportName,
    reportDescription,
  }).success

  const navigate = useNavigate()

  return (
    <ReportHeaderWrapper>
      <ReportNameDescription
        reportState={{ name: reportName, description: reportDescription }}
        setReportState={(payload) => {
          if (typeof payload === 'function') {
            const newState = payload({
              name: reportName,
              description: reportDescription,
            })

            setReportName(newState.name)
            setReportDescription(newState.description)
          } else {
            setReportName(payload.name)
            setReportDescription(payload.description)
          }
        }}
      />
      <Flex gap={4} alignItems="start">
        <Flex alignItems="start" gap={2}>
          <Button
            size="sm"
            colorScheme="gray"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <CreateNewReportButton isValidReportNaming={isValidReportNaming} />
        </Flex>

        <ReportPanelControl />
      </Flex>
    </ReportHeaderWrapper>
  )
}
