import { Icon as ChakraIcon } from '@chakra-ui/react'
import LogoIcon from './logo.svg?react' // Dema Logo

type LogoColor = 'white' | 'black'

export const Logo = ({ color = 'black' }: { color: LogoColor }) => {
  return (
    <ChakraIcon
      as={LogoIcon}
      aria-hidden="true"
      color={color}
      flexShrink={0}
      width="80px"
      height="18px"
    />
  )
}
