import { OrderedList, ListItem, Box } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { useEffect, useState } from 'react'
import { CodeSnippet } from '../CodeSnippet'
import pageViewTrackingSnippet from './pageViewSnippet.txt'

export const PageViewTracking = () => {
  const [fetchedSnippet, setFetchedSnippet] = useState('')

  useEffect(() => {
    fetch(pageViewTrackingSnippet)
      .then((r) => r.text())
      .then(setFetchedSnippet)
  }, [])

  return (
    <>
      <Typography fontSize="xl" lineHeight={8} color="grey.900" noOfLines={1}>
        Pageview tag
      </Typography>
      <OrderedList my={4} spacing={2} px={2} maxW={800}>
        <ListItem>
          Log in to your Google Tag Manager account, click on &quot;Tags&quot;
          in the left sidebar navigation and click the &quot;New&quot; button on
          top of the page.
        </ListItem>
        <ListItem>Name the tag &quot;Dema pageview&quot;.</ListItem>
        <ListItem>
          Set the tag configuration to &quot;Custom HTML&quot; and copy/paste
          the following code into the text field:
        </ListItem>
        <Box>
          <CodeSnippet snippet={fetchedSnippet} language="xml" />
        </Box>
        <ListItem>
          Update the Data Layer Variables (DLVs) in the &quot;Page data&quot;
          section with your data layer variables, or create new ones if
          necessary.
        </ListItem>
        <ListItem>
          Make sure to replace &quot;FRONTEND_ID&quot; with your actual frontend
          ID before publishing the tag.
        </ListItem>
      </OrderedList>
    </>
  )
}
