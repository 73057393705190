import {
  Menu,
  MenuList,
  MenuItem,
  useDisclosure,
  Portal,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon, type IconName } from 'components/Icon/Icon'
import { type FC } from 'react'
import { FILTER_OPTION, FILTER_OPTION_NAME } from './consts'
import { FilterMenuButton } from './FilterMenuButton'
import { type FilterOptionValue } from './types'

type Props = {
  setSelectedFilterOption: (option?: FilterOptionValue) => void
}

type FilterItem = {
  id: FilterOptionValue
  icon: IconName
}
const filterItems: FilterItem[] = [
  { id: FILTER_OPTION.VISIBILITY, icon: 'VisibleIcon' },
  { id: FILTER_OPTION.OWNER, icon: 'UserCircleIcon' },
  { id: FILTER_OPTION.LABELS, icon: 'TagIcon' },
]

export const FilterMenu: FC<Props> = ({ setSelectedFilterOption }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={() => {
        setSelectedFilterOption(undefined)
        onClose()
      }}
      size="sm"
      variant="outline"
      gutter={2}
      isLazy={true}
      closeOnSelect={false}
    >
      <FilterMenuButton />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <MenuList>
          {filterItems.map((item) => (
            <MenuItem
              key={item.id}
              icon={<Icon size="small" name={item.icon} />}
              onClick={() => setSelectedFilterOption(item.id)}
            >
              {FILTER_OPTION_NAME[item.id]}
            </MenuItem>
          ))}

          {/* <MenuDivider />
          
          <MenuItem
            icon={<Icon size="small" name="CalendarAddIcon" />}
            onClick={() => setSelectedFilterOption(FILTER_OPTION.DATE_CREATED)}
          >
            {FILTER_OPTION_NAME[FILTER_OPTION.DATE_CREATED]}
          </MenuItem>
          <MenuItem
            icon={<Icon size="small" name="CalendarEditIcon" />}
            onClick={() => setSelectedFilterOption(FILTER_OPTION.DATE_UPDATED)}
          >
            {FILTER_OPTION_NAME[FILTER_OPTION.DATE_UPDATED]}
          </MenuItem> */}
        </MenuList>
      </Portal>
    </Menu>
  )
}
