import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const REVOKE_ORGANIZATION_INVITATION = graphql(/* GraphQL */ `
  mutation RevokeOrganizationInvitation(
    $params: RevokeOrganizationInvitationInput!
  ) {
    revokeOrganizationInvitation(params: $params)
  }
`)

export const useRevokeOrganizationInvitation = () =>
  useMutation(REVOKE_ORGANIZATION_INVITATION)
