import { type Statistic } from 'graphql/statistics/types'

interface PageSize {
  name: string
  size: number
  id: number
}

export const pageSizeList: PageSize[] = [
  {
    name: '50 / Page',
    size: 50,
    id: 0,
  },
  {
    name: '100 / Page',
    size: 100,
    id: 1,
  },
  {
    name: '150 / Page',
    size: 150,
    id: 2,
  },
  {
    name: '200 / Page',
    size: 200,
    id: 3,
  },
  {
    name: '500 / Page',
    size: 500,
    id: 4,
  },
  {
    name: '1000 / Page',
    size: 1000,
    id: 5,
  },
]

export const MIN_COLUMN_SIZE = 132

export const loadingRow: Statistic = {
  id: 'loading',
  value: 'loading',
  formattedValue: 'loading',
}
export const skeletonRowsLength = 20
