export interface DashboardState {
  dashboards: Dashboard[]
  selectedDashboardId?: string
}
export interface Dashboard {
  id: string
  name: string
  layout: DashboardLayout
}
export interface DashboardLayout {
  order: string[]
  rows: Record<string, DashboardLayoutRow>
}
export interface DashboardLayoutRow {
  widgets: DashboardWidget[]
  height: number
}
export interface DashboardWidget {
  id: string
  size: number
  configId: string
}

export enum DashboardsReducerActionEnum {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
}

type DashboardsReducerActionCreate = {
  type: DashboardsReducerActionEnum.CREATE
  payload: Dashboard
}

type DashboardsReducerActionDelete = {
  type: DashboardsReducerActionEnum.DELETE
  payload: { id: string }
}

export type DashboardsReducerAction =
  | DashboardsReducerActionCreate
  | DashboardsReducerActionDelete

export enum LayoutReducerActionEnum {
  ADD_WIDGET = 'ADD_WIDGET',
  REMOVE_WIDGET = 'REMOVE_WIDGET',
}

type LayoutReducerActionAddWidget = {
  type: LayoutReducerActionEnum.ADD_WIDGET
  payload: { rowId?: string; widget: DashboardWidget }
}

type LayoutReducerActionRemoveWidget = {
  type: LayoutReducerActionEnum.REMOVE_WIDGET
  payload: { rowId: string; widgetId: string }
}

export type LayoutReducerAction =
  | LayoutReducerActionRemoveWidget
  | LayoutReducerActionAddWidget
