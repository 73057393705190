import {
  PinInput as ChakraPinInput,
  type ColorMode,
  PinInputField,
  HStack,
} from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type FC } from 'react'
import { type FieldError } from 'react-hook-form'
import { DarkModeHandler } from 'ui/DarkModeHandler'

type PinInputProps = {
  length: number
  value: string
  onChange: (value: string) => void
  colorMode: ColorMode
  error: FieldError | undefined
}

export const PinInput: FC<PinInputProps> = ({
  length,
  value,
  onChange,
  colorMode,
  error,
}) => {
  const isDarkMode = colorMode === 'dark'

  return (
    <DarkModeHandler colorMode={colorMode}>
      <HStack gap={2}>
        <ChakraPinInput
          variant="outline"
          value={value}
          onChange={(input) => onChange(input)}
          otp
          isInvalid={!!error}
        >
          {Array.from({ length }, (_, index) => (
            <PinInputField key={index} data-1p-ignore />
          ))}
        </ChakraPinInput>
      </HStack>
      {error && (
        <Typography
          fontSize="xs"
          color={isDarkMode ? 'red.300' : 'red.500'}
          mt={1}
        >
          {error.message}
        </Typography>
      )}
    </DarkModeHandler>
  )
}
