import { extendTheme } from '@chakra-ui/react'

import { alertTheme } from 'ui/components/alert'
import { badgeTheme } from 'ui/components/badge'
import { buttonTheme } from 'ui/components/button'
import { checkboxTheme } from 'ui/components/checkbox'
import { dividerTheme } from 'ui/components/divider'
import { drawerTheme } from 'ui/components/drawer'
import { inputTheme } from 'ui/components/input'
import { listTheme } from 'ui/components/list'
import { menuTheme } from 'ui/components/menu'
import { modalTheme } from 'ui/components/modal'
import { pinInputTheme } from 'ui/components/pinInput'
import { popoverTheme } from 'ui/components/popover'
import { progressTheme } from 'ui/components/progress'
import { statTheme } from 'ui/components/stat'
import { switchTheme } from 'ui/components/switch'
import { tableTheme } from 'ui/components/table'
import { tagTheme } from 'ui/components/tag'
import { tooltipTheme } from 'ui/components/tooltip'
import { colorTheme } from './colors'
import { fontSizeTheme } from './fonts'
import { shadowsTheme } from './shadows'

export const theme = extendTheme({
  colors: colorTheme,
  components: {
    Alert: alertTheme,
    Badge: badgeTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Divider: dividerTheme,
    Drawer: drawerTheme,
    Input: inputTheme,
    List: listTheme,
    Menu: menuTheme,
    Modal: modalTheme,
    PinInput: pinInputTheme,
    Popover: popoverTheme,
    Progress: progressTheme,
    Stat: statTheme,
    Switch: switchTheme,
    Table: tableTheme,
    Tag: tagTheme,
    Tooltip: tooltipTheme,
  },
  fontSizes: fontSizeTheme,
  shadows: shadowsTheme,
})
