import { z } from 'zod'

export const envConfigSchema = z.object({
  // Required
  VITE_CLERK_PUBLISHABLE_KEY: z.string(),
  VITE_API_URL: z.string(),
  VITE_SUPPORTED_API_VERSION: z.string(),

  // Defaulted
  VITE_PORT: z.string().default('3000'),
  VITE_APP_BASENAME: z.string().default('/'),

  // Optional
  VITE_HOTJAR_ID: z.coerce.number().optional(),
  VITE_HOTJAR_SV: z.coerce.number().optional(),
  VITE_MIXPANEL_ID: z.string().optional(),
  VITE_SENTRY_DSN_KEY: z.string().optional(),
  VITE_INTERCOM_APP_ID: z.string().optional(),
})
