let timer: NodeJS.Timeout

export const defaultTimeout = 300
export const debounce = <T>(
  func: (args?: T) => void,
  timeout = defaultTimeout,
): ((args?: T) => void) => {
  return (args) => {
    clearTimeout(timer)
    timer = setTimeout(() => func(args), timeout)
  }
}
