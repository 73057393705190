import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { useRef } from 'react'
import { useDeleteTeamById } from '../graphql/useDeleteTeamById'

interface DeleteTeamProps {
  team: MerchantTeam
}

export const DeleteTeam: React.FC<DeleteTeamProps> = ({ team }) => {
  const [deleteTeamMutation, deleteTeamState] = useDeleteTeamById()

  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box mt={12}>
      <Typography color="gray.900" lineHeight={6} mb={2}>
        Delete team
      </Typography>
      <Typography
        fontSize="sm"
        lineHeight={5}
        color="gray.800"
        maxW="540px"
        mb={4}
      >
        When deleting the team any team reports that are not shared with another
        team will be set as visible for everyone
      </Typography>
      <Button variant="solid" size="sm" colorScheme="red" onClick={onOpen}>
        Delete team
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight={500}>
              Delete team
            </AlertDialogHeader>

            <AlertDialogBody fontSize="sm">
              The team will be permanently deleted. All the team’s reports that
              are not shared with any other team will be shared with everyone in
              the organisation.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                isLoading={deleteTeamState.loading}
                onClick={() => {
                  deleteTeamMutation(team.id)
                }}
              >
                Delete team
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
