import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useEditReport } from 'graphql/reports/useEditReport'
import { type ReportLabelWithoutCount } from 'graphql/reports/useReportLabels'

type Props = {
  selectedReport: ReportState
  newReportLabels: ReportLabelWithoutCount[]
  oldReportLabels: ReportLabelWithoutCount[]
}

export const useEditReportLabels = () => {
  const [editReport] = useEditReport()
  const [trackEvent] = useTrackEvent()
  const toast = useToast()

  const editReportLabels = async ({
    selectedReport,
    newReportLabels,
    oldReportLabels,
  }: Props) => {
    if (!selectedReport.id) return

    try {
      await editReport({
        id: selectedReport.id,
        report: {
          ...selectedReport,
          labels: newReportLabels,
        },
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not update report labels, please try again',
      })

      captureException(`Update report labels: ${error}`)
    }

    const oldReportLabelIds = oldReportLabels.map((label) => label.id)
    const newSelectedLabelIds = newReportLabels.map((label) => label.id)

    trackEvent({
      eventName: 'Report Labels Changed',
      eventProperties: {
        reportId: selectedReport.id,
        oldLabels: oldReportLabelIds,
        newLabels: newSelectedLabelIds,
      },
    })
  }

  return { editReportLabels }
}
