import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Checkbox } from 'components/Checkbox'
import { useState } from 'react'
import { type AdsMatchingRule } from './AccountsTable/types'

interface Props {
  isOpen: boolean
  onClose: () => void
  accounts?: AdsMatchingRule[]
  connectCallback: (accounts: string[]) => void
}

export const SelectAccountsModal = ({
  isOpen,
  onClose,
  accounts,
  connectCallback,
}: Props) => {
  const [selectedAccounts, setSelectedAccounts] = useState<
    Record<string, AdsMatchingRule>
  >({})

  const isSelected = (id: string) => !!selectedAccounts[id]
  const toggleSelectedAccount = (account: AdsMatchingRule) => {
    setSelectedAccounts((prev) => {
      const newAccounts = { ...prev }

      if (newAccounts[account.accountId]) {
        delete newAccounts[account.accountId]
      } else {
        newAccounts[account.accountId] = account
      }

      return newAccounts
    })
  }

  const onClick = () => {
    connectCallback(
      Object.values(selectedAccounts).map(({ accountId }) => accountId),
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select accounts to connect</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {accounts?.map((account) => (
            <Flex key={account.accountId} alignItems="center" p={2}>
              <Checkbox
                onChange={() => toggleSelectedAccount(account)}
                isChecked={isSelected(account.accountId)}
              >
                {account.name ?? account.accountId}
              </Checkbox>
            </Flex>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={Object.keys(selectedAccounts).length === 0}
            onClick={onClick}
          >
            Connect
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
