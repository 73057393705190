import { type FilterOptionReportFields, type ReportListFilters } from './types'

export const FILTER_OPTION: Record<string, FilterOptionReportFields> = {
  VISIBILITY: 'visibility',
  OWNER: 'owner',
  LABELS: 'labels',
  DATE_CREATED: 'createdAt',
  DATE_UPDATED: 'updatedAt',
} as const

export const FILTER_OPTION_NAME: Record<FilterOptionReportFields, string> = {
  visibility: 'Visibility',
  owner: 'Owner',
  labels: 'Labels',
  createdAt: 'Date created',
  updatedAt: 'Date updated',
} as const

export const initialFilters = Object.values(FILTER_OPTION).reduce(
  (acc, key) => {
    acc[key] = []

    return acc
  },
  {} as ReportListFilters,
)
