import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'

export const WidgetErrorState = () => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      w="full"
      h="inherit"
    >
      <Icon
        name="ExclamationCircleIcon"
        color="red.500"
        customSize={64}
        mb={4}
      />
      <Typography
        fontSize="lg"
        lineHeight={7}
        fontWeight={700}
        color="grey.900"
        textAlign="center"
        mb={1}
      >
        Something went wrong
      </Typography>
      <Typography
        fontSize="sm"
        lineHeight={5}
        fontWeight={400}
        color="grey.900"
        textAlign="center"
      >
        We could not show your data. Please try again later.
      </Typography>
    </Flex>
  )
}
