import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'

export type FeatureFlags = {
  allowSettingsWrite: boolean
  enableCopilot: boolean
  enableOptimizations: boolean
  enableSettings: boolean
  enableDashboard: boolean
  enableCustomDashboard: boolean
  enableProductFeed: boolean
  enableInventoryDashboard: boolean
  enableReturnThreshold: boolean
  enableGeoLift: boolean
}

const FEATURE_FLAGS_QUERY = graphql(/* GraphQL */ `
  query FeatureFlags {
    viewer {
      id
      featureFlags
    }
  }
`)

const emptyObject = {}

export const useFeatureFlags = () => {
  const { data } = useQuery(FEATURE_FLAGS_QUERY)

  return (data?.viewer?.featureFlags ?? emptyObject) as FeatureFlags
}
