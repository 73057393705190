import { useQuery, type QueryHookOptions } from '@apollo/client'
import { graphql } from 'generated/graphql'
import type {
  Channel,
  ChannelGroupsQuery,
  Exact,
} from 'generated/graphql/graphql'
import { useMemo } from 'react'
import type { NormalizedChannels } from './useChannels'

const CHANNEL_GROUPS_QUERY = graphql(/* GraphQL */ `
  query ChannelGroups {
    channelGroups {
      id
      name
      channels {
        id
        name
      }
    }
  }
`)

interface NormalizedChannelGroup {
  id: string
  name: string
  channels: Record<string, Channel>
}

export type NormalizedChannelGroups = Record<string, NormalizedChannelGroup>
type ChannelsPerChannelGroups = Record<string, Channel[]>

const mapDataToChannelGroup = (data?: ChannelGroupsQuery | null) => {
  const channelGroups = data?.channelGroups ?? []

  const normalizedChannelGroups = channelGroups.reduce<NormalizedChannelGroups>(
    (acc, channelGroup) => {
      const { id, channels, ...rest } = channelGroup

      const normalizedChannels: NormalizedChannels = Object.fromEntries(
        channels.map((channel) => [channel.id, channel]),
      )

      acc[id] = { id: id, channels: normalizedChannels, ...rest }

      return acc
    },
    {},
  )

  const channelsPerChannelGroups =
    channelGroups.reduce<ChannelsPerChannelGroups>((acc, channelGroup) => {
      const { id, channels } = channelGroup

      acc[id] = channels.slice().sort((a, b) => a.name.localeCompare(b.name))

      return acc
    }, {})

  return {
    channelGroups,
    normalizedChannelGroups,
    channelsPerChannelGroups,
  }
}

// To use in components
export const useChannelGroups = (
  options?:
    | QueryHookOptions<ChannelGroupsQuery, Exact<{ [key: string]: never }>>
    | undefined,
) => {
  const query = useQuery(CHANNEL_GROUPS_QUERY, options)

  const channelGroupsInfo = useMemo(
    () => mapDataToChannelGroup(query.data),
    [query.data],
  )

  return { ...channelGroupsInfo, query }
}
