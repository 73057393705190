import { createColumnHelper } from '@tanstack/react-table'
import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { NumericInputCell } from 'features/settings/views/shared/cellls/NumericInputCell'
import { ActionCell } from '../shared/cellls/ActionCell'
import { TaxonomyCell } from '../shared/cellls/TaxonomyCell/TaxonomyCell'
import {
  MANUAL_INPUT_COLUMN,
  MANUAL_INPUT_FORMAT,
  defaultCurrencyOptions,
} from '../shared/consts'
import {
  type ColumnId,
  type InputError,
  type ManualInputColumns,
} from '../shared/types'

const normalizedDefaultCurrencyOptions = defaultCurrencyOptions.reduce(
  (acc, next) => {
    acc[next.id] = next

    return acc
  },
  {} as Record<string, DropdownOption>,
)

const columnHelper = createColumnHelper<ManualInputColumns>()

export const LogisticsColumns = [
  columnHelper.accessor(
    (row) => ({ groupName: row.groupName, name: row.name }),
    {
      cell: ({ column, getValue, row, table }) => {
        const { isReadOnly, updateData } = table.options.meta || {}

        const setError = (errorMessage = '') => {
          const error = {
            [MANUAL_INPUT_COLUMN.ERROR]: {
              [MANUAL_INPUT_COLUMN.GROUP_NAME]: errorMessage,
            } as InputError,
          }

          updateData?.(row.id, { ...error })
        }

        return (
          <TaxonomyCell
            columnId={column.id}
            row={row}
            initialValue={getValue()}
            isReadOnly={isReadOnly}
            setSelected={(taxonomy) => updateData?.(row.id, taxonomy)}
            setError={setError}
          />
        )
      },
      header: 'Costs',
    },
  ),
  columnHelper.accessor('currency', {
    cell: ({ getValue, row, column, table }) => {
      const { isReadOnly, updateData } = table.options.meta || {}

      return (
        <ComboBox
          selectionMode={SELECTION_MODE.SINGLE}
          size="sm"
          options={defaultCurrencyOptions}
          isReadOnly={isReadOnly}
          selected={String(
            normalizedDefaultCurrencyOptions[getValue() ?? '']?.id,
          )}
          setSelected={(newValue) =>
            updateData?.(row.id, {
              [column.id]: newValue,
            })
          }
          isFullHeight
        />
      )
    },
    header: 'Currency',
    meta: {
      format: MANUAL_INPUT_FORMAT.CURRENCY,
    },
  }),
  columnHelper.accessor('perOrder', {
    cell: ({ column, getValue, row, table }) => {
      const { isReadOnly, updateData } = table.options.meta || {}

      return (
        <NumericInputCell
          initialValue={getValue()}
          updateData={(value) => updateData?.(row.id, value)}
          format={column.columnDef.meta?.format}
          currency={row.original.currency}
          columnId={column.id as ColumnId}
          error={row.original.error}
          isReadOnly={isReadOnly}
        />
      )
    },
    header: 'Fixed per order',
    meta: {
      format: MANUAL_INPUT_FORMAT.NUMBER_CURRENCY,
    },
  }),
  columnHelper.accessor('perItem', {
    cell: ({ column, getValue, row, table }) => {
      const { isReadOnly, updateData } = table.options.meta || {}

      return (
        <NumericInputCell
          initialValue={getValue()}
          updateData={(value) => updateData?.(row.id, value)}
          format={column.columnDef.meta?.format}
          currency={row.original.currency}
          columnId={column.id as ColumnId}
          error={row.original.error}
          isReadOnly={isReadOnly}
        />
      )
    },
    header: 'Fixed per item',
    meta: {
      format: MANUAL_INPUT_FORMAT.NUMBER_CURRENCY,
    },
  }),
  columnHelper.accessor('percentage', {
    cell: ({ column, getValue, row, table }) => {
      const { isReadOnly, updateData } = table.options.meta || {}

      return (
        <NumericInputCell
          initialValue={getValue()}
          updateData={(value) => updateData?.(row.id, value)}
          format={column.columnDef.meta?.format}
          currency={row.original.currency}
          columnId={column.id as ColumnId}
          error={row.original.error}
          isReadOnly={isReadOnly}
        />
      )
    },
    header: '% of order value',
    meta: {
      format: MANUAL_INPUT_FORMAT.PERCENTAGE,
    },
  }),
  columnHelper.accessor('action', {
    cell: ({ row, table }) => {
      const { isReadOnly, addSubRow, deleteRow } = table.options.meta || {}

      return (
        <ActionCell
          row={row}
          addSubRow={() => addSubRow?.(row.id)}
          deleteRow={() => deleteRow?.(row.id)}
          isDisabled={isReadOnly}
        />
      )
    },
    id: 'actions',
    header: 'Actions',
  }),
]
