import { type DateRange } from 'constants/types'
import { type As } from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { Typography } from 'components/Typography'
import { forwardRef } from 'react'
import { getCompareDateString } from './RangeDatepicker/getCompareDateString/getCompareDateString'

interface DatePreviewProps extends ButtonProps {
  badgeLabel: string | undefined
  actualDate: DateRange
  compareDate?: DateRange
}

export const DatePreview = forwardRef<As, DatePreviewProps>(
  ({ badgeLabel, actualDate, compareDate, ...rest }, ref) => {
    return (
      <Button
        variant="ghost"
        title="Pick a date range"
        colorScheme="gray"
        whiteSpace="normal"
        wordBreak="break-word"
        display="flex"
        flexDir="column"
        height="unset"
        size="sm"
        p={1}
        border="none"
        alignItems="flex-end"
        minW="max-content"
        {...rest}
        ref={ref}
      >
        <Typography
          color="gray.800"
          fontSize="xs"
          fontWeight="semibold"
          lineHeight={4}
        >
          {badgeLabel && <Badge>{badgeLabel}</Badge>}{' '}
          {getCompareDateString(actualDate[0], actualDate[1])}
        </Typography>

        {compareDate && (
          <Typography
            color="grey.600"
            fontSize="2xs"
            lineHeight="14px"
            mt="2px"
          >
            Compare: {getCompareDateString(compareDate[0], compareDate[1])}
          </Typography>
        )}
      </Button>
    )
  },
)

DatePreview.displayName = 'DatePreview'
