import { useMutation } from '@apollo/client'
import { getApolloClient } from 'apollo'
import { analyticsConfigIdAtom } from 'features/reports/atoms/reportViewStateAtom'
import { graphql } from 'generated/graphql'
import { type AnalyticsConfigInput } from 'generated/graphql/graphql'
import { useSetAtom } from 'jotai'
import { omit } from 'lodash-es'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { type OptionalKey } from 'types/optionalKey'
import { v7 as uuidv7 } from 'uuid'
import { AnalyticsConfigFragment } from './fragments'
import { type AnalyticsConfig } from './types'

const CREATE_ANALYTICS_CONFIG_MUTATION = graphql(/* GraphQL */ `
  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {
    createAnalyticsConfig(params: $params) {
      ...AnalyticsConfigFields
    }
  }
`)

export const ANALYTICS_CONFIG_SEARCH_PARAM_KEY = 'state'

// Map of cache keys to config UUIDs. The cache reduces the number of analytics config created
const configIdMap = new Map<string, string>()
const generateCacheKey = (config: Omit<AnalyticsConfigInput, 'id'>) => {
  const sortedKeys = Object.keys(config).sort()

  return sortedKeys
    .map(
      (key) => `${key}:${JSON.stringify(config[key as keyof typeof config])}`,
    )
    .join('|')
}

export const useCreateAnalyticsConfig = ({
  shouldSetUrl = true,
}: { shouldSetUrl?: boolean } = {}): [
  typeof createAnalyticsConfig,
  typeof state,
] => {
  const [mutation, state] = useMutation(CREATE_ANALYTICS_CONFIG_MUTATION)
  const [, setSearchParams] = useSearchParams()
  const setAnalyticsConfigIdAtom = useSetAtom(analyticsConfigIdAtom)

  const createAnalyticsConfig = useCallback(
    async (analyticsConfigInput: OptionalKey<AnalyticsConfigInput, 'id'>) => {
      const cacheKey = generateCacheKey(analyticsConfigInput)
      const cachedConfigId = configIdMap.get(cacheKey)

      if (cachedConfigId) {
        if (shouldSetUrl) {
          setSearchParams({
            [ANALYTICS_CONFIG_SEARCH_PARAM_KEY]: cachedConfigId,
          })
        }

        const apolloClient = getApolloClient()
        const cachedConfig = apolloClient.readFragment({
          id: apolloClient.cache.identify({
            id: cachedConfigId,
            __typename: 'AnalyticsConfig',
          }),
          fragment: AnalyticsConfigFragment,
          fragmentName: 'AnalyticsConfigFields',
        })

        if (cachedConfig) {
          return cachedConfig as unknown as AnalyticsConfig
        }
      }

      const id = uuidv7()
      const inputWithId = {
        ...analyticsConfigInput,
        id,
      }

      configIdMap.set(cacheKey, id)

      const createMutation = await mutation({
        variables: {
          params: omit(inputWithId, [
            '__typename',
          ]) as unknown as AnalyticsConfigInput,
        },
        onCompleted: (data) => {
          if (!data.createAnalyticsConfig) return
          if (shouldSetUrl) {
            setSearchParams({
              [ANALYTICS_CONFIG_SEARCH_PARAM_KEY]:
                data.createAnalyticsConfig.id,
            })
          }

          setAnalyticsConfigIdAtom(data.createAnalyticsConfig.id)
        },
        onError: () => {
          configIdMap.delete(cacheKey)
        },
      })

      const result = createMutation.data
        ?.createAnalyticsConfig as unknown as AnalyticsConfig

      if (!result) throw new Error(createMutation.errors?.[0].message)

      return result
    },
    [mutation, shouldSetUrl, setSearchParams, setAnalyticsConfigIdAtom],
  )

  return [createAnalyticsConfig, state]
}
