import { DiffStatus } from 'constants/statusColors'
import { Flex } from '@chakra-ui/react'
import { StatCard, type StatCardProps } from 'components/StatCard/StatCard'
import type { PropsWithChildren } from 'react'

export type MetricCard = Pick<
  StatCardProps,
  'number' | 'label' | 'helpText' | 'iconName' | 'status'
>
interface MetricCardsProps {
  metrics: MetricCard[] | undefined
  metricCount?: number // for skeleton loader
  isLoading: boolean
  hasError: boolean
}

const MetricCardsWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Flex gap={3} flexWrap="wrap">
      {children}
    </Flex>
  )
}

const cardFlex = '1 1 180px' // 150px becomes a min width here for the card almost
const cardSize = 'lg'

export const MetricCards: React.FC<MetricCardsProps> = ({
  metrics,
  metricCount = 5,
  isLoading,
  hasError,
}) => {
  if (isLoading) {
    return (
      <MetricCardsWrapper>
        {Array(metricCount)
          .fill(0)
          .map((_, index) => (
            <StatCard
              key={index}
              size={cardSize}
              flex={cardFlex}
              status={DiffStatus.NEUTRAL}
              isLoading={true}
              iconName={null}
              label="loading"
              number="loading"
              helpText="loading"
            />
          ))}
      </MetricCardsWrapper>
    )
  }

  if (hasError || !metrics) {
    return (
      <MetricCardsWrapper>
        {Array(metricCount)
          .fill(0)
          .map((_, index) => (
            <StatCard
              key={index}
              size={cardSize}
              flex={cardFlex}
              status={DiffStatus.NEUTRAL}
              iconName={null}
              label="Unknown"
              number="0"
              helpText=""
            />
          ))}
      </MetricCardsWrapper>
    )
  }

  return (
    <MetricCardsWrapper>
      {metrics?.map((metric) => {
        return (
          <StatCard
            key={metric.label}
            size={cardSize}
            flex={cardFlex}
            {...metric}
          />
        )
      })}
    </MetricCardsWrapper>
  )
}
