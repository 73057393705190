import { type ColorMode, DarkMode } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

interface Props {
  colorMode?: ColorMode
}

// Component used to force dark mode in Chakra components when colorMode is set to "dark"
export const DarkModeHandler = ({
  colorMode = 'light',
  children,
}: PropsWithChildren<Props>) => {
  return colorMode === 'dark' ? (
    <DarkMode>{children}</DarkMode>
  ) : (
    <>{children}</>
  )
}
