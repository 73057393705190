import { useAuth } from '@clerk/clerk-react'
import { config } from 'config'

type IntegrationAuthorizationUrlProps = {
  callbackURL: string
  merchantId?: string
  frontendId?: string
}

export const useIntegrationAuthorizationURL = ({
  callbackURL,
  merchantId,
  frontendId,
}: IntegrationAuthorizationUrlProps) => {
  const { getToken } = useAuth()

  const getIntegrationAuthorizationUrl = async (serviceId: string) => {
    const params = new URLSearchParams({ callbackURL })

    if (!frontendId || !merchantId) return null
    const token = await getToken()

    if (!token) return null

    return `${
      config.API_URL
    }integration/${merchantId}/${frontendId}/${serviceId}/connect?${params.toString()}`
  }

  return getIntegrationAuthorizationUrl
}
