import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCBoost from 'highcharts/modules/boost'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { useMemo } from 'react'
import { colorTheme } from 'ui/theme/colors'
import { staticChartOptions } from 'utils/chart/constants'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HCBoost(Highcharts)

export const ReturnThresholdChart = ({
  data,
  returnThreshold,
}: {
  data: number[][]
  returnThreshold: number
}) => {
  const options = useMemo(
    () => ({
      ...staticChartOptions,
      plotOptions: {
        ...staticChartOptions.plotOptions,
        series: {
          ...staticChartOptions.plotOptions.series,
          stickyTracking: true,
        },
      },
      series: [
        {
          id: 'return-threshold-curve',
          name: 'Percentage of returns',
          type: 'line',
          data,
        },
      ],
      xAxis: {
        ...staticChartOptions.xAxis,
        title: { text: 'Days until return' },
        plotLines: [
          {
            value: returnThreshold,
            label: {
              align: 'left',
              text: 'Threshold',
              rotation: 0,
              useHTML: true,
              style: {
                backgroundColor: colorTheme.orange[100],
                color: colorTheme.orange[700],
                paddingLeft: '4px',
                paddingRight: '4px',
                fontWeight: '600',
                fontSize: '10px',
                lineHeight: '12px',
                borderRadius: 2,
                textTransform: 'uppercase',
              },
              x: -1,
            },
            color: colorTheme.orange[100],
          },
        ],
        crosshair: {
          color: colorTheme.grey[100],
          dashStyle: 'Solid',
        },
      },
      yAxis: {
        ...staticChartOptions.yAxis,
        title: { text: 'Percentage of returns' },
      },
      legend: { ...staticChartOptions.legend, enabled: false },
      tooltip: {
        ...staticChartOptions.tooltip,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          const roundedPercentage = Math.round(Number(this.point.y) * 100) / 100

          return `Days until return: <b>${this.point.category}</b></br>Percentage of returns: <b>${roundedPercentage}%</b>`
        },
      },
    }),
    [data, returnThreshold],
  )

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { width: '100%' } }}
    />
  )
}
