import { Box } from '@chakra-ui/react'
import { Input } from 'components/Input'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import { Typography } from 'components/Typography'
import { TARGETS, type TargetType } from 'features/optimizations/consts'
import { useFormContext } from 'react-hook-form'
import { BudgetSection } from './BudgetSection'
import { type SidebarFormState } from './types'

const sections: { id: TargetType; label: string }[] = [
  { id: 'budget', label: 'Spend' },
  { id: 'roas', label: 'ROAS' },
  { id: 'ep_roas', label: 'epROAS' },
]

const budgetSectionTargets: TargetType[] = [
  TARGETS.BUDGET,
  TARGETS.OPTIMAL_BUDGET,
]

export const TargetSection = () => {
  const {
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useFormContext<SidebarFormState>()
  const selectedTarget = getValues('selectedTarget')

  return (
    <Box px={6} py={4} overflowY="auto">
      <Typography
        fontSize="sm"
        color="gray.700"
        fontWeight={600}
        lineHeight={5}
        mb={4}
      >
        Target
      </Typography>
      <Box mb={6}>
        <ToggleWithLabels
          items={sections}
          selected={
            selectedTarget === TARGETS.OPTIMAL_BUDGET
              ? TARGETS.BUDGET
              : selectedTarget
          }
          buttonProps={{
            h: '28px',
            minH: '28px',
            width: '100%',
          }}
          w="full"
          setSelected={(path) =>
            setValue('selectedTarget', path, { shouldValidate: true })
          }
        />
      </Box>
      {budgetSectionTargets.includes(selectedTarget) && <BudgetSection />}
      {selectedTarget === TARGETS.ROAS && (
        <Input
          label="ROAS target"
          size="sm"
          type="number"
          {...register(`target.${TARGETS.ROAS}`)}
          placeholder="100"
          error={errors.target?.[TARGETS.ROAS]}
          helperText="%"
        />
      )}
      {selectedTarget === TARGETS.EP_ROAS && (
        <Input
          label="epROAS target"
          size="sm"
          placeholder="100"
          type="number"
          {...register(`target.${TARGETS.EP_ROAS}`)}
          helperText="%"
          error={errors.target?.[TARGETS.EP_ROAS]}
        />
      )}
    </Box>
  )
}
