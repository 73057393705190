import { Box, Flex } from '@chakra-ui/react'
import type React from 'react'
import { Logo } from 'shared/Logo/Logo'

interface ContainerWrapperProps {
  children: React.ReactNode
}

export const ContainerWrapper: React.FC<ContainerWrapperProps> = ({
  children,
}) => {
  return (
    <>
      <Box pos="absolute" left={8} top={7}>
        <Logo color="white" />
      </Box>

      <Flex
        bgColor="grey.950"
        minH="100vh"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        px={4}
        py={10}
      >
        <Box maxW="340px" w="full">
          {children}
        </Box>
      </Flex>
    </>
  )
}
