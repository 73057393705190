import { useState } from 'react'
import { ContainerWrapper } from '../../components/ContainerWrapper'
import { EmailInputForm } from './EmailInputForm'
import { PasswordInputForm } from './PasswordInputForm'

const PasswordReset: React.FC = () => {
  const [resetCodeRequested, setResetCodeRequested] = useState(false)

  return (
    <ContainerWrapper>
      {resetCodeRequested ? (
        <PasswordInputForm colorMode="dark" />
      ) : (
        <EmailInputForm setResetCodeRequested={setResetCodeRequested} />
      )}
    </ContainerWrapper>
  )
}

export default PasswordReset
