import { Box, Flex, MenuButton } from '@chakra-ui/react'
import { type DropdownGroup } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'

interface Props {
  title: string
  subTitle: string
  groups: DropdownGroup[]
  selectedItems: string[]
  onSelectedItemsChange: (selectedItems: string[]) => void
}

export const DataPicker: FC<Props> = ({
  title,
  subTitle,
  groups,
  selectedItems,
  onSelectedItemsChange,
}) => {
  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      searchPlaceholder="Search metrics"
      customMenuButton={
        <MenuButton
          p={4}
          w="full"
          border="solid 1px"
          borderColor="gray.400"
          _hover={{ bg: 'gray.100', borderColor: 'gray.500' }}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Box textAlign="left">
              <Typography color="gray.800" lineHeight={6}>
                {title}
              </Typography>
              <Typography fontSize="sm" color="gray.700" lineHeight={5}>
                {subTitle}
              </Typography>
            </Box>
            <Box>
              <Icon name="ToggleHorizontalIcon" size="small" />
            </Box>
          </Flex>
        </MenuButton>
      }
      options={groups}
      selected={selectedItems}
      setSelected={onSelectedItemsChange}
      isFullHeight
    />
  )
}
