import {
  Icon as ChakraIcon,
  type PlacementWithLogical,
  type IconProps as ChakraIconProps,
} from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { forwardRef, type SVGProps, type MouseEvent } from 'react'
import { type graphIcons, icons } from './icons'

export type IconName = keyof typeof icons
export type GraphIconName = keyof typeof graphIcons

export type IconSize = 'small' | 'medium' | 'large'
const iconSizes: Record<IconSize, number> = { large: 24, medium: 20, small: 16 }

const getIconSize = (size: IconSize, customSize: number | undefined) => {
  return (customSize ? customSize : iconSizes[size]) + 'px'
}

const getTooltipSize = (placement: PlacementWithLogical | undefined) => {
  // the arrow of left/right tooltip with sm size is not aligned
  return placement && ['left', 'right'].includes(placement) ? 'md' : 'sm'
}

export type IconProps = Omit<SVGProps<SVGSVGElement>, 'ref' | 'type'> &
  Omit<ChakraIconProps, 'type'> & {
    name: IconName
    hexColor?: string // incase you want to use hex color, will override the color prop
    customSize?: number
    size?: IconSize
    clickable?: boolean
    tooltipLabel?: string
    tooltipPlacement?: PlacementWithLogical
    onClick?: (event: MouseEvent<SVGSVGElement>) => void
  }

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      name,
      color,
      hexColor,
      size = 'large',
      customSize,
      clickable = true,
      tooltipLabel,
      tooltipPlacement,
      onClick,
      width,
      height,
      ...rest
    },
    ref,
  ) => {
    const IconComponent = icons[name]

    if (!IconComponent) {
      captureException(`Unknown icon name: ${name}`)

      return <></>
    }

    const IconToBeDisplayed = (
      <ChakraIcon
        as={IconComponent}
        ref={ref}
        color={hexColor ?? color ?? 'inherit'}
        flexShrink={0}
        minW={width ?? getIconSize(size, customSize)}
        maxW={width ?? getIconSize(size, customSize)}
        minH={height ?? getIconSize(size, customSize)}
        maxH={height ?? getIconSize(size, customSize)}
        width={width}
        height={height}
        cursor={clickable && !!onClick ? 'pointer' : 'inherit'}
        onClick={onClick}
        // if there is a tooltip, we don't want show arial-label
        aria-label={tooltipLabel ? undefined : name}
        {...rest}
      />
    )

    if (tooltipLabel)
      return (
        <Tooltip
          placement={tooltipPlacement}
          label={tooltipLabel}
          size={getTooltipSize(tooltipPlacement)}
        >
          {IconToBeDisplayed}
        </Tooltip>
      )

    return IconToBeDisplayed
  },
)

Icon.displayName = 'Icon'
