import {
  useCombobox,
  type UseComboboxState,
  type UseComboboxStateChangeOptions,
} from 'downshift'
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type RefObject,
} from 'react'
import {
  type ComboBoxOption,
  type DropdownGroup,
  type DropdownOption,
} from '../types'

const getOptionsFilter = (inputValue: string | undefined) => {
  const lowerCasedInputValue = inputValue?.toLowerCase().trim() ?? ''

  return function optionsFilter(option: DropdownOption) {
    return option.name.toLowerCase().includes(lowerCasedInputValue)
  }
}

export const getFilteredOptions = <T extends DropdownOption>(
  options: ComboBoxOption<T>[],
  inputValue: string,
) => {
  const optionsFilter = getOptionsFilter(inputValue)
  const updatedFilteredOptions: ComboBoxOption<T>[] = []

  // If it is group we filter on items. Otherwise we filter on the option itself
  for (const option of options) {
    if (isGroupOption(option)) {
      const filteredItems = option.items.filter(optionsFilter)

      if (filteredItems.length > 0) {
        updatedFilteredOptions.push({
          ...option,
          items: filteredItems,
        })
      }
    } else if (optionsFilter(option)) {
      updatedFilteredOptions.push(option)
    }
  }

  return updatedFilteredOptions
}

const createSelectedGroup = <T extends DropdownOption>(
  options: DropdownGroup<T>[],
  selectedSet: Set<string>,
): DropdownGroup<T>[] => {
  return [
    {
      name: 'Selected',
      items: options.flatMap((group) =>
        group.items.filter((item) => selectedSet.has(String(item.id))),
      ),
      showDeselect: true,
    },
    ...options,
  ]
}

export const sortOptions = <T extends DropdownOption>(
  options: ComboBoxOption<T>[],
  selected: string[] = [],
) => {
  const selectedFilters: T[] = []
  const unselectedFilters: T[] = []
  const selectedSet = new Set(selected)

  for (const option of options) {
    if (isGroupOption(option)) {
      // If options contains groups, add Selected group and return as is
      return selected.length > 0
        ? createSelectedGroup(options as DropdownGroup<T>[], selectedSet)
        : options
    }
    if (selectedSet.has(String(option.id))) {
      selectedFilters.push(option)
    } else {
      unselectedFilters.push(option)
    }
  }

  return selectedFilters.concat(unselectedFilters)
}

export const handleStateChanges = (
  state: UseComboboxState<DropdownOption>,
  actionAndChanges: UseComboboxStateChangeOptions<DropdownOption>,
) => {
  const { changes, type } = actionAndChanges
  const { InputKeyDownEnter, ItemClick, InputChange } =
    useCombobox.stateChangeTypes

  switch (type) {
    case ItemClick:
    case InputKeyDownEnter:
      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        inputValue: state.inputValue,
      }

    case InputChange: {
      return changes
    }
    default:
      return {
        ...changes,
        inputValue: state.inputValue,
      }
  }
}

export const useFullHeight = <T extends HTMLElement>(
  popoverRef: RefObject<T>,
  enabled?: boolean,
) => {
  const [popupHeight, setPopupHeight] = useState(0)

  useEffect(() => {
    const updatePopupHeight = () => {
      if (popoverRef?.current) {
        const popoverContainerBox = popoverRef.current.getBoundingClientRect()
        const { clientHeight } = popoverRef.current
        const bottomOffset = 30 // pixels from bottom

        const shouldPlacePopoverOnBottom =
          popoverContainerBox.bottom > popoverContainerBox.top

        startTransition(() => {
          setPopupHeight(
            window.innerHeight -
              (shouldPlacePopoverOnBottom
                ? popoverContainerBox.bottom
                : popoverContainerBox.top) -
              clientHeight -
              bottomOffset,
          )
        })
      }
    }

    if (enabled) {
      updatePopupHeight()

      window.addEventListener('resize', () => {
        updatePopupHeight()
      })

      return () =>
        window.removeEventListener('resize', () => {
          updatePopupHeight()
        })
    }
  }, [popoverRef, enabled])

  return popupHeight
}

export const isGroupOption = <T extends DropdownOption>(
  item: ComboBoxOption<T>,
): item is DropdownGroup<T> => 'items' in item

export const hasGroupOptions = <T extends DropdownOption>(
  options: ComboBoxOption<T>[],
): options is DropdownGroup<T>[] => options[0] && isGroupOption(options[0])

export const useSelectDeselect = ({
  options,
  selected,
  enabled = true,
}: {
  options: DropdownOption[]
  selected?: string[] | string
  enabled?: boolean
}) => {
  const optionIds = useMemo(
    () =>
      enabled
        ? options
            .filter((option) => !option.disabled)
            .map((option) => String(option.id))
        : undefined,
    [options, enabled],
  )
  const isAllSelected = useMemo(() => {
    if (!enabled || (optionIds?.length ?? 0) === 0) {
      return false
    }
    const selectedSet = selected
      ? new Set(Array.isArray(selected) ? selected : [selected])
      : new Set()

    return optionIds?.every((id) => selectedSet.has(id))
  }, [enabled, optionIds, selected])

  const toggleAllSelected = useCallback<(prev: string[]) => string[]>(
    (prev) => {
      const optionsSet = new Set(optionIds)
      const prevExcludingFilteredOptions = prev.filter(
        (id) => !optionsSet.has(id),
      )

      return isAllSelected
        ? // If all are selected, we remove the optionIds
          prevExcludingFilteredOptions
        : // If not, we add all the optionIds making sure we don't insert duplicates
          prevExcludingFilteredOptions.concat([...optionsSet])
    },
    [isAllSelected, optionIds],
  )

  return { isAllSelected, toggleAllSelected }
}
