import { Flex } from '@chakra-ui/react'

import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { useReturnThreshold } from './graphql/useReturnThreshold'
import { ReturnThresholdContent } from './ReturnThresholdContent'

export const ReturnThresholdView = () => {
  const { returnThreshold, returnThresholdCurve, loading } =
    useReturnThreshold()

  return (
    <>
      <TitleLayoutView
        title="Returns threshold"
        description="Specify the number of days after an order is placed to apply standard costs. Beyond this threshold, actual return data will be used for more accurate analysis. You can see your return patterns in the chart to help you select a threshold that fits your needs."
      >
        <Flex minH={400} gap={6} flexWrap="wrap">
          {!loading && !returnThresholdCurve?.length ? (
            <Flex justifyContent="center" alignItems="center" w="full">
              <EmptyData label="No return data found. Please contact us for information and support" />
            </Flex>
          ) : (
            <ReturnThresholdContent
              isLoading={loading}
              key={returnThreshold}
              returnThreshold={returnThreshold}
              returnThresholdCurve={returnThresholdCurve}
            />
          )}
        </Flex>
      </TitleLayoutView>
    </>
  )
}
