import {
  Box,
  Flex,
  Menu,
  MenuList,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { GroupHeader } from 'components/Filters/shared/GroupHeader'
import { Loader } from 'components/Loader/Loader'
import { useCombobox } from 'downshift'
import {
  cloneElement,
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MenuButton } from '../../components/MenuButton'
import { MenuOverlay } from '../../components/MenuOverlay'
import { NothingFound } from '../../NothingFound'
import {
  type ComboBoxOption,
  type ComboBoxSingleProps,
  type DropdownOption,
} from '../../types'
import { ComboBoxItems } from '../components/ComboBoxItems'
import { FilterInput } from '../components/FilterInput'
import {
  getFilteredOptions,
  handleStateChanges,
  isGroupOption,
  sortOptions,
  useFullHeight,
} from '../utils'

export const ComboBoxSingle = <
  TItem extends DropdownOption,
  T extends ComboBoxOption<TItem>,
>({
  options,
  buttonText,
  customHeader,
  customInput,
  customMenuButton,
  customEmptyState,
  customFooter,
  headerLabel,
  placeholder,
  searchPlaceholder,
  selected,
  variant = 'outline',
  size = 'md',
  menuProps,
  listProps,
  error,
  isLoading,
  isDisabled = false,
  isReadOnly = false,
  isFullHeight,
  matchWidth = true,
  isOpenByDefault,
  isOpen: isOpenProps,
  onOpen: onOpenProps,
  onClose: onCloseProps,
  setSelected,
  setSearchValue,
  onOpenChange,
  itemWrapper,
  extendedPopoverContent,
}: ComboBoxSingleProps<TItem, T>) => {
  const [inputValue, setInputValue] = useState('')
  const sortedOptions = useMemo(
    () => sortOptions(options, selected ? [selected] : []),
    [options, selected],
  )
  const filteredOptions = useMemo(() => {
    return getFilteredOptions(sortedOptions, inputValue)
  }, [sortedOptions, inputValue])

  const flattenedFilteredOptions = useMemo(() => {
    return filteredOptions.flatMap((item) =>
      isGroupOption(item) ? item.items : item,
    )
  }, [filteredOptions])

  const listRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: isOpenByDefault,
    isOpen: isOpenProps,
    onOpen: onOpenProps,
    onClose: onCloseProps,
  })
  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupHeight = useFullHeight(buttonRef, isFullHeight)

  useEffect(() => {
    if (isOpen) {
      // We reset input value on open to avoid some visual glitch that would happen when resetting when closing
      setInputValue('')
    }
    onOpenChange?.(isOpen)
  }, [isOpen, onOpenChange])

  const defaultButtonText = useMemo(
    () =>
      options.reduce<string | undefined>((acc, option) => {
        if (isGroupOption(option)) {
          const foundItem = option.items.find((item) => item.id === selected)

          if (foundItem) {
            return foundItem.name
          }
        } else if (option.id === selected) {
          return option.name
        }

        return acc
      }, undefined),
    [options, selected],
  )

  const {
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    isOpen,
    inputValue,
    items: flattenedFilteredOptions,
    isItemDisabled(item) {
      return item?.disabled ?? false
    },
    itemToString: (item) => item?.name ?? '',
    onInputValueChange: ({ inputValue }) => {
      startTransition(() => {
        setInputValue(inputValue ?? '')
        setSearchValue?.(inputValue ?? '')
      })
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return

      onClose()
      setSelected(selectedItem.id.toString())
    },
    stateReducer: handleStateChanges,
  })

  const listMaxHeight = isFullHeight ? `max(350px,${popupHeight}px)` : '350px'

  const showPopoverContent =
    extendedPopoverContent && flattenedFilteredOptions[highlightedIndex]

  return (
    <Menu
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      variant={variant}
      isLazy={true}
      gutter={2} // offset from button
      matchWidth={matchWidth}
      size={size}
      initialFocusRef={inputRef}
      {...menuProps}
    >
      <MenuButton
        buttonProps={getToggleButtonProps({ ref: buttonRef })}
        error={error}
        size={size}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        isOpen={isOpen}
        customMenuButton={customMenuButton}
        buttonText={buttonText || defaultButtonText}
        placeholder={placeholder}
        variant={variant}
      />
      <Portal>
        <MenuOverlay isOpen={isOpen} />
        <Popover
          trigger="hover"
          isLazy
          placement="left-start"
          strategy="fixed"
          gutter={1}
        >
          <MenuList
            listStyleType="none"
            py={0}
            maxH={listMaxHeight}
            maxW="600px"
            h="fit-content"
            display="flex"
            flexDir="column"
            overflow="hidden"
            onClick={(e) => e.stopPropagation()}
            {...listProps}
            {...getMenuProps({ ref: listRef }, { suppressRefError: true })}
          >
            <PopoverAnchor>
              <Box />
            </PopoverAnchor>
            {customHeader ||
              (headerLabel && <GroupHeader label={headerLabel} />)}
            {customInput ? (
              cloneElement(customInput, {
                ...getInputProps(
                  { ref: inputRef, value: inputValue },
                  { suppressRefError: true },
                ),
              })
            ) : (
              <FilterInput
                labelProps={getLabelProps()}
                inputProps={getInputProps(
                  { ref: inputRef, value: inputValue },
                  { suppressRefError: true },
                )}
                placeholder={searchPlaceholder}
              />
            )}
            <Flex flex={1} minH={0} flexDir="column">
              {isLoading ? (
                <Flex p={3} justifyContent="center" alignItems="center">
                  <Loader size="small" />
                </Flex>
              ) : filteredOptions.length === 0 ? (
                customEmptyState || <NothingFound />
              ) : (
                <ComboBoxItems
                  size={size}
                  filteredOptions={filteredOptions}
                  selected={selected}
                  getItemProps={getItemProps}
                  highlightedIndex={highlightedIndex}
                  itemWrapper={itemWrapper}
                />
              )}
            </Flex>
            {customFooter}
          </MenuList>
          {showPopoverContent && (
            <PopoverContent
              display={{ base: 'none', md: 'block' }}
              maxH={listMaxHeight}
              overflowY="auto"
              boxShadow="md"
            >
              {extendedPopoverContent({
                item: flattenedFilteredOptions[highlightedIndex],
              })}
            </PopoverContent>
          )}
        </Popover>
      </Portal>
    </Menu>
  )
}
