import { type Filter } from 'constants/types'
import { Flex, MenuButton, Skeleton, type TextProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type DropdownOption } from 'components/Dropdown'
import { useFilterOperator } from 'components/Filters/useFilterOperator'
import { Typography } from 'components/Typography'
import { type Metric } from 'graphql/statistics/useMetrics'
import { forwardRef } from 'react'

const textStyles: TextProps = {
  fontSize: 'sm',
  lineHeight: 5,
}

type Props = {
  label: string
  filter: Filter
  options: DropdownOption[]
  metric?: Metric
  isLoading?: boolean
  onClick?: () => void
}

export const PopupButton = forwardRef<HTMLDivElement, Props>(
  ({ label, filter, options, metric, isLoading, onClick }, ref) => {
    const values = Array.isArray(filter.value) ? filter.value : [filter.value]

    const { selectedValues, operatorText } = useFilterOperator({
      values,
      operator: filter.comparisonId,
      options,
      metricFormat: metric?.format,
      maxValue: filter.maxValue,
    })

    return (
      <MenuButton
        as={Button}
        ref={ref}
        size="sm"
        colorScheme="gray"
        onClick={onClick}
        height="fit-content"
        pr={2}
      >
        <Flex gap={1} flexWrap="wrap" py={1}>
          <Typography {...textStyles}>{label}</Typography>
          <Typography {...textStyles}>{operatorText}</Typography>
          {isLoading ? (
            <Skeleton height="20px" width="50px" />
          ) : (
            <Typography {...textStyles} isTruncated fontWeight="medium">
              {selectedValues}
            </Typography>
          )}
        </Flex>
      </MenuButton>
    )
  },
)

PopupButton.displayName = 'PopupButton'
