import {
  INTEGRATION_TYPES,
  type IntegrationType,
  type RedirectIntegrationType,
  availableIntegrations,
} from 'constants/availableIntegrations'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
} from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'

import { Typography } from 'components/Typography'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type { FC } from 'react'
import { Connector } from './Connector'
import { useIntegrationAuthorizationURL } from './utils'

type ConnectorProps = {
  serviceId: string
  redirectIntegration: RedirectIntegrationType
  type: IntegrationType
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const AddModal: FC<Props> = ({ isOpen, onClose }) => {
  const { merchantId, frontendId } = useMerchantInfo()
  const getIntegrationAuthorizationURL = useIntegrationAuthorizationURL({
    callbackURL: window.location.href,
    merchantId,
    frontendId,
  })

  const onClickConnector = async ({
    serviceId,
    redirectIntegration,
  }: ConnectorProps) => {
    if (redirectIntegration === 'api') {
      const authorizationURL = await getIntegrationAuthorizationURL(serviceId)

      if (authorizationURL) {
        window.location.href = authorizationURL
      }
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add data connection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Typography fontSize="sm" fontWeight={500} lineHeight={5}>
            Marketing data
          </Typography>
          <SimpleGrid mt={2} columns={2} spacing={4}>
            {availableIntegrations[INTEGRATION_TYPES.MARKETING].map(
              ({
                channel,
                title,
                logo,
                serviceId,
                redirectIntegration,
                type,
              }) => (
                <Connector
                  key={channel}
                  name={title}
                  logo={`/images/${logo}`}
                  onClick={() =>
                    onClickConnector({ serviceId, redirectIntegration, type })
                  }
                />
              ),
            )}
          </SimpleGrid>
          <Alert
            mt={4}
            status="info"
            content={
              <>
                Please contact us for information and support in integrating
                your order and inventory data to the dema.ai platform.
              </>
            }
            closable={false}
          ></Alert>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
