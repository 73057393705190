import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useRef } from 'react'

export interface RemoveImageModalProps {
  onRemove: () => void
  removeTitle: string
  removeDescription: string
}

export const RemoveImageModal: React.FC<RemoveImageModalProps> = ({
  onRemove,
  removeTitle,
  removeDescription,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)

  const removePhotoAndCloseModalHandler = () => {
    onRemove()
    onClose()
  }

  return (
    <Box>
      <Button variant="link" size="sm" colorScheme="primary" onClick={onOpen}>
        {removeTitle}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight={500}>
              {removeTitle}
            </AlertDialogHeader>

            <AlertDialogBody>{removeDescription}</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                onClick={removePhotoAndCloseModalHandler}
              >
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
