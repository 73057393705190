import { type ColumnId, type CurrencyOptions } from './types'

export const MANUAL_INPUT_COLUMN: Record<string, ColumnId> = {
  CURRENCY: 'currency',
  GROUP_NAME: 'groupName',
  ERROR: 'error',
  NAME: 'name',
  PER_ORDER: 'perOrder',
  PER_ITEM: 'perItem',
  PERCENTAGE: 'percentage',
  SUB_ROWS: 'subRows',
  TYPE: 'type',
} as const

export const MANUAL_INPUT_FORMAT = {
  CURRENCY: 'currency', // Used for 'SEK'
  NUMBER: 'number',
  NUMBER_CURRENCY: 'numberCurrency', // Used for 0 'SEK'
  PERCENTAGE: 'percentage',
  STRING: 'string', //default
} as const

export const TAXONOMY_TYPE = {
  COGS_ADJUSTMENT: 'cogsAdjustment', // currently not used
  GROSS_MARGIN: 'grossMargin',
  RETURN_PERCENTAGE: 'returnPercentage',
  RETURN_TRANSACTION: 'returnTransaction',
  RETURN_SHIPPING: 'returnShipping',
  RETURN_PICK_AND_PACK: 'returnPickAndPack',
  RETURN_TOLL: 'returnToll',
  LOGISTIC_TRANSACTION: 'logisticTransaction',
  LOGISTIC_PICK_AND_PACK: 'logisticPickAndPack',
  LOGISTIC_TOLL: 'logisticToll',
  LOGISTIC_SHIPPING: 'logisticShipping',
} as const

export const TAXONOMY_NAME = {
  COGS_ADJUSTMENT: 'Cogs Adjustment',
  GROSS_MARGIN: 'Gross Margin',
  RETURN_PERCENTAGE: 'Return percentage',
  RETURN_TRANSACTION: 'Transaction costs',
  RETURN_SHIPPING: 'Shipping costs',
  RETURN_PICK_AND_PACK: 'Pick and pack costs',
  RETURN_TOLL: 'Customs costs',
  LOGISTIC_TRANSACTION: 'Transaction costs',
  LOGISTIC_SHIPPING: 'Shipping costs',
  LOGISTIC_PICK_AND_PACK: 'Pick and pack costs',
  LOGISTIC_TOLL: 'Customs costs',
} as const

export const DEFAULT_CURRENCIES = [
  'AUD',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'INR',
  'JPY',
  'NOK',
  'SEK',
  'USD',
] as const

export const defaultCurrencyOptions: CurrencyOptions[] = DEFAULT_CURRENCIES.map(
  (currency) => ({ id: currency, name: currency }),
)
