import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { useCallback } from 'react'

const DELETE_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteReportMutation($id: ID!) {
    deleteReport(id: $id) {
      id
      __typename
    }
  }
`)

type DeleteReportParams = {
  id: string
}

export const useDeleteReport = (): [typeof deleteReport, typeof state] => {
  const [mutation, state] = useMutation(DELETE_REPORT_MUTATION)

  const deleteReport = useCallback(
    ({ id }: DeleteReportParams) => {
      return mutation({
        variables: {
          id,
        },
        optimisticResponse: {
          deleteReport: {
            id,
            __typename: 'Report',
          },
        },
        update: (cache, { data: deleteReport }) => {
          // Optimistically remove the report from the cache
          if (!deleteReport?.deleteReport) return

          cache.evict({
            id: cache.identify(deleteReport?.deleteReport),
          })
          cache.gc()
        },
      })
    },
    [mutation],
  )

  return [deleteReport, state]
}
