import { Divider } from '@chakra-ui/react'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { OrderTracking } from './OrderTracking/OrderTracking'
import { PageViewTracking } from './PageViewTracking/PageViewTracking'

export const TrackerView = () => {
  return (
    <TitleLayoutView
      title="Tracker"
      description="Use the instructions below to set up your trackers and capture your data to Dema."
    >
      <Divider my={6} />
      <OrderTracking />
      <Divider my={6} />
      <PageViewTracking />
    </TitleLayoutView>
  )
}
