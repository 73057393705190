import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { graphql } from 'generated/graphql'
import { type IntegrationsViewQueryQuery } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { AccountCell } from './AccountCell'
import { ConnectionButton } from './ConnectionButton'
import { type AccountConnection } from './types'
import { generateIntegrationAccountsForTable } from './utils'

const columnHelper = createColumnHelper<AccountConnection>()

const getConnectedOpacity = (connected: boolean) => (connected ? '100%' : '50%')

const columns = [
  columnHelper.accessor('account', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        <AccountCell
          accounts={getValue().accounts}
          company={getValue().company}
          logo={getValue().logo}
        />
      </Flex>
    ),
    header: 'Account',
  }),
  columnHelper.accessor('status', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        {getValue()}
      </Flex>
    ),
    header: 'Status',
  }),
  columnHelper.accessor('type', {
    cell: ({ row, getValue }) => (
      <Flex opacity={getConnectedOpacity(row.original.connected)}>
        {getValue()}
      </Flex>
    ),
    header: 'Type',
  }),
  columnHelper.accessor('disconnectData', {
    cell: ({ row, getValue }) => (
      <ConnectionButton
        connected={row.original.connected}
        serviceName={row.original.account.company}
        {...getValue()}
      />
    ),
    id: 'disconnectButton',
    header: '',
  }),
]

const EmptyTable = () => (
  <Tr>
    <Td colSpan={100} border="unset">
      <EmptyData label="You haven't added any integrations yet." />
    </Td>
  </Tr>
)

export const Integrations_MerchantSiteFragment = graphql(/* GraphQL */ `
  fragment Integrations_MerchantSite on MerchantSite {
    integrations {
      ...IntegrationFields
    }
  }
`)

type AccountsTableProps = {
  merchantSite: NonNullable<
    IntegrationsViewQueryQuery['viewer']
  >['merchantSite']
  isReadOnly: boolean
  isLoading: boolean
}

export const AccountsTable = ({
  merchantSite,
  isReadOnly,
  isLoading,
}: AccountsTableProps) => {
  const accounts = useMemo(
    () => generateIntegrationAccountsForTable(merchantSite?.integrations ?? []),
    [merchantSite],
  )

  const filteredColumns = useMemo(() => {
    return isReadOnly
      ? columns.filter((column) => column.id !== 'disconnectButton')
      : columns
  }, [isReadOnly])

  const table = useReactTable({
    data: accounts,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header as string,
                      header.getContext(),
                    )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isLoading ? (
            <SkeletonTableRows columns={columns.length} rows={7} />
          ) : tableRows.length === 0 ? (
            <EmptyTable />
          ) : (
            tableRows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    minW="130px"
                    alignContent="baseline"
                    _last={{ w: '1px' }} // button column should not expand more than needed
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
