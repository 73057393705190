export const formatDate = (date: Date) => {
  const locale =
    navigator.languages != undefined ? navigator.languages[0] : 'sv-SE'

  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  })
}
