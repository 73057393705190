import { Flex } from '@chakra-ui/react'
import { Checkbox } from 'components/Checkbox'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { type ReactElement, useRef } from 'react'
import { type DropdownOption } from '../types'

interface Props<TItem extends DropdownOption> {
  leftItem?: ReactElement
  rightItem?: ReactElement
  option: TItem
  isDisabled?: boolean
  isOption?: boolean
  isSelected?: boolean
}

export const MenuItemText = <TItem extends DropdownOption>({
  leftItem,
  rightItem,
  option,
  isDisabled,
  isOption,
  isSelected,
}: Props<TItem>) => {
  const textRef = useRef<HTMLParagraphElement>(null)
  const isOverflowing = useIsOverflowing(textRef)

  return (
    <Tooltip isDisabled={isDisabled || !isOverflowing} label={option.name}>
      <Flex w="full" justifyContent="space-between" alignItems="center" gap={2}>
        <Flex gap={2} alignItems="center" overflowX="hidden">
          {isOption && <Checkbox size="sm" isChecked={isSelected} />}
          {leftItem}
          <Typography
            ref={textRef}
            isTruncated
            color={isDisabled ? 'grey.400' : 'inherit'}
          >
            {option.name}
            {option.subLabel && (
              <Typography as="span" fontSize="xs" color="gray.500">
                {' '}
                {option.subLabel}
              </Typography>
            )}
          </Typography>
        </Flex>
        {rightItem}
      </Flex>
    </Tooltip>
  )
}
