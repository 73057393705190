import { Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { type FC, type PropsWithChildren } from 'react'
import type { TooltipProps } from './types'

export const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({
  children,
  gutter = 8,
  size = 'md',
  placement,
  ...rest
}) => {
  return (
    <ChakraTooltip
      openDelay={500}
      hasArrow
      size={size}
      gutter={gutter} // offset
      _firstLetter={{ textTransform: 'uppercase' }}
      orientation={
        placement?.includes('left') || placement?.includes('right')
          ? 'vertical'
          : 'horizontal'
      }
      variant="normal"
      placement={placement}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  )
}
