import { type Options } from 'highcharts'
import { colorTheme } from 'ui/theme/colors'
import { staticChartOptions } from 'utils/chart/constants'

export const baseSaturationChartOptions: Options = {
  ...staticChartOptions,
  chart: {
    ...staticChartOptions.chart,
    zooming: { type: 'xy' },
    type: 'spline',
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    ...staticChartOptions.xAxis,
    crosshair: {
      color: colorTheme.grey[300],
      dashStyle: 'ShortDot',
    },
    title: {
      text: 'Marketing spend',
    },
  },
  yAxis: {
    ...staticChartOptions.yAxis,
    title: {
      text: 'Net gross profit 3',
    },
  },
  plotOptions: {
    ...staticChartOptions.plotOptions,
    spline: {
      animation: false,
      marker: {
        enabled: false,
      },
    },
    scatter: {
      animation: false,
      marker: {
        symbol: 'circle',
      },
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    headerFormat: '<b>{series.name}</b><br>',
    pointFormat: '{point.x} EUR: {point.y} EUR',
  },
}
