import { TARGETS } from 'features/optimizations/consts'
import { z } from 'zod'

export const validationSchema = z
  .object({
    selectedTarget: z.nativeEnum(TARGETS),
    target: z
      .object({
        [TARGETS.BUDGET]: z.string(),
        [TARGETS.ROAS]: z.string(),
        [TARGETS.EP_ROAS]: z.string(),
      })
      .partial(),
    channelBounds: z.record(z.string(), z.array(z.number())),
  })
  .refine(
    ({ target, selectedTarget }) => {
      // value for selectedTarget should exist except for OPTIMAL_BUDGET where we don't need a value
      return selectedTarget === TARGETS.OPTIMAL_BUDGET || target[selectedTarget]
    },
    ({ selectedTarget }) => ({
      message: 'Value is required',
      path: [`target.${selectedTarget}`],
    }),
  )
  .superRefine(({ selectedTarget, target }, ctx) => {
    if (selectedTarget === TARGETS.OPTIMAL_BUDGET) {
      return z.OK
    }

    const value = Number(target[selectedTarget])

    if (selectedTarget === TARGETS.BUDGET && value <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Value cannot be less than or equal to 0`,
        fatal: true,
        path: [`target.${selectedTarget}`],
      })

      return z.NEVER
    }
    if (selectedTarget === TARGETS.ROAS || selectedTarget === TARGETS.EP_ROAS) {
      if (value < 100) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Value cannot be less than 100`,
          fatal: true,
          path: [`target.${selectedTarget}`],
        })

        return z.NEVER
      }
      if (value > 1000) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Value cannot be greater than 1000`,
          fatal: true,
          path: [`target.${selectedTarget}`],
        })

        return z.NEVER
      }
    }
  })
