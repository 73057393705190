import {
  Checkbox as ChakraCheckbox,
  type CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react'
import { useFormContext, type FieldError } from 'react-hook-form'

interface CheckboxProps extends ChakraCheckboxProps {
  error?: FieldError
  name?: string
  required?: boolean
  validate?: (value: string) => boolean
}

const Checkbox = ({
  error,
  name,
  size = 'md',
  required,
  validate,
  children,
  ...rest
}: CheckboxProps) => {
  const { register } = useFormContext() ?? {}
  const hasError = error?.type === 'required' || error?.type === 'validate'

  return (
    <ChakraCheckbox
      size={size}
      isInvalid={hasError}
      {...(name && register ? register(name, { required, validate }) : {})}
      {...rest}
    >
      {children}
    </ChakraCheckbox>
  )
}

Checkbox.displayName = 'Checkbox'

export default Checkbox
