import {
  Input as ChakraInput,
  forwardRef,
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  type As,
  type InputProps as ChakraInputProps,
  type FlexProps,
  type ColorMode,
} from '@chakra-ui/react'
import { mergeRefs } from '@chakra-ui/react-utils'
import { ErrorMessage } from 'components/Error/ErrorMessage'
import { getFormErrorMessage } from 'components/Form'
import { Icon, type IconProps } from 'components/Icon/Icon'
import { Label } from 'components/Label/Label'
import { Typography } from 'components/Typography'
import { type ReactNode, useId, type RefCallback } from 'react'
import { type FieldError, useFormContext } from 'react-hook-form'
import { DarkModeHandler } from 'ui/DarkModeHandler'

type Props = ChakraInputProps & {
  leadingIcon?: IconProps
  trailingIcon?: IconProps
  error?: FieldError
  label?: string
  colorMode?: ColorMode
  wrapperProps?: FlexProps
  action?: ReactNode
  actionWidth?: number
  helperText?: string
  isReadOnly?: boolean
  onTrailingIconClick?: () => void
  onSubmitClick?: () => void
}

const hasRef = (
  obj: object,
): obj is {
  ref?: RefCallback<unknown>
} => 'ref' in obj

const Input = forwardRef<Props, As>(
  (
    {
      id,
      name,
      error,
      label,
      type = 'text',
      leadingIcon,
      trailingIcon,
      colorMode = 'light',
      disabled,
      action,
      actionWidth,
      size,
      wrapperProps,
      helperText,
      isReadOnly,
      onSubmitClick,
      onTrailingIconClick,
      ...rest
    },
    ref,
  ) => {
    const { register } = useFormContext() ?? {}
    const validationErrorMessage = getFormErrorMessage(error, label)
    const isDarkMode = colorMode === 'dark'
    const registerProps = name && register ? register(name) : {}
    const defaultId = useId()
    const inputId = id || defaultId
    const defaultIconSize = 'small'
    const inputRef = hasRef(registerProps)
      ? mergeRefs(ref, registerProps.ref)
      : ref

    return (
      <Flex flexDir="column" {...wrapperProps}>
        {label && (
          <Label
            label={label}
            htmlFor={inputId}
            colorMode={colorMode}
            isDisabled={disabled}
          />
        )}
        <DarkModeHandler colorMode={colorMode}>
          <InputGroup size={size}>
            {leadingIcon && (
              <InputLeftElement
                pointerEvents={leadingIcon.onClick ? undefined : 'none'}
              >
                <Icon
                  onClick={leadingIcon.onClick}
                  size={leadingIcon.size ?? defaultIconSize}
                  color={isDarkMode ? 'gray.600' : undefined}
                  {...leadingIcon}
                />
              </InputLeftElement>
            )}
            <ChakraInput
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              aria-readonly={isReadOnly}
              type={type}
              isInvalid={!!validationErrorMessage}
              onKeyDown={(event) => {
                event.key === 'Enter' && onSubmitClick?.()
              }}
              pr={
                action
                  ? actionWidth
                    ? `${actionWidth + 16}px` // the 16 is for 8px on both sides
                    : '100px'
                  : undefined
              }
              data-1p-ignore={!['password', 'email'].includes(type)}
              {...registerProps}
              {...rest}
              id={inputId}
              ref={inputRef}
            />
            {helperText && (
              <InputRightElement
                pointerEvents={onTrailingIconClick ? undefined : 'none'}
              >
                <Typography
                  fontSize="xs"
                  lineHeight={4}
                  aria-disabled={disabled}
                  color={disabled ? 'gray.400' : 'gray.700'}
                  ml={2}
                  mr={3}
                >
                  {helperText}
                </Typography>
              </InputRightElement>
            )}
            {trailingIcon && (
              <InputRightElement
                pointerEvents={onTrailingIconClick ? undefined : 'none'}
              >
                <Icon
                  onClick={onTrailingIconClick}
                  size={trailingIcon.size ?? defaultIconSize}
                  color={isDarkMode ? 'gray.600' : undefined}
                  {...trailingIcon}
                />
              </InputRightElement>
            )}
            {action && (
              <InputRightElement
                width={actionWidth ? `${actionWidth}px` : '100px'}
                px={2}
                justifyContent="flex-end"
              >
                {action}
              </InputRightElement>
            )}
          </InputGroup>
        </DarkModeHandler>
        {validationErrorMessage && (
          <ErrorMessage
            error={validationErrorMessage}
            isDarkMode={isDarkMode}
          />
        )}
      </Flex>
    )
  },
)

Input.displayName = 'Input'

export default Input
