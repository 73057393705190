import { Box, Flex } from '@chakra-ui/react'
import {
  closestCenter,
  type CollisionDetection,
  DndContext,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { selectedDashboardAtom } from 'features/customDashboard/atoms/dashboards'
import { type DashboardLayoutRow } from 'features/customDashboard/atoms/types'
import { useAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SortableItem } from './SortableItem'
import { SortableOverlay } from './SortableOverlay'
import { WidgetGroup } from './WidgetGroup'

const emptyLayoutRows: Record<string, DashboardLayoutRow> = {}

export const WidgetContainer = () => {
  const [dashboard, setDashboard] = useAtom(selectedDashboardAtom)

  const setItems = (items: Record<string, DashboardLayoutRow>) => {
    if (!dashboard) {
      return
    }
    setDashboard({
      id: dashboard.id,
      layout: {
        ...dashboard.layout,
        rows: items,
      },
    })
  }

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const lastOverId = useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = useRef(false)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const items = dashboard?.layout.rows ?? emptyLayoutRows

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        })
      }

      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args)
      let overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId]?.widgets

          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.find((item) => item.id === container.id),
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId

        return [{ id: overId }]
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, items],
  )

  const onDragCancel = () => {
    setActiveId(null)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false
    })
  }, [items])

  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id
    }
    if (id === 'new-row' || id === 'new-row-1') {
      return String(Object.keys(items).length + 1)
    }

    return Object.keys(items).find((key) =>
      items[key]?.widgets.find((item) => item.id === id),
    )
  }

  return (
    <DndContext
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      onDragCancel={onDragCancel}
      onDragStart={({ active }) => {
        setActiveId(active.id)
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id

        if (overId == null) {
          return
        }

        const overContainer = findContainer(overId)
        const activeContainer = findContainer(active.id)

        if (!overContainer || !activeContainer) {
          return
        }

        if (activeContainer !== overContainer) {
          const isNewRow = !items[overContainer]

          const activeItems = items[activeContainer]?.widgets
          const overItems = isNewRow ? [] : items[overContainer]?.widgets
          const overIndex = overItems.findIndex((item) => item.id === overId)
          const activeIndex = activeItems.findIndex(
            (item) => item.id === active.id,
          )

          let newIndex: number

          if (overId in items) {
            newIndex = overItems.length + 1
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height

            const modifier = isBelowOverItem ? 1 : 0

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1
          }

          recentlyMovedToNewContainer.current = true

          setItems({
            ...items,
            [activeContainer]: {
              widgets: activeItems.filter((item) => item.id !== active.id),
              height: items[activeContainer].height,
            },
            [overContainer]: isNewRow
              ? {
                  widgets: [items[activeContainer].widgets[activeIndex]],
                  height: 300,
                }
              : {
                  widgets: [
                    ...items[overContainer].widgets.slice(0, newIndex),
                    items[activeContainer].widgets[activeIndex],
                    ...items[overContainer].widgets.slice(
                      newIndex,
                      items[overContainer]?.widgets.length,
                    ),
                  ],
                  height: items[overContainer]?.height,
                },
          })
        }
      }}
      onDragEnd={({ active, over }) => {
        const activeContainer = findContainer(active.id)

        if (!activeContainer) {
          setActiveId(null)

          return
        }

        const overId = over?.id

        if (overId == null) {
          setActiveId(null)

          return
        }

        const overContainer = findContainer(overId)

        if (overContainer) {
          const activeIndex = items[activeContainer].widgets.findIndex(
            (item) => item.id === active.id,
          )
          const overIndex = items[overContainer].widgets.findIndex(
            (item) => item.id === overId,
          )

          if (activeIndex !== overIndex) {
            setItems({
              ...items,
              [overContainer]: {
                widgets: arrayMove(
                  items[overContainer]?.widgets,
                  activeIndex,
                  overIndex,
                ),
                height: items[overContainer].height,
              },
            })
          }
        }

        setActiveId(null)
      }}
      collisionDetection={collisionDetectionStrategy}
      sensors={sensors}
    >
      <Flex direction="column" gap={2} w="full" p={4}>
        {Object.keys(items).map((key) => (
          <WidgetGroup key={key} widgets={items[key]?.widgets} id={key} />
        ))}
        {activeId && (
          <SortableContext
            id={'new-row'}
            items={[]}
            strategy={rectSortingStrategy}
          >
            <SortableItem id={'new-row-1'}>
              <Box p={10} h="100px" border="dashed 2px" borderColor="grey.200">
                Drop here to add a new row
              </Box>
            </SortableItem>
          </SortableContext>
        )}
      </Flex>
      <SortableOverlay>
        {activeId && (
          <SortableItem id={activeId}>
            <WidgetGroup
              widgets={items[activeId]?.widgets}
              id={String(activeId)}
            />
          </SortableItem>
        )}
      </SortableOverlay>
    </DndContext>
  )
}
