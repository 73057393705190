export const getCompareCellColor = (
  value?: number,
  isReversePositive?: boolean,
) => {
  // zero will be treated as 'N/A' so it should be gray
  if (value) {
    const sign = isReversePositive ? -1 : 1

    return sign * value > 0 ? 'green.600' : 'red.500'
  }

  return 'grey.800'
}
