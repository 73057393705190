import { Box, useDisclosure } from '@chakra-ui/react'
import type React from 'react'
import { NavIconLink } from '../NavLinks'
import { MenuDrawer } from './MenuDrawer'

export const MenuDrawerIcon: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box>
      <NavIconLink
        onClick={onOpen}
        tooltip="Menu"
        iconName="HamburgerMenuIcon"
        isSelected={isOpen}
      />
      <MenuDrawer onClose={onClose} isOpen={isOpen} />
    </Box>
  )
}
