import { toZonedTime } from 'date-fns-tz'
import type Highcharts from 'highcharts'
import { escape } from 'lodash-es'
import { colorTheme } from 'ui/theme/colors'
import {
  getXAxisDateFormat,
  sortByNumberOrString,
  getXAxisLabel,
} from 'utils/chart/common'
import {
  CHART_COLORS,
  isTimeUnit,
  staticChartOptions,
} from 'utils/chart/constants'
import type { GroupedChartData } from 'utils/chart/types'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { parseDate } from 'utils/parseDate'
import { tooltipFormatter } from './common'
import { type BaseChartOptionsProps, type GroupedChartDataProps } from './types'

const maxNumberOfRows = 50

// Same colors as default but in another order
const defaultColors = [
  CHART_COLORS[0],
  CHART_COLORS[1],
  CHART_COLORS[2],
  CHART_COLORS[3],
  CHART_COLORS[4],
  CHART_COLORS[5],
  CHART_COLORS[6],
  CHART_COLORS[7],
]

export const getGroupedChartDataForPieChart = ({
  data,
  xAxis,
  serie: { key },
  timezone,
}: GroupedChartDataProps): GroupedChartData => {
  const newGroupedData = (data ?? []).reduce<GroupedChartData>((acc, row) => {
    const color = 'no-color'
    const group = acc[color] ?? {}

    const value = Number(row[key].value)

    if (isTimeUnit(xAxis)) {
      const parsedDate = parseDate(row[xAxis].value, timezone)
      const timestamp = toZonedTime(parsedDate, timezone ?? '').getTime()

      group[timestamp] = (group[timestamp] ?? 0) + value
    } else {
      const key = row[xAxis].formattedValue

      group[key] = (group[key] ?? 0) + value
    }
    acc[color] = group

    return acc
  }, {})

  return newGroupedData
}

export const getChartOptionsForPieChart = ({
  groupedData,
  serie: { key, type },
  normalizedDimensions,
  normalizedMetrics,
  xAxis,
  currency,
}: BaseChartOptionsProps) => {
  const xAxisLabel = getXAxisLabel(
    xAxis,
    type,
    normalizedDimensions[xAxis],
    normalizedMetrics[xAxis],
    currency,
  )
  const yAxisLabel = formatMetricLabel(normalizedMetrics[key], currency)
  const series = Object.values(groupedData).map((value, index) => {
    return {
      color:
        staticChartOptions.colors[index % staticChartOptions.colors.length],
      data: Object.entries(value)
        .sort(([, first], [, second]) =>
          sortByNumberOrString(first ?? 0, second ?? 0),
        )
        .splice(0, maxNumberOfRows)
        .map(([key, value]) => {
          return {
            name: isTimeUnit(xAxis)
              ? getXAxisDateFormat(xAxis, Number(key))
              : escape(key),
            y: Number(value),
          }
        }),
      type: 'pie',
    }
  })

  return {
    ...staticChartOptions,
    plotOptions: {
      ...staticChartOptions.plotOptions,
      turboThreshold: 0,
      pie: {
        colors: defaultColors,
        dataLabels: {
          style: {
            fontWeight: '500',
            color: colorTheme.grey[800],
            textOutline: 'none',
            textOverflow: 'ellipsis',
          },
        },
      },
    },
    series: series as Highcharts.SeriesOptionsType[],
    title: {
      ...staticChartOptions.title,
      text: yAxisLabel,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: tooltipFormatter(
        xAxis,
        key,
        xAxisLabel,
        normalizedMetrics[xAxis]?.format,
        yAxisLabel,
        normalizedMetrics[key]?.format,
      ),
    },
    xAxis: {
      visible: false,
    },
    yAxis: {
      visible: false,
    },
  }
}
