import { type PropsWithChildren, useEffect } from 'react'

export let isAppVisible = true

export const VisibilityHandler = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      isAppVisible = document.visibilityState === 'visible'
    }

    window.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return <>{children}</>
}
