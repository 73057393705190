import { Flex, Table, TableContainer, Tbody, Td, Tr } from '@chakra-ui/react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type CellContext,
  getSortedRowModel,
} from '@tanstack/react-table'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql } from 'generated/graphql'
import { type MappingsViewQueryQuery } from 'generated/graphql/graphql'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMemo } from 'react'
import { parameterTypes, sharedCellStyles } from '../shared/consts'
import { EmptyMappings } from '../shared/EmptyMappings'
import { MappingThead } from '../shared/MappingThead'
import { useMappingsAtom } from '../shared/state'
import { CreateMappingPanel } from './CreateMappingPanel'
import { UnhandledMappingsCell } from './UnhandledMappingsCell'

interface UnmappedChannel {
  id: string
  utmCampaign: string
  utmMedium: string
  utmSource: string
  visits: number
}

interface CustomCellContext extends CellContext<UnmappedChannel, string> {
  isMatchingRow: boolean
  isReadOnly: boolean
}

const columnHelper = createColumnHelper<UnmappedChannel>()
const columns = [
  ...parameterTypes.map(({ id, label }) =>
    columnHelper.accessor(id, {
      cell: (info) => (
        <UnhandledMappingsCell
          value={info.getValue()}
          type={id}
          isReadOnly={(info as CustomCellContext).isReadOnly}
        />
      ),
      header: label,
    }),
  ),
  columnHelper.accessor('visits', {
    cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
    header: 'Visits',
  }),
]

export const MappableSessions_MerchantSiteFragment = graphql(/* GraphQL */ `
  fragment MappableSessions_MerchantSite on MerchantSite {
    mappableSessions {
      utmCampaign
      utmMedium
      utmSource
      visits
    }
  }
`)

type Props = {
  merchantSite: NonNullable<MappingsViewQueryQuery['viewer']>['merchantSite']
  isLoading: boolean
}

export const UnhandledMappings = ({ merchantSite, isLoading }: Props) => {
  const [mappingsState] = useMappingsAtom()
  const flags = useFeatureFlags()
  const isReadOnly = !flags.allowSettingsWrite

  const isAnyMappingSelected = Object.values(mappingsState).some(Boolean)

  const sortedUnmappedChannels = useMemo(() => {
    const unmappedChannels =
      (merchantSite?.mappableSessions as UnmappedChannel[]) ?? []

    const getMatchCount = (channel: UnmappedChannel) =>
      parameterTypes.filter(({ id }) => channel[id] === mappingsState[id])
        .length

    return [...unmappedChannels]
      .map((channel) => ({
        ...channel,
        id: `${channel.utmSource}-${channel.utmMedium}-${channel.utmCampaign}`, // Stable ID needed for the animations
      }))
      .sort((a, b) => getMatchCount(b) - getMatchCount(a))
  }, [merchantSite?.mappableSessions, mappingsState])

  const table = useReactTable({
    data: sortedUnmappedChannels,
    manualSorting: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const tableRows = table.getRowModel().rows

  return (
    <>
      <Typography fontSize="sm">
        Select UTM parameters by clicking on them and then create a mapping to a
        Channel group and Channel. Note that created mappings will only be
        applied to future visits and not to past visits before the mapping was
        created.
      </Typography>
      <Flex
        gap={12}
        flexDir={{ base: 'column-reverse', xl: 'row' }}
        justifyContent="space-between"
        flexGrow={1}
      >
        <TableContainer flexGrow={1}>
          <Table>
            <MappingThead headerGroups={table.getHeaderGroups()} />
            <Tbody as={motion.tbody}>
              {isLoading ? (
                <SkeletonTableRows columns={columns.length} rows={7} />
              ) : tableRows.length === 0 ? (
                <EmptyMappings />
              ) : (
                <AnimatePresence>
                  {tableRows.map((row) => {
                    const isMatchingRow = parameterTypes.every(
                      ({ id }) =>
                        !mappingsState[id] ||
                        row.original[id] === mappingsState[id],
                    )

                    return (
                      <Tr
                        key={row.original.id}
                        as={motion.tr}
                        layout
                        bg={
                          isMatchingRow && isAnyMappingSelected
                            ? 'grey.50'
                            : 'inherit'
                        }
                        pos="relative"
                        zIndex={isMatchingRow && isAnyMappingSelected ? 1 : 0}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <Td key={cell.id} {...sharedCellStyles}>
                            {flexRender(cell.column.columnDef.cell, {
                              ...cell.getContext(),
                              isReadOnly: !isMatchingRow || isReadOnly,
                            })}
                          </Td>
                        ))}
                      </Tr>
                    )
                  })}
                </AnimatePresence>
              )}
            </Tbody>
          </Table>
        </TableContainer>

        {!isReadOnly && (
          <Flex pos="sticky" h="fit-content">
            <CreateMappingPanel frontendId={merchantSite?.frontendId} />
          </Flex>
        )}
      </Flex>
    </>
  )
}
