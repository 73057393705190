import { Flex, useRadioGroup } from '@chakra-ui/react'
import { type FilterOperator } from 'components/Filters/types'
import { filterOperatorMappings } from 'features/reports/utils/utils'
import {
  type Dispatch,
  type FC,
  type SetStateAction,
  useEffect,
  useId,
} from 'react'
import { FilterGroupChip } from './FilterGroupChip'

interface FilterGroupProps {
  options: FilterOperator[]
  defaultValue?: FilterOperator
  onChange?: (value: FilterOperator) => void
  setSelectedFilter?: Dispatch<SetStateAction<FilterOperator>>
}

export const FilterGroup: FC<FilterGroupProps> = ({
  options,
  defaultValue,
  onChange,
  setSelectedFilter,
}) => {
  const id = useId()

  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: id,
    defaultValue,
    onChange,
  })

  const group = getRootProps()

  useEffect(() => {
    setSelectedFilter?.(value as FilterOperator)
  }, [value, setSelectedFilter])

  return (
    <Flex
      py={2}
      px={4}
      wrap="wrap"
      rowGap={2}
      columnGap={6}
      {...group}
      borderBottom="1px solid"
      borderColor="grey.200"
    >
      {options.map((value) => {
        const label = filterOperatorMappings[value]
        const radio = getRadioProps({ value })

        return <FilterGroupChip key={value} radioProps={radio} value={label} />
      })}
    </Flex>
  )
}
