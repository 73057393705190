import {
  type As,
  Tag as ChakraTag,
  TagCloseButton,
  TagLabel,
  type TagProps as ChakraTagProps,
  Tooltip,
} from '@chakra-ui/react'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { forwardRef, useRef } from 'react'

export type SolidVariant = {
  variant: Extract<ChakraTagProps['variant'], 'solid'>
  colorScheme: Extract<
    ChakraTagProps['colorScheme'],
    'primary' | 'grey' | 'blackAlpha' | 'purple'
  >
}

type OutlineVariant = {
  variant: Extract<ChakraTagProps['variant'], 'outline'>
  colorScheme?: never
}

type SelectableVariant = {
  variant: 'selectable'
  colorScheme?: Extract<
    ChakraTagProps['colorScheme'],
    'white' | 'grey' | 'blackAlpha'
  >
}

export type TagProps = ChakraTagProps & {
  label: string
  onClose?: () => void
} & (SolidVariant | OutlineVariant | SelectableVariant)

export const Tag = forwardRef<As, TagProps>(
  ({ label, variant, colorScheme, size, onClose, ...props }, ref) => {
    const textRef = useRef<HTMLElement>(null)
    const isOverflowing = useIsOverflowing(textRef)

    return (
      <Tooltip label={label} placement="top" isDisabled={!isOverflowing}>
        <ChakraTag
          ref={ref}
          size={size}
          variant={variant}
          colorScheme={colorScheme}
          justifyContent="space-between"
          pr={onClose && size === 'sm' ? 0.5 : onClose ? 1 : undefined}
          {...props}
        >
          <TagLabel ref={textRef} display="inline">
            {label}
          </TagLabel>
          {onClose && <TagCloseButton onClick={onClose} />}
        </ChakraTag>
      </Tooltip>
    )
  },
)

Tag.displayName = 'Tag'
