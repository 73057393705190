import { useUser } from '@clerk/clerk-react'
import {
  REPORT_VISIBILITY,
  getIsTemplateReport,
  getReportVisibilityType,
} from 'graphql/reports/utils'
import { useIsGuestUser } from 'hooks/useIsGuestUser'
import { type ReportState } from '../atoms/reportViewStateAtom'

export const useCanEditReport = ({
  visibility,
  owner,
}: Pick<ReportState, 'visibility' | 'owner'> | undefined = {}) => {
  const isGuestUser = useIsGuestUser()
  const { user } = useUser()

  if (!visibility || !owner) return false

  const isOwner = owner.id === user?.publicMetadata.dema_id
  const isPrivate =
    getReportVisibilityType(visibility) === REPORT_VISIBILITY.PRIVATE
  const isTemplateReport = getIsTemplateReport(owner)

  // Logic is:
  // 1. If report is template report, no one can edit it
  // 2. If user is owner, they always have edit access no matter what
  // 3. If user is a guest, they never have edit access to other reports
  // 4. If the report is private, only the owner has edit access
  return !isTemplateReport && (isOwner || (!isGuestUser && !isPrivate))
}
