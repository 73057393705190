import { Box } from '@chakra-ui/react'
import type React from 'react'
import { useParams } from 'react-router'
import { InfoAlert } from './components/InfoAlert/InfoAlert'
import { ProfitAnalysisGraph } from './components/ProfitAnalysisGraph/ProfitAnalysisGraph'
import { ReportTable } from './components/ReportTable/ReportTable'
import { useHistoricalHistoryAnalysis } from './hooks/useHistoricalHistoryAnalysis'

export const YearlyOverviewView: React.FC = () => {
  const { market } = useParams()
  const { query, data } = useHistoricalHistoryAnalysis({
    market: String(market),
  })
  const isLoading = query.loading
  const hasError = !!query.error

  return (
    <Box p={6} pt={0}>
      <InfoAlert mb={6} />
      <Box mb={4}>
        <ProfitAnalysisGraph
          reports={data?.historicalReport}
          contributions={data?.historicalContributions}
          isLoading={isLoading}
          hasError={hasError}
        />
      </Box>
      <ReportTable
        reports={data?.historicalReport}
        market={String(market)}
        isLoading={isLoading}
        hasError={hasError}
      />
    </Box>
  )
}
