import { type AnalyticsConfig, type ReportResult } from 'graphql/reports/types'
import { atom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { userAtom } from 'shared/atoms'
import { type OptionalKey } from 'types/optionalKey'
import { demaReports } from '../demaReports'

// Our report state can be partial in some fields since we can have a real report or a template / dema report that does not have all the fields. The atoms handle missing fields adding defaults where needed to get the full object in the end.
type AnalyticsConfigState = OptionalKey<AnalyticsConfig, 'id' | '__typename'>
export type ReportState = Omit<
  OptionalKey<
    ReportResult,
    | 'createdAt'
    | 'favorite'
    | 'id'
    | 'labels'
    | 'owner'
    | 'updatedAt'
    | 'visibility'
  >,
  'analyticsConfig'
> & { analyticsConfig: AnalyticsConfigState }

export enum ReportViewStatus {
  Loading = 'loading',
  NotFound = 'notFound',
  InSync = 'inSync',
}

type ReportViewState = {
  report: ReportState | undefined
  analyticsConfig: AnalyticsConfigState | undefined
  status: ReportViewStatus
}

// Status is set for the initial loading state. After that, everything is in sync by updating the atoms directly.
const initialReportViewState: ReportViewState = {
  report: undefined,
  analyticsConfig: undefined,
  status: ReportViewStatus.Loading,
}

const reportViewStateAtom = atom(initialReportViewState)

export const focusReportStatusAtom = focusAtom(reportViewStateAtom, (optic) =>
  optic.prop('status'),
)

const focusReportStateAtom = focusAtom(reportViewStateAtom, (optic) =>
  optic.prop('report'),
)

const defaultReport: ReportState = demaReports[0]

export const newReportAtom = atom<ReportState>((get) => {
  const user = get(userAtom)

  return {
    name: '',
    description: '',
    owner: user,
    visibility: [],
    analyticsConfig: {
      ...demaReports[0].analyticsConfig,
      dimensions: [],
      metrics: [],
    },
  }
})

export const reportStateAtom = atom(
  (get) => {
    return get(focusReportStateAtom) ?? defaultReport
  },
  (_, set, report: SetStateAction<ReportState | undefined>) => {
    set(focusReportStateAtom, report)
  },
)

const reportAnalyticsConfigAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('analyticsConfig'),
)

const focusAnalyticsConfigStateAtom = focusAtom(reportViewStateAtom, (optic) =>
  optic.prop('analyticsConfig'),
)

export const analyticsConfigAtom = atom(
  (get) => {
    const reportAnalyticsConfig = get(reportAnalyticsConfigAtom)
    const analyticsConfig = get(focusAnalyticsConfigStateAtom)

    return analyticsConfig ?? reportAnalyticsConfig
  },
  (
    _,
    set,
    analyticsConfig: SetStateAction<AnalyticsConfigState | undefined>,
  ) => {
    set(focusAnalyticsConfigStateAtom, analyticsConfig)
  },
)

export const analyticsConfigIdAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('id'),
)
