import { Box, Divider } from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useMemo, useState } from 'react'
import { useDimensionsState } from '../hooks/useDimensionsState'
import { useMetricsState } from '../hooks/useMetricsState'

export const HiddenDimensionsOrMetricsAlert: React.FC = () => {
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()
  const { unsafe_allDimensionKeys } = useDimensionsState()
  const { unsafe_allMetricsKeys } = useMetricsState()

  const hasHiddenDimensionsOrMetrics = useMemo(
    () =>
      unsafe_allDimensionKeys.find(
        (dimension) => !normalizedDimensions[dimension],
      ) || unsafe_allMetricsKeys.find((metric) => !normalizedMetrics[metric]),
    [
      unsafe_allDimensionKeys,
      unsafe_allMetricsKeys,
      normalizedDimensions,
      normalizedMetrics,
    ],
  )

  const [hideAlert, setHideAlert] = useState(false)

  if (!hasHiddenDimensionsOrMetrics || hideAlert) return null

  return (
    <>
      <Box px={6} py={1.5}>
        <Alert
          status="info"
          closable={true}
          content="This report contains hidden dimensions or metrics that you don't have access to"
          onClose={() => setHideAlert(true)}
        />
      </Box>
      <Divider />
    </>
  )
}
