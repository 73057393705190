import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type FutureAnalysisQueryQuery,
  type FutureAnalysisInput,
} from 'generated/graphql/graphql'
import { TARGETS } from '../consts'
import { type ContributionData } from './useHistoricalAnalysisQuery'

const FUTURE_ANALYSIS_QUERY = graphql(/* GraphQL */ `
  query FutureAnalysisQuery($futureAnalysisInput: FutureAnalysisInput!) {
    viewer {
      id
      merchant {
        id
        futureAnalysis(params: $futureAnalysisInput) {
          contribution
          summary
          curve {
            channel {
              channel
              channelGroup
              funnelCampaign
            }
            line
            optimalPoint
            actualPoint
            saturationPoint
          }
        }
      }
    }
  }
`)

const isValidInput = ({
  startDate,
  endDate,
  markets,
  frontendIds,
  target,
  targetValue,
}: FutureAnalysisInput) => {
  return (
    startDate &&
    endDate &&
    markets &&
    frontendIds &&
    target &&
    (targetValue || target === TARGETS.OPTIMAL_BUDGET) // targetValue is not needed for OPTIMAL_BUDGET
  )
}

export type FutureAnalysis = Omit<
  NonNullable<FutureAnalysisQueryQuery['viewer']>['merchant']['futureAnalysis'],
  'contribution'
> & { contribution: ContributionData }

export const useFutureAnalysisQuery = (input: FutureAnalysisInput) => {
  const query = useQuery(FUTURE_ANALYSIS_QUERY, {
    skip: !isValidInput(input),
    variables: {
      futureAnalysisInput: input,
    },
  })

  return {
    futureAnalysis: query.data?.viewer?.merchant.futureAnalysis as
      | FutureAnalysis
      | undefined,
    query,
  }
}
