import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { graphql } from 'generated/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { cloneDeep, set } from 'lodash-es'
import { useCallback } from 'react'
import { MERCHANT_TEAMS_QUERY } from './useMerchantTeams'
import { TEAM_BY_ID_QUERY } from './useTeamById'
import { USER_TEAMS_QUERY } from './useUserTeams'

const ADD_TEAM_MEMBER_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {
    updateTeamMembership(params: $payload) {
      ...Teams_TeamMemberFields
    }
  }
`)

type AddTeamMemberPayload = {
  teamId: string
  clerkUserId: string
}

export const useAddTeamMember = () => {
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const { user } = useUser()

  const [mutation, state] = useMutation(ADD_TEAM_MEMBER_MUTATION)

  const addTeamMember = useCallback(
    (addTeamMemberPayload: AddTeamMemberPayload) => {
      mutation({
        variables: {
          payload: { ...addTeamMemberPayload, action: 'ADD' },
        },
        optimisticResponse: {
          updateTeamMembership: {
            __typename: 'User',
            externalId: addTeamMemberPayload.clerkUserId,
            id: 'optimistic',
          },
        },
        update: (cache, { data }) => {
          if (!data) return

          // Insert team into user teams if user is the current user
          cache.updateQuery({ query: USER_TEAMS_QUERY }, (queryData) => {
            if (
              !queryData?.viewer ||
              data.updateTeamMembership.externalId !== user?.id
            ) {
              return queryData
            }

            const merchantQuery = cache.readQuery({
              query: MERCHANT_TEAMS_QUERY,
            })
            const newTeam = merchantQuery?.viewer?.merchant.teams.find(
              (team) => team.id === addTeamMemberPayload.teamId,
            )

            if (!newTeam) return queryData

            const teams = [...queryData.viewer.teams, newTeam]
            const queryDataClone = cloneDeep(queryData)

            set(queryDataClone, 'viewer.teams', teams)

            return queryDataClone
          })
          // Insert member into team members
          cache.updateQuery(
            {
              query: TEAM_BY_ID_QUERY,
              variables: { id: addTeamMemberPayload.teamId },
            },
            (queryData) => {
              if (!queryData?.viewer?.team) return queryData

              const queryDataClone = cloneDeep(queryData)

              set(queryDataClone, 'viewer.team.members', [
                ...queryData.viewer.team.members,
                data.updateTeamMembership,
              ])

              return queryDataClone
            },
          )
        },
        onError: () => {
          toast({
            description: 'Could not add team member',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
        onCompleted: () => {
          const { clerkUserId, teamId } = addTeamMemberPayload

          trackEvent({
            eventName: 'Team Member Added',
            eventProperties: {
              clerkUserId,
              teamId,
            },
          })
        },
      })
    },
    [mutation, toast, trackEvent, user?.id],
  )

  return [addTeamMember, state] as const
}
