import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type OptimizationConfigQueryQuery } from 'generated/graphql/graphql'

const OPTIMIZATION_CONFIG_QUERY = graphql(/* GraphQL */ `
  query OptimizationConfigQuery {
    viewer {
      id
      merchant {
        id
        optimizationConfig {
          markets
          frontendIds
          optimizableChannels {
            channelGroup
            channel
            funnelCampaign
          }
          targets {
            id
            name
          }
          summaries {
            id
            name
            description
          }
          startWeekday
          guardrail
        }
      }
    }
  }
`)

export type OptimizationConfig = NonNullable<
  OptimizationConfigQueryQuery['viewer']
>['merchant']['optimizationConfig']

export const useOptimizationConfig = () => useQuery(OPTIMIZATION_CONFIG_QUERY)
