import { Flex, Skeleton } from '@chakra-ui/react'
import { DiffBadge } from 'components/Badge/DiffBadge'
import { Typography } from 'components/Typography'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { formatMetric } from 'utils/numberFormats'
import { stringToNumber } from 'utils/stringToNumber'
import { type HeaderTotalSectionProps } from '../types'

export const HeaderTotalSection = ({
  metric,
  aggregatedData,
  isCompare,
  isLoading,
  compareUnit,
}: HeaderTotalSectionProps) => {
  if (!metric) {
    return null
  }

  if (isLoading) {
    return (
      <Flex flexDir="column">
        <Skeleton w="80px" h="16px" alignSelf="flex-end" />
        <Skeleton w="50px" h="14px" mt="4px" alignSelf="flex-end" />
      </Flex>
    )
  }

  const baseStatistic = aggregatedData?.[0]?.[metric.key]

  // Display currency as integer
  const displayValue =
    metric.format === METRIC_FORMAT.CURRENCY
      ? formatMetric(METRIC_FORMAT.INTEGER, Number(baseStatistic?.value))
      : baseStatistic?.formattedValue

  if (!displayValue) {
    return null
  }

  return (
    <Flex flexDir="column" gap={0.5}>
      <Typography
        textAlign="end"
        noOfLines={1}
        color="grey.900"
        fontSize="md"
        whiteSpace="nowrap"
        display="block"
      >
        {displayValue ?? '-'}
      </Typography>
      {isCompare && (
        <DiffBadge
          hasIcon={false}
          value={stringToNumber(baseStatistic.value)}
          compareValue={stringToNumber(baseStatistic.comparisonValue)}
          format={metric.format}
          isOppositeDiffColor={!!metric.isReversePositive}
          neutralColor="grey.800"
          includePrefix
          badgeProps={{
            alignSelf: 'end',
            h: 'unset',
            w: 'fit-content',
            fontWeight: 400,
          }}
          displayFormat={compareUnit}
        />
      )}
    </Flex>
  )
}
