import { useQuery } from '@apollo/client'
import { useHistoricalAnalysisDateAtom } from 'features/optimizations/atoms/dateAtom'
import { graphql } from 'generated/graphql'
import { type HistoricalOverviewAnalysisQueryQuery } from 'generated/graphql/graphql'
import { useSelectedFrontendIdAtom } from '../components/Settings/Filter'
import { type HistoricalOverviewMetricCard } from './types'

const HISTORICAL_OVERVIEW_ANALYSIS_QUERY = graphql(/* GraphQL */ `
  query HistoricalOverviewAnalysisQuery(
    $historicalOverviewAnalysisInput: HistoricalOverviewAnalysisInput!
  ) {
    viewer {
      id
      merchant {
        id
        historicalOverviewAnalysis(params: $historicalOverviewAnalysisInput) {
          missedPotentialMarkets
          metricCards
          missedPotentialAggregated {
            actualProfit
            missedProfit
            optimalProfit
          }
        }
      }
    }
  }
`)

export interface HistoricalOverviewMarket {
  id: string
  name: string
  optimalSpend: number
  actualSpend: number
  missedPotential: number
}

export type HistoricalOverviewAnalysis = Omit<
  NonNullable<
    HistoricalOverviewAnalysisQueryQuery['viewer']
  >['merchant']['historicalOverviewAnalysis'],
  'metricCards' | 'missedPotentialMarkets'
> & {
  metricCards: HistoricalOverviewMetricCard[]
  missedPotentialMarkets: HistoricalOverviewMarket[]
}

export const useHistoricalOverviewAnalysis = () => {
  const [
    {
      formattedValue: [start, end],
    },
  ] = useHistoricalAnalysisDateAtom()
  const selectedFrontendId = useSelectedFrontendIdAtom()

  const { data, ...query } = useQuery(HISTORICAL_OVERVIEW_ANALYSIS_QUERY, {
    skip: !selectedFrontendId,
    variables: {
      historicalOverviewAnalysisInput: {
        startDate: start,
        endDate: end,
        frontendId: selectedFrontendId ?? '',
      },
    },
  })

  return {
    // Selected segment gest loaded in the config query
    // This avoids race conditions when segment is not yet loaded
    // so we show a a loading state for that time
    query: { ...query, loading: query.loading || !selectedFrontendId },
    historicalOverviewAnalysis: data?.viewer?.merchant
      ?.historicalOverviewAnalysis as HistoricalOverviewAnalysis | undefined,
  }
}
