import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import { type ToggleIntegrationMutationVariables } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'

const TOGGLE_INTEGRATION_MUTATION = graphql(/* GraphQL */ `
  mutation ToggleIntegration($params: ToggleIntegrationInput!) {
    toggleIntegration(params: $params) {
      id
      __typename
      connected
    }
  }
`)

export const useToggleIntegration = () => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const [mutation, state] = useMutation(TOGGLE_INTEGRATION_MUTATION)

  const toggleIntegrationConnection = (
    params: ToggleIntegrationMutationVariables,
  ) => {
    mutation({
      variables: params,
      optimisticResponse: {
        toggleIntegration: {
          __typename: 'Integration',
          id: params.params.id,
          connected: params.params.connected,
        },
      },
      onCompleted: ({ toggleIntegration }) => {
        if (!toggleIntegration) return

        trackEvent({
          eventName: 'Settings Integration Toggled',
          eventProperties: {
            connected: Boolean(toggleIntegration.connected),
            integrationId: toggleIntegration.id,
          },
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Failed to toggle integration, please try again',
        })
      },
    })
  }

  return [toggleIntegrationConnection, state] as const
}
