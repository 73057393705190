import { useToast } from '@chakra-ui/react'
import { PRODUCT_LABELS_QUERY } from 'features/productFeed/graphql/useProductLabels'
import { type CreateProductLabelInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { set } from 'lodash-es'
import cloneDeep from 'lodash-es/cloneDeep'
import { type ReactElement } from 'react'
import { type ProductLabel } from '../graphql/fragments'
import { useCreateProductLabel } from '../graphql/useCreateProductLabel'
import { ProductLabelPicker } from './ProductLabelPicker'

interface AddProductLabelModalProps {
  modalButton: ReactElement
  onCompleted?: (label: ProductLabel) => void
}

export const AddProductLabelModal: React.FC<AddProductLabelModalProps> = ({
  modalButton,
  onCompleted,
}) => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const createProductLabel = useCreateProductLabel()

  const onApply = (label: CreateProductLabelInput) => {
    if (!label.name || !label.iconName || !label.iconColor) return

    createProductLabel({
      variables: {
        params: {
          name: label.name,
          iconName: label.iconName,
          iconColor: label.iconColor,
        },
      },
      optimisticResponse: {
        createProductLabel: {
          name: label.name,
          iconName: label.iconName,
          iconColor: label.iconColor,
          id: 'optimistic',
          __typename: 'ProductLabel',
        },
      },
      update: (cache, { data }) => {
        if (!data) return
        cache.updateQuery({ query: PRODUCT_LABELS_QUERY }, (queryData) => {
          if (!queryData?.viewer?.merchant.productLabels) return queryData

          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.merchant.productLabels', [
            ...queryData.viewer.merchant.productLabels,
            data.createProductLabel,
          ])

          return queryDataClone
        })
      },
      onCompleted: ({ createProductLabel }) => {
        trackEvent({
          eventName: 'ProductFeed Product Label Created',
          eventProperties: {
            name: createProductLabel.name,
            iconName: createProductLabel.iconName,
            iconColor: createProductLabel.iconColor,
          },
        })

        onCompleted?.(createProductLabel as ProductLabel)
      },
      onError: () => {
        toast({
          description: 'Could not add product label. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <ProductLabelPicker
      currentLabel={undefined}
      modalButton={modalButton}
      onApply={onApply}
    />
  )
}
