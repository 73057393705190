import { Box, Divider, Flex } from '@chakra-ui/react'
// import { useState } from 'react'
// import { LoaderPage } from 'shared'
// import { NotFound } from 'shared/NotFound'
import { LoaderPage } from 'shared/LoaderPage'
import { NotFound } from 'shared/NotFound'
import { ReportViewStatus } from '../atoms/reportViewStateAtom'
import { AnalyticsChartContainer } from '../components/AnalyticsChart/AnalyticsChartContainer'
import { AnalyticsTable } from '../components/AnalyticsTable/AnalyticsTable'
import { ReportFiltersContainer } from '../components/Filters/ReportFiltersContainer'
import { HiddenDimensionsOrMetricsAlert } from '../components/HiddenDimensionsOrMetricsAlert'
import { ReportHeader } from '../components/ReportHeader/ReportHeader'
import { ReportPanel } from '../components/ReportPanel/ReportPanel'
import { ReportStateSync } from '../components/ReportStateSync'
import { useReportStatus } from '../hooks/useReportStatus'

export const ReportView = () => {
  return (
    <>
      <ReportStateSync />
      <ReportViewContent />
    </>
  )
}

const ReportViewContent = () => {
  const reportStatus = useReportStatus()

  if (reportStatus === ReportViewStatus.Loading) return <LoaderPage />

  if (reportStatus === ReportViewStatus.NotFound) return <NotFound />

  return (
    <>
      {/* zIndex 0 is needed to create a new stacking context so that the different popovers work as expected */}
      <Flex direction="column" flex={1} h="full" zIndex={0} overflowY="hidden">
        <ReportHeader />
        <Flex flex={1} h="full" w="full" overflowY="hidden" maxW="full">
          <Flex
            direction="column"
            w="100%"
            flexBasis={0}
            flexShrink={1}
            flexGrow={1}
            h="full"
            overflowY="auto"
          >
            <Box position="sticky" top={0} zIndex={2} bg="white">
              <HiddenDimensionsOrMetricsAlert />

              <ReportFiltersContainer />

              <Divider />
            </Box>

            <AnalyticsChartContainer />

            <AnalyticsTable />
          </Flex>

          <ReportPanel />
        </Flex>
      </Flex>
    </>
  )
}
