import { type MutationFunctionOptions, useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import type {
  CreateConversationInput,
  CreateConversationMutation,
} from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'

const CREATE_CONVERSATION = graphql(/* GraphQL */ `
  mutation CreateConversation($payload: CreateConversationInput!) {
    createConversation(params: $payload) {
      ...ConversationFields
    }
  }
`)

export const useCreateConversation = () => {
  const [mutation, state] = useMutation(CREATE_CONVERSATION)
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const createConversation = async ({
    payload,
    options,
  }: {
    payload: CreateConversationInput
    options?: MutationFunctionOptions<CreateConversationMutation>
  }) => {
    return mutation({
      ...options,
      variables: {
        payload,
      },
      onCompleted: (data) => {
        trackEvent({
          eventName: 'Copilot Question Asked',
          eventProperties: {
            question: payload.text,
            conversationId: data.createConversation.id,
          },
        })
      },
      onError: (error) => {
        toast({
          status: 'error',
          description: 'Could not create conversation. Please try again',
        })

        options?.onError?.(error)
      },
    })
  }

  return [createConversation, state] as const
}
