import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMemo } from 'react'
import { productColumns } from './LogisticsCosts/ProductColumns'
import { TAXONOMY_TYPE } from './shared/consts'
import {
  ManualInputTable,
  type Banner,
} from './shared/ManualInput/ManualInputTable'

const types = [TAXONOMY_TYPE.GROSS_MARGIN]

export const ProductView = () => {
  const flags = useFeatureFlags()
  const filteredColumns = useMemo(() => {
    return flags.allowSettingsWrite
      ? productColumns
      : productColumns.filter((column) => column.id !== 'actions')
  }, [flags.allowSettingsWrite])

  const banner: Banner = {
    status: 'info',
    content:
      'These gross margin values will only be used as a fallback solution for products that lack purchase price (COGS) data.',
  }

  return (
    <ManualInputTable
      columns={filteredColumns}
      title="Product costs"
      banner={banner}
      types={types}
    />
  )
}
