import { useEffect, useState } from 'react'

export const AnimatedEllipsis: React.FC = () => {
  const [dots, setDots] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'))
    }, 250)

    return () => clearInterval(interval)
  }, [])

  return <>{dots}</>
}
