import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationSet } from 'features/productFeed/graphql/fragments'
import { getProductFeedCSVLink } from 'features/productFeed/shared/CopyCSVLinkModal'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'

interface CopyCSVItemProps {
  segmentationSet: SegmentationSet
}

export const CopyCSVItem: React.FC<CopyCSVItemProps> = ({
  segmentationSet,
}) => {
  const { merchantId } = useMerchantInfo()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  return (
    <>
      <MenuItem
        icon={<Icon name="LinkIcon" size="small" />}
        onClick={(e) => {
          e.stopPropagation()
          const csvLink = getProductFeedCSVLink(merchantId, segmentationSet.id)

          navigator.clipboard.writeText(csvLink).then(() => {
            toast({
              status: 'success',
              description: 'CSV link copied to clipboard',
              position: 'bottom-right',
            })
          })

          trackEvent({
            eventName: 'ProductFeed Segmentation Set CSV Link Copied',
            eventProperties: {
              name: segmentationSet.name,
              id: segmentationSet.id,
              fromModal: false,
            },
          })
        }}
        closeOnSelect={true}
      >
        Copy link to CSV file
      </MenuItem>
    </>
  )
}
