import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type SegmentationSet } from '../../../graphql/fragments'

export const SEGMENTATION_SETS_QUERY = graphql(/* GraphQL */ `
  query SegmentationSets {
    viewer {
      id
      merchant {
        id
        segmentationSets {
          ...SegmentationSetFields
        }
      }
    }
  }
`)

export const useSegmentationSets = () => {
  const { data, ...query } = useQuery(SEGMENTATION_SETS_QUERY)

  return {
    query,
    segmentationSets: (data?.viewer?.merchant.segmentationSets ??
      []) as SegmentationSet[],
  }
}
