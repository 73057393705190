import { Box, Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type ConversationMessageFieldsFragment } from 'generated/graphql/graphql'
import type React from 'react'

interface UserMessageProps {
  message: ConversationMessageFieldsFragment
}

export const UserMessage: React.FC<UserMessageProps> = ({ message }) => {
  return (
    <Flex justifyContent="end">
      <Box bg="gray.200" maxW="70%" borderRadius="sm" px={4} py={1.5}>
        <Typography
          fontSize="sm"
          lineHeight={5}
          color="gray.900"
          whiteSpace="pre-wrap"
        >
          {message.items.map((item) =>
            item.__typename === 'TextMessageItem' ? item.text : null,
          )}
        </Typography>
      </Box>
    </Flex>
  )
}
