import { useQuery, type QueryHookOptions } from '@apollo/client'
import { graphql } from 'generated/graphql'
import type { Channel, ChannelsQuery, Exact } from 'generated/graphql/graphql'
import { useMemo } from 'react'

const CHANNELS_QUERY = graphql(/* GraphQL */ `
  query Channels {
    channels {
      id
      name
    }
  }
`)

export type NormalizedChannels = Record<string, Channel>

const mapDataToChannelInfo = (data?: ChannelsQuery | null) => {
  const channels =
    data?.channels.slice().sort((a, b) => a.name.localeCompare(b.name)) ?? []

  const normalizedChannels: NormalizedChannels = Object.fromEntries(
    channels.map((channel) => [channel.id, channel]),
  )

  return {
    channels,
    normalizedChannels,
  }
}

// To use in components
export const useChannels = (
  options?:
    | QueryHookOptions<ChannelsQuery, Exact<{ [key: string]: never }>>
    | undefined,
) => {
  const query = useQuery(CHANNELS_QUERY, options)

  const { channels } = useMemo(
    () => mapDataToChannelInfo(query.data),
    [query.data],
  )

  return { channels, query }
}
