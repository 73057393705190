import { type DateRange } from 'constants/types'
import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { sub } from 'date-fns'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { useMemo, type FC } from 'react'
import { TaxonomyMenuButton } from './TaxonomyMenuButton'

type Props = {
  depth: number
  rowHasValue: boolean
  name?: string
  dimension: DropdownOption
  prevDimensionValues: string[]
  isReadOnly?: boolean
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  setSelectedItem: (option: string) => void
  setError: (errorMessage?: string) => void
}

const now = new Date()

export const dateRange: DateRange = [sub(now, { months: 2 }), now]

export const TaxonomyDropdown: FC<Props> = ({
  depth,
  rowHasValue,
  name,
  dimension,
  isOpen,
  isReadOnly,
  prevDimensionValues,
  onOpen,
  onClose,
  setSelectedItem,
  setError,
}) => {
  const { query, uniqueDimensionValues } = useUniqueDimensionValues({
    dimensionId: String(dimension.id),
    dateRange,
  })

  const options = useMemo(() => {
    let isNameInValues = false

    const options = uniqueDimensionValues.reduce<DropdownOption[]>(
      (acc, option) => {
        if (option.id && option.name) {
          if (name && option.id === name) {
            isNameInValues = true
          }

          acc.push({
            ...option,
            disabled: prevDimensionValues.includes(option.id.toString()),
          })
        }

        return acc
      },
      [],
    )

    // Adds missing option (if a value is set but not retrieved as dimension value, may be outside queried date range)
    if (name && !isNameInValues) {
      options.push({
        id: name,
        name,
        disabled: false,
      })
    }

    return options
  }, [name, prevDimensionValues, uniqueDimensionValues])

  const selectedName = options.find((o) => o.id === name)?.name

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.SINGLE}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      customMenuButton={
        <TaxonomyMenuButton
          rowHasValue={rowHasValue}
          depth={depth}
          group={dimension}
          name={selectedName}
          isOpen={isOpen}
          isReadOnly={isReadOnly}
          isLoading={query.loading}
          setError={setError}
        />
      }
      options={options}
      isLoading={query.loading}
      selected={name}
      setSelected={setSelectedItem}
      searchPlaceholder={dimension.name}
      containerProps={{ w: 'full' }}
    />
  )
}
