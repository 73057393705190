import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { DYNAMIC_DATE_ID, getDatePreset } from 'constants/getDatePresets'
import {
  Box,
  Table,
  type TableCellProps,
  type TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Button } from 'components/buttons'
import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { useHistoricalAnalysisDateValueSetter } from 'features/optimizations/atoms/dateAtom'
import { OPTIMIZATION_METRIC } from 'features/optimizations/consts'
import { OPTIMIZATIONS_PAGES } from 'features/optimizations/routePages'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import type React from 'react'
import { Link } from 'react-router-dom'
import { calcDifference } from 'utils/calcDifference'
import { formatMetric } from 'utils/numberFormats'
import { type HistoricalHistoryReport } from '../../hooks/useHistoricalHistoryAnalysis'

interface ReportTableProps {
  reports: HistoricalHistoryReport[] | undefined
  market: string
  isLoading: boolean
  hasError: boolean
}

const thStyles: TableColumnHeaderProps = {
  color: 'gray.800',
  borderColor: 'gray.200',
  px: 3,
}

const tdStyles: TableCellProps = {
  color: 'gray.700',
  borderColor: 'gray.200',
  px: 3,
}

export const ReportTable: React.FC<ReportTableProps> = ({
  reports,
  market,
  isLoading,
  hasError,
}) => {
  const { currency } = useMerchantInfo()
  const setDateValue = useHistoricalAnalysisDateValueSetter()

  if (isLoading) {
    return (
      <Box p={4} bg="white" boxShadow="base">
        <Table>
          <Tbody>
            <SkeletonTableRows rows={10} columns={8} />
          </Tbody>
        </Table>
      </Box>
    )
  }

  if (hasError || !reports) {
    return (
      <Box px={4} py={32} bg="white" boxShadow="base">
        <Box h="full">
          <WidgetErrorState />
        </Box>
      </Box>
    )
  }

  return (
    <Box p={4} bg="white" boxShadow="base">
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th {...thStyles}>Week</Th>
              <Th {...thStyles}>Spend ({currency})</Th>
              <Th {...thStyles}>Proposed spend ({currency})</Th>
              <Th {...thStyles}>Sales ({currency})</Th>
              <Th {...thStyles}>Missed sales ({currency})</Th>
              <Th {...thStyles}>Profit ({currency})</Th>
              <Th {...thStyles}>Missed profit ({currency})</Th>
              <Th {...thStyles}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {reports.map((report) => {
              const { formattedDifference: spendDiff, color: spendDiffColor } =
                calcDifference({
                  value: report[OPTIMIZATION_METRIC.SPEND_OPTIMAL],
                  compareValue: report[OPTIMIZATION_METRIC.SPEND],
                  currency,
                  format: METRIC_FORMAT.PERCENT_INT,
                  includePrefix: true,
                  displayFormat: DISPLAY_FORMAT.PERCENTAGE_DIFF,
                })

              const { formattedDifference: profitDiff } = calcDifference({
                value: report[OPTIMIZATION_METRIC.NGP3_OPTIMAL],
                compareValue: report[OPTIMIZATION_METRIC.NGP3],
                format: METRIC_FORMAT.INTEGER,
                currency,
                displayFormat: DISPLAY_FORMAT.PERCENTAGE_DIFF,
              })

              return (
                <Tr key={report.startDate}>
                  <Td {...tdStyles} fontWeight={500} color="gray.900">
                    {getCompareDateString(
                      new Date(report.startDate),
                      new Date(report.endDate),
                    )}
                  </Td>
                  <Td {...tdStyles}>
                    {formatMetric(
                      METRIC_FORMAT.INTEGER,
                      report[OPTIMIZATION_METRIC.SPEND],
                      currency,
                    )}
                  </Td>
                  <Td {...tdStyles}>
                    {formatMetric(
                      METRIC_FORMAT.INTEGER,
                      report[OPTIMIZATION_METRIC.SPEND_OPTIMAL],
                      currency,
                    )}{' '}
                    (
                    <Box as="span" fontWeight={600} color={spendDiffColor}>
                      {spendDiff}
                    </Box>
                    )
                  </Td>
                  <Td {...tdStyles}>
                    {formatMetric(
                      METRIC_FORMAT.INTEGER,
                      report[OPTIMIZATION_METRIC.SALES],
                      currency,
                    )}
                  </Td>
                  <Td
                    {...tdStyles}
                  >{`${formatMetric(METRIC_FORMAT.INTEGER, report[OPTIMIZATION_METRIC.SALES_OPTIMAL] - report[OPTIMIZATION_METRIC.SALES], currency)}`}</Td>
                  <Td {...tdStyles}>
                    {formatMetric(
                      METRIC_FORMAT.INTEGER,
                      report[OPTIMIZATION_METRIC.NGP3],
                      currency,
                    )}
                  </Td>
                  <Td {...tdStyles}>
                    <Badge
                      px={1.5}
                      py={0.5}
                      bg="primary.100"
                      color="primary.500"
                    >
                      {profitDiff}
                    </Badge>
                  </Td>
                  <Td {...tdStyles}>
                    <Button
                      as={Link}
                      to={OPTIMIZATIONS_PAGES.MARKET_WEEKLY_ANALYSIS(market)}
                      variant="link"
                      colorScheme="blue"
                      size="sm"
                      onClick={() =>
                        setDateValue({
                          ...getDatePreset(DYNAMIC_DATE_ID.CUSTOM_RANGE),
                          value: [
                            new Date(report.startDate),
                            new Date(report.endDate),
                          ],
                        })
                      }
                    >
                      View details
                    </Button>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}
