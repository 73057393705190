import { Box, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type { ReactElement } from 'react'

interface EmptyStateProps {
  imageSrc: string
  title: string
  description: string
  modalButton: ReactElement
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  imageSrc,
  title,
  description,
  modalButton,
}) => {
  return (
    <Box py="120px" px={4}>
      <Box maxW="380px" mx="auto">
        <Image src={imageSrc} alt={title} boxSize={120} mb={6} />

        <Typography
          color="gray.900"
          fontSize="medium"
          fontWeight={500}
          lineHeight={6}
          mb={2}
        >
          {title}
        </Typography>

        <Typography mb={6}>{description}</Typography>

        {modalButton}
      </Box>
    </Box>
  )
}
