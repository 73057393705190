import { useToast } from '@chakra-ui/react'
import { captureException } from '@sentry/react'
import { Button } from 'components/buttons'
import { Tooltip } from 'components/Tooltip/Tooltip'
import {
  analyticsConfigAtom,
  reportStateAtom,
} from 'features/reports/atoms/reportViewStateAtom'
import { useSelectReport } from 'features/reports/components/ReportSidebar/hooks/useSelectReport'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { useCreateReport } from 'graphql/reports/useCreateReport'
import { useNavigate } from 'react-router'
import { getStore } from 'shared/store'

interface CreateNewReportButtonProps {
  isValidReportNaming: boolean
}

export const CreateNewReportButton = ({
  isValidReportNaming,
}: CreateNewReportButtonProps) => {
  const [createReport, createReportState] = useCreateReport()
  const [createAnalyticsConfig] = useCreateAnalyticsConfig({
    shouldSetUrl: false,
  })
  const toast = useToast()
  const navigate = useNavigate()
  const { dimensions } = useDimensionsState()
  const { metrics } = useMetricsState()
  const hasValidDataOptions = dimensions.length > 0 && metrics.length > 0
  const isValidReport = isValidReportNaming && hasValidDataOptions
  const selectReport = useSelectReport()

  const createNewReport = async () => {
    try {
      // We get the report state from the store directly to save unnecessary rerenders. We don't need to subscribe to the values
      const reportState = getStore().get(reportStateAtom)
      const analyticsConfig = getStore().get(analyticsConfigAtom)

      const newAnalyticsConfig = await createAnalyticsConfig(analyticsConfig)

      const report = await createReport({
        report: {
          ...reportState,
          analyticsConfig: newAnalyticsConfig,
          visibility: reportState.visibility ?? [],
          labels: reportState.labels ?? [],
        },
      })

      selectReport(report)
      navigate(REPORT_PAGES.REPORT_BY_ID(report.id))
      toast({ status: 'success', description: 'Successfully created report!' })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not save report as copy, please try again',
      })
      captureException(`Save report copy: ${error}`)
    }
  }

  return (
    <Tooltip
      isDisabled={isValidReport}
      label={
        !hasValidDataOptions
          ? 'Select at least one dimension and metric'
          : !isValidReportNaming
            ? 'Title must include at least 2 characters'
            : undefined
      }
      placement="bottom-end"
    >
      <Button
        size="sm"
        colorScheme="primary"
        variant="solid"
        onClick={createNewReport}
        isDisabled={!isValidReport}
        isLoading={createReportState.loading}
      >
        Create report
      </Button>
    </Tooltip>
  )
}
