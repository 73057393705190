import { Flex, type FlexProps } from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { OPTIMIZATION_METRIC } from 'features/optimizations/consts'
import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { type FC } from 'react'
import { calcPercentageDiff } from 'utils/calcPercentageDiff'

interface Props {
  channelContribution: ContributionData[number] | undefined
}

const diffPercentageThreshold = 2.5

export const RecommendationCell: FC<Props> = ({ channelContribution }) => {
  if (!channelContribution) return null

  const diffPercentage = calcPercentageDiff(
    channelContribution[OPTIMIZATION_METRIC.SPEND_OPTIMAL],
    channelContribution[OPTIMIZATION_METRIC.SPEND],
  )
  const isDiffSignificant = Math.abs(diffPercentage) > diffPercentageThreshold

  if (!isDiffSignificant) {
    return <EfficientBadge />
  }
  if (diffPercentage > 0) {
    return <IncreaseBadge />
  }

  return <DecreaseBadge />
}

interface BadgeProps extends FlexProps {
  text: string
  iconName: IconName
}
const Badge = ({ text, iconName, color, bgColor }: BadgeProps) => {
  return (
    <Flex
      gap={2}
      bgColor={bgColor}
      color={color}
      alignItems="center"
      px={2.5}
      py={1}
      justifyContent="center"
    >
      <Typography fontSize="xs" color="inherit">
        {text}
      </Typography>
      <Icon size="small" name={iconName} color="inherit" />
    </Flex>
  )
}

const EfficientBadge = () => (
  <Badge
    text="Efficient spend"
    iconName="LikeIcon"
    color="primary.500"
    bgColor="primary.100"
  />
)
const IncreaseBadge = () => (
  <Badge
    text="Increase spend"
    iconName="ArrowUpCircleIcon"
    color="green.700"
    bgColor="green.50"
  />
)
const DecreaseBadge = () => (
  <Badge
    text="Decrease spend"
    iconName="ArrowDownCircleIcon"
    color="red.600"
    bgColor="red.50"
  />
)
