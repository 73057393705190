import { Flex } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import type React from 'react'

interface ExampleQuestionsProps {
  onQuestionClick: (message: string) => void
}

const questions = [
  'Which was our most profitable product last week?',
  'Can you show the traffic data for our website last month?',
]

export const ExampleQuestions: React.FC<ExampleQuestionsProps> = ({
  onQuestionClick,
}) => {
  return (
    <Flex direction="column" alignItems="end">
      <Typography
        fontSize="xs"
        fontWeight={500}
        lineHeight={4}
        color="gray.700"
        mb={1}
      >
        Example questions
      </Typography>

      <Flex direction="column" gap={2} alignItems="end">
        {questions.map((question) => (
          <Button
            key={question}
            onClick={() => onQuestionClick(question)}
            size="sm"
            variant="outline"
            colorScheme="gray"
          >
            {question}
          </Button>
        ))}
      </Flex>
    </Flex>
  )
}
