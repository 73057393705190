import { Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { FilterOperator } from 'components/Filters/types'
import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { PopupButton } from 'features/reports/components/Filters/SelectedFilterChip/PopupButton'
import { type FC } from 'react'
import { FILTER_OPTION_NAME } from './consts'
import { FilterOptionPicker } from './FilterOptionPicker'
import {
  type ReportListFilters,
  type FilterOptionValue,
  type ReportListFilterValues,
} from './types'

type Props = {
  values: ReportListFilterValues
  option: FilterOptionValue
  reports: ReportState[]
  filters: ReportListFilters
  setFilters: (
    selectedFilterOption: FilterOptionValue,
    values: ReportListFilterValues,
  ) => void
  onRemoveFilter: () => void
}

export const FilterBadge: FC<Props> = ({
  values,
  option,
  reports,
  filters,
  setFilters,
  onRemoveFilter,
}) => {
  const filter = {
    comparisonId: FilterOperator.isAnyOf,
    value: values.map((value) => value.id),
  }

  return (
    <Flex alignItems="stretch" minW="fit-content">
      <FilterOptionPicker
        isOpenByDefault={false}
        selectedFilterOption={option}
        reports={reports}
        filters={filters}
        setFilters={setFilters}
        customMenuButton={
          <PopupButton
            options={values}
            label={FILTER_OPTION_NAME[option]}
            filter={filter}
          />
        }
        setSelectedFilterOption={() => undefined}
      />
      <ButtonIcon
        name="CloseIcon"
        title="Remove filter"
        size="sm"
        h="unset"
        maxH="unset"
        onClick={onRemoveFilter}
      />
    </Flex>
  )
}
