import { useOrganization } from '@clerk/clerk-react'
import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Icon, type IconName } from 'components/Icon/Icon'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import { type Team } from 'generated/graphql/graphql'
import { getIsMerchantVisibility } from 'graphql/reports/utils'
import uniqBy from 'lodash-es/uniqBy'
import { useMemo, type FC } from 'react'
import { nonTeamVisibilityOptions } from '../../ReportVisibility/ReportVisibility'
import { type ReportListFilterPickerChildProps } from '../types'

export const FilterOptionVisibility: FC<ReportListFilterPickerChildProps> = ({
  selectedFilterOption,
  customMenuButton,
  reports,
  isOpenByDefault,
  selectedIds,
  setFilters,
  setSelectedFilterOption,
}) => {
  const { organization } = useOrganization()

  const uniqueOptions = useMemo(() => {
    const reportOptions: (DropdownOption & { id: string })[] = []

    reports.forEach((report) =>
      report.visibility?.map((visibilityItem) => {
        const { iconName = '', iconColor = '' } =
          (visibilityItem as unknown as Team) ?? {}
        const isMerchantVisibility = getIsMerchantVisibility(visibilityItem)

        reportOptions.push({
          ...visibilityItem,
          name: isMerchantVisibility ? 'Everyone' : visibilityItem.name,
          leftItem: isMerchantVisibility ? (
            <ClerkImage imageUrl={organization?.imageUrl} type="organisation" />
          ) : (
            <Icon
              size="small"
              name={iconName as IconName}
              hexColor={iconColor}
            />
          ),
        })
      }),
    )

    const options = [
      {
        ...nonTeamVisibilityOptions[0],
        leftItem: (
          <Icon
            size="small"
            name={nonTeamVisibilityOptions[0].iconName as IconName}
            hexColor={nonTeamVisibilityOptions[0].iconColor}
          />
        ),
      },
      ...reportOptions,
    ]

    return uniqBy(options, 'id')
  }, [organization?.imageUrl, reports])

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.MULTIPLE}
      isOpenByDefault={isOpenByDefault}
      customMenuButton={customMenuButton}
      options={uniqueOptions}
      selected={selectedIds}
      setSelected={(selected) =>
        setFilters(
          selectedFilterOption,
          uniqueOptions.filter((option) => selected.includes(option.id)),
        )
      }
      onClose={() => setSelectedFilterOption(undefined)}
    />
  )
}
