import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const SAVE_INTEGRATION_ACCOUNTS_MUTATION = graphql(/* GraphQL */ `
  mutation SaveIntegrationAccounts($params: SaveIntegrationAccountsInput!) {
    saveIntegrationAccounts(params: $params) {
      ...IntegrationFields
    }
  }
`)

export const useSaveIntegrationAccounts = () =>
  useMutation(SAVE_INTEGRATION_ACCOUNTS_MUTATION)
