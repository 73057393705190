import { Td, Tr } from '@chakra-ui/react'
import { EmptyData } from 'shared/EmptyData/EmptyData'

export const EmptyMappings = () => (
  <Tr>
    <Td colSpan={100} border="unset">
      <EmptyData label="No mappings found." />
    </Td>
  </Tr>
)
