import { Flex } from '@chakra-ui/react'
import { type Row } from '@tanstack/react-table'
import { ButtonIcon } from 'components/buttons'
import { MANUAL_INPUT_COLUMN } from '../consts'
import { getInvalidColumns } from '../ManualInput/utils/getErrors'
import { type ManualInputColumns } from '../types'
import { ErrorIconCell } from './ErrorIconCell'
import { validDimensionIds } from './TaxonomyCell/TaxonomyDimensionSelector'

type Props = {
  row: Row<ManualInputColumns>
  isDisabled?: boolean
  addSubRow: () => void
  deleteRow: () => void
}

export const ActionCell = ({
  row,
  isDisabled,
  addSubRow,
  deleteRow,
}: Props) => {
  const isFirstRow = row.depth === 0
  const invalidRow = getInvalidColumns({
    columnsToValidate: [MANUAL_INPUT_COLUMN.NAME],
    row,
  }).some((col) => col)
  const lastLevel = row.depth === validDimensionIds.length
  const isAddDisabled = (!isFirstRow && invalidRow) || lastLevel || isDisabled

  return (
    <Flex justifyContent="left" h="full" alignItems="center">
      <ButtonIcon
        name="PlusSmallIcon"
        variant="ghost"
        colorScheme="grey"
        disabled={isAddDisabled}
        isDisabled={isAddDisabled}
        size="sm"
        onClick={() => {
          if (!row.getIsExpanded()) row.toggleExpanded()
          addSubRow()
        }}
        title="Add nested row"
      />

      <ButtonIcon
        visibility={isFirstRow ? 'hidden' : 'visible'}
        variant="ghost"
        colorScheme="grey"
        size="sm"
        name="TrashIcon"
        disabled={isDisabled}
        isDisabled={isDisabled}
        onClick={deleteRow}
        title="Delete row"
        mr={3}
      />

      <ErrorIconCell row={row} />
    </Flex>
  )
}
