import {
  chartMetricIds,
  chartMetricMap,
  optimizationsMetricValuesMap,
} from 'features/optimizations/consts'
import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { getChannelName } from 'features/optimizations/utils/transformChannel'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import type { Options } from 'highcharts'
import { colorTheme } from 'ui/theme/colors'
import { yAxisFormatter } from 'utils/chart/common'
import {
  getChartStripedPattern,
  staticChartOptions,
} from 'utils/chart/constants'
import { getTooltipFormatter } from './getTooltipFormatter'

interface GetChartOptionsForBaseChartProps {
  contribution: ContributionData
  currency: string | undefined
}

export const getChartOptions = ({
  contribution,
  currency,
}: GetChartOptionsForBaseChartProps): Options => {
  const categories = contribution.map((row) => getChannelName(row.channel))

  const series: Options['series'] = []

  chartMetricIds.forEach((metric) => {
    const striped = chartMetricMap[metric].striped
    const serie = {
      data: contribution?.map((row) => {
        const value = optimizationsMetricValuesMap[metric].getValue(row)

        return [row.channel, isFinite(value) ? value : 0] // We want to avoid NaN values to have good looking charts
      }),
      ...chartMetricMap[metric],
      minPointLength: 4,
      color: striped
        ? getChartStripedPattern({
            backgroundColor: chartMetricMap[metric].color,
            stroke: striped.color,
          })
        : chartMetricMap[metric].color,
      groupPadding: 0.1,
      stickyTracking: true,
      legendSymbol: 'rectangle',
    } satisfies NonNullable<Options['series']>[number]

    series.push(serie)
  })

  return {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      alignThresholds: true,
    },
    series,
    xAxis: [
      {
        minPadding: 0,
        title: {
          style: {
            fontWeight: '500',
            color: colorTheme.gray[700],
            fontSize: '12px',
          },
          text: 'Channels',
        },
        labels: {
          style: {
            color: colorTheme.gray[600],
            fontSize: '10px',
          },
          autoRotationLimit: 300,
        },
        lineColor: colorTheme.gray[200],
        type: 'category',
        categories: categories,
        visible: true,
        id: 'channel',
        crosshair: {
          color: colorTheme.gray[100],
          dashStyle: 'Solid',
        },
      },
    ],
    yAxis: [
      {
        gridLineWidth: 1,
        gridLineColor: colorTheme.gray[200],
        title: undefined,
        labels: {
          style: {
            color: colorTheme.gray[600],
            fontSize: '10px',
          },
          formatter: yAxisFormatter(METRIC_FORMAT.CURRENCY),
        },
        visible: true,
        id: '1',
        opposite: false,
      },
      {
        title: undefined,
        gridLineWidth: 0,
        labels: {
          style: {
            color: colorTheme.gray[600],
            fontSize: '10px',
          },
          formatter: yAxisFormatter(METRIC_FORMAT.PERCENT),
        },
        visible: true,
        id: '2',
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      shared: true,
      stickOnContact: true,

      formatter: getTooltipFormatter({ currency }),
      positioner: function (_, __, point) {
        const chart = this.chart
        const columnW = chart.plotWidth / categories.length
        const index = Math.round(point.plotX / columnW) + 1
        const leftLegendOffset = (chart.chartWidth - chart.plotWidth) / 2
        const rightIndent = 10 // just a little indent from the right to make the tooltip stick on contact

        return {
          x: columnW * index + leftLegendOffset - rightIndent,
          y: 0,
        }
      },
    },
  }
}
