import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'

export const EmptyScenario = () => {
  return (
    <Flex
      flexDirection="column"
      w="full"
      height="full"
      overflowY="auto"
      justifyContent="center"
      py={5}
      px={{ base: 10, lg: 120 }}
    >
      <Flex flexDirection="column" gap={4} maxW={330}>
        <Icon name="ChartColumnIcon" customSize={56} color="primary.500" />
        <Flex flexDirection="column" w="full" gap={2}>
          <Typography fontSize="xl" fontWeight="light">
            No scenario created yet
          </Typography>
          <Typography fontSize="sm">
            Optimize your marketing spend by running a scenario for a specific
            week, segment and market.
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}
