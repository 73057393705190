import { useEffect, useState } from 'react'

export const useIsOverflowing = (ref: React.RefObject<HTMLElement>) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    const element = ref.current

    setIsOverflowing(
      element ? element.offsetWidth < element.scrollWidth : false,
    )
  }, [ref])

  return isOverflowing
}
