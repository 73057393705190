import { useQuery } from '@apollo/client'
import { type IconName } from 'components/Icon/Icon'
import { graphql } from 'generated/graphql'
import type { UserTeamsQuery } from 'generated/graphql/graphql'

export const USER_TEAMS_QUERY = graphql(/* GraphQL */ `
  query UserTeams {
    viewer {
      id
      teams {
        ...Teams_TeamFields
      }
    }
  }
`)

export type UserTeam = NonNullable<
  UserTeamsQuery['viewer']
>['teams'][number] & { iconName: IconName }

export const useUserTeams = () => {
  const query = useQuery(USER_TEAMS_QUERY)

  return {
    userTeams: (query.data?.viewer?.teams ?? []) as UserTeam[],
    query,
  }
}
