import { Flex } from '@chakra-ui/react'
import { useSignIn } from '@clerk/clerk-react'
import { zodResolver } from '@hookform/resolvers/zod'

import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { useState, type Dispatch, type SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'

const validationSchema = z.object({
  email: z
    .string()
    .min(1, 'Email address is required')
    .email('Invalid email address'),
})

type ValidationSchema = z.infer<typeof validationSchema>

type Props = {
  setResetCodeRequested: Dispatch<SetStateAction<boolean>>
}

export const EmailInputForm = ({ setResetCodeRequested }: Props) => {
  const [error, setError] = useState('')
  const { isLoaded, signIn } = useSignIn()

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
  })
  const {
    formState: { errors, isSubmitting },
  } = methods

  async function onSubmit({ email }: ValidationSchema) {
    await signIn
      ?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      .then(() => {
        setResetCodeRequested(true)
        setError('')
      })
      .catch((err) => {
        setError(err.errors[0].longMessage)
      })
  }

  return (
    <>
      <Form display="flex" methods={methods} onSubmit={onSubmit}>
        <Typography
          fontSize="3xl"
          fontWeight="600"
          lineHeight="36px"
          textAlign="center"
          color="grey.100"
          mb={2}
        >
          Forgot your password?
        </Typography>
        <Typography
          fontSize="sm"
          lineHeight="20px"
          textAlign="center"
          color="grey.100"
          mb={8}
        >
          No problem! Just enter the email address you signed up with to reset
          it.
        </Typography>
        <Input
          w="340px"
          colorMode="dark"
          name="email"
          type="email"
          label="Email"
          wrapperProps={{ mb: 4 }}
          error={errors.email}
        />
        <Button
          mt={2}
          isLoading={isSubmitting}
          type="submit"
          variant="solid"
          minW="100%"
          isDisabled={!isLoaded}
        >
          Send reset instructions
        </Button>
        {error && (
          <Typography fontSize="xs" color="red.300" mt={1}>
            {error}
          </Typography>
        )}
      </Form>
      <Flex justify="center">
        <Link to="/auth/login">
          <Button mt={6} variant="link" color="primary.300">
            Back to log in
          </Button>
        </Link>
      </Flex>
    </>
  )
}
