import { Tag } from 'components/Tag/Tag'
import { getMappingsLabel } from '../shared/utils'

interface Props {
  value: string
  maxWidth?: string
  channelValue?: boolean
}

export const ExistingMappingsCell = ({
  value,
  maxWidth,
  channelValue,
}: Props) => (
  <Tag
    variant="solid"
    size="lg"
    label={getMappingsLabel(value)}
    minW="full"
    maxW={maxWidth}
    colorScheme={channelValue ? 'primary' : 'grey'}
  />
)
