import { Divider, Flex } from '@chakra-ui/react'
import { type GridApi } from 'ag-grid-community'
import { ButtonIcon } from 'components/buttons'
import { Dropdown } from 'components/Dropdown'
import { Typography } from 'components/Typography'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useEffect, useState } from 'react'
import { pageSizeList } from './consts'

interface Props {
  gridApi: GridApi<NormalizedStatistic> | undefined
}
export const TablePagination = ({ gridApi }: Props) => {
  const [selectedPageSize, setSelectedPageSize] = useState(pageSizeList[1])
  const [selectedPageIndex, setSelectedPageIndex] = useState(0)

  useEffect(() => {
    if (selectedPageSize && gridApi) {
      gridApi.setGridOption('paginationPageSize', selectedPageSize.size)
    }
  }, [selectedPageSize, gridApi])

  if (!gridApi) {
    return null
  }

  const totalPages = gridApi.paginationGetTotalPages()
  const totalTableRows = gridApi.paginationGetRowCount()
  const pageSize = gridApi.paginationGetPageSize()
  const pageIndex = gridApi.paginationGetCurrentPage()
  const isFirstPage = pageIndex === 0
  const isLastPage = pageIndex === totalPages - 1
  const currentPage = `${pageSize * pageIndex + 1} - ${Math.min(
    selectedPageSize.size * pageIndex + pageSize,
    totalTableRows,
  )}`

  const pageIndexOptions = Array(totalPages)
    .fill(0)
    .map((_, i) => ({
      id: i,
      value: i,
      name: (i + 1).toLocaleString(),
    }))

  if (totalPages === 0) {
    return null
  }

  return (
    <Flex justifyContent="end" alignItems="center" gap={4} flexWrap="wrap">
      <Typography color="grey.700" fontSize="sm">
        {currentPage} of {totalTableRows.toLocaleString()} items
      </Typography>
      <Dropdown
        options={pageSizeList}
        callback={(size) => {
          setSelectedPageSize(size)
          gridApi.setGridOption('paginationPageSize', size.size)
          setSelectedPageIndex(0)
          gridApi.paginationGoToPage(0)
        }}
        size="sm"
        variant="solid"
        menuProps={{ placement: 'top-start' }}
        listProps={{ minW: 'unset' }}
        value={selectedPageSize?.id}
        displayValue={`${pageSize} / page`}
      />

      <Divider orientation="vertical" h="4" />

      <Flex alignItems="center" gap={1}>
        <Dropdown
          options={pageIndexOptions}
          callback={(e) => {
            setSelectedPageIndex(e.value)
            gridApi.paginationGoToPage(e.value)
          }}
          size="sm"
          variant="solid"
          menuProps={{ placement: 'top-start' }}
          listProps={{ minW: 'unset' }}
          value={selectedPageIndex}
          displayValue={(selectedPageIndex + 1).toLocaleString()}
        />

        <Typography color="grey.700" fontSize="sm">
          of {totalPages} pages
        </Typography>
      </Flex>

      <Flex alignItems="center" alignSelf="center">
        <ButtonIcon
          size="md"
          onClick={() => {
            gridApi.paginationGoToFirstPage()
            setSelectedPageIndex(0)
          }}
          isDisabled={isFirstPage}
          name="FirstPageIcon"
          title="Go to first page"
          variant="ghost"
          colorScheme="grey"
        />

        <ButtonIcon
          size="md"
          onClick={() => {
            gridApi.paginationGoToPreviousPage()
            setSelectedPageIndex((c) => c - 1)
          }}
          isDisabled={isFirstPage}
          name="ChevronLeftIcon"
          title="Go to previous page"
          variant="ghost"
          colorScheme="grey"
        />

        <ButtonIcon
          size="md"
          onClick={() => {
            gridApi.paginationGoToNextPage()
            setSelectedPageIndex((c) => c + 1)
          }}
          isDisabled={isLastPage}
          name="ChevronRightIcon"
          title="Go to next page"
          variant="ghost"
          colorScheme="grey"
        />

        <ButtonIcon
          size="md"
          onClick={() => {
            gridApi.paginationGoToLastPage()
            setSelectedPageIndex(totalPages - 1)
          }}
          isDisabled={isLastPage}
          name="LastPageIcon"
          title="Go to last page"
          variant="ghost"
          colorScheme="grey"
        />
      </Flex>
    </Flex>
  )
}
