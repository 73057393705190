import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import { initializeMetricsAttribution } from 'features/reports/utils/utils'
import {
  getMetricKeyWithoutAttribution,
  type Metric,
} from 'graphql/statistics/useMetrics'

export const useFinalMetrics = () => {
  const { metrics } = useMetricsState()

  const getFinalMetrics = (selectedMetrics: Metric[]) => {
    const selectedMetricsSet = new Set(
      selectedMetrics.map(getMetricKeyWithoutAttribution),
    )
    const addedMetrics = new Set()
    const result = []

    // Preserve all current metrics that have been selected
    for (const metric of metrics) {
      const key = getMetricKeyWithoutAttribution(metric)

      if (selectedMetricsSet.has(key)) {
        result.push(metric)
        addedMetrics.add(key)
      }
    }

    // Add new metrics with default attribution
    const newMetrics = selectedMetrics.filter(
      (metric) => !addedMetrics.has(getMetricKeyWithoutAttribution(metric)),
    )
    const newMetricsWithAttribution: Metric[] =
      initializeMetricsAttribution(newMetrics)

    const finalMetrics = result.concat(newMetricsWithAttribution)

    return finalMetrics
  }

  return { getFinalMetrics }
}
