import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const DELETE_PRODUCT_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteProductLabel($id: ID!) {
    deleteProductLabel(id: $id) {
      id
      __typename
    }
  }
`)

export const useDeleteProductLabel = () => {
  const [deleteProductLabel] = useMutation(DELETE_PRODUCT_LABEL_MUTATION)

  return deleteProductLabel
}
