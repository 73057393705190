import { useIsGuestUser } from 'hooks/useIsGuestUser'
import type React from 'react'
import { NotFound } from 'shared/NotFound'

interface GuestUserGuardProps {
  children: React.ReactNode
}

export const GuestUserGuard: React.FC<GuestUserGuardProps> = ({ children }) => {
  const isGuestUser = useIsGuestUser()

  if (isGuestUser) {
    return <NotFound />
  }

  return children
}
