import { type DropdownOption } from 'components/Dropdown'
import { FilterOperator } from 'components/Filters/types'
import {
  filterOperatorMappings,
  filterOperatorMappingsWithShortName,
  filterOperatorMappingsWithSingleValue,
  isFilterOperatorPercent,
  isFilterOperatorSingleValue,
} from 'features/reports/utils/utils'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type Metric } from 'graphql/statistics/useMetrics'
import { useMemo } from 'react'
import { formatMetric } from 'utils/numberFormats'

type FormatDisplayValueProps = {
  value?: string
  options: DropdownOption[]
  metricFormat?: Metric['format']
  operator: FilterOperator
}

const formatDisplayValue = ({
  value = '',
  options,
  metricFormat,
  operator,
}: FormatDisplayValueProps) => {
  const selectedDropdownValue = options.find(({ id }) => id === value)

  if (selectedDropdownValue) {
    return selectedDropdownValue.name
  }

  const isPercentOperator = isFilterOperatorPercent(operator)
  const finalValue = isPercentOperator ? Number(value) / 100 : Number(value)
  const format = isPercentOperator
    ? METRIC_FORMAT.PERCENT_INT
    : (metricFormat ?? METRIC_FORMAT.INTEGER)

  return formatMetric(format, Number(finalValue))
}

type Props = {
  values: string[]
  operator: FilterOperator
  options: DropdownOption[]
  metricFormat?: Metric['format']
  maxValue?: string
}

export const useFilterOperator = ({
  values,
  operator,
  options,
  metricFormat,
  maxValue,
}: Props) => {
  const { selectedValues, operatorText } = useMemo(() => {
    const firstFormattedValue = formatDisplayValue({
      value: values[0],
      options,
      metricFormat,
      operator,
    })

    const formattedMaxValue = formatDisplayValue({
      value: maxValue,
      options,
      metricFormat,
      operator,
    })

    const firstValue = [
      FilterOperator.contains,
      FilterOperator.doesNotContain,
    ].includes(operator)
      ? values[0]
      : firstFormattedValue

    const selectedValues =
      operator === FilterOperator.between
        ? `${firstValue} and ${formattedMaxValue}`
        : values.length > 1
          ? `${firstValue} and ${values.length - 1} more`
          : firstValue

    const operatorText =
      values.length === 1 && isFilterOperatorSingleValue(operator)
        ? filterOperatorMappingsWithSingleValue[operator]
        : isFilterOperatorPercent(operator)
          ? filterOperatorMappingsWithShortName[operator]
          : filterOperatorMappings[operator]

    return { selectedValues, operatorText }
  }, [maxValue, metricFormat, operator, options, values])

  return { selectedValues, operatorText }
}
