import { atom, useAtom, useSetAtom } from 'jotai'

export enum ReportPanelOpenState {
  CHART_SETTINGS = 'chartSettings',
  REPORT_DETAILS = 'reportDetails',
}

const reportPanelOpenAtom = atom<ReportPanelOpenState | null>(
  ReportPanelOpenState.REPORT_DETAILS,
)

export const useReportPanelOpenAtom = () => useAtom(reportPanelOpenAtom)
export const useReportPanelSetAtom = () => useSetAtom(reportPanelOpenAtom)
