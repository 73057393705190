import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)

const baseStyle = definePartsStyle({
  dialog: {
    borderRadius: 0,
    boxShadow: 'md',
  },
  closeButton: {
    borderRadius: 0,
    top: 4,
    right: 4,
  },
  header: {
    p: 4,
    fontSize: 'xl',
    fontWeight: 400,
    color: 'gray.900',
  },
  body: {
    px: 4,
    pt: 0,
    pb: 4,
    fontSize: 'sm',
    fontWeight: 400,
    color: 'gray.900',
    lineHeight: 5,
  },
  footer: {
    p: 4,
  },
})

export const modalTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    // @ts-expect-error Chakra has type issues here
    isCentered: true,
  },
})
