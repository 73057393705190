import { Image } from '@chakra-ui/react'
import { type FC } from 'react'
type Props = {
  imageUrl: string | undefined
  type: 'organisation' | 'user'
}

export const ClerkImage: FC<Props> = ({ imageUrl, type }) => {
  if (!imageUrl) return null

  return (
    <Image
      alt={`${type} image`}
      src={imageUrl}
      w={4}
      h={4}
      objectFit="contain"
      borderRadius={type === 'organisation' ? 1 : 'full'}
    />
  )
}
