import { Box, Flex } from '@chakra-ui/react'
import { type ColDef, type ValueFormatterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import {
  useAnalyticsTableState,
  useSetTableState,
} from 'features/reports/hooks/useAnalyticsTableState'
import { useCompareUnitState } from 'features/reports/hooks/useCompareUnitState'
import { useReportsAnalytics } from 'features/reports/hooks/useReportsAnalytics'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useEffect, useRef, useState } from 'react'
import './agGridTheme.scss'
import { useTableColumns } from './columnGenerator'
import {
  MIN_COLUMN_SIZE,
  loadingRow,
  pageSizeList,
  skeletonRowsLength,
} from './consts'
import { EmptyTableInfo } from './EmptyTableInfo'
import { AnalyticsTableHeader } from './header/AnalyticsTableHeader'
import { TablePagination } from './TablePagination'
import { type AnalyticsCell } from './types'
import { mapSortToAGSort } from './utils'

const dataWithEmptyState: NormalizedStatistic[] = Array(
  skeletonRowsLength,
).fill({ loading: loadingRow })

const emptyArray: NormalizedStatistic[] = []

const rowHeight = 42

export const AnalyticsTable = () => {
  const {
    data = emptyArray,
    aggregatedData,
    loading: isLoading,
  } = useReportsAnalytics()

  const gridRef = useRef<AgGridReact<NormalizedStatistic> | null>(null)
  const gridApi = gridRef.current?.api
  const normalizedDimensions = useNormalizedDimensions()
  const normalizedMetrics = useNormalizedMetrics()
  const [selectedPageSize] = useState(pageSizeList[1])
  const state = useAnalyticsTableState()
  const { moveTableColumn } = useSetTableState()
  const compareUnit = useCompareUnitState()

  // These properties apply to all of the columns
  const [defaultColDef] = useState<ColDef>({
    sortable: true,
    filter: false,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    minWidth: MIN_COLUMN_SIZE,
    flex: 1,
    initialFlex: 1,
    lockPinned: true, // Prevents columns from being pinned when using drag and drop
    headerComponent: AnalyticsTableHeader,
    valueFormatter: ({
      value,
    }: ValueFormatterParams<NormalizedStatistic, AnalyticsCell>) =>
      value?.formattedValue ?? '',
  })

  const columns = useTableColumns({
    normalizedDimensions,
    normalizedMetrics,
    aggregatedData,
    isLoading,
    tableState: state,
    compareUnit,
  })

  useEffect(() => {
    // TODO isLoading is a mitigation until we can solve columns freeze issue with column width
    if (gridApi && !isLoading) {
      gridApi.applyColumnState({
        state: state?.map(({ id, sort, isPinned }) => ({
          colId: id,
          sort: mapSortToAGSort(sort),
          pinned: isPinned ? 'left' : null,
        })),
      })
    }
  }, [state, gridApi, isLoading, columns.length])

  const renderData = isLoading ? dataWithEmptyState : data

  return (
    <>
      <Flex zIndex={1} position="relative" flexDir="column" flex={1}>
        <Box
          maxW="100%"
          height="100%"
          width="100%"
          className="ag-theme-quartz"
          minH="50vh"
        >
          <AgGridReact<NormalizedStatistic>
            rowData={renderData} // dataWithEmptyState enables showing loading skeleton
            columnDefs={columns}
            defaultColDef={defaultColDef}
            pagination
            paginationPageSize={selectedPageSize.size}
            ref={gridRef}
            animateRows={false}
            suppressDragLeaveHidesColumns
            onColumnMoved={(e) => {
              if (!e.finished) return

              const toIndex = e.toIndex

              const fromIndex = columns?.findIndex(
                (column) => column.field === e.column?.getColId(),
              )

              if (toIndex === undefined || fromIndex === undefined) {
                return
              }

              moveTableColumn(fromIndex, toIndex)
            }}
            suppressPaginationPanel
            suppressScrollOnNewData
            suppressCellFocus
            groupMaintainOrder
            enableCellTextSelection
            noRowsOverlayComponent={EmptyTableInfo}
            rowHeight={rowHeight}
          />
        </Box>
      </Flex>
      {!isLoading && (
        <Flex
          py={2}
          px={4}
          borderTop="solid 1px"
          borderTopColor="grey.200"
          justifyContent="end"
        >
          <TablePagination gridApi={gridApi} />
        </Flex>
      )}
    </>
  )
}
