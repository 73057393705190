import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { pick } from 'lodash-es'

const EDIT_TEAM_BY_ID_QUERY = graphql(/* GraphQL */ `
  mutation EditMerchantTeamById($teamId: ID!, $payload: EditTeamInput!) {
    editTeam(id: $teamId, params: $payload) {
      id
      iconName
      iconColor
      name
      preferredMetrics
      preferredDimensions
    }
  }
`)

export const useEditTeamById = () => {
  const [mutation, state] = useMutation(EDIT_TEAM_BY_ID_QUERY)
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const editTeam = async (teamId: string, payload: MerchantTeam) => {
    const pickProperties: (keyof typeof payload)[] = [
      'iconColor',
      'iconName',
      'name',
      'preferredDimensions',
      'preferredMetrics',
    ]

    return mutation({
      variables: {
        teamId,
        payload: pick(payload, pickProperties),
      },
      optimisticResponse: {
        editTeam: {
          ...payload,
          id: teamId,
        },
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Could not update team. Please try again',
        })
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'Team Edited',
          eventProperties: {
            teamId,
            teamName: payload.name,
            preferredDimensions: payload.preferredDimensions,
            preferredMetrics: payload.preferredMetrics,
          },
        })
      },
    })
  }

  return [editTeam, state] as const
}
