import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const sizes = {
  lg: definePartsStyle({
    field: {
      fontSize: 'sm',
      borderRadius: 0,
      px: 4,
      py: 2.5,
    },
  }),
  md: definePartsStyle({
    field: {
      fontSize: 'sm',
      borderRadius: 0,
      px: 4,
      py: 2,
    },
  }),
  sm: definePartsStyle({
    field: {
      fontSize: 'sm',
      borderRadius: 0,
      px: 2.5,
      py: 1.5,
    },
  }),
  xs: definePartsStyle({
    field: {
      fontSize: 'xs',
      borderRadius: 0,
      px: 2,
      py: 1,
    },
  }),
}

const outline = definePartsStyle((props) => {
  const borderColor = mode('grey.400', 'grey.600')(props)

  return {
    field: {
      borderColor,
      bgColor: mode('white', 'grey.900')(props),
      color: mode('grey.800', 'grey.100')(props),
      _focusVisible: {
        borderColor: mode('primary.500', 'primary.400')(props),
        boxShadow: 'unset',
      },
      _placeholder: { color: mode('grey.400', 'grey.500')(props) },
      _hover: {
        borderColor: mode('grey.600', 'grey.500')(props),
        _disabled: {
          borderColor,
        },
      },
      _invalid: {
        boxShadow: 'unset',
      },
      _disabled: {
        cursor: 'default',
      },
      _readOnly: {
        bg: 'grey.100',
        color: 'grey.800',
        cursor: 'default',
        _hover: {
          borderColor,
        },
        _focusVisible: {
          borderColor,
        },
      },
    },
  }
})

const flushed = definePartsStyle((props) => {
  const borderColor = mode('grey.200', 'grey.800')(props)

  return {
    field: {
      borderColor,
      bgColor: mode('white', 'grey.900')(props),
      color: mode('grey.800', 'grey.100')(props),
      _focusVisible: {
        borderColor,
        boxShadow: 'unset',
      },
      _placeholder: { color: mode('grey.400', 'grey.500')(props) },
      _hover: {
        borderColor,
        _disabled: {
          borderColor,
        },
      },
      _invalid: {
        boxShadow: 'unset',
      },
    },
  }
})

export const inputTheme = defineMultiStyleConfig({
  variants: { outline, flushed },
  sizes,
})
