import { type MenuItemProps, MenuItem } from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'
import { Link, type LinkProps } from 'react-router-dom'
import { type OptionalKey } from 'types/optionalKey'

type Props = MenuItemProps &
  OptionalKey<LinkProps, 'to'> & {
    name: string
    iconName: IconName
  }

export const AccountMenuItem: FC<Props> = ({ name, iconName, to, ...rest }) => {
  return (
    <MenuItem
      icon={<Icon name={iconName} size="small" />}
      color="gray.700"
      iconSpacing={2}
      as={to ? Link : undefined}
      to={to}
      {...rest}
    >
      <Typography as="span" size="sm">
        {name}
      </Typography>
    </MenuItem>
  )
}
