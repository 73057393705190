import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  useToast,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export const userFullNameSchema = z.object({
  fullName: z
    .string()
    .trim()
    .refine((value) => value.split(' ').filter(Boolean).length >= 2, {
      message: 'Name must contain both a first and a last name.',
    })
    .transform((value) => value.split(' ').filter(Boolean).join(' ')),
})

export const ChangeNameModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const { user } = useUser()

  const {
    formState: { isValid, errors, isSubmitting },
    register,
    handleSubmit,
  } = useForm({
    resolver: zodResolver(userFullNameSchema),
    defaultValues: {
      fullName: user?.fullName ?? '',
    },
  })

  const changeName = async (values: z.infer<typeof userFullNameSchema>) => {
    try {
      if (!values.fullName || !user) return

      const names = values.fullName.split(' ')
      const firstName = names.shift()
      const lastName = names.join(' ')
      const previousName = user.fullName

      await user.update({
        firstName,
        lastName,
      })

      toast({
        status: 'success',
        description: 'Name changed',
        position: 'bottom',
      })

      trackEvent({
        eventName: 'Profile Name Changed',
        eventProperties: {
          previousName,
          newName: values.fullName,
        },
      })

      onClose()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not change name, please try again',
        position: 'bottom',
      })
    }
  }

  return (
    <Box>
      <Button onClick={onOpen} variant="link" colorScheme="primary">
        Change name
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change name</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(changeName)}>
            <ModalBody>
              <FormControl>
                <FormLabel
                  htmlFor="fullName"
                  fontSize="xs"
                  mb={2}
                  color="gray.700"
                >
                  Name
                </FormLabel>
                <Input
                  id="fullName"
                  type="string"
                  placeholder="John Doe"
                  {...register('fullName', {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>{errors.fullName?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="gray"
                variant="outline"
                mr={2}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                type="submit"
                colorScheme="primary"
                isLoading={isSubmitting}
                isDisabled={!isValid}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
