import { getApolloClient } from 'apollo'
import type { ConversationMessageFieldsFragment as ConversationMessageFieldsFragmentType } from 'generated/graphql/graphql'
import { ConversationMessageFieldsFragment } from '../graphql/fragments'
import { type useCreateConversationMessage } from '../graphql/useCreateConversationMessage'

export const addOptimisticMessageToCache = async ({
  conversationId,
  message,
  optimisticNewMessage,
  onSetMessage,
  createConversationMessage,
}: {
  conversationId: string
  message: string
  optimisticNewMessage: ConversationMessageFieldsFragmentType
  onSetMessage: (id: string) => void
  createConversationMessage: ReturnType<typeof useCreateConversationMessage>[0]
}) => {
  const apolloClient = getApolloClient()

  const fragmentRef = apolloClient.cache.writeFragment({
    id: apolloClient.cache.identify(optimisticNewMessage),
    data: optimisticNewMessage,
    fragment: ConversationMessageFieldsFragment,
    fragmentName: 'ConversationMessageFields',
  })

  apolloClient.cache.modify({
    id: apolloClient.cache.identify({
      __typename: 'Conversation',
      id: conversationId,
    }),
    fields: {
      messages(existingMessages = []) {
        return [...existingMessages, fragmentRef]
      },
    },
  })

  await createConversationMessage({
    payload: {
      id: conversationId,
      text: message,
    },
    options: {
      update: (cache, { data }) => {
        if (data?.createConversationMessage) {
          cache.modify({
            id: cache.identify({
              __typename: 'Conversation',
              id: conversationId,
            }),
            fields: {
              messages(existingMessages = [], { toReference }) {
                const newMessageRef = toReference(
                  data.createConversationMessage,
                )

                if (newMessageRef) {
                  return [...existingMessages, newMessageRef]
                }

                return existingMessages
              },
            },
          })
        }
      },
      onError: () => {
        apolloClient.cache.evict({
          id: apolloClient.cache.identify(optimisticNewMessage),
        })

        onSetMessage(message)
      },
    },
  })
}
