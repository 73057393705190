import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { IconPicker } from 'components/Pickers/IconPicker'
import { Typography } from 'components/Typography'
import { type CreateProductLabelInput } from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { type FC, type ReactElement, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { z } from 'zod'

import {
  PRODUCT_FEED_COLORS,
  PRODUCT_FEED_ICON_NAMES,
} from '../../SegmentationSetsView/consts'
import { type ProductLabel } from '../graphql/fragments'

export type UpdatedProductLabel = CreateProductLabelInput & { id?: string }

const defaultLabel: UpdatedProductLabel = {
  iconColor: PRODUCT_FEED_COLORS[0],
  name: '',
  iconName: PRODUCT_FEED_ICON_NAMES[0],
}

type ProductLabelPickerProps = {
  currentLabel: ProductLabel | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (label: UpdatedProductLabel) => void
  disclosure?: DefaultDisclosure
}

const schema = z.object({
  id: z.string().optional(), // will be empty if we are creating a new label
  name: z
    .string()
    .max(50, 'Name must be 50 characters or less')
    .regex(/^[^,\\'"]*$/, 'Name cannot include commas, quotes, or backslashes'),
  iconColor: z.string(),
  iconName: z.string(),
})

export const ProductLabelPicker: FC<ProductLabelPickerProps> = ({
  currentLabel,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  const methods = useForm<UpdatedProductLabel>({
    defaultValues: currentLabel ?? defaultLabel,
    resolver: zodResolver(schema),
  })

  const {
    control,
    formState: { errors },
    reset,
    watch,
  } = methods

  const onSubmit = (data: UpdatedProductLabel) => {
    onApply(data)
    onClose()
  }

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentLabel) {
          reset(defaultLabel)
        }
      }}
      initialFocusRef={inputRef}
    >
      <Form<UpdatedProductLabel> methods={methods} onSubmit={onSubmit}>
        <ModalHeader>
          {isEditMode ? 'Edit product label' : 'Add product label'}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Typography mb={4}>
            Choose a color and an icon for the product label.
          </Typography>
          <Box mb={6}>
            <Controller
              name="iconColor"
              control={control}
              render={({ field }) => (
                <ColorPicker
                  colors={PRODUCT_FEED_COLORS}
                  selectedColor={field.value}
                  setSelectedColor={field.onChange}
                />
              )}
            />
          </Box>

          <Box mb={8}>
            <Controller
              name="iconName"
              control={control}
              render={({ field }) => (
                <IconPicker
                  iconColor={watch('iconColor')}
                  icons={PRODUCT_FEED_ICON_NAMES}
                  selectedIcon={field.value}
                  setSelectedIcon={field.onChange}
                />
              )}
            />
          </Box>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                ref={inputRef}
                label="Product label name"
                title="Product label name"
                placeholder="E.g High Margin or Low Margin"
                maxLength={50}
                error={errors.name}
              />
            )}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            type="button"
            variant="outline"
            mr={2}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button variant="solid" type="submit" colorScheme="primary">
            {isEditMode ? 'Save' : 'Add product label'}
          </Button>
        </ModalFooter>
      </Form>
    </GenericPickerModal>
  )
}
