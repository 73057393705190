import {
  type DateRange,
  type ISODateRange,
  type TimestampRange,
} from 'constants/types'
import { endOfDay } from 'date-fns'

import { fromZonedTime } from 'date-fns-tz'

// Given a date range in a timezone, maps it to a date range in UTC
export const toUTCDateRange = (
  dateRange: TimestampRange | DateRange,
  timezone: string | undefined,
): DateRange => {
  return dateRange.map((date) =>
    date && timezone ? fromZonedTime(date, timezone) : null,
  ) as DateRange
}

// Given a date range in a timezone, maps it to a date range in UTC and formats it to ISO string
export const toISODateRange = (
  dateRange: DateRange,
  timezone?: string,
): ISODateRange => {
  return toUTCDateRange(dateRange, timezone).map((date) =>
    date && timezone ? date.toISOString() : '',
  ) as ISODateRange
}

export const dateRangeToTimestamp = (dateRange: DateRange): TimestampRange => {
  const [dateFrom, dateTo] = dateRange

  if (!dateFrom) return [null, null]
  if (!dateTo) {
    const endSameDay = endOfDay(dateFrom)

    return [dateFrom.getTime(), endSameDay.getTime()]
  }

  return [dateFrom.getTime(), dateTo.getTime()]
}
