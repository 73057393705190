import { Box } from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { useCopilotIsOpen } from 'features/copilot/state'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { NavIconLink } from '../NavLinks'

export const CopilotButton = () => {
  const flags = useFeatureFlags()
  const [, setIsOpen] = useCopilotIsOpen()

  if (!flags.enableCopilot) return null

  return (
    <Box position="relative">
      <NavIconLink
        iconName="LightbulbIcon"
        onClick={() => setIsOpen(true)}
        tooltip="Dema Copilot"
      />

      <Badge
        size="sm"
        fontSize="xx-small"
        position="absolute"
        top={4}
        right={'100%'}
        transform="translateX(10px)"
        textAlign="right"
      >
        New Copilot report
      </Badge>
    </Box>
  )
}
