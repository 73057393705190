import { DISPLAY_FORMAT, type DisplayFormat } from 'constants/displayFormats'
import { DiffStatus, STATUS_COLORS } from 'constants/statusColors'
import { type IconName } from 'components/Icon/Icon'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { type ColorType } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'
import { calcPercentageDiff } from './calcPercentageDiff'

const getAbbreviatedFormat = (format: MetricFormat): MetricFormat => {
  switch (format) {
    case METRIC_FORMAT.INTEGER:
    case METRIC_FORMAT.CURRENCY:
      return METRIC_FORMAT.ABBREVIATED_INT
    case METRIC_FORMAT.DECIMAL:
      return METRIC_FORMAT.ABBREVIATED_DECIMAL
    default:
      return format
  }
}

type NeutralColor = 'orange.400' | 'grey.800' | 'grey.600'

type GetColorVariablesProps = {
  includePrefix?: boolean
  isPositiveDiff: boolean
  isNegativeDiff: boolean
  isOppositeDiffColor: boolean
  neutralColor: NeutralColor
}

const getColorVariables = ({
  isPositiveDiff,
  isNegativeDiff,
  isOppositeDiffColor,
  neutralColor,
}: GetColorVariablesProps) => {
  const isGreenCondition =
    (isPositiveDiff && !isOppositeDiffColor) ||
    (isNegativeDiff && isOppositeDiffColor)

  const isRedCondition =
    (isNegativeDiff && !isOppositeDiffColor) ||
    (isPositiveDiff && isOppositeDiffColor)

  const color: ColorType = isGreenCondition
    ? STATUS_COLORS[DiffStatus.INCREASE].color
    : isRedCondition
      ? STATUS_COLORS[DiffStatus.DECREASE].color
      : neutralColor

  const iconName: IconName = isPositiveDiff
    ? 'ArrowUpIcon'
    : isNegativeDiff
      ? 'ArrowDownIcon'
      : 'ArrowRightIcon'

  const status: DiffStatus = isGreenCondition
    ? DiffStatus.INCREASE
    : isRedCondition
      ? DiffStatus.DECREASE
      : DiffStatus.NEUTRAL

  return { color, iconName, status }
}

export type calcDifferenceProps = {
  value: number
  compareValue: number | undefined
  format: MetricFormat
  currency?: string
  isOppositeDiffColor?: boolean
  includePrefix?: boolean
  percentFormat?: MetricFormat
  neutralColor?: NeutralColor
  displayFormat: DisplayFormat
  isAbbreviated?: boolean
}

export const calcDifference = ({
  value,
  compareValue,
  format,
  currency,
  isOppositeDiffColor = false,
  includePrefix,
  neutralColor = 'orange.400',
  displayFormat,
  isAbbreviated,
}: calcDifferenceProps) => {
  const hasCompareValue = !!compareValue && isFinite(compareValue)

  const percentageDifference = hasCompareValue
    ? calcPercentageDiff(value, compareValue) / 100
    : undefined

  const valueDifference = value - (compareValue ?? 0)

  const difference =
    displayFormat === DISPLAY_FORMAT.PERCENTAGE_DIFF
      ? percentageDifference
      : valueDifference

  const isPositiveDiff = (difference ?? 0) > 0
  const { color, iconName, status } = getColorVariables({
    isPositiveDiff,
    isNegativeDiff: (difference ?? 0) < 0,
    isOppositeDiffColor,
    neutralColor,
  })

  // negative values will already get the '-' sign
  const prefix = includePrefix && isPositiveDiff ? '+' : ''

  const percentageFormat = format.includes(METRIC_FORMAT.PERCENT)
    ? format
    : METRIC_FORMAT.PERCENT

  const formattedPercentageDifference = percentageDifference
    ? formatMetric(percentageFormat, percentageDifference)
    : undefined

  const metricFormat = isAbbreviated ? getAbbreviatedFormat(format) : format

  const formattedValue = formatMetric(metricFormat, value, currency)

  const formattedValueDifference = formatMetric(
    metricFormat,
    valueDifference,
    currency,
  )

  const diffBasedOnDisplayFormat =
    displayFormat === DISPLAY_FORMAT.PERCENTAGE_DIFF
      ? formattedPercentageDifference
      : formattedValueDifference

  const formattedDifference = diffBasedOnDisplayFormat
    ? prefix + diffBasedOnDisplayFormat
    : undefined

  return {
    hasCompareValue,
    difference,
    formattedDifference,
    formattedValue,
    prefix,
    color,
    iconName,
    status,
  }
}
