import { Table, Tbody, Skeleton, Flex } from '@chakra-ui/react'
import { useOrganization } from '@clerk/clerk-react'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { useTeamById } from 'graphql/teams/useTeamById'
import { useParams } from 'react-router'
import { NotFound } from 'shared/NotFound'
import { MembersTable } from '../../OrganisationViews/OrganisationMembersView/MembersTable'

export const TeamMembersView = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { team, query } = useTeamById(teamId ?? '')
  const { memberships } = useOrganization({
    memberships: { pageSize: 500 },
  })

  if (query.loading || memberships?.isLoading) {
    return (
      <TitleLayoutView
        title="Members"
        description="Manage team members and their access"
      >
        <Skeleton w={24} h={4} mb={4} />
        <Table>
          <Tbody>
            <SkeletonTableRows columns={5} rows={5} />
          </Tbody>
        </Table>
      </TitleLayoutView>
    )
  }

  if (query.error || memberships?.isError || !team) {
    return (
      <Flex alignItems="center" w="full">
        <NotFound />
      </Flex>
    )
  }

  const members = memberships?.data?.filter(
    (member) =>
      member.publicUserData.userId &&
      team?.members?.find(
        (teamMember) => teamMember.externalId === member.publicUserData.userId,
      ),
  )

  const notMembers =
    memberships?.data?.filter(
      (member) =>
        member.publicUserData.userId &&
        !team?.members?.find(
          (teamMember) =>
            teamMember.externalId === member.publicUserData.userId,
        ),
    ) ?? []

  return (
    <TitleLayoutView
      title="Members"
      description="Manage team members and their access"
    >
      <MembersTable
        members={members}
        notMembers={notMembers}
        memberships={memberships}
        team={team}
      />
    </TitleLayoutView>
  )
}
