import { Button } from 'components/buttons'
import { compareColor } from 'utils/chart/constants'

interface Props {
  type: 'main' | 'compare'
  text: string | undefined
  isActive: boolean
  onClick?: () => void
}

export const DateSelectorButton = ({
  type,
  text,
  isActive,
  onClick,
}: Props) => {
  return (
    <Button
      isSelected={isActive}
      onClick={onClick}
      variant="ghost"
      colorScheme={type === compareColor.id ? 'grey' : 'primary'}
      size="sm"
      px={2} // special case for these buttons
    >
      {text || 'Add date'}
    </Button>
  )
}
