import {
  type SystemStyleInterpolation,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react'
import { colorTheme } from '../theme/colors'

// define the base component styles
const baseStyle: SystemStyleInterpolation = {
  bg: 'grey.800',
  '--popper-arrow-bg': colorTheme.grey[800], // change the color of the arrow
  borderRadius: '4px',
  fontWeight: 'normal', // change the font weight
  color: 'white',
  px: 3,
}

const variants = {
  normal: defineStyle((props) => {
    switch (props.orientation) {
      case 'horizontal':
        return {
          lineHeight: 4,
          fontSize: '13px',
        }
      case 'vertical':
      default:
        return {}
    }
  }),
}

// define custom sizes
const sizes = {
  sm: defineStyle({
    py: '0.5',
  }),
  md: defineStyle({
    py: '2',
  }),
}

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle, sizes, variants })
