import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMemo } from 'react'
import { TAXONOMY_TYPE } from '../shared/consts'
import { ManualInputTable } from '../shared/ManualInput/ManualInputTable'
import { LogisticsColumns } from './LogisticsColumns'

const types = [
  TAXONOMY_TYPE.LOGISTIC_TRANSACTION,
  TAXONOMY_TYPE.LOGISTIC_SHIPPING,
  TAXONOMY_TYPE.LOGISTIC_PICK_AND_PACK,
  TAXONOMY_TYPE.LOGISTIC_TOLL,
]

export const FulfillmentCostsView = () => {
  const flags = useFeatureFlags()
  const filteredColumns = useMemo(() => {
    return flags.allowSettingsWrite
      ? LogisticsColumns
      : LogisticsColumns.filter((column) => column.id !== 'actions')
  }, [flags.allowSettingsWrite])

  return (
    <ManualInputTable
      columns={filteredColumns}
      title="Fulfilment costs"
      description="Input your fulfilment costs on different levels."
      types={types}
    />
  )
}
