import { type FC } from 'react'
import { colorTheme } from 'ui/theme/colors'

interface TooltipMetricRowProps {
  metricName: string
  iconColor?: string
  value: string
  valueColor?: string
  diffValue?: string
  diffColor?: string
  hideTooltipColorIcon?: boolean
}

export const TooltipMetricRow: FC<TooltipMetricRowProps> = ({
  metricName,
  iconColor,
  value,
  valueColor,
  diffValue,
  diffColor,
  hideTooltipColorIcon,
}) => {
  return (
    <div
      style={{
        fontSize: '12px',
        lineHeight: '16px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {hideTooltipColorIcon || !iconColor ? null : (
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '9999px',
            marginRight: 4,
            background: iconColor,
          }}
        ></div>
      )}

      <span
        style={{
          color: colorTheme.grey[900],
          fontWeight: 400,
          marginRight: 4,
        }}
      >
        {`${metricName}:`}
      </span>
      <span
        style={{
          color: valueColor ?? colorTheme.grey[900],
          fontWeight: 600,
          marginRight: 8,
        }}
      >
        {value}
      </span>
      {diffValue ? (
        <span
          style={{
            color: diffColor,
            fontWeight: 600,
          }}
        >
          {diffValue}
        </span>
      ) : null}
    </div>
  )
}
