import { Flex, Skeleton } from '@chakra-ui/react'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { ToggleWithLabels } from 'components/ToggleWithLabels/ToggleWithLabels'
import { BaseWidget } from 'features/optimizations/components/BaseWidget'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import { useState } from 'react'
import {
  type HistoricalHistoryReport,
  type HistoricalHistoryContribution,
} from '../../hooks/useHistoricalHistoryAnalysis'
import { ChannelContribution } from './ChannelContribution/ChannelContribution'
import { MissedPotential } from './MissedPotential/MissedPotential'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

interface ProfitAnalysisGraphProps {
  contributions: HistoricalHistoryContribution[] | undefined
  reports: HistoricalHistoryReport[] | undefined
  isLoading: boolean
  hasError: boolean
}

enum VIEW_IDS {
  MISSED_POTENTIAL = 'missed-potential',
  CHANNEL_CONTRIBUTION = 'channel-contribution',
}

export const ProfitAnalysisGraph: React.FC<ProfitAnalysisGraphProps> = ({
  contributions,
  reports,
  isLoading,
  hasError,
}) => {
  const [view, setView] = useState<VIEW_IDS>(VIEW_IDS.MISSED_POTENTIAL)

  return (
    <BaseWidget
      title="Profit analysis"
      rightElement={
        <ToggleWithLabels
          setSelected={setView}
          selected={view}
          items={[
            { id: VIEW_IDS.MISSED_POTENTIAL, label: 'Missed potential' },
            {
              id: VIEW_IDS.CHANNEL_CONTRIBUTION,
              label: 'Channel contribution',
            },
          ]}
        />
      }
    >
      <Flex flex={1} minH="392px" overflowX="hidden" w="full" gap={12}>
        {isLoading ? (
          <>
            <Skeleton w="full" />
          </>
        ) : hasError || !contributions || !reports ? (
          <WidgetErrorState />
        ) : view === VIEW_IDS.MISSED_POTENTIAL ? (
          <MissedPotential contributions={contributions} reports={reports} />
        ) : (
          <ChannelContribution contributions={contributions} />
        )}
      </Flex>
    </BaseWidget>
  )
}
