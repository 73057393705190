import { Box, FormLabel, FormControl, useToast, Fade } from '@chakra-ui/react'
import type { OrganizationResource } from '@clerk/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'

import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const MAX_LENGTH = 50

const orgNameSchema = z.object({
  orgName: z.string().trim().min(2).max(MAX_LENGTH),
})

interface ChangeNameProps {
  organization: OrganizationResource
}

export const ChangeName: React.FC<ChangeNameProps> = ({ organization }) => {
  const toast = useToast()
  const [trackEvent] = useTrackEvent()

  const methods = useForm({
    resolver: zodResolver(orgNameSchema),
    defaultValues: {
      orgName: organization.name,
    },
  })
  const {
    formState: { isValid, isSubmitting, isDirty },
    register,
    reset,
  } = methods

  const changeName = async (values: z.infer<typeof orgNameSchema>) => {
    try {
      trackEvent({
        eventName: 'Organisation Name Changed',
        eventProperties: {
          newName: values.orgName,
          previousName: organization.name,
        },
      })

      await organization.update({
        name: values.orgName,
      })

      reset({ orgName: values.orgName }) // needed to reset the state and hide the button
      toast({
        status: 'success',
        description: 'Organisation name changed',
        position: 'bottom',
      })
    } catch (error) {
      toast({
        status: 'error',
        description: 'Could not change name, please try again',
        position: 'bottom',
      })
    }
  }

  return (
    <Box>
      <Form methods={methods} onSubmit={changeName}>
        <FormControl>
          <FormLabel
            htmlFor="orgName"
            fontSize="md"
            lineHeight={6}
            mb={2}
            color="gray.900"
          >
            Organisation name
          </FormLabel>
          <Input
            id="orgName"
            type="string"
            maxLength={MAX_LENGTH}
            maxW="240px"
            {...register('orgName')}
          />
        </FormControl>

        <Fade in={isDirty}>
          <Button
            mt={4}
            size="sm"
            variant="solid"
            type="submit"
            colorScheme="primary"
            isLoading={isSubmitting}
            isDisabled={!isValid}
          >
            Update
          </Button>
        </Fade>
      </Form>
    </Box>
  )
}
