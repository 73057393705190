import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { type SetStateAction, atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import {
  getActualDimensions,
  getAnalyticsConfigFromStore,
} from '../utils/utils'
import { useIsNewReportFlow } from './useIsNewReportFlow'

const focusDimensionsStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('dimensions'),
)

export const dimensionsStateAtom = atom(
  (get) => {
    const dimensions = get(focusDimensionsStateAtom)

    const normalizedDimensions = get(normalizedDimensionsAtom)

    const actualDimensions = getActualDimensions(
      dimensions,
      normalizedDimensions,
    )

    return {
      dimensionKeys: actualDimensions.map((d) => d.id),
      dimensions: actualDimensions,
      unsafe_allDimensionKeys: dimensions, // could involve invalid dimensions
    }
  },
  (_, set, dimensionsState: SetStateAction<string[]>) => {
    set(focusDimensionsStateAtom, dimensionsState)
  },
)

export const useDimensionsState = () => useAtomValue(dimensionsStateAtom)

export const useSetDimensionsState = () => {
  const setDimensionsStateAtom = useSetAtom(dimensionsStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setDimensionsState = useCallback(
    (payload: SetStateAction<string[]>) => {
      setDimensionsStateAtom(payload)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.dimensions = payload(newAnalyticsConfig.dimensions)
        } else {
          newAnalyticsConfig.dimensions = payload
        }
        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setDimensionsStateAtom],
  )

  return setDimensionsState
}
