import { type As, MenuButton } from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { Icon } from 'components/Icon/Icon'
import { forwardRef } from 'react'

// eslint-disable-next-line no-empty-pattern
export const FilterMenuButton = forwardRef<As>(({}, ref) => (
  <MenuButton
    ref={ref}
    as={Button}
    size="sm"
    variant="outline"
    colorScheme="grey"
    leftIcon={<Icon name="FilterIcon" size="small" />}
  >
    Filter
  </MenuButton>
))

FilterMenuButton.displayName = 'FilterMenuButton'
