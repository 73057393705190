import { Box, List, PopoverTrigger } from '@chakra-ui/react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useCallback, useRef } from 'react'
import { menuSizes } from 'ui/components/menu'
import { type DropdownOption } from '../../types'
import { ComboBoxItem } from './ComboBoxItem'
import { type ComboBoxItemsProps } from './ComboBoxItems'

export const SimpleListItems = <TItem extends DropdownOption>({
  size,
  items,
  selected,
  isOption,
  highlightedIndex,
  getItemProps,
  itemWrapper,
}: Omit<ComboBoxItemsProps<TItem>, 'filteredOptions'> & {
  items: TItem[]
}) => {
  const listRef = useRef<HTMLUListElement | null>(null)
  const estimateSize = useCallback(
    () => (menuSizes[size]?.item.h || 0) * 4,
    [size],
  )
  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => listRef.current,
    estimateSize,
    overscan: 3,
  })

  return (
    <List variant="menu" ref={listRef} width="full" overflowY="auto">
      <Box
        style={{
          position: 'relative',
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer
          .getVirtualItems()
          .map(({ index, start, size: virtualRowSize }) => {
            const option = items[index]
            const optionId = option.id.toString()
            const isSelected = Array.isArray(selected)
              ? selected.includes(optionId)
              : selected === optionId
            const isHighlighted = highlightedIndex === index
            const itemComponent = (
              <ComboBoxItem
                isHighlighted={isHighlighted}
                isOption={isOption}
                isSelected={isSelected}
                itemProps={getItemProps({
                  index,
                  item: option,
                  'aria-selected': isSelected,
                })}
                itemStyles={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  h: `${virtualRowSize}px`,
                  maxH: `${virtualRowSize}px`,
                  transform: `translateY(${start}px)`,
                }}
                key={option.id}
                leftItem={option.leftItem}
                rightItem={option.rightItem}
                option={option}
              />
            )

            // This is only to be able to use JSX syntax
            const ItemWrapper = itemWrapper

            return ItemWrapper ? (
              <ItemWrapper key={option.id} index={index} item={option}>
                {itemComponent}
              </ItemWrapper>
            ) : (
              <PopoverTrigger key={option.id}>{itemComponent}</PopoverTrigger>
            )
          })}
      </Box>
    </List>
  )
}
