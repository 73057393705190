import { MenuButton, MenuItem, Skeleton } from '@chakra-ui/react'
import { useOrganizationList, useOrganization } from '@clerk/clerk-react'
import { Dropdown } from 'components/Dropdown'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import { AccountMenuItem } from './AccountMenuItem'

export const SwitchOrganisationMenuItem = () => {
  const {
    isLoaded: isOrgListLoaded,
    userMemberships,
    setActive: setActiveOrganization,
  } = useOrganizationList({
    userMemberships: true,
  })

  const { organization: activeOrganization } = useOrganization()

  const memberships = userMemberships.data ?? []

  if (memberships.length <= 1) return null

  return (
    <Dropdown
      isLoading={!isOrgListLoaded && memberships.length === 0}
      options={memberships.map(({ organization }) => ({
        ...organization,
        leftItem: (
          <ClerkImage imageUrl={organization?.imageUrl} type="organisation" />
        ),
      }))}
      callback={async ({ id }) => {
        await setActiveOrganization?.({ organization: id })
        window.location.reload()
      }}
      placement="left-start"
      showOnHover
      size="xs"
      value={activeOrganization?.id}
      customMenuButton={
        <AccountMenuItem
          as={MenuButton}
          name="Switch organisation"
          iconName="ChevronLeftIcon"
          px={3}
          border="none"
          sx={{ span: { verticalAlign: 'inherit', display: 'inherit' } }}
        />
      }
      customEmptyState={
        <MenuItem
          isDisabled
          _disabled={{ bg: 'inherit', cursor: 'default' }}
          gap={2}
        >
          <Skeleton w={4} h={4} />
          <Skeleton w={32} h={4} />
        </MenuItem>
      }
    />
  )
}
