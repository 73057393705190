import { type useCreateConversation } from '../graphql/useCreateConversation'
import { type useCreateConversationMessage } from '../graphql/useCreateConversationMessage'
import { addOptimisticConversationToCache } from './addOptimisticConversationToCache'
import { addOptimisticMessageToCache } from './addOptimisticMessageToCache'
import { getOptimisticMessage } from './getOptimisticMessage'

export const sendOptimisticMessage = async ({
  conversationId,
  message,
  onSetMessage,
  onSetConversationId,
  createConversation,
  createConversationMessage,
  viewerId,
}: {
  conversationId: string | null
  message: string
  onSetMessage: (id: string) => void
  onSetConversationId: (id: string | null) => void
  createConversation: ReturnType<typeof useCreateConversation>[0]
  createConversationMessage: ReturnType<typeof useCreateConversationMessage>[0]
  viewerId: string
}) => {
  onSetMessage('')

  const optimisticNewMessage = getOptimisticMessage({ message })

  if (conversationId) {
    addOptimisticMessageToCache({
      conversationId,
      message,
      onSetMessage,
      optimisticNewMessage,
      createConversationMessage,
    })
  } else {
    addOptimisticConversationToCache({
      conversationId,
      message,
      optimisticNewMessage,
      createConversation,
      createConversationMessage,
      onSetMessage,
      onSetConversationId,
      viewerId,
    })
  }
}
