import { Image, MenuButton } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Button } from 'components/buttons'
import { ComboBox } from 'components/Dropdown/ComboBox/ComboBox'
import { useAddTeamMember } from 'graphql/teams/useAddTeamMember'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'

interface AddMemberToTeamProps {
  team: MerchantTeam
  notMembers: OrganizationMembershipResource[] | undefined
}

export const AddMemberToTeam: React.FC<AddMemberToTeamProps> = ({
  team,
  notMembers = [],
}) => {
  const [addMemberToTeam] = useAddTeamMember()
  const { user } = useUser()

  const addMemberHandler = (clerkUserId: string) => {
    addMemberToTeam({
      teamId: team.id,
      clerkUserId,
    })
  }

  return (
    <ComboBox
      setSelected={addMemberHandler}
      size="sm"
      selectionMode="single"
      menuProps={{
        placement: 'bottom-end',
      }}
      customMenuButton={
        <MenuButton as={Button} size="sm" variant="solid">
          Add member
        </MenuButton>
      }
      options={notMembers.map((member) => ({
        id: String(member.publicUserData.userId),
        name: `${member.publicUserData.firstName} ${member.publicUserData.lastName}`,
        subLabel:
          user?.id === member.publicUserData.userId ? '(You)' : undefined,
        leftItem: (
          <Image
            src={member.publicUserData.imageUrl}
            boxSize={4}
            borderRadius="full"
          />
        ),
      }))}
      matchWidth={false}
    />
  )
}
