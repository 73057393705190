import { Flex, Box } from '@chakra-ui/react'
import type React from 'react'

interface ProductFeedHeaderWrapperProps {
  children: React.ReactNode
}

interface ProductFeedHeaderWrapperTitleProps {
  children: React.ReactNode
}

const Title: React.FC<ProductFeedHeaderWrapperTitleProps> = ({ children }) => {
  return (
    <Box color="gray.900" fontSize="medium" fontWeight={500} lineHeight={6}>
      {children}
    </Box>
  )
}

export const ProductFeedHeaderWrapper: React.FC<ProductFeedHeaderWrapperProps> & {
  Title: React.FC<ProductFeedHeaderWrapperTitleProps>
} = ({ children }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={4}
      borderBottom="1px solid"
      borderColor="gray.200"
    >
      {children}
    </Flex>
  )
}

ProductFeedHeaderWrapper.Title = Title
