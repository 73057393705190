import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const EDIT_PRODUCT_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation EditProductLabel($id: ID!, $params: EditProductLabelInput!) {
    editProductLabel(id: $id, params: $params) {
      ...ProductLabelFields
    }
  }
`)

export const useEditProductLabel = () => {
  const [editProductLabel] = useMutation(EDIT_PRODUCT_LABEL_MUTATION)

  return editProductLabel
}
