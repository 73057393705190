import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { useMemo } from 'react'
import { transformReports } from './utils'

export const FAVORITE_REPORTS_QUERY = graphql(/* GraphQL */ `
  query FavoriteReportsQuery {
    viewer {
      id
      favoriteReports {
        ...ReportWithOwnerFields
      }
    }
  }
`)

export const useFavoriteReports = () => {
  const { data, ...rest } = useQuery(FAVORITE_REPORTS_QUERY)

  const favoriteReports = useMemo(
    () => transformReports(data?.viewer?.favoriteReports ?? []),
    [data?.viewer?.favoriteReports],
  )

  return { favoriteReports, ...rest }
}
