import { Input } from 'components/Input'

interface RuleDescriptionProps {
  description: string | undefined | null
  onChange: (value: string) => void
}

export const RuleDescription: React.FC<RuleDescriptionProps> = ({
  description,
  onChange,
}) => {
  return (
    <Input
      size="xs"
      placeholder="Edit description"
      fontSize="sm"
      variant="unstyled"
      lineHeight={5}
      color="gray.800"
      value={description ?? ''}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}
