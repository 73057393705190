import { type DateRange } from 'constants/types'
import { type DropdownOption } from 'components/Dropdown/types'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useDimensionValues } from 'graphql/statistics/useDimensionValues'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import {
  type StaticDimensionId,
  useStaticDimensionValues,
} from 'graphql/useStaticDimensionValues'
import { useMemo } from 'react'
import { toISODateRange } from '../utils/dateRange'

type BaseProps = {
  skipStaticValues?: boolean
}

type StaticDimensionProps = {
  dimensionId: StaticDimensionId
}

type DynamicDimensionProps = {
  dimensionId: string
  dateRange: DateRange
  skipDynamicValues?: boolean
}

type Props = BaseProps & (StaticDimensionProps | DynamicDimensionProps)

const useDynamicDimensionValues = ({
  dimensionId,
  dateRange,
  skipDynamicValues,
}: DynamicDimensionProps): {
  values: DropdownOption[]
  query: typeof query
} => {
  const { timezone, frontendIds = [] } = useMerchantInfo()
  const [startDate, endDate] = toISODateRange(dateRange, timezone)
  const normalizedDimensions = useNormalizedDimensions()

  const shouldFetch = useMemo(
    () =>
      Boolean(normalizedDimensions[dimensionId]) &&
      Boolean(timezone) &&
      Boolean(startDate) &&
      Boolean(endDate) &&
      frontendIds.length > 0,
    [
      normalizedDimensions,
      dimensionId,
      timezone,
      startDate,
      endDate,
      frontendIds,
    ],
  )

  const { query, dimensionValues } = useDimensionValues({
    skip: skipDynamicValues || !shouldFetch,
    variables: {
      dimensionValuesParams: {
        frontendIds,
        startDate: startDate as string,
        endDate: endDate as string,
        dimension: dimensionId,
      },
    },
  })

  const values = dimensionValues.map<DropdownOption>(
    ({ value, formattedValue }) => ({
      id: String(value),
      name: formattedValue,
    }),
  )

  return { values, query }
}

const staticDimensionIds: StaticDimensionId[] = [
  'country',
  'channel',
  'channelGroup',
  'market',
]

const isStaticDimensionProps = (
  props: Props,
): props is StaticDimensionProps => {
  return (
    !props.skipStaticValues &&
    staticDimensionIds.includes(props.dimensionId as StaticDimensionId)
  )
}

export const useUniqueDimensionValues = (props: Props) => {
  const { dimensionId, skipStaticValues } = props

  const staticResult = useStaticDimensionValues(skipStaticValues)

  const dynamicResult = useDynamicDimensionValues(
    isStaticDimensionProps(props)
      ? {
          dimensionId,
          dateRange: [null, null],
          skipDynamicValues: true,
        }
      : props,
  )

  const values = isStaticDimensionProps(props)
    ? staticResult.data[props.dimensionId]
    : dynamicResult.values

  const query = isStaticDimensionProps(props)
    ? staticResult.query
    : dynamicResult.query

  const sortedUniqueDimensionValues = useMemo(
    () => [...(values ?? [])].sort((a, b) => a.name.localeCompare(b.name)),
    [values],
  )

  return {
    uniqueDimensionValues: sortedUniqueDimensionValues,
    query,
  }
}
