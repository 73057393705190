import { useEffect, useState } from 'react'
import { type BaseFilterProps, type FilterOperator } from './types'

type UseSelectedFilterGroupArgs = Pick<
  BaseFilterProps,
  'defaultSelectedFilter' | 'filterId' | 'filters'
> & { fallback: FilterOperator }

export function useSelectedFilterGroup({
  defaultSelectedFilter,
  filterId,
  fallback,
  filters,
}: UseSelectedFilterGroupArgs) {
  const selectedFilter = filters[filterId]?.[0]
  const [selectedFilterGroup, setSelectedFilterGroup] = useState(
    (defaultSelectedFilter || selectedFilter?.comparisonId) ?? fallback,
  )

  useEffect(() => {
    setSelectedFilterGroup(selectedFilter?.comparisonId ?? fallback)
  }, [selectedFilter, fallback])

  return { selectedFilterGroup, setSelectedFilterGroup }
}
