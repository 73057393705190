import { captureException } from '@sentry/react'
import { type ColumnDef } from '@tanstack/react-table'
import { MANUAL_INPUT_COLUMN } from '../../consts'
import {
  type ManualInputColumns,
  type ColumnId,
  type ColumnValue,
} from '../../types'
import { getEmptyDataRow } from './common'

const getRowIndexes = (rowId: string) => {
  const indexes = rowId.split('.').map((i) => Number(i))

  if (indexes.some((index) => isNaN(index))) {
    captureException(`Manual input: Invalid rowId: ${rowId}`)

    return []
  }

  return indexes
}

type UpdateDataAtIndexBaseArgs = {
  rows: ManualInputColumns[]
  handleIndexUpdate: (
    rows: ManualInputColumns[],
    index: number,
  ) => ManualInputColumns[]
}

type UpdateDataAtIndexArgsTraverse = UpdateDataAtIndexBaseArgs & {
  indexes: number[]
  currentIndex: number
}

// Traverses through data to correct index where data should be updated
// handleIndexUpdate occurs in function that made the call
const updateAtIndexTraverse = ({
  rows,
  indexes,
  currentIndex,
  handleIndexUpdate,
}: UpdateDataAtIndexArgsTraverse) => {
  if (indexes.length === 0) {
    return handleIndexUpdate(rows, currentIndex)
  }

  const [nextIndex, ...remainingIndexes] = indexes

  if (rows[currentIndex]?.subRows) {
    const updatedRow: ManualInputColumns = {
      ...rows[currentIndex],
      subRows: updateAtIndexTraverse({
        rows: rows[currentIndex].subRows ?? [],
        indexes: remainingIndexes,
        currentIndex: nextIndex,
        handleIndexUpdate,
      }),
    }

    return [
      ...rows.slice(0, currentIndex),
      updatedRow,
      ...rows.slice(currentIndex + 1),
    ]
  }

  return rows
}

type UpdateDataAtIndexArgs = UpdateDataAtIndexBaseArgs & {
  rowId: string
  setData: (value: ManualInputColumns[]) => void
}

const updateAtIndex = ({
  rowId,
  rows,
  handleIndexUpdate,
  setData,
}: UpdateDataAtIndexArgs) => {
  const [firstIndex, ...remainingIndexes] = getRowIndexes(rowId)

  if (firstIndex !== undefined) {
    const updatedData = updateAtIndexTraverse({
      rows,
      indexes: remainingIndexes,
      currentIndex: firstIndex,
      handleIndexUpdate,
    })

    setData(updatedData)
  }
}

const isObject = (value: unknown): value is object => typeof value === 'object'

type UpdateData = {
  rowId: string
  values: Partial<Record<ColumnId, ColumnValue>>
  data: ManualInputColumns[]
  setData: (value: ManualInputColumns[]) => void
}

export const updateData = ({ rowId, values, data, setData }: UpdateData) => {
  const handleIndexUpdate = (rows: ManualInputColumns[], index: number) => {
    let updatedData: ManualInputColumns[] = [...rows]

    Object.entries(values).map(([columnId, value]) => {
      if (Object.values(MANUAL_INPUT_COLUMN).includes(columnId as ColumnId)) {
        const updatedValue = isObject(value)
          ? { ...updatedData[index].error, ...value }
          : value
        const updatedItem = { ...updatedData[index], [columnId]: updatedValue }

        if (
          updatedItem.error &&
          Object.values(updatedItem.error).every((val) => val === '')
        ) {
          delete updatedItem.error
        }
        updatedData = [
          ...updatedData.slice(0, index),
          updatedItem,
          ...updatedData.slice(index + 1),
        ]
      }
    })

    return updatedData
  }

  updateAtIndex({
    rowId,
    rows: data,
    handleIndexUpdate,
    setData,
  })
}

export const addRow = (
  rowId: string,
  data: ManualInputColumns[],
  setData: (value: ManualInputColumns[]) => void,
  columns: ColumnDef<ManualInputColumns, unknown>[],
  currency?: string,
) => {
  const emptyDataRow = getEmptyDataRow(columns, currency)

  const handleIndexUpdate = (rows: ManualInputColumns[]) => {
    return [...rows, emptyDataRow]
  }

  updateAtIndex({
    rowId,
    rows: data,
    handleIndexUpdate,
    setData,
  })
}

export const addSubRow = (
  rowId: string,
  data: ManualInputColumns[],
  setData: (value: ManualInputColumns[]) => void,
  columns: ColumnDef<ManualInputColumns, unknown>[],
  currency?: string,
) => {
  const emptyDataRow = getEmptyDataRow(columns, currency)

  const handleIndexUpdate = (rows: ManualInputColumns[], index: number) => {
    return rows.map((row, idx) =>
      idx == index
        ? { ...row, subRows: [...(row.subRows ?? []), emptyDataRow] }
        : row,
    )
  }

  updateAtIndex({
    rowId,
    rows: data,
    handleIndexUpdate,
    setData,
  })
}

type DeleteRow = {
  rowId: string
  data: ManualInputColumns[]
  setData: (value: ManualInputColumns[]) => void
}

export const deleteRow = ({ rowId, data, setData }: DeleteRow) => {
  const handleIndexUpdate = (rows: ManualInputColumns[], index: number) => {
    return rows.filter((_, idx) => idx !== index)
  }

  updateAtIndex({
    rowId,
    rows: data,
    handleIndexUpdate,
    setData,
  })
}
