import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useDeleteOrganizationMember } from 'graphql/settings/useDeleteOrganizationMember'
import { useRemoveTeamMember } from 'graphql/settings/useRemoveTeamMember'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { useRef } from 'react'
import { type Member, type Memberships } from './types'

interface RemoveMemberButtonProps {
  memberships: Memberships | null
  member: Member
  team: MerchantTeam | null | undefined
}

export const RemoveMemberButton: React.FC<RemoveMemberButtonProps> = ({
  member,
  memberships,
  team,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const [deleteOrganizationMemberMutation, deleteOrganizationMemberState] =
    useDeleteOrganizationMember()
  const [removeTeamMemberMutation, removeTeamMemberState] =
    useRemoveTeamMember()

  const { identifier, firstName, lastName } = member.publicUserData
  const fullName = `${firstName} ${lastName}`

  const clerkUserId = member.publicUserData.userId
  const removeMemberText = team ? 'Remove team member' : 'Delete member'

  const deleteOrganizationMember = async () => {
    await deleteOrganizationMemberMutation({
      clerkUserId,
      onCompleted: async () => {
        await memberships?.revalidate?.()
        toast({
          status: 'success',
          description: 'Successfully deleted member',
          position: 'bottom',
          isClosable: true,
        })
        trackEvent({
          eventName: 'Organisation Member Deleted',
          eventProperties: {
            deletedMemberEmail: identifier,
            deletedMemberName: fullName,
            deletedMemberRole: member.role,
          },
        })
        onClose()
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Could not delete member',
          position: 'bottom',
          isClosable: true,
        })
      },
    })
  }

  const removeTeamMember = async () => {
    if (!team || !clerkUserId) return

    await removeTeamMemberMutation({
      teamId: team.id,
      clerkUserId,
      onCompleted: async () => {
        trackEvent({
          eventName: 'Team Member Removed',
          eventProperties: {
            removedMemberName: fullName,
            removedMemberEmail: identifier,
            removedMemberRole: member.role,
          },
        })
        onClose()
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Could not remove member.',
          position: 'bottom',
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <ButtonIcon
        name="TrashIcon"
        size="sm"
        title={removeMemberText}
        onClick={onOpen}
        variant="ghost"
        color="gray.800"
        colorScheme="gray"
      />

      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight={500}>
              {removeMemberText}
            </AlertDialogHeader>

            <AlertDialogBody>
              {team
                ? 'The team member will be removed from the team. They will still have access to the organization.'
                : 'The team members account will be deleted permanently'}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                isLoading={
                  deleteOrganizationMemberState.loading ||
                  removeTeamMemberState.loading
                }
                onClick={team ? removeTeamMember : deleteOrganizationMember}
              >
                {team ? 'Remove' : 'Delete'}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
