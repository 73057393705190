import { type TableCellProps } from '@chakra-ui/react'

const PARAMETER_TYPE = {
  UTM_SOURCE: 'utmSource',
  UTM_MEDIUM: 'utmMedium',
  UTM_CAMPAIGN: 'utmCampaign',
} as const

export type ParameterTypeId =
  (typeof PARAMETER_TYPE)[keyof typeof PARAMETER_TYPE]

interface ParameterType {
  id: ParameterTypeId
  label: string
}

export const parameterTypes: ParameterType[] = [
  {
    id: PARAMETER_TYPE.UTM_SOURCE,
    label: 'UTM source',
  },
  {
    id: PARAMETER_TYPE.UTM_MEDIUM,
    label: 'UTM medium',
  },
  {
    id: PARAMETER_TYPE.UTM_CAMPAIGN,
    label: 'UTM campaign',
  },
]

export const sharedCellStyles: TableCellProps = {
  px: 3,
  _first: { pl: 4 },
  _last: { pr: 4 },
}
