import { graphql } from 'generated/graphql'

export const Teams_TeamFieldsFragment = graphql(/* GraphQL */ `
  fragment Teams_TeamFields on Team {
    id
    name
    iconName
    iconColor
    members {
      ...Teams_TeamMemberFields
    }
    preferredDimensions
    preferredMetrics
    __typename
  }
`)

export const Teams_TeamMemberFieldsFragment = graphql(/* GraphQL */ `
  fragment Teams_TeamMemberFields on User {
    id
    externalId
    __typename
  }
`)
