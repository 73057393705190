import { Flex } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import type { OrganizationMembershipResource } from '@clerk/types'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { ClerkImage } from 'features/reports/components/ClerkImage'
import { getIsTemplateReport, getReportOwnerName } from 'graphql/reports/utils'
interface Props {
  owner: { id: string; externalId: string } | undefined
  membershipsData: OrganizationMembershipResource[] | undefined
}

export const ReportListOwnerCell = ({ owner, membershipsData }: Props) => {
  const { user } = useUser()
  const isTemplateReport = getIsTemplateReport(owner)

  const name = getReportOwnerName({
    owner,
    user,
    memberships: membershipsData,
  })

  const ownerDetails = membershipsData?.find(
    (member) => member.publicUserData.userId === owner?.externalId,
  )

  const { imageUrl } = ownerDetails?.publicUserData ?? {}

  if (!name) return null

  return (
    <Flex gap={2} alignItems="center">
      {isTemplateReport ? (
        <Icon size="small" borderRadius={1} name="DemaDIcon" />
      ) : (
        <ClerkImage imageUrl={imageUrl} type="user" />
      )}
      <Typography>{name}</Typography>
    </Flex>
  )
}

ReportListOwnerCell.displayName = 'ReportListOwnerCell'
