import { type MutationFunctionOptions, useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type DeleteOrganizationMembershipMutation } from 'generated/graphql/graphql'

const DELETE_ORGANIZATION_MEMBER_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteOrganizationMembership(
    $payload: DeleteOrganizationMembershipInput!
  ) {
    deleteOrganizationMembership(params: $payload)
  }
`)

type DeleteOrganizationMemberParams = {
  clerkUserId: string | undefined
} & MutationFunctionOptions<DeleteOrganizationMembershipMutation>

export const useDeleteOrganizationMember = (): [
  typeof deleteMember,
  typeof state,
] => {
  const [mutation, state] = useMutation(DELETE_ORGANIZATION_MEMBER_MUTATION)

  const deleteMember = async ({
    clerkUserId,
    ...rest
  }: DeleteOrganizationMemberParams) => {
    if (!clerkUserId) return null

    const removedMember = await mutation({
      ...rest,
      variables: {
        payload: {
          clerkUserId,
        },
      },
    })

    return removedMember
  }

  return [deleteMember, state]
}
