import { type DatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Stack,
} from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { Button, ButtonIcon } from 'components/buttons'
import { SingleDatepicker } from 'components/Datepicker/SingleDatepicker/SingleDatepicker'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { type BaseFilterProps } from 'components/Filters/types'
import { Icon, type IconName } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { addDays, startOfDay } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { type ProductLabel } from 'features/productFeed/graphql/fragments'
import { type SegmentationSetByIdQueryResult } from 'features/productFeed/graphql/useSegmentationSetById'
import { AddProductLabelModal } from 'features/productFeed/views/ProductLabelsView/components/AddProductLabelModal'
import { AddFilterButton } from 'features/reports/components/Filters/AddFilterButton'
import { SelectedFilterChip } from 'features/reports/components/Filters/SelectedFilterChip/SelectedFilterChip'
import { addNewFilter } from 'features/reports/hooks/filters/useAddFilter'
import { removeFilter } from 'features/reports/hooks/filters/useRemoveFilter'
import { getUniqueMetrics } from 'features/reports/hooks/useMetricsState'
import {
  getValidMetricProcessors,
  initializeMetricsAttribution,
} from 'features/reports/utils/utils'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import {
  type Metric,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import {
  type FieldArrayWithId,
  type UseFieldArrayReturn,
} from 'react-hook-form'
import { formatDateTime } from 'utils/chart/common'
import { isTimeUnit } from 'utils/chart/constants'
import { RuleContextMenu } from './RuleContextMenu'
import { RuleDescription } from './RuleDescription'
import { type RuleForm } from './types'

interface RuleCardProps {
  field: FieldArrayWithId<
    {
      rules: RuleForm[]
    },
    'rules',
    'internal_id'
  >
  index: number
  productLabels: ProductLabel[]
  methods: UseFieldArrayReturn<
    {
      rules: RuleForm[]
    },
    'rules',
    'internal_id'
  >
  segmentationSet: SegmentationSetByIdQueryResult
  datePreset: DatePreset<DYNAMIC_DATE_ID>
}

const metricIsDisabled = (metric: Metric, validMetricProcessors: string[]) => {
  let isDisabled = false

  if ('dependencies' in metric && validMetricProcessors) {
    isDisabled = !metric.dependencies.every((dependency) =>
      validMetricProcessors.includes(dependency),
    )
  }

  return isDisabled
}

export const RuleCard: React.FC<RuleCardProps> = ({
  field,
  index,
  productLabels,
  methods,
  datePreset,
  segmentationSet,
}) => {
  const normalizedMetrics = useNormalizedMetrics()
  const normalizedDimensions = useNormalizedDimensions()
  const filters = Object.entries(field.filters)

  const filterKeys = Object.keys(field.filters)
  const dimensionFilters = filterKeys.filter(
    (filter) => normalizedDimensions[filter],
  )
  const metricFilters = filterKeys.filter((filter) => normalizedMetrics[filter])

  const allDimensionFilters = dimensionFilters.includes('product')
    ? dimensionFilters
    : dimensionFilters.concat(['product'])

  const validMetricProcessors = getValidMetricProcessors(
    allDimensionFilters.map((filter) => normalizedDimensions[filter]),
  )
  const validMetricIds = getUniqueMetrics(
    Object.values(normalizedMetrics).filter(
      (metric) =>
        !metricIsDisabled(metric, validMetricProcessors) &&
        metric.groupId !== 'session',
    ),
  ).map((metric) => initializeMetricsAttribution([metric])[0].key)

  const validDimensionIds = Object.values(normalizedDimensions)
    .filter((dimension) => {
      if (isTimeUnit(dimension.id)) return false
      if (!metricFilters.length) return true

      // dont include any dimensions that will make a selected metric invalid
      return metricFilters.some((metricId) => {
        const metric = normalizedMetrics[metricId]

        return !metricIsDisabled(metric, dimension.processors)
      })
    })
    .map((dimension) => dimension.id)

  const { update } = methods
  const { timezone } = useMerchantInfo()
  const [trackEvent] = useTrackEvent()

  const addFilterHandler = (
    args: Parameters<BaseFilterProps['addFilter']>[0],
  ) => {
    const { newFilters } = addNewFilter({
      ...args,
      filters: field.filters,
      normalizedMetrics,
    })

    trackEvent({
      eventName: 'ProductFeed Segmentation Rule Filter Added',
      eventProperties: {
        segmentationSetId: segmentationSet.id,
        segmentationSetName: segmentationSet.name,
        key: args.filterId,
        filterType: args.selectedFilterGroup,
        value: Array.isArray(args.value) ? args.value.slice(0, 10) : args.value,
      },
    })

    update(index, {
      ...field,
      filters: newFilters,
    })
  }

  const removeFilterHandler = (
    args: Parameters<BaseFilterProps['removeFilter']>[0],
  ) => {
    const newFilters = removeFilter({
      ...args,
      filters: field.filters,
    })

    trackEvent({
      eventName: 'ProductFeed Segmentation Rule Filter Removed',
      eventProperties: {
        segmentationSetId: segmentationSet.id,
        segmentationSetName: segmentationSet.name,
        key: args.filterId,
        filterIndex: args.filterIndex,
      },
    })

    update(index, { ...field, filters: newFilters })
  }

  const isExpired = field.expiresAt && new Date(field.expiresAt) < new Date()

  // We dont want to read from the field as it might be stale
  const selectedProductLabel = useMemo(() => {
    return (
      productLabels.find((label) => label.id === field.productLabel?.id) ??
      field.productLabel
    )
  }, [productLabels, field.productLabel])

  return (
    <Stack p={4} gap={4} borderBottom="solid 1px" borderBottomColor="gray.200">
      <Box>
        <Flex justifyContent="space-between">
          <Box w="full">
            <Typography
              fontSize="medium"
              lineHeight={6}
              color="gray.900"
              mb={1}
            >
              Rule {index + 1}
            </Typography>

            <RuleDescription
              description={field.description}
              onChange={(value) =>
                update(index, { ...field, description: value })
              }
            />
          </Box>

          <RuleContextMenu
            methods={methods}
            index={index}
            segmentationSet={segmentationSet}
          />
        </Flex>
        {isExpired && (
          <Box mt={4}>
            <Alert
              size="sm"
              status="error"
              closable={false}
              content="This rule has expired and is no longer applied."
            />
          </Box>
        )}
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Filters
        </Typography>

        <Flex flexWrap="wrap" gap={2}>
          {filters.map(([filterId, filterValues]) =>
            filterValues.map((filter, index) => (
              <SelectedFilterChip
                key={filterId + index}
                filter={filter}
                filterId={filterId}
                filterIndex={index}
                filters={field.filters}
                metricShouldShowAttribution={false}
                addFilter={addFilterHandler}
                removeFilter={removeFilterHandler}
              />
            )),
          )}

          <AddFilterButton
            showMetricsFirst
            customMenuButton={
              filters.length > 0 ? (
                <MenuButton
                  as={ButtonIcon}
                  name={'FilterIcon' satisfies IconName}
                  size="sm"
                  variant="ghost"
                  colorScheme="gray"
                />
              ) : (
                <MenuButton
                  as={Button}
                  size="sm"
                  colorScheme="gray"
                  leadingIcon={{
                    name: 'FilterIcon',
                    size: 'small',
                  }}
                >
                  Add filter
                </MenuButton>
              )
            }
            metrics={[]}
            validDimensionIds={validDimensionIds}
            validMetricIds={validMetricIds}
            skipDimensionGroupValidation={true}
            dateRange={datePreset.value}
            filters={field.filters}
            addFilter={addFilterHandler}
            removeFilter={removeFilterHandler}
          />
        </Flex>
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Product label
        </Typography>

        <Menu size="sm" placement="bottom-start">
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                flexShrink={0}
                size="sm"
                aria-label="Actions"
                variant="solid"
                colorScheme="gray"
                leadingIcon={{
                  name: selectedProductLabel?.iconName ?? 'TagIcon',
                  color: selectedProductLabel?.iconColor ?? undefined,
                }}
              >
                {selectedProductLabel?.name ?? 'Add product label'}
              </MenuButton>
              <Portal>
                <MenuOverlay isOpen={isOpen} />
                <MenuList maxH="400px" pb={0}>
                  {productLabels?.length > 0 ? (
                    <>
                      {productLabels.map((productLabel) => (
                        <MenuItem
                          key={productLabel.id}
                          onClick={() => {
                            update(index, {
                              ...field,
                              productLabel: productLabel,
                            })
                            trackEvent({
                              eventName:
                                'ProductFeed Segmentation Rule Label Changed',
                              eventProperties: {
                                segmentationSetId: segmentationSet.id,
                                segmentationSetName: segmentationSet.name,
                                labelId: productLabel.id,
                                labelName: productLabel.name,
                                isCreated: false,
                              },
                            })
                          }}
                          icon={
                            <Icon
                              name={productLabel.iconName}
                              color={productLabel.iconColor}
                              size="small"
                            />
                          }
                        >
                          {productLabel.name}
                        </MenuItem>
                      ))}
                    </>
                  ) : null}

                  <Box position="sticky" bottom={0} pb={2} bg="white">
                    {productLabels.length > 0 && <MenuDivider />}
                    <AddProductLabelModal
                      modalButton={
                        <MenuItem
                          icon={<Icon name="PlusSmallIcon" size="small" />}
                        >
                          Add label
                        </MenuItem>
                      }
                      onCompleted={(label) => {
                        update(index, {
                          ...field,
                          productLabel: label,
                        })
                        trackEvent({
                          eventName:
                            'ProductFeed Segmentation Rule Label Changed',
                          eventProperties: {
                            segmentationSetId: segmentationSet.id,
                            segmentationSetName: segmentationSet.name,
                            labelId: label.id,
                            labelName: label.name,
                            isCreated: true,
                          },
                        })
                      }}
                    />
                  </Box>
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      </Box>

      <Box>
        <Typography
          fontSize="xs"
          lineHeight={4}
          color="gray.700"
          fontWeight={500}
          mb={1}
        >
          Expiry date
        </Typography>

        <Flex>
          <SingleDatepicker
            selectedDate={field.expiresAt ? new Date(field.expiresAt) : null}
            setSelectedDate={(value) => {
              update(index, {
                ...field,
                expiresAt: value
                  ? fromZonedTime(startOfDay(value), timezone).toISOString()
                  : null,
              })

              trackEvent({
                eventName: 'ProductFeed Segmentation Rule Expiry Date Changed',
                eventProperties: {
                  segmentationSetId: segmentationSet.id,
                  segmentationSetName: segmentationSet.name,
                  expiryDate: value?.toISOString(),
                },
              })
            }}
            minDate={addDays(new Date(), 1)}
            placement="bottom-start"
            customButton={
              <Button
                size="sm"
                leadingIcon={{
                  name: isExpired ? 'CalendarBlockIcon' : 'CalendarBlankIcon',
                  color: isExpired ? 'red.500' : undefined,
                }}
                colorScheme="gray"
              >
                {field.expiresAt
                  ? formatDateTime({
                      start: new Date(field.expiresAt),
                      end: new Date(field.expiresAt),
                      isPerHour: false,
                    })
                  : 'Set expiry date'}
              </Button>
            }
          />
          {field.expiresAt && (
            <ButtonIcon
              name="CloseIcon"
              title="Remove expiry date"
              size="sm"
              maxH="unset"
              h="unset"
              onClick={() => {
                update(index, {
                  ...field,
                  expiresAt: null,
                })
              }}
            />
          )}
        </Flex>
      </Box>
    </Stack>
  )
}
