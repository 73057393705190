import { type DateRange } from 'constants/types'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { subMonths } from 'date-fns'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC, useState } from 'react'
import { type FieldError } from 'react-hook-form'

const now = new Date()
const dateRange: DateRange = [subMonths(now, 1), now]

type Props = {
  selected: string | undefined
  error: FieldError | undefined
  updateDropdown: (id: string) => void
}

export const CampaignMarketingCost: FC<Props> = ({
  selected,
  error,
  updateDropdown,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { uniqueDimensionValues: campaigns, query: campaignQuery } =
    useUniqueDimensionValues({
      dateRange,
      dimensionId: 'campaign',
    })
  const [campaignsSearchValue, setCampaignSearchValue] = useState<string>()

  const hasCampaigns = !campaignQuery.loading && campaigns.length > 0

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.SINGLE}
      label="Campaign"
      options={campaigns}
      selected={selected}
      error={error}
      placeholder={
        selected && !campaigns.map((c) => c.id).includes(selected)
          ? selected
          : 'Select campaign'
      }
      isLoading={campaignQuery.loading}
      isFullHeight
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      setSelected={updateDropdown}
      setSearchValue={setCampaignSearchValue}
      customEmptyState={
        hasCampaigns ? (
          <Button
            justifyContent="left"
            variant="ghost"
            size="md"
            colorScheme="grey"
            w="full"
            leadingIcon={{ name: 'PlusSmallIcon' }}
            onClick={() => {
              if (!campaignsSearchValue) return

              updateDropdown(campaignsSearchValue)
              onClose()
            }}
          >
            Add missing campaign
          </Button>
        ) : undefined
      }
    />
  )
}
