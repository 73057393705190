import { useAtom } from 'jotai'
import { atomWithReducer } from 'jotai/utils'

type MappingsState = {
  utmMedium?: string
  utmSource?: string
  utmCampaign?: string
}

type SetMappingTypeValuePayload = {
  type: keyof MappingsState
  value: string | undefined
}

export enum MappingsActionType {
  SetMappingTypeValue = 'SET_MAPPING_TYPE_VALUE',
  ResetMappingsState = 'RESET_MAPPINGS_STATE',
}

type MappingsAction =
  | {
      type: MappingsActionType.SetMappingTypeValue
      payload: SetMappingTypeValuePayload
    }
  | { type: MappingsActionType.ResetMappingsState }

const initialState: MappingsState = {
  utmMedium: undefined,
  utmSource: undefined,
  utmCampaign: undefined,
}

const mappingsReducer = (
  state: MappingsState,
  action: MappingsAction,
): MappingsState => {
  switch (action.type) {
    case MappingsActionType.SetMappingTypeValue: {
      const { type, value } = action.payload

      return {
        ...state,
        [type]: value,
      }
    }
    case MappingsActionType.ResetMappingsState: {
      return {
        ...initialState,
      }
    }
    default:
      console.warn('Unknown action type: ', (action as MappingsAction)?.type)

      return {}
  }
}

const mappingsAtom = atomWithReducer(initialState, mappingsReducer)

export const useMappingsAtom = () => useAtom(mappingsAtom)
