import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_PRODUCT_LABEL_MUTATION = graphql(/* GraphQL */ `
  mutation CreateProductLabel($params: CreateProductLabelInput!) {
    createProductLabel(params: $params) {
      ...ProductLabelFields
    }
  }
`)

export const useCreateProductLabel = () => {
  const [createProductLabel] = useMutation(CREATE_PRODUCT_LABEL_MUTATION)

  return createProductLabel
}
