import { ApolloLink, type Operation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { type OperationDefinitionNode, print } from 'graphql'

const extractDefinition = (operation: Operation): OperationDefinitionNode =>
  operation.query.definitions.find(
    (q) => q.kind === 'OperationDefinition',
  ) as OperationDefinitionNode

/**
 * Log GraphQL operations as Sentry breadcrumbs
 */
export const sentryLink = new ApolloLink((operation, forward) => {
  const definition = extractDefinition(operation)
  const message = `GraphQL: ${operation.operationName}`

  Sentry.addBreadcrumb({
    message,
    data: {
      query: definition.loc?.source?.body ?? print(definition),
      variables: JSON.stringify(operation.variables, null, 2),
    },
    category: `graphql.${definition.operation}`,
    level: 'info',
    type: 'http',
  })

  return forward(operation)
})
