import { METRIC_FORMAT } from 'graphql/statistics/constants'
import type { Options } from 'highcharts'
import { colorTheme } from 'ui/theme/colors'
import { yAxisFormatter } from 'utils/chart/common'
import { staticChartOptions } from 'utils/chart/constants'
import type { HistoricalHistoryContribution } from '../../../hooks/useHistoricalHistoryAnalysis'
import { getChartTooltip } from './getChartTooltip'

interface Props {
  contributions: HistoricalHistoryContribution[]
  currency: string | undefined
}

const X_AXIS_ID = 'week'

export const getChart = ({ contributions, currency }: Props): Options => {
  const allContributionsChannelsNames = contributions[0].contributions.map(
    (c) => c.name,
  )

  return {
    ...staticChartOptions,
    chart: {
      ...staticChartOptions.chart,
      type: 'area',
    },
    plotOptions: {
      ...staticChartOptions.plotOptions,
      area: {
        stacking: 'normal',
        fillOpacity: 1,
        marker: {
          enabled: false,
        },
      },
    },
    series: allContributionsChannelsNames.map((cName) => ({
      type: 'area',
      xAxis: X_AXIS_ID,
      stickyTracking: true,
      id: cName,
      legendSymbol: 'rectangle',
      name: cName,
      data: contributions.map((contribution) => [
        new Date(contribution.date).getTime(),
        contribution.contributions.find((a) => a.name === cName)?.ngp3 ?? 0,
      ]),
    })),
    xAxis: {
      ...staticChartOptions.xAxis,
      title: {
        text: undefined,
      },
      labels: {
        style: {
          color: colorTheme.gray[600],
          fontSize: '10px',
        },
        autoRotationLimit: 300,
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%B',
      },
      visible: true,
      id: X_AXIS_ID,
      crosshair: {
        color: colorTheme.grey[200],
        dashStyle: 'Solid',
      },
    },
    yAxis: {
      title: {
        ...staticChartOptions.yAxis.title,
        margin: 16,
        style: {
          color: colorTheme.gray[700],
        },
        text: `Net gross profit 3 (${currency})`,
      },
      gridLineWidth: 0,
      labels: {
        ...staticChartOptions.yAxis.labels,
        formatter: yAxisFormatter(METRIC_FORMAT.CURRENCY),
      },
      visible: true,
    },
    legend: {
      ...staticChartOptions.legend,
    },
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: getChartTooltip({
        currency,
      }),
      shared: true,
    },
  }
}
