import { Box, Flex } from '@chakra-ui/react'
import { SelectDeselectButton } from 'components/Filters/SelectDeselectButton'
import { Typography } from 'components/Typography'

interface Props {
  label: string
  isAllSelected: boolean
  toggleAllSelected: () => void
}

export const TableHeaderCell = ({
  label,
  isAllSelected,
  toggleAllSelected,
}: Props) => {
  return (
    <Box as="th" zIndex={1} borderX="none" w="100px" borderColor="grey.200">
      <Typography fontWeight="600" mb={2}>
        {label}
      </Typography>
      <Flex justifyContent="center">
        <SelectDeselectButton
          isAllSelected={isAllSelected}
          toggleAllSelected={toggleAllSelected}
          size="xs"
        />
      </Flex>
    </Box>
  )
}
