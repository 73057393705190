import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'

const INITIAL_QUERY = graphql(/* GraphQL */ `
  query InitializeApp {
    viewer {
      id
      featureFlags
      merchant {
        id
        timezone
        currency
        sites {
          domain
          frontendId
        }
        metrics {
          id
          label
          description
          descriptionMarkdown
          groupId
          groupLabel
          format
          dependencies
          isAttributionAllowed
          isHidden
          isAverage
          isReversePositive
        }
        dimensions {
          id
          label
          description
          descriptionMarkdown
          processors
          groupId
          groupLabel
          isHidden
        }
        attributionModels {
          id
          label
          description
        }
      }
    }
  }
`)

export const useInitialQuery = () => {
  return useQuery(INITIAL_QUERY)
}
