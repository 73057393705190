import {
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationSet } from 'features/productFeed/graphql/fragments'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useDeleteSegmentationSet } from '../../graphql/useDeleteSegmentationSet'

interface DeleteSegmentationSetProps {
  segmentationSet: SegmentationSet
  onDelete?: () => void
}

export const DeleteSegmentationSetModal = ({
  segmentationSet,
  onDelete,
}: DeleteSegmentationSetProps) => {
  const deleteSegmentationSet = useDeleteSegmentationSet()

  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDeleteSet = () => {
    onDelete?.()

    deleteSegmentationSet({
      variables: {
        id: segmentationSet.id,
      },
      optimisticResponse: {
        deleteSegmentationSet: {
          id: segmentationSet.id,
          __typename: 'SegmentationSet',
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteSegmentationSet) return

        cache.evict({
          id: cache.identify(data?.deleteSegmentationSet),
        })
        cache.gc()
      },
      onCompleted: () => {
        trackEvent({
          eventName: 'ProductFeed Segmentation Set Deleted',
          eventProperties: {
            id: segmentationSet.id,
            name: segmentationSet.name,
          },
        })
      },
      onError: () => {
        toast({
          description: 'Could not delete product feed. Try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  return (
    <>
      <MenuItem
        icon={<Icon name="TrashIcon" size="small" />}
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
        closeOnSelect={true}
      >
        Delete product feed
      </MenuItem>

      <Modal isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete product feed</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            The product feed will be deleted including all the rules it
            contains.
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="gray"
              variant="outline"
              mr={2}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              type="submit"
              onClick={() => {
                onDeleteSet()
                onClose()
              }}
            >
              Delete product feed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
