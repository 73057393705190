import { Box, Flex, type UseRadioProps, useRadio } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { type FC } from 'react'

interface FilterGroupChipProps {
  radioProps: UseRadioProps
  value: string
}

export const FilterGroupChip: FC<FilterGroupChipProps> = ({
  radioProps,
  value,
}) => {
  const { getInputProps, getRadioProps } = useRadio(radioProps)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Flex as="label" alignItems="center" gap={2} cursor="pointer">
      {/* tabIndex is used to prevent the inputs from being tabable */}
      <input tabIndex={-1} {...input} />
      <Flex
        {...checkbox}
        w={3}
        h={3}
        justifyContent="center"
        alignItems="center"
        border="1.5px solid"
        borderColor="grey.800"
        borderRadius="full"
      >
        <Box
          {...checkbox}
          boxShadow="md"
          borderRadius="full"
          w={1.5}
          h={1.5}
          _checked={{
            bg: 'grey.800',
          }}
        ></Box>
      </Flex>
      <Typography fontSize="xs" lineHeight={5} fontWeight={400}>
        {value}
      </Typography>
    </Flex>
  )
}
