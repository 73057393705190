import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle((props) => ({
  color: `${props.colorScheme}.600`,
  fontWeight: 600,
  textTransform: 'capitalize',
}))

const sizes = {
  sm: defineStyle({}),
  md: defineStyle({
    py: 1,
    px: 2,
  }),
  lg: defineStyle({
    py: 2,
    px: 3,
  }),
}

export const badgeTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'blue',
  },
  baseStyle,
  sizes,
})
