import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  MenuButton,
} from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { ReportHeaderWrapper } from 'features/reports/components/ReportHeader/ReportHeaderWrapper'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useUserReports } from 'graphql/reports/useUserReports'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { v4 as uuid } from 'uuid'
import {
  dashboardsState,
  selectedDashboardAtom,
  selectedDashboardIdAtom,
  selectedDashboardLayoutState,
} from '../atoms/dashboards'
import {
  DashboardsReducerActionEnum,
  LayoutReducerActionEnum,
} from '../atoms/types'
import { EmptyWidget } from './EmptyWidget'
import { WidgetContainer } from './new-dashboard/WidgetContainer'

export const DashboardComponent = () => {
  const setSelectedDashboardId = useSetAtom(selectedDashboardIdAtom)
  const [dashboard, updateSelectedDashboard] = useAtom(selectedDashboardAtom)
  const dispatch = useSetAtom(dashboardsState)
  const [dashboardName, setDashboardName] = useState(dashboard?.name || '')
  const navigate = useNavigate()
  const { userReports, query } = useUserReports()
  const dispatchLayoutChange = useSetAtom(selectedDashboardLayoutState)
  const [trackEvent] = useTrackEvent()

  useEffect(() => {
    setDashboardName(dashboard?.name || '')
  }, [dashboard])

  const handleNameChange = () => {
    if (!dashboard?.id) {
      return
    }

    updateSelectedDashboard({
      name: dashboardName,
      id: dashboard.id,
    })
  }

  return (
    <Flex direction="column" flex={1} h="full" zIndex={0} overflowY="hidden">
      <ReportHeaderWrapper>
        <Flex gap={2} alignItems="center">
          <Editable
            value={dashboardName}
            onChange={setDashboardName}
            onBlur={handleNameChange}
          >
            <EditablePreview fontSize="lg" fontWeight={500} />
            <EditableInput fontSize="lg" fontWeight={500} />
          </Editable>
        </Flex>
        <Flex gap={2} alignItems="center">
          <Button
            size="sm"
            width="full"
            variant="solid"
            colorScheme="primary"
            onClick={() => {
              const newId = uuid()

              dispatch({
                type: DashboardsReducerActionEnum.CREATE,
                payload: {
                  id: newId,
                  name: 'New dashboard',
                  layout: { order: [], rows: {} },
                },
              })
              navigate(`/custom-dashboard/${newId}`)
              setSelectedDashboardId(newId)
            }}
          >
            Create new dashboard
          </Button>
          {dashboard && (
            <ButtonIcon
              variant="solid"
              size="sm"
              name="TrashIcon"
              title="Delete dashboard"
              onClick={() => {
                if (!dashboard) return

                dispatch({
                  type: DashboardsReducerActionEnum.DELETE,
                  payload: {
                    id: dashboard.id,
                  },
                })
                navigate('/custom-dashboard')
              }}
            />
          )}
        </Flex>
      </ReportHeaderWrapper>
      <Flex flex={1} h="full" w="full" overflowY="hidden" maxW="full">
        <Box
          px={{ base: 4, lg: 6 }}
          py="6"
          w="full"
          h="full"
          bg="grey.50"
          overflowY="auto"
        >
          {!dashboard?.layout.order.length ? (
            <EmptyWidget />
          ) : (
            <>
              <WidgetContainer />
              <ComboBox
                selectionMode={SELECTION_MODE.SINGLE}
                options={userReports}
                setSelected={(item) => {
                  dispatchLayoutChange({
                    type: LayoutReducerActionEnum.ADD_WIDGET,
                    payload: {
                      widget: {
                        configId: item,
                        size: 100,
                        id: uuid(),
                      },
                    },
                  })

                  trackEvent({
                    eventName: 'CustomDashboard Widget Added',
                    eventProperties: {
                      reportId: item,
                      report: userReports.find((report) => report.id === item),
                    },
                  })
                }}
                customMenuButton={
                  <MenuButton
                    as={Button}
                    size="sm"
                    variant="ghost"
                    colorScheme="grey"
                    width="full"
                    mt={6}
                  >
                    Add widget from your reports
                  </MenuButton>
                }
                isLoading={query.loading}
              />
            </>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
