import { Flex } from '@chakra-ui/react'
import { Label } from 'components/Label/Label'
import {
  type ComboBoxOption,
  type ComboBoxMultiProps,
  type ComboBoxProps,
  type DropdownOption,
} from '../types'
import { ComboBoxMulti } from './ComboBoxMulti/ComboBoxMulti'
import { ComboBoxSingle } from './ComboBoxSingle/ComboBoxSingle'

export const SELECTION_MODE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
} as const

const isComboBoxMultiProps = <
  TItem extends DropdownOption,
  T extends ComboBoxOption<TItem>,
>(
  props: ComboBoxProps<TItem, T>,
): props is ComboBoxMultiProps<TItem, T> =>
  props.selectionMode === SELECTION_MODE.MULTIPLE

/**
 * `ComboBox` is a flexible component that renders either a single-select or multi-select dropdown. It also supports grouping of options.
 * @param selectionMode The selection mode of the dropdown. It can be either `SELECTION_MODE.SINGLE` or `SELECTION_MODE.MULTIPLE`.
 * @param options An array of options to be displayed in the dropdown. It can be a flat array of options or an array of groups with options.
 *
 * Obs! This prop should be stable and not change on every render.
 *
 * @param selected The currently selected option's identifier as a string for single-select or string[] for multi-select. This value corresponds to the id/ids of the options.
 *
 * Obs! This prop should be stable and not change on every render.
 * @param setSelected A function that updates the currently selected option. It takes the id/ids of the selected options as its argument.
 *
 *
 */
export const ComboBox = <
  TItem extends DropdownOption,
  T extends ComboBoxOption<TItem> = ComboBoxOption<TItem>,
>(
  props: ComboBoxProps<TItem, T>,
) => {
  const isMultiSelect = isComboBoxMultiProps(props)
  const { label, isDisabled, containerProps } = props

  return (
    <Flex flexDir="column" {...containerProps}>
      {label && <Label isDisabled={isDisabled} label={label} />}
      {isMultiSelect ? (
        <ComboBoxMulti {...props} />
      ) : (
        <ComboBoxSingle {...props} />
      )}
    </Flex>
  )
}
