import { Badge as ChakraBadge, type BadgeProps } from '@chakra-ui/react'

const Badge = ({ children, colorScheme, size = 'sm', ...rest }: BadgeProps) => {
  if (!children) return null

  return (
    <ChakraBadge colorScheme={colorScheme} size={size} {...rest}>
      {children}
    </ChakraBadge>
  )
}

export default Badge
