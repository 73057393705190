import {
  type ExtendedSortDirection,
  type TableState,
} from 'graphql/reports/types'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { type SetStateAction, atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { getStore } from 'shared/store'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import { getAnalyticsConfigFromStore } from '../utils/utils'
import { dateStateAtom } from './useDateState'
import { dimensionsStateAtom } from './useDimensionsState'
import { useIsNewReportFlow } from './useIsNewReportFlow'
import { metricsStateAtom } from './useMetricsState'

const getDefaultColumnState = (metricKeys: string[], dimensionKeys: string[]) =>
  dimensionKeys.concat(metricKeys).map(
    (key) =>
      ({
        id: key,
        isPinned: false,
        sort: metricKeys[0] === key ? 'desc' : null,
      }) as const,
  )

export const getValidTableState = ({
  tableState,
  metricKeys,
  dimensionKeys,
  isCompare,
}: {
  tableState: TableState | null | undefined
  metricKeys: string[]
  dimensionKeys: string[]
  isCompare: boolean
}): TableState => {
  if (!tableState || tableState.length === 0) {
    return getDefaultColumnState(metricKeys, dimensionKeys)
  }

  const newTableState = [...tableState]

  const dimensionsSet = new Set(dimensionKeys)
  const metricsSet = new Set(metricKeys)

  const dimensionsAndMetrics = [...dimensionKeys, ...metricKeys]

  const columnIds = newTableState.map((column) => column.id)
  const columnIdsSet = new Set(columnIds)
  const missingIds = dimensionsAndMetrics.filter((id) => !columnIdsSet.has(id))

  // insert the missing ids into state in the correct order so that the missing id comes right behind the closest id to the left of it from the metricKeys array
  missingIds.forEach((id) => {
    const indexOfId = dimensionsAndMetrics.indexOf(id)
    let leftMostIndex = 0

    for (let i = indexOfId - 1; i >= 0; i--) {
      const pivotId = dimensionsAndMetrics[i]

      if (columnIdsSet.has(pivotId)) {
        leftMostIndex = columnIds.indexOf(pivotId) + 1
        break
      }
    }

    newTableState.splice(leftMostIndex, 0, { id, sort: null, isPinned: false })
    columnIds.splice(leftMostIndex, 0, id)
    columnIdsSet.add(id)
  })

  // Keep only selected metrics or dimensions
  let validColumns = newTableState.filter(
    (column) => metricsSet.has(column.id) || dimensionsSet.has(column.id),
  )

  if (!isCompare) {
    // Remove compare sort keys
    validColumns = validColumns.map((column) => ({
      ...column,
      sort: ['ascCompare', 'descCompare'].includes(column?.sort ?? '')
        ? null
        : (column?.sort ?? null),
    }))
  }

  return validColumns
}

const focusTableStateStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('tableState'),
)

const tableStateAtom = atom(
  (get) => {
    const tableState = get(focusTableStateStateAtom)
    const { dimensionKeys } = get(dimensionsStateAtom)
    const { metricKeys } = get(metricsStateAtom)
    const { isCompare } = get(dateStateAtom)

    return getValidTableState({
      tableState,
      dimensionKeys,
      metricKeys,
      isCompare,
    })
  },
  (_, set, tableState: SetStateAction<TableState>) => {
    set(focusTableStateStateAtom, tableState)
  },
)

export const useAnalyticsTableState = () => useAtomValue(tableStateAtom)

export const useSetTableState = () => {
  const setTableState = useSetAtom(tableStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setTableSorting = useCallback(
    (columnId: string, sortOrder: ExtendedSortDirection) => {
      const setColumnSorting = (
        tableState: TableState,
        columnId: string,
        sortOrder: ExtendedSortDirection,
      ) => {
        return tableState.map((column) => ({
          ...column,
          sort: columnId === column.id ? sortOrder : null,
        }))
      }

      const currentTableState = getStore().get(tableStateAtom)
      const newTableState = setColumnSorting(
        currentTableState,
        columnId,
        sortOrder,
      )

      setTableState(newTableState)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        newAnalyticsConfig.tableState = newTableState

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setTableState],
  )

  const setTableColumnPinning = useCallback(
    (columnId: string, isPinned: boolean) => {
      const setColumnPinning = (
        tableState: TableState,
        columnId: string,
        isPinned: boolean,
      ) => {
        return tableState.map((column) => ({
          ...column,
          isPinned: columnId === column.id ? isPinned : column.isPinned,
        }))
      }

      const currentTableState = getStore().get(tableStateAtom)
      const newTableState = setColumnPinning(
        currentTableState,
        columnId,
        isPinned,
      )

      setTableState(newTableState)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        newAnalyticsConfig.tableState = newTableState

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setTableState],
  )

  const moveTableColumn = useCallback(
    (fromIndex: number, toIndex: number) => {
      // Ag-Grid returns fromIndex = -1 when the column is moved to an invalid position
      if (fromIndex < 0 || fromIndex === toIndex) {
        return
      }

      const moveColumn = (columns: TableState, from: number, to: number) => {
        const newColumns = [...columns]
        const [movedColumn] = newColumns.splice(from, 1)

        newColumns.splice(to, 0, movedColumn)

        return newColumns
      }

      const currentTableState = getStore().get(tableStateAtom)
      const newTableState = moveColumn(currentTableState, fromIndex, toIndex)

      setTableState(newTableState)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        newAnalyticsConfig.tableState = newTableState

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setTableState],
  )

  return {
    setTableSorting,
    setTableColumnPinning,
    moveTableColumn,
  }
}
