import { type DatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { type ProductLabel } from 'features/productFeed/graphql/fragments'
import { type MetricFormat } from 'graphql/statistics/types'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'
import { colorTheme } from 'ui/theme/colors'
import { staticChartOptions } from 'utils/chart/constants'
import { getPieChartTooltip } from './getPieChartTooltip'

interface PieChartProps {
  metricKey: string
  aggregatedProductMetrics: Record<string, Record<string, number>>
  uncategorized: Record<string, number>
  label: string
  htmlLabel: string | undefined
  format: MetricFormat
  productLabels: ProductLabel[]
  selectedDatePreset: DatePreset<DYNAMIC_DATE_ID>
}

export const PieChart: React.FC<PieChartProps> = ({
  aggregatedProductMetrics,
  metricKey,
  label,
  htmlLabel,
  uncategorized,
  format,
  productLabels,
  selectedDatePreset,
}) => {
  const { currency } = useMerchantInfo()
  const uncategorizedMetric = uncategorized[metricKey]
  const mappedData = Object.entries(aggregatedProductMetrics).map(
    ([key, value]) => {
      const label = productLabels.find((label) => label.id === key)

      return {
        name: label?.name ?? 'Uncategorized',
        color: label?.iconColor ?? colorTheme.gray[300],
        y: value[metricKey],
      }
    },
  )

  const chartOptions: Highcharts.Options = {
    ...staticChartOptions,
    chart: {
      type: 'pie',
      animation: false,
      height: 180,
      width: 180,
    },
    title: {
      text: htmlLabel ?? label,
      verticalAlign: 'middle',
      floating: true,
      useHTML: true,
      style: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        width: 60,
        textAlign: 'center',
        color: colorTheme.gray[900],
      },
    },
    plotOptions: {
      pie: {
        borderRadius: 0,
        borderWidth: 1,
        innerSize: '55%',
        animation: false,
        size: 180,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.0f}%',
          style: {
            color: colorTheme.white,
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: '500',
            textOutline: `1px solid ${colorTheme.gray[800]}`, // Added outline color for the text
          },
          distance: -20,
          alignTo: 'none',
          crop: false,
          x: 0,
          y: 0,
          overflow: 'allow',
          // Hide the label if the percentage is less than 5%
          filter: {
            property: 'percentage',
            operator: '>=',
            value: 5,
          },
        },
      },
    },
    series: [
      {
        type: 'pie',
        data:
          typeof uncategorizedMetric === 'number'
            ? [
                ...mappedData,
                {
                  name: 'Uncategorised',
                  y: uncategorizedMetric,
                  color: colorTheme.gray[300],
                  dataLabels: {
                    enabled: false,
                  },
                },
              ]
            : mappedData,
      },
    ],
    tooltip: {
      ...staticChartOptions.tooltip,
      formatter: getPieChartTooltip({
        currency,
        label,
        format,
        selectedDatePreset,
      }),
      shared: true,
    },
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      containerProps={{
        style: { width: '100%', height: '100%', overflow: 'visible' },
      }}
    />
  )
}
