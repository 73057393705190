import {
  Button as ChakraButton,
  forwardRef,
  type ButtonProps as ChakraButtonProps,
  type As,
  Box,
} from '@chakra-ui/react'
import { ErrorMessage } from 'components/Error/ErrorMessage'
import { Icon, type IconProps } from 'components/Icon/Icon'

export type ButtonProps = Omit<ChakraButtonProps, 'leftIcon' | 'rightIcon'> & {
  leadingIcon?: IconProps
  leadingComponent?: React.ReactElement
  trailingIcon?: IconProps
  isSelected?: boolean
}

const defaultIconSize = 'small'

const BaseButton = forwardRef<ButtonProps, As>(
  (
    {
      children,
      colorScheme = 'primary',
      trailingIcon,
      leadingIcon,
      leadingComponent,
      isSelected,
      ...rest
    },
    ref,
  ) => {
    return (
      <ChakraButton
        leftIcon={
          leadingIcon ? (
            <Icon
              onClick={leadingIcon.onClick}
              size={leadingIcon.size ?? defaultIconSize}
              {...leadingIcon}
            />
          ) : (
            // TODO rethink this
            leadingComponent || undefined
          )
        }
        rightIcon={
          trailingIcon ? (
            <Icon
              onClick={trailingIcon.onClick}
              size={trailingIcon.size ?? defaultIconSize}
              {...trailingIcon}
            />
          ) : undefined
        }
        colorScheme={colorScheme}
        ref={ref}
        data-hover={isSelected ? true : undefined} // sending false still enables the hover state
        {...rest}
      >
        {children}
      </ChakraButton>
    )
  },
)

const ErrorButton = forwardRef<ButtonProps, As>(({ error, ...rest }, ref) => (
  <Box w={rest.w ?? rest.width}>
    <BaseButton ref={ref} {...rest} />
    {error && <ErrorMessage error={error} />}
  </Box>
))

export const Button = forwardRef<ButtonProps, As>(({ error, ...rest }, ref) => {
  if (error) return <ErrorButton ref={ref} error={error} {...rest} />

  return <BaseButton ref={ref} {...rest} />
})
