import { Box, Flex, Skeleton } from '@chakra-ui/react'
import { TitleLayoutView } from 'components/Layouts/TitleLayoutView'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { useTeamById } from 'graphql/teams/useTeamById'
import { useState } from 'react'
import { useParams } from 'react-router'
import { NotFound } from 'shared/NotFound'
import { useEditTeamById } from '../graphql/useEditTeamById'
import { IconAndNameForm } from '../shared/IconAndNameForm'
import { DeleteTeam } from './DeleteTeam'

export const TeamGeneralView = () => {
  const { teamId } = useParams<{ teamId: string }>()
  const { team, query } = useTeamById(teamId ?? '')

  if (query.loading) {
    return (
      <Box p={6}>
        <Skeleton h={10} w={40} mb={2} />
        <Skeleton h={5} w={80} mb={6} />

        <Skeleton h={10} w={40} mb={2} />
        <Skeleton h={5} w={80} mb={6} />

        <Skeleton h={10} w={40} mb={2} />
        <Skeleton h={5} w={80} mb={6} />
      </Box>
    )
  }

  if (query.error || !team) {
    return (
      <Flex alignItems="center" w="full">
        <NotFound />
      </Flex>
    )
  }

  // The key is needed to reset the state when swapping between teams
  return <GeneralView team={team} key={team.id} />
}

const GeneralView = ({ team }: { team: MerchantTeam }) => {
  const [teamName, setTeamName] = useState(team.name)
  const [icon, setIcon] = useState({
    name: team.iconName,
    color: team.iconColor,
  })
  const [editTeam] = useEditTeamById()

  return (
    <TitleLayoutView title={team.name} description="Manage team settings">
      <IconAndNameForm
        teamName={teamName}
        setTeamName={setTeamName}
        icon={icon}
        setIcon={(icon) => {
          setIcon(icon)
          editTeam(team.id, {
            ...team,
            iconName: icon.name,
            iconColor: icon.color,
          })
        }}
        actionButton={{
          text: 'Save',
          isShown: teamName !== team.name && !!teamName?.trim(),
          onClick: () => {
            editTeam(team.id, {
              ...team,
              name: teamName,
            })
          },
        }}
        boxProps={{ mt: 8 }}
      />

      <DeleteTeam team={team} />
    </TitleLayoutView>
  )
}
