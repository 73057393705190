import { Box } from '@chakra-ui/react'
import { type UniqueIdentifier, useDroppable } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { type DashboardWidget } from 'features/customDashboard/atoms/types'
import { Fragment, useEffect, useRef } from 'react'
import {
  type ImperativePanelGroupHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels'
import { CustomDashboardWidget } from './CustomDashboardWidget'
import { DragHandle, SortableItem } from './SortableItem'

type ContainerProps = {
  id: string
  widgets?: DashboardWidget[]
}

export const WidgetGroup = ({ id, widgets = [] }: ContainerProps) => {
  const { setNodeRef } = useDroppable({ id })
  const panelGroupRef = useRef<ImperativePanelGroupHandle>(null)
  const layoutMapRef = useRef<Map<UniqueIdentifier, number>>(new Map())

  const onLayout = (sizes: number[]) => {
    const layoutMap = layoutMapRef.current

    widgets.forEach((item, index) => {
      const size = sizes[index]

      layoutMap.set(item.id, size)
    })
  }

  useEffect(() => {
    const layoutMap = layoutMapRef.current

    if (layoutMap.size === 0) return

    const panelGroup = panelGroupRef.current

    if (panelGroup) {
      const sizes = widgets.map((item) => layoutMap.get(item.id)!)

      panelGroup.setLayout(sizes)
    }
  }, [widgets])

  if (widgets.length === 0) return null

  return (
    <SortableContext id={id} items={widgets} strategy={rectSortingStrategy}>
      <Box ref={setNodeRef}>
        <PanelGroup
          direction="horizontal"
          onLayout={onLayout}
          ref={panelGroupRef}
          style={{ overflow: 'initial' }}
        >
          {widgets.map((item, index) => (
            <Fragment key={item.id}>
              <Panel
                id={`${item.id}`}
                order={index}
                defaultSize={item.size ?? undefined}
                style={{ overflow: 'initial', minWidth: 0 }}
                minSize={item.size ? 25 : undefined}
              >
                <SortableItem id={item.id}>
                  <Box position="relative">
                    <CustomDashboardWidget height={300} widget={item} />
                    <DragHandle />
                  </Box>
                </SortableItem>
              </Panel>
              {index !== widgets.length - 1 && (
                <PanelResizeHandle>
                  <Box
                    width="10px"
                    height="100%"
                    background="grey.100"
                    borderRadius="4px"
                  />
                </PanelResizeHandle>
              )}
            </Fragment>
          ))}
        </PanelGroup>
      </Box>
    </SortableContext>
  )
}
