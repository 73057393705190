import { Box, Flex } from '@chakra-ui/react'
import { Typography } from 'components/Typography'

export const ChartPointsList = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Flex
      direction="column"
      gap={2}
      position="absolute"
      top={0}
      right={0}
      zIndex={1}
    >
      {children}
    </Flex>
  )
}

export const ChartPointsItem = ({
  color,
  label,
}: {
  color: string | undefined
  label: string
}) => {
  return (
    <Flex gap={2} alignItems="center">
      <Box w={2} h={2} borderRadius="full" bg={color} flexShrink={0} />
      <Typography fontSize="xs" color="gray.800" lineHeight={4}>
        {label}
      </Typography>
    </Flex>
  )
}
