import { Typography } from 'components/Typography'
import { type FC } from 'react'

export const NothingFound: FC = () => {
  return (
    <Typography px={4} py={2} textAlign="left" fontSize="xs">
      Could not find anything that matches
    </Typography>
  )
}
