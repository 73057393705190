import { type GraphIconName, type IconName } from 'components/Icon/Icon'

interface ChartType {
  label: string
  id: string
  type: string
  iconName: IconName
  graphIconName: GraphIconName
  graphWideIconName: GraphIconName
  stack: boolean
}

export type ChartTypeId = keyof typeof chartTypes

export const CHART_TYPE_ID = {
  LINE: 'line',
  SPLINE: 'spline',
  COLUMN: 'column',
  STACKED_COLUMN: 'stackedColumn',
  BAR: 'bar',
  STACKED_BAR: 'stackedBar',
  AREA: 'area',
  STACKED_AREA: 'stackedArea',
  PIE: 'pie',
  SCATTER: 'scatter',
} as const satisfies Record<string, ChartTypeId>

export const chartTypes = {
  line: {
    label: 'Line chart',
    id: 'line',
    type: 'line',
    iconName: 'ChartLineIcon',
    graphIconName: 'LineIcon',
    graphWideIconName: 'LineWideIcon',
    stack: false,
  },
  spline: {
    label: 'Spline chart',
    id: 'spline',
    type: 'spline',
    iconName: 'ChartSplineIcon',
    graphIconName: 'SplineIcon',
    graphWideIconName: 'SplineWideIcon',
    stack: false,
  },
  column: {
    label: 'Column chart',
    id: 'column',
    type: 'column',
    iconName: 'ChartColumnIcon',
    graphIconName: 'ColumnIcon',
    graphWideIconName: 'ColumnWideIcon',
    stack: false,
  },
  stackedColumn: {
    label: 'Stacked column chart',
    id: 'stackedColumn',
    type: 'column',
    iconName: 'ChartColumnStackedIcon',
    graphIconName: 'StackedColumnIcon',
    graphWideIconName: 'StackedColumnWideIcon',
    stack: true,
  },
  bar: {
    label: 'Bar chart',
    id: 'bar',
    type: 'bar',
    iconName: 'ChartBarIcon',
    graphIconName: 'BarIcon',
    graphWideIconName: 'BarWideIcon',
    stack: false,
  },
  stackedBar: {
    label: 'Stacked bar chart',
    id: 'stackedBar',
    type: 'bar',
    iconName: 'ChartBarStackedIcon',
    graphIconName: 'StackedBarIcon',
    graphWideIconName: 'StackedBarWideIcon',
    stack: true,
  },
  area: {
    label: 'Area chart',
    id: 'area',
    type: 'area',
    iconName: 'ChartAreaIcon',
    graphIconName: 'AreaIcon',
    graphWideIconName: 'AreaWideIcon',
    stack: false,
  },
  stackedArea: {
    label: 'Stacked area chart',
    id: 'stackedArea',
    type: 'area',
    iconName: 'ChartAreaStackedIcon',
    graphIconName: 'StackedAreaIcon',
    graphWideIconName: 'StackedAreaWideIcon',
    stack: true,
  },
  pie: {
    label: 'Pie chart',
    id: 'pie',
    type: 'pie',
    iconName: 'ChartPieIcon',
    graphIconName: 'PieIcon',
    graphWideIconName: 'PieWideIcon',
    stack: false,
  },
  scatter: {
    label: 'Scatterplot',
    id: 'scatter',
    type: 'scatter',
    iconName: 'ChartScatterplotIcon',
    graphIconName: 'ScatterIcon',
    graphWideIconName: 'ScatterWideIcon',
    stack: false,
  },
} as const satisfies Record<string, ChartType>

export const multiMetricChartTypes: ChartTypeId[] = [
  CHART_TYPE_ID.LINE,
  CHART_TYPE_ID.SPLINE,
  CHART_TYPE_ID.COLUMN,
  CHART_TYPE_ID.AREA,
]
