import { Card as ChakraCard, Flex, Image, MenuButton } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { Typography } from 'components/Typography'
import { useUserReports } from 'graphql/reports/useUserReports'
import { useSetAtom } from 'jotai'
import { v4 as uuid } from 'uuid'
import { selectedDashboardLayoutState } from '../atoms/dashboards'
import { LayoutReducerActionEnum } from '../atoms/types'

export const EmptyWidget = () => {
  const { userReports, query } = useUserReports()
  const dispatchLayoutChange = useSetAtom(selectedDashboardLayoutState)

  return (
    <Flex alignItems="center" justifyContent="center" h="full">
      <ChakraCard
        rounded="none"
        w="fit-content"
        p={8}
        gap={4}
        boxShadow="base"
        overflow="hidden"
      >
        <Image src="/images/EmptyData.svg" alt="Not found" boxSize="280px" />
        <Flex
          flex={1}
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          gap={4}
        >
          <Typography fontSize="md">
            You have not added any widgets yet
          </Typography>
          <ComboBox
            selectionMode={SELECTION_MODE.SINGLE}
            options={userReports}
            setSelected={(item) =>
              dispatchLayoutChange({
                type: LayoutReducerActionEnum.ADD_WIDGET,
                payload: {
                  widget: {
                    configId: item,
                    size: 100,
                    id: uuid(),
                  },
                },
              })
            }
            customMenuButton={
              <MenuButton
                as={Button}
                size="sm"
                variant="outline"
                colorScheme="grey"
              >
                Add from your reports
              </MenuButton>
            }
            isLoading={query.loading}
          />
        </Flex>
      </ChakraCard>
    </Flex>
  )
}
