import { type IntegrationsViewQueryQuery } from 'generated/graphql/graphql'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { type AdsMatchingRule } from '../AccountsTable/types'
import { useSaveIntegrationAccounts } from '../graphql/useSaveIntegrationAccounts'

type UseSelectAccountsParams = {
  merchantSite: NonNullable<
    IntegrationsViewQueryQuery['viewer']
  >['merchantSite']
  merchantId: string
}

const integrationSearchParamKey = 'integration'

export const useSelectAccounts = ({
  merchantSite,
}: UseSelectAccountsParams) => {
  const [isSelectAccountsModalOpen, setIsSelectAccountsModalOpen] =
    useState(false)
  const closeSelectAccountsModal = () => setIsSelectAccountsModalOpen(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const integrationId = searchParams.get(integrationSearchParamKey)

  const [saveIntegrationAccounts] = useSaveIntegrationAccounts()

  const connectedIntegration = useMemo(
    () =>
      merchantSite?.integrations?.find(
        (integration) => integration.id === integrationId,
      ),
    [merchantSite, integrationId],
  )

  const connectCallback = useCallback(
    async (accounts: string[]) => {
      if (integrationId) {
        searchParams.delete(integrationSearchParamKey)
        setSearchParams(searchParams)
        setIsSelectAccountsModalOpen(false)
        await saveIntegrationAccounts({
          variables: { params: { id: integrationId, accounts } },
        })
      }
    },
    [integrationId, searchParams, setSearchParams, saveIntegrationAccounts],
  )

  useEffect(() => {
    const accounts =
      (connectedIntegration?.costConfig
        ?.adsMatchingRules as AdsMatchingRule[]) ?? []

    if (integrationId && connectedIntegration && accounts) {
      const accountsLength = accounts.length

      if (accountsLength > 1) {
        setIsSelectAccountsModalOpen(true)
      } else if (accountsLength === 1) {
        connectCallback([accounts[0].accountId])
      }
    }
  }, [integrationId, connectedIntegration, connectCallback])

  return {
    isSelectAccountsModalOpen,
    closeSelectAccountsModal,
    connectCallback,
    connectedIntegration,
  }
}
