import { useQuery } from '@apollo/client'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { config } from 'config'
import { graphql } from 'generated/graphql'
import { type PropsWithChildren, useEffect } from 'react'
import { isAppVisible } from 'shared/VisibilityHandler'

export const API_VERSION_QUERY = graphql(/* GraphQL */ `
  query apiVersion {
    apiVersion
  }
`)

export const POLL_INTERVAL = 10 * 60 * 1000 // 10 minutes

/**
 * Compares the supported API version with the current version.
 *
 * @param supportedVersion - The maximum supported API version.
 * @param currentVersion - The current API version.
 * @returns {boolean} True if the current version is valid (less than or equal to the supported version), false otherwise.
 *
 * This function assumes versions are in the format "X.Y.Z" and only compares the major version number.
 * It considers the current version valid if its major version is less than or equal to the supported major version.
 * If the current version is undefined, it returns false.
 */
const getIsValidVersion = (
  supportedVersion: string,
  currentVersion: string | undefined,
): boolean => {
  if (!currentVersion) return false

  const supportedMajor = parseInt(supportedVersion.split('.')[0])
  const currentMajor = parseInt(currentVersion.split('.')[0])

  return currentMajor <= supportedMajor
}

export const VersionHandler = ({ children }: PropsWithChildren) => {
  const { data, refetch, loading, error } = useQuery(API_VERSION_QUERY, {
    pollInterval: POLL_INTERVAL,
    skipPollAttempt: () => {
      return !isAppVisible
    },
  })

  const isValidVersion = getIsValidVersion(
    config.SUPPORTED_API_VERSION,
    data?.apiVersion,
  )

  useEffect(() => {
    const refetchQueries = () => {
      if (isAppVisible) {
        // Refetch whenever the tab becomes visible
        refetch()
      }
    }

    window.addEventListener('visibilitychange', refetchQueries)

    return () => {
      window.removeEventListener('visibilitychange', refetchQueries)
    }
  }, [refetch])

  return (
    <>
      {children}
      <OutdatedVersionModal
        isOpen={!loading && !error && !isValidVersion}
        onClose={() => window.location.reload()}
      />
    </>
  )
}

const OutdatedVersionModal = ({
  isOpen,
  onClose,
}: Omit<ModalProps, 'children'>) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>App update required</ModalHeader>
        <ModalBody>
          <Typography>
            The application is running on an outdated version. A refresh is
            required to update to the latest version. If the issue persists
            after refreshing, the API may be undergoing maintenance. Please try
            again in a few minutes.
          </Typography>
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" colorScheme="primary" onClick={onClose}>
            Refresh
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
