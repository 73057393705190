import { type ContributionData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import { getChartOptions } from './getChartOptions'

interface ChannelBreakdownChartProps {
  contributionData: ContributionData
}

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
})

export const ChannelBreakdownChart: React.FC<ChannelBreakdownChartProps> = ({
  contributionData,
}) => {
  const { currency } = useMerchantInfo()

  const chartOptions = getChartOptions({
    contribution: contributionData,
    currency,
  })

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        containerProps={{ style: { width: '100%' } }}
      />
    </>
  )
}
