import { DiffStatus, STATUS_COLORS } from 'constants/statusColors'
import {
  Flex,
  Skeleton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  forwardRef,
  type StatProps,
} from '@chakra-ui/react'
import { Icon, type IconName } from 'components/Icon/Icon'
import { type statTheme } from 'ui/components/stat'

export interface StatCardProps extends StatProps {
  size: keyof NonNullable<(typeof statTheme)['sizes']>
  label: string
  number: string
  helpText?: string
  status: DiffStatus | null
  iconName: IconName | null
  vertical?: boolean
  isLoading?: boolean
}

export const StatCard = forwardRef<StatCardProps, 'div'>(
  (
    {
      size,
      label,
      number,
      helpText,
      status,
      iconName,
      vertical = true,
      isLoading,
      ...rest
    },
    ref,
  ) => {
    status = status || DiffStatus.NEUTRAL

    return (
      <Stat
        size={size}
        ref={ref}
        sx={{
          // The State component renders a dl tag that wraps all the content below
          '& > dl': {
            display: 'flex',
            flexDirection: 'column',
            h: 'full',
          },
        }}
        py={vertical ? undefined : 2}
        {...rest}
      >
        <Skeleton
          isLoaded={!isLoading}
          w={isLoading ? '60%' : undefined}
          minW="fit-content" // override the 60% if the content text is larger
          mb={1}
        >
          <StatLabel>{label}</StatLabel>
        </Skeleton>
        <Flex
          direction={vertical ? 'column' : 'row'}
          gap={2}
          alignItems={vertical ? undefined : 'center'}
          flexWrap="wrap"
        >
          <Skeleton
            isLoaded={!isLoading}
            w={isLoading ? (vertical ? '70%' : '30%') : undefined}
            minW="fit-content"
          >
            <StatNumber>{number}</StatNumber>
          </Skeleton>

          {helpText && (
            <Skeleton
              isLoaded={!isLoading}
              w={isLoading ? (vertical ? '35%' : '15%') : undefined}
              minW="fit-content"
            >
              <StatHelpText
                bg={STATUS_COLORS[status].bg}
                color={STATUS_COLORS[status].color}
              >
                {status === DiffStatus.NEUTRAL ? 'N/A' : helpText}
                {iconName ? <Icon name={iconName} size="small" /> : null}
              </StatHelpText>
            </Skeleton>
          )}
        </Flex>
      </Stat>
    )
  },
)

StatCard.displayName = 'StatCard'
