import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'

interface TooltipFormatterProps {
  currency: string | undefined
  yAxisLabel: string
}

export const getWaterfallTooltipFormatter = ({
  currency,
  yAxisLabel,
}: TooltipFormatterProps) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const category = this.point.name

    const element = (
      <>
        <p
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {category}
        </p>

        <TooltipMetricRow
          metricName={yAxisLabel}
          value={this.y ? formatMetric('currency', this.y, currency) : 'N/A'}
        />
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
