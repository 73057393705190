import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(() => {
  return {
    overlay: {
      zIndex: 1,
    },
    dialogContainer: {
      zIndex: 2,
    },
  }
})

export const drawerTheme = defineMultiStyleConfig({
  baseStyle,
})
