import { useQuery } from '@apollo/client'
import { Skeleton } from '@chakra-ui/react'
import { Tag } from 'components/Tag/Tag'
import { graphql } from 'generated/graphql'

export const MERCHANT_TOTAL_UNMAPPED_SESSIONS_QUERY = graphql(/* GraphQL */ `
  query TotalUnmappedSessions {
    viewer {
      id
      merchant {
        id
        totalUnmappedSessions
      }
    }
  }
`)

export const NoOfUnhandledMappings = () => {
  const { data, loading } = useQuery(MERCHANT_TOTAL_UNMAPPED_SESSIONS_QUERY)

  const totalUnmappedSessions = data?.viewer?.merchant.totalUnmappedSessions

  if (loading) {
    return <Skeleton w={5} h={5} />
  }

  if (!totalUnmappedSessions) {
    return null
  }

  return (
    <Tag
      size="sm"
      variant="solid"
      colorScheme="grey"
      fontWeight={600}
      label={totalUnmappedSessions.toString()}
    />
  )
}
