import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type ReportWithOwnerFieldsFragment } from 'generated/graphql/graphql'
import { useMemo } from 'react'
import { transformReports } from './utils'

export const MERCHANT_REPORTS_QUERY = graphql(/* GraphQL */ `
  query ReportsQuery {
    viewer {
      id
      merchant {
        id
        reports {
          ...ReportWithOwnerFields
        }
      }
    }
  }
`)

const emptyArray: ReportWithOwnerFieldsFragment[] = []

export const useMerchantReports = () => {
  const query = useQuery(MERCHANT_REPORTS_QUERY)

  const reportsData = query.data?.viewer?.merchant?.reports ?? emptyArray

  const reports = useMemo(() => {
    return transformReports(reportsData)
  }, [reportsData])

  return { merchantReports: reports, query }
}
