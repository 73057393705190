import { Box } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { ProductFeedHeaderWrapper } from 'features/productFeed/shared/ProductFeedHeaderWrapper'
import type React from 'react'
import { AddProductLabelModal } from './components/AddProductLabelModal'
import { ProductLabelsList } from './components/ProductLabelsList'

export const ProductLabelsView: React.FC = () => {
  return (
    <Box>
      <ProductFeedHeaderWrapper>
        <ProductFeedHeaderWrapper.Title>
          Product labels
        </ProductFeedHeaderWrapper.Title>

        <AddProductLabelModal
          modalButton={
            <Button
              size="sm"
              leadingIcon={{ name: 'PlusSmallIcon' }}
              colorScheme="gray"
            >
              New product label
            </Button>
          }
        />
      </ProductFeedHeaderWrapper>

      <ProductLabelsList />
    </Box>
  )
}
