import { Box, Flex } from '@chakra-ui/react'
import { useCanEditReport } from 'features/reports/hooks/useCanEditReport'
import { useIsNewReportFlow } from 'features/reports/hooks/useIsNewReportFlow'
import {
  useReportStateAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { useEditReportLabels } from 'features/reports/views/shared/ReportLabelsPicker/useEditReportLabels'
import {
  type ReportLabelWithoutCount,
  useReportLabels,
} from 'graphql/reports/useReportLabels'
import { type FC, useMemo } from 'react'
import {
  ReportLabelsPicker,
  type ReportLabelsPickerProps,
} from '../../../views/shared/ReportLabelsPicker/ReportLabelsPicker'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

const emptyArray: ReportLabelWithoutCount[] = []

export const LabelsSection: FC = () => {
  const isNewReportFlow = useIsNewReportFlow()
  const { editReportLabels } = useEditReportLabels()
  const { reportLabels, query: reportLabelsQuery } = useReportLabels({
    withReportCount: false,
  })

  const selectedReport = useReportStateAtom()
  const setReportState = useSetReportStateAtom()

  const { labels = emptyArray } = selectedReport ?? {}

  const selectedIds = useMemo(
    () => labels?.map((label) => label.id) ?? [],
    [labels],
  )

  const canEditReport = useCanEditReport(selectedReport)

  const commonProps = {
    reportLabels,
    isLoading: reportLabelsQuery.loading,
    selectedReport,
    isDisabled: !canEditReport,
    onLabelsChanged: (newLabels) => {
      setReportState((prev) => (prev ? { ...prev, labels: newLabels } : prev))
      if (!isNewReportFlow && selectedReport) {
        editReportLabels({
          selectedReport,
          newReportLabels: newLabels,
          oldReportLabels: labels,
        })
      }
    },
  } satisfies Partial<ReportLabelsPickerProps>

  return (
    <Box>
      <ReportDetailsHeader title="Labels" />

      {!labels || labels.length === 0 ? (
        <ReportLabelsPicker {...commonProps} selectedLabelIds={[]} />
      ) : (
        <Flex gap={1} flexWrap="wrap">
          {labels.map((label) => (
            <ReportLabelsPicker
              key={label.id}
              {...commonProps}
              currentLabel={label}
              selectedLabelIds={selectedIds}
            />
          ))}
        </Flex>
      )}
    </Box>
  )
}
