import { Box, type BoxProps, Flex, Switch } from '@chakra-ui/react'
import { Input } from 'components/Input'
import { Typography } from 'components/Typography'
import { TARGETS } from 'features/optimizations/consts'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useFormContext } from 'react-hook-form'
import { ChannelConstraints } from './ChannelConstraints'
import { type SidebarFormState } from './types'

export const BudgetSection = ({ display }: BoxProps) => {
  const { currency } = useMerchantInfo()
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<SidebarFormState>()
  const selectedTarget = getValues('selectedTarget')
  const isOptimalBudget = selectedTarget === TARGETS.OPTIMAL_BUDGET

  return (
    <Box display={display}>
      <Box position="relative">
        {isOptimalBudget ? (
          <Input
            key="optimisedBudget" // key is needed to update the input correctly when toggling optimal budget
            label="Spend target"
            size="sm"
            type="number"
            helperText={currency}
            placeholder="Spend will be optimised"
            isReadOnly
            value=""
          />
        ) : (
          <Input
            label="Spend target"
            size="sm"
            type="number"
            helperText={currency}
            error={errors.target?.[TARGETS.BUDGET]}
            placeholder="Input spend target"
            {...register(`target.${TARGETS.BUDGET}`)}
          />
        )}
        <Flex
          alignItems="center"
          gap={2}
          position="absolute"
          top="0"
          h={5} // mimic the height of the label. Makes it centered
          right={0}
        >
          <Typography fontSize="xs" lineHeight={4} color="gray.700">
            Optimise
          </Typography>
          <Switch
            size="sm"
            isChecked={isOptimalBudget}
            onChange={(ev) => {
              const toggleValue = ev.target.checked
                ? 'optimal_budget'
                : 'budget'

              setValue('selectedTarget', toggleValue, { shouldValidate: true })
            }}
          />
        </Flex>
      </Box>

      <ChannelConstraints isDisabled={isOptimalBudget} />
    </Box>
  )
}
