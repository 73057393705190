import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import { type UpdateReturnThresholdInput } from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'

const UPDATE_RETURN_THRESHOLD_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateReturnThreshold($params: UpdateReturnThresholdInput!) {
    updateReturnThreshold(params: $params) {
      returnThreshold
      id
      __typename
    }
  }
`)

export const useUpdateReturnThreshold = () => {
  const [trackEvent] = useTrackEvent()
  const toast = useToast()
  const [mutation, state] = useMutation(UPDATE_RETURN_THRESHOLD_MUTATION)
  const { merchantId } = useMerchantInfo()

  const updateReturnThreshold = (params: UpdateReturnThresholdInput) => {
    mutation({
      variables: { params },
      optimisticResponse: {
        updateReturnThreshold: {
          __typename: 'Merchant',
          returnThreshold: params.returnThreshold,
          id: merchantId,
        },
      },
      onCompleted: ({ updateReturnThreshold }) => {
        if (!updateReturnThreshold) return

        trackEvent({
          eventName: 'Settings Return Threshold Updated',
          eventProperties: {
            value: params.returnThreshold,
          },
        })
        toast({
          status: 'success',
          description: 'Return threshold updated',
          position: 'bottom',
          isClosable: true,
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Failed to update return threshold, please try again',
        })
      },
    })
  }

  return [updateReturnThreshold, state] as const
}
