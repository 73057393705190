import { captureException } from '@sentry/react'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { getChannelId } from 'features/optimizations/utils/transformChannel'
import { useReducer } from 'react'

export interface SingleChannelControlState {
  channel: string
}

export enum SingleChannelControlActionEnum {
  CHANGE_CHANNEL = 'CHANGE_CHANNEL',
}

export type SingleChannelControlAction = {
  type: SingleChannelControlActionEnum.CHANGE_CHANNEL
  payload: { channel: string }
}

const singleChannelControlReducer = (
  state: SingleChannelControlState | null,
  action: SingleChannelControlAction,
) => {
  if (!state) return state

  switch (action?.type) {
    case SingleChannelControlActionEnum.CHANGE_CHANNEL: {
      state.channel = action.payload.channel

      return { ...state }
    }
  }
}

export const getSelectedCurveData = (
  curveData: SaturationCurveData,
  channel: string,
): SaturationCurveData[number] => {
  const selectedCurveData = curveData.find(
    (c) => getChannelId(c.channel) === channel,
  )

  if (!selectedCurveData) {
    captureException(
      'Could not find selectedCurveData for saturation curve in mmm',
    )

    return curveData[0]
  }

  return selectedCurveData
}

export const useSingleChannelState = (data: SaturationCurveData) =>
  useReducer(singleChannelControlReducer, data, (data) => {
    const firstCurve = data[0]

    return {
      channel: getChannelId(firstCurve.channel),
    } satisfies SingleChannelControlState
  })
