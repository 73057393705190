import { type TextProps } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { CompareValueType } from 'features/optimizations/consts'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { type MetricFormat } from 'graphql/statistics/types'
import { formatMetric } from 'utils/numberFormats'
import { getCompareCellColor } from 'utils/tableUtils'

type Props = TextProps & {
  value: number
  format: MetricFormat
  compareValue?: number
  skipCompareValue?: boolean
  isReversePositive?: boolean
  isBold?: boolean
  compareValueType?: CompareValueType
}

export const CellValue = ({
  value,
  compareValue,
  isReversePositive,
  skipCompareValue,
  format,
  isBold,
  compareValueType,
  ...rest
}: Props) => {
  const diffFormat =
    compareValueType === CompareValueType.Percentage ||
    format === METRIC_FORMAT.PERCENT
      ? METRIC_FORMAT.PERCENT_INT
      : METRIC_FORMAT.INTEGER

  const hasCompareValue = compareValue !== undefined && isFinite(compareValue)
  const formattedCompareValue = hasCompareValue
    ? `${compareValue > 0 ? '+' : ''}${formatMetric(diffFormat, compareValue)}`
    : 'N/A'

  return (
    <>
      <Typography
        fontSize={isBold ? 'sm' : 'xs'}
        fontWeight={isBold ? 600 : 400}
        lineHeight={isBold ? 5 : 4}
        color="grey.900"
        textAlign="end"
        {...rest}
      >
        {isFinite(value) ? formatMetric(format, value) : '-'}
      </Typography>
      {skipCompareValue ? null : (
        <Typography
          fontSize={isBold && hasCompareValue ? 'xs' : '2xs'}
          lineHeight={isBold && hasCompareValue ? 4 : 3}
          fontWeight={600}
          color={
            hasCompareValue
              ? getCompareCellColor(compareValue, isReversePositive)
              : 'grey.700'
          }
          textAlign="end"
          marginTop={0.5}
        >
          {formattedCompareValue}
        </Typography>
      )}
    </>
  )
}
