import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type HexColor, hexToColorType } from 'ui/theme/colors'

export const LegendRow = ({
  label,
  color,
  type,
  stripedBackground,
}: {
  label: string
  color: HexColor
  type: 'line' | 'column'
  stripedBackground?: HexColor
}) => {
  const customSize = type === 'column' ? 8 : undefined

  return (
    <Flex
      gap={2}
      alignItems="center"
      sx={
        stripedBackground ? { '--striped-icon-color': stripedBackground } : {}
      }
    >
      <Icon
        name={`${stripedBackground ? 'Striped' : 'Solid'}Legend${type === 'line' ? 'Line' : 'Column'}Icon`}
        size="small"
        customSize={customSize}
        color={hexToColorType(color)}
      />
      <Typography color="gray.900" fontSize="xs" fontWeight="medium">
        {label}
      </Typography>
    </Flex>
  )
}
