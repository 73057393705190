import { SingleDatepicker } from 'components/Datepicker/SingleDatepicker/SingleDatepicker'
import { endOfDay, startOfDay } from 'date-fns'
import { type ComponentProps, useEffect, useState } from 'react'

interface Props
  extends Omit<
    ComponentProps<typeof SingleDatepicker>,
    'selectedDate' | 'setSelectedDate'
  > {
  initialValue: Date | null
  isEndDate?: boolean
  updateDate: (date: Date) => void
}

export const SettingsDatePicker = ({
  initialValue,
  isEndDate = false,
  updateDate,
  ...rest
}: Props) => {
  const [date, setDate] = useState(initialValue)

  useEffect(() => {
    setDate(initialValue)
  }, [initialValue])

  const handleSelectedDate = (updatedDate: Date | null) => {
    if (updatedDate) {
      const newDate = isEndDate
        ? startOfDay(updatedDate)
        : endOfDay(updatedDate)

      setDate(newDate)
      updateDate(newDate)
    }
  }

  return (
    <SingleDatepicker
      selectedDate={date}
      placement="bottom"
      setSelectedDate={handleSelectedDate}
      placeholder="DD MMM, YYYY"
      {...rest}
    />
  )
}
