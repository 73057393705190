import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { addWeeks } from 'date-fns'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'

interface TooltipFormatterProps {
  currency: string | undefined
}

export const getChartTooltip = ({ currency }: TooltipFormatterProps) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const startDateTime = new Date(Number(this.x))
    const endTime = addWeeks(startDateTime, 1)

    const element = (
      <>
        <p
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {getCompareDateString(startDateTime, endTime)}
        </p>

        {this.points
          ?.filter(({ point }) => !!point.y)
          .map(({ point }) => {
            return (
              <TooltipMetricRow
                key={point.colorIndex}
                metricName={point.series.name}
                iconColor={String(point.color)}
                value={
                  point.y ? formatMetric('currency', point.y, currency) : 'N/A'
                }
              />
            )
          })}
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
