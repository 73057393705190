import { useQuery } from '@apollo/client'
import { type IconName } from 'components/Icon/Icon'
import { graphql } from 'generated/graphql'
import { type MerchantTeamsQuery } from 'generated/graphql/graphql'

export const MERCHANT_TEAMS_QUERY = graphql(/* GraphQL */ `
  query MerchantTeams {
    viewer {
      id
      merchant {
        id
        teams {
          ...Teams_TeamFields
        }
      }
    }
  }
`)

export type MerchantTeam = NonNullable<
  MerchantTeamsQuery['viewer']
>['merchant']['teams'][number] & { iconName: IconName }

export const useMerchantTeams = () => {
  const query = useQuery(MERCHANT_TEAMS_QUERY)

  return {
    merchantTeams: (query.data?.viewer?.merchant.teams ?? []) as MerchantTeam[],
    query,
  }
}
