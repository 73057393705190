import { Box } from '@chakra-ui/react'
import { type FC, type PropsWithChildren, type MouseEvent } from 'react'

type Props = {
  isOpen: boolean
  onClick?: (event: MouseEvent) => void
}

export const MenuOverlay: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClick,
}) => {
  if (!isOpen) return null

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      w="100vw"
      h="100vh"
      onClick={(e) => {
        e.stopPropagation()
        onClick?.(e)
      }}
    />
  )
}
