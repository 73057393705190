import { Box, Code, type BoxProps } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import hljs from 'highlight.js'
import 'highlight.js/styles/a11y-light.css'
import { useEffect, useState, type FC } from 'react'

type TagCopierProps = BoxProps & {
  snippet: string
  language: 'xml' | 'javascript'
}

export const CodeSnippet: FC<TagCopierProps> = ({
  snippet,
  language,
  ...rest
}) => {
  const [copied, setCopied] = useState(false)
  const [snippetToShow, setSnippetToShow] = useState('')

  useEffect(() => {
    const highlightedSnippet = hljs.highlight(snippet, {
      language,
    }).value

    setSnippetToShow(highlightedSnippet)
  }, [language, snippet])

  const handleCopy = () => {
    navigator.clipboard.writeText(snippet)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }

  return (
    <Box
      bg="grey.800"
      border="1px solid"
      borderColor="grey.300"
      p={4}
      maxH={440}
      overflowY="auto"
      ml={-5}
      my={4}
      {...rest}
    >
      <Button
        size="xs"
        variant="outline"
        colorScheme="grey"
        onClick={handleCopy}
        bg="white"
        leadingIcon={
          copied
            ? {
                name: 'CheckCircleIcon',
                size: 'small',
              }
            : undefined
        }
        position="sticky"
        right={0}
        top={0}
        marginLeft="auto"
        display="flex"
      >
        {copied ? 'Copied' : 'Copy'}
      </Button>
      <Typography as="pre" mt="-16px" whiteSpace="pre-wrap">
        <Code
          className="language-html"
          fontFamily="Fira Code, monospace"
          textColor="white"
          dangerouslySetInnerHTML={{ __html: snippetToShow }}
          bg="inherit"
          sx={{
            span: { fontFamily: 'inherit' },
            // Colors for variable names, string values
            '.hljs-name, .hljs-string': {
              color: 'cyan.300',
            },
            // Colors for template variables like {{ _name_ }}, keywords like 'var', 'const', tags like =, <, >, numbers
            '.hljs-template-variable, .hljs-keyword, .hljs-tag, .hljs-number, .hljs-params, .hljs-title':
              {
                color: 'white',
              },
            // Color for object keys and HTML attributes, variables
            '.hljs-attr, .hljs-variable': {
              color: 'purple.300',
            },
            // Color for comments
            '.hljs-comment': {
              color: 'grey.400',
            },
          }}
        />
      </Typography>
    </Box>
  )
}
