import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { useToggleIntegration } from 'features/settings/graphql/useToggleIntegration'
import { useRef } from 'react'
import type { DisconnectData } from './types'

export const ConnectionButton = ({
  connected,
  integrationId,
  serviceName,
}: DisconnectData & { connected: boolean; serviceName: string }) => {
  const [toggleIntegration] = useToggleIntegration()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef<HTMLElement>(null)

  const handleToggleIntegration = async () => {
    toggleIntegration({
      params: { id: integrationId, connected: !connected },
    })
    onClose()
  }

  return (
    <>
      <Button
        variant="outline"
        colorScheme="grey"
        size="sm"
        onClick={() => {
          if (connected) {
            onOpen()

            return
          }

          handleToggleIntegration()
        }}
      >
        {connected ? 'Disconnect' : 'Connect'}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Disconnect {serviceName}</AlertDialogHeader>

            <AlertDialogBody>
              When the integration is disconnected no data will be fetched.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                variant="outline"
                colorScheme="gray"
                onClick={onClose}
                mr={2}
              >
                Cancel
              </Button>
              <Button
                onClick={handleToggleIntegration}
                variant="solid"
                colorScheme="red"
              >
                Disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
