import { Skeleton } from '@chakra-ui/react'
import {
  type CellRendererSelectorFunc,
  type ICellRendererParams,
} from 'ag-grid-community'
import { Typography } from 'components/Typography'
import { type NormalizedStatistic } from 'graphql/statistics/types'
import { useIsOverflowing } from 'hooks/useIsOverflowing'
import { useRef } from 'react'
import { type AnalyticsCell } from '../types'
import { CellWrapper } from './CellWrapper'

const DimensionCellRenderer = ({
  value,
}: ICellRendererParams<NormalizedStatistic, AnalyticsCell>) => {
  const { formattedValue = '' } = value ?? {}
  const textRef = useRef<HTMLParagraphElement>(null)
  const isOverflowing = useIsOverflowing(textRef)

  return (
    <CellWrapper>
      <Typography
        ref={textRef}
        title={isOverflowing ? formattedValue : undefined}
        fontSize="xs"
        noOfLines={1}
        display="block"
      >
        {formattedValue}
      </Typography>
    </CellWrapper>
  )
}
const DimensionSkeletonLoader = () => {
  return (
    <CellWrapper>
      <Skeleton w="100px" h="16px" />
    </CellWrapper>
  )
}

export const dimensionsCellRendererSelector: CellRendererSelectorFunc<
  NormalizedStatistic,
  AnalyticsCell
> = (params) => {
  if (params.data?.['loading']) {
    return {
      component: DimensionSkeletonLoader,
    }
  }

  return { component: DimensionCellRenderer }
}
