import { Box } from '@chakra-ui/react'
import { useIsNewReportFlow } from 'features/reports/hooks/useIsNewReportFlow'
import {
  useReportStateAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { ReportVisibility } from 'features/reports/views/shared/ReportVisibility/ReportVisibility'
import { useEditReportVisibility } from 'features/reports/views/shared/ReportVisibility/useEditReportVisibility'
import { getReportVisibilityType } from 'graphql/reports/utils'
import { useMerchantTeams } from 'graphql/teams/useMerchantTeams'
import { type FC } from 'react'
import { ReportDetailsHeader } from './ReportDetailsRow/ReportDetailsHeader'

export const VisibilitySection: FC = () => {
  const { editReportVisibility } = useEditReportVisibility()
  const selectedReport = useReportStateAtom()
  const setReportState = useSetReportStateAtom()
  const { merchantTeams, query: merchantTeamsQuery } = useMerchantTeams()
  const isNewReportFlow = useIsNewReportFlow()

  if (!selectedReport) return null

  return (
    <Box>
      <ReportDetailsHeader title="Visibility" />
      <ReportVisibility
        key={getReportVisibilityType(selectedReport?.visibility)}
        teamOptions={merchantTeams}
        isLoading={merchantTeamsQuery.loading}
        report={selectedReport}
        onVisibilityChanged={(visibility) => {
          setReportState((c) => (c ? { ...c, visibility } : c))
          if (!isNewReportFlow) {
            editReportVisibility({
              selectedReport,
              newVisibility: visibility,
              oldVisibility: selectedReport.visibility?.map((v) => v.id) ?? [],
            })
          }
        }}
      />
    </Box>
  )
}
