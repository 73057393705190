import { MenuItem, MenuList } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type DimensionHeaderMenuProps } from '../types'

export const DimensionMenu = ({
  setSortOrder,
  openFilterPopup,
  onFreezeItemClick,
  isFrozen,
}: DimensionHeaderMenuProps) => {
  return (
    <MenuList>
      <MenuItem
        icon={<Icon name="ArrowUpIcon" size="small" />}
        onClick={() => setSortOrder('asc')}
      >
        <Typography>Sort A → Z</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="ArrowDownIcon" size="small" />}
        onClick={() => setSortOrder('desc')}
      >
        <Typography>Sort Z → A</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="FilterIcon" size="small" />}
        onClick={openFilterPopup}
      >
        <Typography>Filter</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="FreezeColumnIcon" size="small" />}
        onClick={onFreezeItemClick}
      >
        <Typography>{isFrozen ? 'Unfreeze' : 'Freeze'}</Typography>
      </MenuItem>
    </MenuList>
  )
}
