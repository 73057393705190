import { Box, Flex, type BoxProps } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type React from 'react'

interface BaseWidgetProps {
  title: string | null
  titleMargin?: BoxProps['mb']
  subTitle?: React.ReactNode
  rightElement?: React.ReactNode
  children: React.ReactNode
}

export const BaseWidget: React.FC<BaseWidgetProps> = ({
  title,
  titleMargin,
  subTitle,
  rightElement,
  children,
}) => {
  return (
    <Flex direction="column" p={6} bg="white" boxShadow="base">
      {title && (
        <Flex mb={titleMargin || 4} justifyContent="space-between">
          <Box>
            <Typography fontSize="lg" color="gray.900" lineHeight={6}>
              {title}
            </Typography>
            {subTitle && <Box mt={2}>{subTitle}</Box>}
          </Box>

          {rightElement}
        </Flex>
      )}

      {children}
    </Flex>
  )
}
