import { Icon, type IconName } from 'components/Icon/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { type ExtendedSortDirection } from 'graphql/reports/types'
import { type PropsWithChildren } from 'react'

interface Props {
  isMetric?: boolean
  showIcon?: boolean
  sortDirection?: ExtendedSortDirection
  description: string | undefined
}

export const getHeaderIcon = (
  sortDirection: ExtendedSortDirection,
): IconName | undefined => {
  if (sortDirection) {
    if (['asc', 'ascCompare'].includes(sortDirection)) {
      return 'ArrowUpIcon'
    }

    if (['desc', 'descCompare'].includes(sortDirection)) {
      return 'ArrowDownIcon'
    }
  }

  return undefined
}

export const HeaderText = ({
  isMetric,
  children,
  showIcon,
  sortDirection,
  description,
}: PropsWithChildren<Props>) => {
  const headerIcon = sortDirection && getHeaderIcon(sortDirection)

  return (
    <Tooltip label={description} placement="top">
      <Typography
        fontSize="sm"
        fontWeight={600}
        lineHeight="20px"
        noOfLines={3}
        textAlign={isMetric ? 'end' : 'start'}
        color="grey.900"
        _hover={{ color: 'primary.500' }}
        overflowWrap="anywhere"
        as="span"
      >
        {children}
        {showIcon && headerIcon && (
          <Icon
            name={headerIcon}
            size="small"
            verticalAlign="middle"
            className="icon"
            display={sortDirection ? 'inline' : 'none'}
          />
        )}
      </Typography>
    </Tooltip>
  )
}
