import { useQuery } from '@apollo/client'
import { graphql } from 'generated/graphql'
import { type MerchantTeam } from './useMerchantTeams'

export const TEAM_BY_ID_QUERY = graphql(/* GraphQL */ `
  query MerchantTeamById($id: ID!) {
    viewer {
      id
      team(id: $id) {
        ...Teams_TeamFields
      }
    }
  }
`)

export const useTeamById = (teamId: string) => {
  const query = useQuery(TEAM_BY_ID_QUERY, {
    variables: {
      id: teamId,
    },
  })

  return {
    team: query.data?.viewer?.team as MerchantTeam | null | undefined,
    query,
  }
}
