import { orgRolesMap, type OrgRole } from 'constants/roles'
import {
  Flex,
  Box,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Img,
  Skeleton,
  Image,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import { useAuth } from '@clerk/clerk-react'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  type ColumnSort,
} from '@tanstack/react-table'
import Badge from 'components/Badge/Badge'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { Typography } from 'components/Typography'
import { formatDistanceToNow } from 'date-fns'
import { type MerchantTeam } from 'graphql/teams/useMerchantTeams'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { type FC, useMemo, useState } from 'react'
import { AddMemberToTeam } from '../../Teams/TeamMembersView/AddMemberToTeam'
import { ChangeMemberRole } from './ChangeMemberRole'
import { InviteMemberModal } from './InviteMemberModal'
import { MembersTableDataCell } from './MembersTableDataCell'
import { RemoveMemberButton } from './RemoveMemberButton'
import { RevokeInvitationButton } from './RevokeInvitationButton'
import type { Member, Memberships, Invitations } from './types'

type Account = {
  id: string
  name: string
  profileImage: string
}

interface OrgResource {
  isOwnUser: boolean
  member: Member
}

type TransformedMember = {
  account: Account
  email: string
  role: OrgResource & {
    key: string
  }
  joined: Date
  remove: OrgResource & {
    id: string
  }
}

type Props = {
  memberships: Memberships | null
  members: Member[] | undefined
  notMembers?: Member[] | undefined
  invitations?: Invitations | null
  team?: MerchantTeam | null
  isLoading?: boolean
}

export const MembersTable: FC<Props> = ({
  memberships,
  members,
  notMembers,
  invitations,
  team,
  isLoading,
}) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([])
  const flags = useFeatureFlags()
  const { userId } = useAuth()
  const transformedMembers = useMemo(
    () =>
      members?.map<TransformedMember>((member) => {
        const { role, createdAt } = member
        const {
          imageUrl,
          firstName,
          lastName,
          identifier,
          userId: memberUserId,
        } = member.publicUserData
        const isOwnUser = userId === memberUserId

        return {
          account: {
            id: memberUserId ?? '',
            profileImage: imageUrl,
            name: `${firstName} ${lastName}`,
          },
          email: identifier,
          role: {
            key: role,
            isOwnUser,
            member,
          },
          joined: createdAt,
          remove: {
            id: memberUserId ?? '',
            isOwnUser,
            member,
          },
        }
      }) ?? [],
    [members, userId],
  )

  const teamSize = transformedMembers.length ?? 0

  const columnHelper = createColumnHelper<TransformedMember>()
  const columns = [
    columnHelper.accessor('account', {
      cell: ({ getValue }) => {
        const { profileImage, id, name } = getValue()
        const isCurrentUser = id === userId

        return (
          <Flex gap={2} alignItems="center">
            <Img w={6} h={6} borderRadius="full" src={profileImage} />
            <Typography>{name}</Typography>
            {isCurrentUser && <Typography color="gray.500">(You)</Typography>}
          </Flex>
        )
      },
      header: 'Name',
      sortingFn: (a, b) => {
        return a.original.account.name.localeCompare(b.original.account.name)
      },
    }),
    columnHelper.accessor('email', {
      cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
      header: 'Email',
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor('role', {
      cell: ({ getValue }) => {
        const { isOwnUser, member, key } = getValue()

        const canChangeRole =
          memberships && flags.allowSettingsWrite && !isOwnUser && !team

        if (canChangeRole) {
          return <ChangeMemberRole member={member} memberships={memberships} />
        }

        return <Typography>{orgRolesMap[key as OrgRole]?.label}</Typography>
      },
      header: 'Role',
      sortingFn: (a, b) => {
        return a.original.role.key.localeCompare(b.original.role.key)
      },
    }),
    columnHelper.accessor('joined', {
      cell: ({ getValue }) => (
        <Typography>
          {formatDistanceToNow(getValue(), { addSuffix: true })}
        </Typography>
      ),
      header: 'Joined',
      sortingFn: 'datetime',
    }),
    columnHelper.accessor('remove', {
      cell: ({ getValue }) => {
        const { isOwnUser, member } = getValue()
        const isRemovable =
          flags.allowSettingsWrite && ((memberships && !isOwnUser) || team)

        if (!isRemovable) {
          return
        }

        return (
          <RemoveMemberButton
            member={member}
            memberships={memberships}
            team={team}
          />
        )
      },
      header: '',
    }),
  ]

  const table = useReactTable({
    data: transformedMembers,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <>
      <Flex justifyContent="space-between" alignItems="end">
        {isLoading ? (
          <Skeleton w={24} h={4} />
        ) : !teamSize ? (
          <Box /> // Just to push the "add member" button to the right
        ) : (
          <Typography
            fontSize="xs"
            lineHeight={4}
            fontWeight="600"
            color="gray.600"
          >
            {teamSize} {teamSize < 1 ? 'member' : 'members'}
          </Typography>
        )}
        {flags.allowSettingsWrite &&
          (invitations ? (
            <InviteMemberModal invitations={invitations} />
          ) : team ? (
            <AddMemberToTeam team={team} notMembers={notMembers} />
          ) : null)}
      </Flex>
      <Box mt={6}>
        <TableContainer>
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const isSorted = header.column.getIsSorted()
                    const headerName = flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )

                    return (
                      <Th
                        key={header.id}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex alignItems="center" gap={2}>
                          {typeof headerName === 'string' && headerName}
                          <Icon
                            visibility={isSorted ? 'visible' : 'hidden'}
                            name={
                              isSorted === 'asc'
                                ? 'ArrowUpIcon'
                                : 'ArrowDownIcon'
                            }
                            size="small"
                          />
                        </Flex>
                      </Th>
                    )
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {isLoading ? (
                <SkeletonTableRows columns={columns.length} rows={5} />
              ) : !teamSize ? (
                <Tr p={10}>
                  <Td colSpan={columns.length} borderBottom="none">
                    <Flex alignItems="center" py={16} flexDir="column" gap={10}>
                      <Image
                        src="/images/EmptyMembers.svg"
                        alt="No members"
                        boxSize={200}
                      />
                      <Typography>
                        You haven&apos;t added any members to the team yet.
                      </Typography>
                    </Flex>
                  </Td>
                </Tr>
              ) : (
                <>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id} height={16}>
                      {row.getVisibleCells().map((cell) => (
                        <MembersTableDataCell
                          key={cell.id}
                          _first={{ minW: '200px' }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </MembersTableDataCell>
                      ))}
                    </Tr>
                  ))}
                  {invitations?.data?.map((invite) => (
                    <Tr key={invite.id} height="56px">
                      <MembersTableDataCell>
                        <Badge>Pending</Badge>
                      </MembersTableDataCell>
                      <MembersTableDataCell dimmed>
                        {invite.emailAddress}
                      </MembersTableDataCell>
                      <MembersTableDataCell dimmed>
                        {orgRolesMap[invite.role as OrgRole]?.label}
                      </MembersTableDataCell>
                      {flags.allowSettingsWrite && (
                        <MembersTableDataCell>
                          <RevokeInvitationButton
                            invitations={invitations}
                            invite={invite}
                          />
                        </MembersTableDataCell>
                      )}
                    </Tr>
                  ))}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
