import { type DateRange } from 'constants/types'
import { Skeleton } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { subMonths } from 'date-fns'
import { useUniqueDimensionValues } from 'hooks/useUniqueDimensionValues'
import { type FC } from 'react'
import { normalizedMarketingCostDimensions } from '../consts'
import { type MarketingCostDimension } from '../types'

type Props = {
  dimensionId: MarketingCostDimension
  value: string
}

const now = new Date()
const dateRange: DateRange = [subMonths(now, 1), now]

export const IntegrationConfigDimension: FC<Props> = ({
  dimensionId,
  value,
}) => {
  const { uniqueDimensionValues, query } = useUniqueDimensionValues({
    dimensionId,
    dateRange,
  })

  const dimensionValueName =
    uniqueDimensionValues.find((val) => val.id === value)?.name ?? value

  return (
    <>
      <Typography fontSize="xs" color="gray.600" fontWeight={600}>
        {normalizedMarketingCostDimensions[dimensionId]?.name}:
      </Typography>
      {query.loading ? (
        <Skeleton h={4} w={16} />
      ) : (
        <Typography
          noOfLines={1}
          fontSize="sm"
          fontWeight={400}
          lineHeight={5}
          color="gray.900"
          isTruncated
          display="block"
          title={dimensionValueName}
        >
          {dimensionValueName}
        </Typography>
      )}
    </>
  )
}
