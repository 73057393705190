import { useQuery } from '@apollo/client'
import { type DropdownOption } from 'components/Dropdown'
import { graphql } from 'generated/graphql'
import { useMemo } from 'react'

export const STATIC_DIMENSION_VALUES_QUERY = graphql(/* GraphQL */ `
  query StaticDimensionValues {
    channelGroups {
      id
      name
    }
    channels {
      id
      name
    }
    countries {
      id
      name
    }
    viewer {
      id
      merchant {
        id
        markets {
          id
        }
      }
    }
  }
`)

export type StaticDimensionId =
  | 'country'
  | 'channel'
  | 'channelGroup'
  | 'market'

export const useStaticDimensionValues = (skip = false) => {
  const query = useQuery(STATIC_DIMENSION_VALUES_QUERY, { skip })

  const data: Record<StaticDimensionId, DropdownOption[] | undefined> =
    useMemo(() => {
      const country = query.data?.countries
      const channel = query.data?.channels
      const channelGroup = query.data?.channelGroups
      const market = query.data?.viewer?.merchant.markets.map(({ id }) => ({
        id,
        name: id,
      }))

      return {
        country,
        channel,
        channelGroup,
        market,
      }
    }, [query.data])

  return { data, query }
}
