import { useAuth } from '@clerk/clerk-react'
import { getApolloClient } from 'apollo'
import { removeDashboardLocalStorageKeys } from 'features/dashboard/hooks/useDashboardState'
import { useCallback } from 'react'
import { useLocation } from 'react-router'

export const useSignOut = () => {
  const { sessionId, signOut } = useAuth()
  const location = useLocation()

  const signOutClerk = useCallback(async () => {
    await signOut({
      redirectUrl: `${location.pathname}${location.search}`,
      ...(sessionId && { sessionId }),
    })
    getApolloClient().clearStore()
    removeDashboardLocalStorageKeys()
  }, [location.pathname, location.search, sessionId, signOut])

  return signOutClerk
}
