import { type OrgRole, orgRolesArray, orgRolesMap } from 'constants/roles'
import {
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'
import { type Member, type Memberships } from './types'

interface ChangeMemberRoleProps {
  memberships: Memberships
  member: Member
}

export const ChangeMemberRole: FC<ChangeMemberRoleProps> = ({
  member,
  memberships,
}) => {
  const toast = useToast()

  return (
    <Menu size="sm" gutter={0}>
      {({ isOpen }) => (
        <>
          <MenuButton p={0} _hover={{ bg: 'unset' }} _focus={{ bg: 'unset' }}>
            <Flex alignItems="center" gap={0.5}>
              <Typography>
                {member.role in orgRolesMap
                  ? orgRolesMap[member.role as OrgRole]?.label
                  : ''}
              </Typography>
              <Icon name="ChevronDownIcon" size="small" flexShrink={0} />
            </Flex>
          </MenuButton>
          <Portal>
            <MenuOverlay isOpen={isOpen} />
            <MenuList>
              {orgRolesArray.map((role) => (
                <MenuItem
                  key={role.key}
                  onClick={async () => {
                    try {
                      if (member.role === role.key) return

                      await member?.update({
                        role: role.key,
                      })
                      await memberships?.revalidate?.()
                      toast({
                        status: 'success',
                        description: 'Successfully updated user role!',
                        position: 'bottom',
                        isClosable: true,
                      })
                    } catch (error) {
                      toast({
                        status: 'error',
                        description: 'Could not update user role.',
                        position: 'bottom',
                        isClosable: true,
                      })
                    }
                  }}
                >
                  {role.label}
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        </>
      )}
    </Menu>
  )
}
