import { type DatePreset, type DYNAMIC_DATE_ID } from 'constants/getDatePresets'
import { getCompareDateString } from 'components/Datepicker/RangeDatepicker/getCompareDateString/getCompareDateString'
import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { type MetricFormat } from 'graphql/statistics/types'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'

interface Props {
  currency: string | undefined
  label: string
  format: MetricFormat
  selectedDatePreset: DatePreset<DYNAMIC_DATE_ID>
}

export const getPieChartTooltip = ({
  currency,
  label,
  format,
  selectedDatePreset,
}: Props) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const element = (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginBottom: '8px',
          }}
        >
          <div
            style={{
              width: '8px',
              height: '8px',
              backgroundColor: String(this.point.color),
            }}
          ></div>
          <p
            style={{
              color: colorTheme.grey[700],
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: '14px',
            }}
          >
            {this.point.name}
          </p>
        </div>

        <p
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {getCompareDateString(
            selectedDatePreset.value[0],
            selectedDatePreset.value[1],
          )}
        </p>

        <TooltipMetricRow
          metricName={label}
          value={formatMetric(format, this.point.y ?? 0, currency)}
        />
        <TooltipMetricRow
          metricName="Share of total"
          value={`${Math.round(this.point.percentage ?? 0)}%`}
        />
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
