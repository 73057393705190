import { type TableCellProps, Td } from '@chakra-ui/react'

export const MembersTableDataCell = ({
  children,
  dimmed,
  ...rest
}: {
  children: React.ReactNode
  dimmed?: boolean
} & TableCellProps) => (
  <Td
    color={dimmed ? 'gray.600' : 'gray.800'}
    fontSize="sm"
    lineHeight={5}
    py={3.5}
    pr={6}
    pl={2}
    {...rest}
  >
    {children}
  </Td>
)
