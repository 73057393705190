import { Img, Flex, Box, useDisclosure, Collapse } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'

import type { Account, AdsMatchingRule } from './types'

const AccountsContent = ({ accounts }: { accounts: AdsMatchingRule[] }) => {
  return (
    <Flex flexDir="column" w="min-content" mt={2} gap={2} ml={12}>
      {accounts?.map((account) => (
        <Flex key={account.accountId} w="max-content" alignItems="flex-start">
          <Typography fontSize="sm">
            {account.name ?? account.accountId}
          </Typography>
        </Flex>
      ))}
    </Flex>
  )
}

export const AccountCell = ({ accounts, company, logo }: Account) => {
  const { isOpen, onToggle } = useDisclosure()

  const hasAccounts = accounts && accounts.length > 0

  const serviceImage = (
    <Img src={`/images/${logo}`} alt={'Logo for ' + company} w={5} h={5} />
  )

  return (
    <Box py={2}>
      {hasAccounts ? (
        <>
          <Button
            variant="ghost"
            colorScheme="gray"
            size="sm"
            leadingComponent={serviceImage}
            trailingIcon={{
              name: isOpen ? 'ChevronDownIcon' : 'ChevronRightIcon',
            }}
            onClick={onToggle}
          >
            {company}
          </Button>

          {/* This Box is not ment to be shown, its only here to avoid having the columns width expand when toggling the open stat */}
          <Box w="min-content" overflow="hidden" h={0}>
            <AccountsContent accounts={accounts} />
          </Box>

          <Collapse in={isOpen} animateOpacity={false} unmountOnExit>
            <AccountsContent accounts={accounts} />
          </Collapse>
        </>
      ) : (
        <Box
          display="inline-flex"
          verticalAlign="middle"
          gap={2}
          alignItems="center"
          px={4}
          h={8}
          userSelect="none"
        >
          {serviceImage}
          <Typography fontSize="sm" color="gray.800" lineHeight={5}>
            {company}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
