import { type RefinementCtx, z } from 'zod'
import { DEFAULT_CURRENCIES } from '../../shared/consts'
import { TAXONOMY_DATA_TYPES } from '../consts'
import { type MarketingCostDimension } from '../types'

type MarketingDimensions = {
  [K in MarketingCostDimension]?: string
}

const hasAtLeastOneInputFilled = (
  marketingDimensions: MarketingDimensions,
  ctx: RefinementCtx,
) => {
  const isAllFieldsEmpty = Object.values(marketingDimensions).every(
    (md) => !md || md.length === 0,
  )

  if (isAllFieldsEmpty) {
    Object.keys(marketingDimensions).forEach((key) =>
      ctx.addIssue({
        code: 'custom',
        message: 'Input at least one dimension',
        fatal: true,
        path: [key],
      }),
    )
  }
}

// costType is required, but needs to be set to optional first to be validated at the same time as other fields
export const validationSchema = z
  .object({
    channel: z.string().optional(),
    channelGroup: z.string().optional(),
    campaign: z.string().optional(),
    country: z.string().optional(),
    costType: z
      .nativeEnum(TAXONOMY_DATA_TYPES)
      .optional()
      .refine((value) => !!value, {
        message: 'Cost type is required',
      }),
    value: z.coerce
      .number({
        invalid_type_error: 'Input a valid number',
      })
      .min(0)
      .finite()
      .refine((value) => Boolean(value), {
        message: 'Value is required',
      }),
    currency: z.enum(DEFAULT_CURRENCIES),
    startDate: z.date(),
    endDate: z.date().nullable(),
  })
  .refine(({ startDate, endDate }) => !endDate || startDate <= endDate, {
    path: ['endDate'],
    message: 'End date has to be later than start date',
  })
  .refine(
    ({ costType, endDate }) =>
      costType !== TAXONOMY_DATA_TYPES.FIXED || endDate,
    {
      path: ['endDate'],
      message: 'End date is required for fixed cost',
    },
  )
  .superRefine(({ channel, channelGroup, campaign, country }, ctx) => {
    hasAtLeastOneInputFilled(
      {
        channel,
        channelGroup,
        campaign,
        country,
      },
      ctx,
    )
  })

export type ValidationSchema = z.infer<typeof validationSchema>
