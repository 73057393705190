import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { useToggleIntegration } from 'features/settings/graphql/useToggleIntegration'
import { useRef } from 'react'

interface Props {
  integrationId: string
  disabled: boolean
}

export const RemoveButton = ({ integrationId, disabled }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const [disableIntegration, { loading }] = useToggleIntegration()

  return (
    <>
      <ButtonIcon
        colorScheme="grey"
        size="sm"
        variant="ghost"
        name="TrashIcon"
        title="Remove"
        onClick={onOpen}
        isDisabled={disabled || loading}
      >
        Remove
      </ButtonIcon>

      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete marketing costs</AlertDialogHeader>

            <AlertDialogBody>
              Costs up until yesterday have already been added to the database
              and cannot be removed here. If you continue, defined costs from
              today and forward will be removed. If you need to remove or update
              costs before that please contact support.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                isLoading={loading}
                onClick={() => {
                  disableIntegration({
                    params: { id: integrationId, connected: false },
                  })
                  onClose()
                }}
              >
                Delete costs
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
