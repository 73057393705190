import { type Row } from '@tanstack/table-core'
import { type ManualInputColumns } from '../../types'

export const getErrors = (
  firstRow: Row<ManualInputColumns>,
  skipSelf?: boolean,
) => {
  const errorIds: string[] = []

  const getErrorsInSubRows = (
    rows: Row<ManualInputColumns>[],
    skipSelf?: boolean,
  ) => {
    rows.forEach((row) => {
      if (row.original.error && !skipSelf) {
        errorIds.push(row.id)
      }

      if (row.subRows) {
        getErrorsInSubRows(row.subRows)
      }
    })
  }

  getErrorsInSubRows([firstRow], skipSelf)

  return errorIds
}

export const countErrors = (data: ManualInputColumns[]) => {
  let errorCount = 0
  const getErrorsInSubRows = (rows: ManualInputColumns[]) => {
    rows.forEach((row) => {
      Object.values(row.error ?? {}).forEach((val) => {
        if (val) errorCount = errorCount + 1
      })

      if (row.subRows) {
        getErrorsInSubRows(row.subRows)
      }
    })
  }

  getErrorsInSubRows(data)

  return errorCount
}

type InvalidRow = {
  columnsToValidate: (keyof ManualInputColumns)[]
  row: Row<ManualInputColumns> | ManualInputColumns
}

export const getInvalidColumns = ({ columnsToValidate, row }: InvalidRow) =>
  columnsToValidate.map((column) => {
    const col = 'original' in row ? row.original[column] : row[column]
    const rowIsInvalid = !col

    return rowIsInvalid || Number(col) === 0
  })
