import { MenuItem, useToast } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { REPORT_PAGES } from 'features/reports/routePages'
import type React from 'react'

interface Props {
  reportId: string | undefined
}

export const CopyReportURLMenuItem: React.FC<Props> = ({ reportId }) => {
  const toast = useToast()

  const copyReportUrl = () => {
    if (!reportId) {
      return
    }

    const reportUrl = `${window.location.origin}${REPORT_PAGES.REPORT_BY_ID(
      reportId,
    )}`

    navigator.clipboard.writeText(reportUrl)

    toast({
      status: 'success',
      description: 'Copied report url to clipboard',
    })
  }

  return (
    <MenuItem
      onClick={copyReportUrl}
      icon={<Icon name="LinkIcon" size="small" />}
    >
      Copy report URL
    </MenuItem>
  )
}
