import { FormLabel } from '@chakra-ui/react'
import { Input } from 'components/Input'
import { type useCombobox } from 'downshift'
import { type FC } from 'react'
import { SelectDeselectButton } from '../../../Filters/SelectDeselectButton'

type Props = {
  inputProps: ReturnType<ReturnType<typeof useCombobox>['getInputProps']>
  labelProps: ReturnType<ReturnType<typeof useCombobox>['getLabelProps']>
  placeholder?: string
  hasSelectDeselect?: boolean
  isAllSelected?: boolean
  toggleAllSelected?: () => void
  value?: string
}

export const FilterInput: FC<Props> = ({
  labelProps,
  inputProps,
  placeholder = 'Search',
  hasSelectDeselect,
  isAllSelected,
  toggleAllSelected,
  value,
}) => (
  <FormLabel m={0} {...labelProps}>
    <Input
      name={`filter-${labelProps.id}`}
      variant="flushed"
      leadingIcon={{ name: 'MagnifyingGlassIcon', size: 'small' }}
      type="text"
      placeholder={placeholder}
      action={
        hasSelectDeselect ? (
          <SelectDeselectButton
            isAllSelected={isAllSelected}
            toggleAllSelected={toggleAllSelected}
          />
        ) : undefined
      }
      {...inputProps}
      value={value}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus // autoFocus guarantees that the input will be focused when the component is mounted even with isOpenByDefault
    />
  </FormLabel>
)
