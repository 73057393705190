export const METRIC_FORMAT = {
  INTEGER: 'integer',
  SECONDS: 'seconds',
  CURRENCY: 'currency',
  PERCENT: 'percent',
  PERCENT_INT: 'percent_int',
  DECIMAL: 'decimal',
  ABBREVIATED_INT: 'abbreviated_int',
  ABBREVIATED_DECIMAL: 'abbreviated_decimal',
} as const
