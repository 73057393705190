import {
  Button,
  MenuButton as ChakraMenuButton,
  Flex,
  Skeleton,
} from '@chakra-ui/react'
import { type ButtonProps } from 'components/buttons/Button'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type useCombobox } from 'downshift'
import { cloneElement, type FC, type ReactElement } from 'react'
import { type FieldError } from 'react-hook-form'
import { type DropdownProps, type DropdownVariant } from '../types'

interface Props {
  customMenuButton?: ReactElement
  error?: FieldError
  buttonProps?:
    | Partial<
        ReturnType<ReturnType<typeof useCombobox>['getToggleButtonProps']>
      >
    | Partial<ButtonProps>
  size: NonNullable<DropdownProps<never>['size']>
  isOpen: boolean
  isLoading?: boolean
  isReadOnly?: boolean
  isDisabled?: boolean
  buttonText?: string
  placeholder?: string
  variant?: DropdownVariant
}

const fontSizes = {
  xs: 'xs',
  sm: 'sm',
  md: 'sm',
  lg: 'md',
}

const readOnlyStyle = {
  bg: 'grey.100',
  color: 'grey.800',
  borderColor: 'grey.400',
}

export const MenuButton: FC<Props> = ({
  customMenuButton,
  error,
  buttonProps,
  size,
  isOpen,
  isLoading,
  isReadOnly,
  isDisabled,
  buttonText,
  placeholder = 'Select',
  variant,
}) => {
  if (customMenuButton) {
    return cloneElement(customMenuButton, { ...buttonProps })
  }

  return (
    <ChakraMenuButton
      as={Button}
      placeholder={placeholder}
      aria-invalid={!!error}
      isDisabled={isDisabled || isReadOnly}
      aria-readonly={isReadOnly}
      _readOnly={{
        '&': readOnlyStyle,
        '&:hover': readOnlyStyle,
      }}
      type="button" // is needed to stop forms from being submitted when you open the dropdown
      minW={isLoading ? '100px' : undefined}
      variant={variant}
      size={size}
      onClick={(e) => e.stopPropagation()}
      fontSize={
        buttonProps && 'fontSize' in buttonProps
          ? buttonProps.fontSize
          : fontSizes[size]
      }
      {...buttonProps}
    >
      {isLoading ? (
        <Skeleton w="80px" minH={5} />
      ) : (
        <Flex gap={2} alignItems="center" justifyContent="space-between">
          <Typography color="unset" isTruncated fontSize="inherit">
            {buttonText ?? placeholder}
          </Typography>
          <Flex gap={2}>
            {error && (
              <Icon name="ExclamationCircleIcon" size="small" color="red.500" />
            )}

            <Icon
              name={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              size="small"
            />
          </Flex>
        </Flex>
      )}
    </ChakraMenuButton>
  )
}
