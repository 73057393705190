import {
  DYNAMIC_DATE_ID,
  type DatePreset,
  getDatePresets,
} from 'constants/getDatePresets'
import { type DateRange } from 'constants/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { format } from 'date-fns'
import { type SetStateAction, atom, useAtom, useSetAtom } from 'jotai'

const dynamicDateRanges = [
  DYNAMIC_DATE_ID.LAST_WEEK,
  DYNAMIC_DATE_ID.LAST_2_WEEKS,
  DYNAMIC_DATE_ID.LAST_4_WEEKS,
  DYNAMIC_DATE_ID.CUSTOM_RANGE,
]

const dateFormat = 'yyyy-MM-dd'

export const formatOptimizationsDateRange = (dateRange: DateRange) => {
  if (getIsPopulatedDateRange(dateRange)) {
    return [format(dateRange[0], dateFormat), format(dateRange[1], dateFormat)]
  }

  return ['', '']
}

export const optimizationHistoricalDates = getDatePresets(dynamicDateRanges)

const internalDateState = atom(optimizationHistoricalDates[0])

const historicalAnalysisDateAtom = atom(
  (get) => {
    const dateState = get(internalDateState)

    return {
      ...dateState,
      formattedValue: formatOptimizationsDateRange(dateState.value),
    }
  },
  (_, set, datePreset: SetStateAction<DatePreset<DYNAMIC_DATE_ID>>) => {
    set(internalDateState, datePreset)
  },
)

export const useHistoricalAnalysisDateAtom = () =>
  useAtom(historicalAnalysisDateAtom)

export const useHistoricalAnalysisDateValueSetter = () =>
  useSetAtom(historicalAnalysisDateAtom)
