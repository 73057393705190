import { type RouteObject } from 'react-router-dom'
import { DashboardComponent } from './components/DashboardComponent'

export const routes: RouteObject[] = [
  {
    index: true,
    Component: DashboardComponent,
  },
  {
    path: ':dashboardId',
    Component: DashboardComponent,
  },
]
