import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Button } from 'components/buttons'
import { type IconName } from 'components/Icon/Icon'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useSignOut } from 'hooks/useSignOut'
import { useMatch, useNavigate } from 'react-router-dom'
import { headerLinks } from '../consts'
import { OrganizationLegend } from '../OrganizationLegend'

interface MenuDrawerProps {
  isOpen: boolean
  onClose: () => void
}

interface NavLinkProps {
  label: string
  iconName: IconName
  to: string
  matchPath?: string
  onClick?: () => void
  isBeta?: boolean
}

const NavLink: React.FC<NavLinkProps> = ({
  label,
  iconName,
  to,
  matchPath,
  onClick,
  isBeta,
}) => {
  const match = useMatch({ path: matchPath ?? to })
  const navigate = useNavigate()

  return (
    <Button
      variant="ghost"
      colorScheme="grey"
      isSelected={!!match}
      onClick={() => {
        onClick?.()
        navigate(to)
      }}
      size="lg"
      justifyContent="start"
      leadingIcon={{
        name: iconName,
        size: 'medium',
      }}
    >
      <Flex alignItems="center" gap={1}>
        {label}
        {isBeta ? <Badge size="sm">Beta</Badge> : null}
      </Flex>
    </Button>
  )
}

export const MenuDrawer: React.FC<MenuDrawerProps> = ({ isOpen, onClose }) => {
  const signOut = useSignOut()
  const flags = useFeatureFlags()

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader py={12}>
          <OrganizationLegend />

          <DrawerCloseButton top={'44px'} />
        </DrawerHeader>

        <DrawerBody py={0}>
          <Flex flexDir="column" gap={4}>
            {headerLinks.map(
              ({
                path,
                label,
                featureFlag,
                matchPath,
                iconName,
                onClick,
                isBeta,
              }) =>
                (!featureFlag || flags[featureFlag]) && (
                  <NavLink
                    key={path}
                    label={label}
                    iconName={iconName}
                    to={path}
                    matchPath={matchPath}
                    onClick={onClick}
                    isBeta={isBeta}
                  />
                ),
            )}
          </Flex>
        </DrawerBody>

        <DrawerFooter>
          <Button
            w="full"
            size="lg"
            variant="solid"
            onClick={() => {
              signOut()
            }}
            colorScheme="primary"
          >
            Logout
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
