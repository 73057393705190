import { Alert } from 'components/Alert/Alert'
import { type AlertProps } from 'components/Alert/types'
import { useOptimizationConfig } from 'features/optimizations/graphql/useOptimizationConfig'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { formatMetric } from 'utils/numberFormats'

export const InfoAlert = (props: Omit<AlertProps, 'status' | 'content'>) => {
  const { data } = useOptimizationConfig()
  const guardrail = data?.viewer?.merchant.optimizationConfig.guardrail

  if (!guardrail) return null

  const formattedValue = formatMetric(METRIC_FORMAT.PERCENT_INT, guardrail)

  return (
    <Alert
      {...props}
      status="info"
      content={`The proposed marketing spend is based on a maximum change of ${formattedValue} in spend per market compared to the current spend for the selected period.`}
    />
  )
}
