import { type StyleFunctionProps, mode } from '@chakra-ui/theme-tools'

export const pinInputTheme = {
  variants: {
    outline: (props: Record<string, unknown> | StyleFunctionProps) => ({
      borderColor: mode('grey.500', 'grey.600')(props),
      backgroundColor: mode('white', 'gray.900')(props),
      color: mode('grey.800', 'grey.100')(props),
      _hover: {
        borderColor: mode('grey.600', 'grey.500')(props),
      },
      _focus: {
        borderColor: mode('primary.500', 'primary.400')(props),
        boxShadow: 'unset',
      },
      _invalid: {
        borderColor: mode('red.500', 'red.300')(props),
        boxShadow: 'unset',
      },
    }),
  },
}
