import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { isClerkAPIResponseError } from '@clerk/clerk-react/errors'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

const validationSchema = z.object({
  currentPassword: z.string().min(1, 'Current password is required'),
  newPassword: z.string().min(1, 'New password is required'),
})

type ValidationSchema = z.infer<typeof validationSchema>

export const PasswordResetModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: false,
  })
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const { isLoaded, user } = useUser()
  const toast = useToast()
  const [trackEvent] = useTrackEvent()
  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    shouldFocusError: true,
  })
  const {
    formState: { errors, isSubmitting },
    setError,
  } = methods

  function resetAndClose() {
    methods.reset()
    onClose()
  }

  async function onSubmit({ currentPassword, newPassword }: ValidationSchema) {
    try {
      await user?.updatePassword({
        currentPassword,
        newPassword,
        signOutOfOtherSessions: true,
      })
      toast({
        status: 'success',
        description: 'Your password has been updated successfully.',
        position: 'bottom',
      })
      trackEvent({
        eventName: 'Profile Password Updated',
        eventProperties: {},
      })
      resetAndClose()
    } catch (err) {
      if (isClerkAPIResponseError(err)) {
        // if current password is invalid, set error message on current password input
        if (err.errors[0].code === 'form_password_validation_failed') {
          setError('currentPassword', { message: err.errors[0].longMessage })
        }
        // otherwise set error message on new password input
        else {
          setError('newPassword', { message: err.errors[0].longMessage })
        }
      }
    }
  }

  return (
    <Box>
      <Button variant="link" colorScheme="primary" onClick={onOpen}>
        Reset password
      </Button>
      <Modal isOpen={isOpen} onClose={resetAndClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reset password</ModalHeader>
          <ModalCloseButton />
          <Form methods={methods} onSubmit={onSubmit}>
            <ModalBody>
              <Input
                colorMode="light"
                name="currentPassword"
                type={showCurrentPassword ? 'text' : 'password'}
                label="Current password"
                autoComplete="current-password"
                trailingIcon={{ name: 'VisibleIcon' }}
                onTrailingIconClick={() =>
                  setShowCurrentPassword((show) => !show)
                }
                wrapperProps={{ mb: 4 }}
                error={errors.currentPassword}
              />
              <Input
                colorMode="light"
                name="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                label="New password"
                trailingIcon={{ name: 'VisibleIcon' }}
                onTrailingIconClick={() => setShowNewPassword((show) => !show)}
                error={errors.newPassword}
              />
            </ModalBody>
            <ModalFooter>
              <Flex gap={2}>
                <Button
                  type="button"
                  colorScheme="gray"
                  variant="outline"
                  onClick={resetAndClose}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  variant="solid"
                  isDisabled={!isLoaded}
                >
                  Update password
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </Box>
  )
}
