import { keyframes } from '@chakra-ui/react'
import { Icon, type IconProps } from 'components/Icon/Icon'

type Props = Pick<IconProps, 'size' | 'customSize'>
const spin = keyframes`
  from {transform: rotate(0deg)}   
  to {transform: rotate(360deg)} 
`
const animation = `${spin} 1000ms infinite linear`

export const Loader = ({ size = 'large', customSize }: Props) => {
  return (
    <Icon
      name="LoaderIcon"
      animation={animation}
      size={size}
      customSize={customSize}
    />
  )
}
