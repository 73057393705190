import { type OptimizationChannel } from 'generated/graphql/graphql'
import { startCase } from 'lodash-es'
import { type OptionalKey } from 'types/optionalKey'

export const getChannelName = (
  channel: OptionalKey<OptimizationChannel, 'channelGroup'>,
) => `${startCase(channel.channel)} ${startCase(channel.funnelCampaign)}`

export const CHANNEL_ID_SEPARATOR = '_'

export const getChannelId = ({
  channelGroup,
  channel,
  funnelCampaign,
}: OptimizationChannel) =>
  `${channelGroup}${CHANNEL_ID_SEPARATOR}${channel}${CHANNEL_ID_SEPARATOR}${funnelCampaign}`
