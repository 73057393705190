import { DISPLAY_FORMAT } from 'constants/displayFormats'
import { type BadgeProps } from '@chakra-ui/react'
import { Badge } from 'components/Badge'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { type FC } from 'react'
import { calcDifference, type calcDifferenceProps } from 'utils/calcDifference'

type Props = calcDifferenceProps & {
  badgeProps?: BadgeProps
  hasIcon?: boolean
}

export const DiffBadge: FC<Props> = ({
  badgeProps,
  hasIcon = true,
  ...props
}) => {
  const { color, iconName, formattedDifference, formattedValue } =
    calcDifference(props)

  const colorWithoutShade = color.split('.')[0]
  const bgColor =
    colorWithoutShade === 'grey' ? 'grey.200' : `${colorWithoutShade}.50`
  const textColor =
    colorWithoutShade === 'green'
      ? 'green.600'
      : colorWithoutShade === 'grey'
        ? 'grey.800'
        : `${colorWithoutShade}.500`

  return (
    <Badge
      display="flex"
      alignItems="center"
      borderRadius={0.5}
      bg={bgColor}
      color={textColor}
      gap="2px"
      p="2px 6px"
      h={5}
      fontSize="xs"
      lineHeight="16px"
      fontWeight={400}
      {...badgeProps}
    >
      <Typography
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        color="inherit"
      >
        {(props.displayFormat === DISPLAY_FORMAT.VALUE
          ? formattedValue
          : formattedDifference) ?? 'N/A'}
      </Typography>
      {hasIcon && <Icon name={iconName} size="small" />}
    </Badge>
  )
}
