import { Box, Divider, Flex } from '@chakra-ui/react'
import { ReportViewStatus } from 'features/reports/atoms/reportViewStateAtom'
import { ReportFiltersContainer } from 'features/reports/components/Filters/ReportFiltersContainer'
import { ReportStateSync } from 'features/reports/components/ReportStateSync'
import { useReportStatus } from 'features/reports/hooks/useReportStatus'
import { LoaderPage } from 'shared/LoaderPage'
import { ReportPanel } from '../../components/ReportPanel/ReportPanel'
import { DataSection } from './DataSection'
import { NewReportHeader } from './NewReportHeader'

export const NewReportView = () => {
  return (
    <>
      <ReportStateSync />
      <NewReportViewContent />
    </>
  )
}

const NewReportViewContent = () => {
  const reportStatus = useReportStatus()

  if (reportStatus === ReportViewStatus.Loading) return <LoaderPage />

  return (
    <Flex direction="column" flex={1} h="full" zIndex={0} overflowY="hidden">
      <NewReportHeader />
      <Flex flex={1} h="full" w="full" overflowY="hidden" maxW="full">
        <Flex
          direction="column"
          w="100%"
          flexBasis={0}
          flexShrink={1}
          flexGrow={1}
          h="full"
          overflowY="auto"
        >
          <Box position="sticky" top={0} zIndex={2} bg="white">
            <ReportFiltersContainer />

            <Divider />
            <DataSection />
          </Box>
        </Flex>

        <ReportPanel />
      </Flex>
    </Flex>
  )
}
