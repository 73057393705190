import { type MutationFunctionOptions, useMutation } from '@apollo/client'
import { useUser } from '@clerk/clerk-react'
import { graphql } from 'generated/graphql'
import { type UpdateTeamMembershipMutation } from 'generated/graphql/graphql'
import { TEAM_BY_ID_QUERY } from 'graphql/teams/useTeamById'
import { USER_TEAMS_QUERY } from 'graphql/teams/useUserTeams'
import { cloneDeep, set } from 'lodash-es'

const REMOVE_TEAM_MEMBER_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateTeamMembership($payload: UpdateTeamMembershipInput!) {
    updateTeamMembership(params: $payload) {
      ...Teams_TeamMemberFields
    }
  }
`)

type RemoveTeamMemberParams = {
  teamId: string
  clerkUserId: string
} & MutationFunctionOptions<UpdateTeamMembershipMutation>

export const useRemoveTeamMember = (): [typeof removeMember, typeof state] => {
  const [mutation, state] = useMutation(REMOVE_TEAM_MEMBER_MUTATION)
  const { user } = useUser()

  const removeMember = async ({
    teamId,
    clerkUserId,
    ...rest
  }: RemoveTeamMemberParams) => {
    const removedMember = await mutation({
      ...rest,
      variables: {
        payload: {
          teamId,
          clerkUserId,
          action: 'REMOVE',
        },
      },
      optimisticResponse: {
        updateTeamMembership: {
          __typename: 'User',
          externalId: clerkUserId,
          id: 'optimistic',
        },
      },
      update: (cache, { data }) => {
        if (!data) return

        // Update user teams query if user is the current user
        cache.updateQuery({ query: USER_TEAMS_QUERY }, (queryData) => {
          if (
            !queryData?.viewer ||
            data.updateTeamMembership.externalId !== user?.id
          ) {
            return queryData
          }

          const teams = queryData.viewer.teams.filter(
            (team) => team.id !== teamId,
          )
          const queryDataClone = cloneDeep(queryData)

          set(queryDataClone, 'viewer.teams', teams)

          return queryDataClone
        })
        // Update team by id query
        cache.updateQuery(
          {
            query: TEAM_BY_ID_QUERY,
            variables: { id: teamId },
          },
          (queryData) => {
            if (!queryData?.viewer?.team) return queryData

            const queryDataClone = cloneDeep(queryData)

            set(
              queryDataClone,
              'viewer.team.members',
              queryData.viewer.team.members.filter(
                (member) => member.externalId !== clerkUserId,
              ),
            )

            return queryDataClone
          },
        )
      },
    })

    return removedMember
  }

  return [removeMember, state]
}
