import { type DropdownOption } from 'components/Dropdown'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { type FC } from 'react'
import { type FieldError } from 'react-hook-form'
import { normalizedMarketingCostDimensions } from '../consts'
import { type MarketingCostDimension } from '../types'

type Props = {
  dimensionId: MarketingCostDimension
  options: DropdownOption[]
  isLoading: boolean
  selected?: string
  error?: FieldError
  updateDropdown: (
    dimensionId: MarketingCostDimension,
    newSelected: string,
  ) => void
}

export const MarketingCostCombobox: FC<Props> = ({
  dimensionId,
  selected,
  options,
  error,
  isLoading,
  updateDropdown,
}) => {
  const dimensionName = normalizedMarketingCostDimensions[dimensionId].name

  return (
    <ComboBox
      selectionMode={SELECTION_MODE.SINGLE}
      label={dimensionName}
      options={options}
      selected={selected}
      error={error}
      placeholder={`Select ${dimensionName.toLowerCase()}`}
      isLoading={isLoading}
      isFullHeight
      setSelected={(option) => updateDropdown(dimensionId, option)}
    />
  )
}
