import {
  type COMPARE_DYNAMIC_DATE_ID,
  type DYNAMIC_DATE_ID,
  getDatePreset,
} from 'constants/getDatePresets'
import { DatePreview } from 'components/Datepicker/DatePreview'
import { RangeDatepicker } from 'components/Datepicker/RangeDatepicker/RangeDatepicker'
import { type DatepickerApplyStateProps } from 'components/Datepicker/RangeDatepicker/types'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import {
  compareDynamicDates,
  dynamicDates,
  useDateState,
  useSetDateState,
} from 'features/reports/hooks/useDateState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { isEqual } from 'lodash-es'
import { toUTCDateRange } from 'utils/dateRange'

export const DateRangePicker = () => {
  const { dynamicDate, dateRange, compareDateRange, compareDynamicDate } =
    useDateState()
  const setDateState = useSetDateState()
  const { timezone } = useMerchantInfo()
  const dateLabel = dynamicDate ? getDatePreset(dynamicDate)?.name : undefined
  const [trackEvent] = useTrackEvent()

  const onApply = ({
    dateRange: newDateRange,
    dynamicDate: newDynamicDate,
    compareDateRange: newCompareDateRange,
    compareDynamicDate: newCompareDynamicDate,
  }: DatepickerApplyStateProps) => {
    const newUTCDateRange = toUTCDateRange(newDateRange, timezone)
    const newUTCCompareDateRange = toUTCDateRange(newCompareDateRange, timezone)

    const newDateValues = {
      dynamicDate: newDynamicDate as DYNAMIC_DATE_ID,
      dateRange: newUTCDateRange,
      compareDateRange: newUTCCompareDateRange,
      compareDynamicDate: newCompareDynamicDate as COMPARE_DYNAMIC_DATE_ID,
    } satisfies Parameters<typeof setDateState>[0]

    // Update only if there has been some change
    if (
      !isEqual(newDateValues, {
        dynamicDate,
        dateRange,
        compareDateRange,
        compareDynamicDate,
      })
    ) {
      trackEvent({
        eventName: 'Report Dates Changed',
        eventProperties: newDateValues,
      })

      setDateState(newDateValues)
    }
  }

  const hasCompareDate = getIsPopulatedDateRange(compareDateRange)

  return (
    <RangeDatepicker
      popupButton={
        <DatePreview
          actualDate={dateRange}
          compareDate={hasCompareDate ? compareDateRange : undefined}
          badgeLabel={dateLabel}
        />
      }
      dateRange={dateRange}
      dynamicDate={dynamicDate}
      dynamicDateOptions={dynamicDates}
      compareDynamicDateOptions={compareDynamicDates}
      compareDateRange={compareDateRange}
      compareDynamicDate={compareDynamicDate}
      key={`${dateRange.join(',')}:${compareDateRange.join(',')}:${dynamicDate}:${compareDynamicDate}`}
      onApply={onApply}
    />
  )
}
