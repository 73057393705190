import { type CompareUnit } from 'graphql/reports/types'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import { getAnalyticsConfigFromStore } from '../utils/utils'
import { useIsNewReportFlow } from './useIsNewReportFlow'

const focusCompareUnitStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('compareUnit'),
)

export const useCompareUnitState = () => useAtomValue(focusCompareUnitStateAtom)

export const useSetCompareUnitState = () => {
  const setCompareUnitStateAtom = useSetAtom(focusCompareUnitStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setCompareUnitState = useCallback(
    (payload: SetStateAction<CompareUnit>) => {
      setCompareUnitStateAtom(payload)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.compareUnit = payload(
            newAnalyticsConfig.compareUnit,
          )
        } else {
          newAnalyticsConfig.compareUnit = payload
        }

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setCompareUnitStateAtom],
  )

  return setCompareUnitState
}
