import { tagAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const _disabled = {
  bg: 'grey.100',
  color: 'grey.400',
  borderColor: 'grey.400',
  ariaDisabled: true,
  cursor: 'default',
}

const baseStyle = definePartsStyle({
  container: {
    fontSize: 'xs',
    fontWeight: 400,
    borderRadius: 0.5,
    minW: 'fit-content',
    _disabled,
  },
  label: { fontSize: 'inherit', fontWeight: 'inherit', color: 'inherit' },
  closeButton: {
    h: 'full',
    color: 'inherit',
    borderRadius: 0.5,
    opacity: 1,
    _hover: { opacity: 1 },
  },
})

const sizes = {
  sm: definePartsStyle({
    container: {
      px: 1,
      minH: 4,
    },
    closeButton: { w: 4 },
  }),
  md: definePartsStyle({
    container: {
      px: 1.5,
      minH: 6,
    },
    closeButton: { w: 6 },
  }),
  lg: definePartsStyle({
    container: {
      px: 2,
      minH: 8,
    },
    closeButton: { w: 8 },
  }),
}

const variants = {
  solid: definePartsStyle(({ colorScheme }) => {
    switch (colorScheme) {
      case 'primary':
        return {
          container: {
            bg: 'primary.100',
            color: 'primary.600',
            _disabled,
          },
          closeButton: {
            _hover: { bg: 'primary.200', color: 'primary.700', _disabled },
          },
        }

      case 'grey':
        return {
          container: {
            bg: 'grey.200',
            color: 'grey.800',
            _disabled,
          },
          closeButton: {
            _hover: { bg: 'grey.300', color: 'grey.900', _disabled },
          },
        }
      case 'purple':
        return {
          container: {
            bg: 'purple.100',
            color: 'purple.600',
            _disabled,
          },
          closeButton: {
            _hover: { bg: 'purple.200', color: 'purple.700', _disabled },
          },
        }
      default:
      case 'blackAlpha':
        return {
          container: {
            bg: 'grey.800',
            color: 'white',
            _disabled,
          },
          closeButton: {
            opacity: 1,
            _hover: { bg: 'grey.700', _disabled },
          },
        }
    }
  }),
  selectable: definePartsStyle(() => {
    return {
      container: {
        bg: 'grey.100',
        color: 'grey.800',
        border: '1px solid',
        borderColor: 'grey.800',
        _hover: { bg: 'grey.200', cursor: 'pointer', _disabled },
        _selected: {
          bg: 'grey.800',
          color: 'white',
          _hover: { bg: 'grey.700' },
          _disabled,
        },
        _disabled,
      },
    }
  }),
  outline: definePartsStyle(() => ({})),
}

export const tagTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
})
