import { Tr, Td, Skeleton } from '@chakra-ui/react'

interface Props {
  columns: number
  rows: number
}

interface ColumnProps {
  columns: number
}

export const SkeletonTableRows = ({ columns, rows }: Props) =>
  Array.from({ length: rows }).map((_, index) => (
    <Tr key={index}>
      <Columns columns={columns} />
    </Tr>
  ))

const Columns = ({ columns }: ColumnProps) => {
  return Array.from({ length: columns }).map((_, index) => (
    <Td key={index} py={3} px={2}>
      <Skeleton w="full" h={8} />
    </Td>
  ))
}
