import { type FC } from 'react'
import { FILTER_OPTION } from './consts'
import { FilterMenu } from './FilterMenu'
import { FilterOptionLabels } from './filterOptions/FilterOptionLabels'
import { FilterOptionOwner } from './filterOptions/FilterOptionOwner'
import { FilterOptionVisibility } from './filterOptions/FilterOptionVisibility'
import { type ReportListFilterPickerProps } from './types'

export const FilterOptionPicker: FC<ReportListFilterPickerProps> = ({
  ...initialProps
}) => {
  const { filters, selectedFilterOption, setSelectedFilterOption } =
    initialProps

  const noFilterSelectedComponent = (
    <FilterMenu setSelectedFilterOption={setSelectedFilterOption} />
  )

  if (!selectedFilterOption) return noFilterSelectedComponent

  const selectedIds = filters[selectedFilterOption].map((label) => label.id)

  const props = {
    ...initialProps,
    selectedFilterOption,
    selectedIds,
  }

  const filterOptionComponentMap = {
    [FILTER_OPTION.VISIBILITY]: <FilterOptionVisibility {...props} />,
    [FILTER_OPTION.LABELS]: <FilterOptionLabels {...props} />,
    // [FILTER_OPTION.DATE_CREATED]: ,
    // [FILTER_OPTION.DATE_UPDATED]: ,
    [FILTER_OPTION.OWNER]: <FilterOptionOwner {...props} />,
  }

  return (
    filterOptionComponentMap[selectedFilterOption] ?? noFilterSelectedComponent
  )
}
