import { useMutation } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { graphql } from 'generated/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNavigate } from 'react-router'

const DELETE_TEAM_BY_ID_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteMerchantTeamById($teamId: ID!) {
    deleteTeam(id: $teamId) {
      id
      __typename
    }
  }
`)

export const useDeleteTeamById = () => {
  const [mutation, state] = useMutation(DELETE_TEAM_BY_ID_MUTATION)
  const toast = useToast()
  const navigate = useNavigate()
  const [trackEvent] = useTrackEvent()

  const deleteTeam = async (teamId: string) => {
    return mutation({
      variables: {
        teamId,
      },
      optimisticResponse: {
        deleteTeam: {
          id: teamId,
          __typename: 'Team',
        },
      },
      update: (cache, { data }) => {
        // Optimistically remove the report from the cache
        if (!data?.deleteTeam) return

        cache.evict({
          id: cache.identify(data?.deleteTeam),
        })
        cache.gc()
      },
      onCompleted: () => {
        navigate('/settings/organisation/general')
        trackEvent({
          eventName: 'Team Deleted',
          eventProperties: {
            teamId,
          },
        })
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'Could not delete team. Please try again',
        })
      },
    })
  }

  return [deleteTeam, state] as const
}
