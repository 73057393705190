import {
  ListItem,
  OrderedList,
  PopoverBody,
  PopoverHeader,
  PopoverTrigger,
  UnorderedList,
} from '@chakra-ui/react'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { Typography } from 'components/Typography'
import { type PropsWithChildren } from 'react'
import Markdown, { type Components } from 'react-markdown'

interface DescriptionItem {
  name: string
  description: string
  descriptionMarkdown?: string
}
interface DescriptionContentProps<T extends DescriptionItem> {
  item: T
}

interface ExtendedDescriptionTooltipProps<T extends DescriptionItem> {
  item: T
}

const TEMPORARY_DISABLE_POPOVER = true

export const ExtendedDescriptionTooltip = <T extends DescriptionItem>({
  item,
  children,
}: PropsWithChildren<ExtendedDescriptionTooltipProps<T>>) => {
  // TODO DELETE TEMPORARY_DISABLE_POPOVER once ready
  if (!item.descriptionMarkdown || TEMPORARY_DISABLE_POPOVER) {
    return (
      <Tooltip
        key={`${item.name}`}
        label={`${item.description}`}
        closeOnScroll
        placement="left-start"
        closeOnClick={false}
        w="100%"
      >
        {children}
      </Tooltip>
    )
  }

  return <PopoverTrigger>{children}</PopoverTrigger>
}

export const DescriptionMarkdownContent = <T extends DescriptionItem>({
  item,
}: DescriptionContentProps<T>) => {
  if (!item.descriptionMarkdown) return null

  return (
    <>
      <PopoverHeader>
        <Typography fontSize="md" lineHeight={6} color="gray.800" mb={4}>
          {item.name}
        </Typography>
      </PopoverHeader>
      <PopoverBody>
        <Markdown components={markdownComponents}>
          {item.descriptionMarkdown}
        </Markdown>
      </PopoverBody>
    </>
  )
}

const markdownComponents: Partial<Components> = {
  h4: ({ children }) => (
    <Typography
      fontSize="sm"
      lineHeight={5}
      color="gray.700"
      fontWeight={600}
      mt={6}
      _first={{ mt: 0 }}
    >
      {children}
    </Typography>
  ),
  p: ({ children }) => (
    <Typography fontSize="sm" lineHeight={5} color="gray.700" fontWeight={400}>
      {children}
    </Typography>
  ),
  strong: ({ children }) => (
    <Typography
      fontWeight={700}
      // For when we have a code block inside a strong tag, we override the codes font weight
      style={{ '--font-weight': 700 } as React.CSSProperties}
    >
      {children}
    </Typography>
  ),
  ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
  ol: ({ children }) => <OrderedList>{children}</OrderedList>,
  li: ({ children }) => (
    <ListItem fontSize="sm" mb={1.5} _last={{ mb: 0 }}>
      {children}
    </ListItem>
  ),
  code: ({ children }) => (
    <Typography
      as="span"
      color="purple.500"
      fontSize="13px"
      fontWeight={`var(--font-weight, 500)`}
      lineHeight={5}
      fontFamily="Fira Code"
    >
      {children}
    </Typography>
  ),
}
