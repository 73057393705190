import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { Alert } from 'components/Alert/Alert'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { MultiCheckboxSelect } from 'components/MultiCheckboxSelect'
import type { SelectedItem } from 'components/MultiCheckboxSelect/types'
import { Typography } from 'components/Typography'
import {
  useMetricsState,
  useSetMetricsState,
} from 'features/reports/hooks/useMetricsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import {
  DEFAULT_ATTRIBUTION,
  useNormalizedAttributionModels,
} from 'graphql/statistics/useAttributionModels'
import {
  getMetricKeyWithoutAttribution,
  type AttributedMetric,
} from 'graphql/statistics/useMetrics'
import { isEqual, sortBy, uniqBy } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { ReportDetailRow } from '../ReportDetailsRow/ReportDetailsRow'

const infoDetails = {
  tooltipText:
    "An attribution model defines how credit is assigned to different touchpoints such as channels and ads in the user's path to completing an important action such as placing an order.",
  link: 'https://dema.ai',
}

export const AttributionSection = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [draftItems, setDraftItems] = useState<SelectedItem[]>([])
  const normalizedAttributionModels = useNormalizedAttributionModels()
  const attributionColumns = sortBy(
    Object.values(normalizedAttributionModels),
    'id',
  )
  const { metrics } = useMetricsState()
  const [trackEvent] = useTrackEvent()
  const setMetrics = useSetMetricsState()
  const selectedAttributions = useMemo(
    () =>
      metrics.filter(
        (metric) => !!(metric as AttributedMetric).attributionId,
      ) as AttributedMetric[],
    [metrics],
  )
  const attributionMetricRows = useMemo(
    () =>
      uniqBy(selectedAttributions, (val) =>
        getMetricKeyWithoutAttribution(val),
      ).map((metric) => ({
        id: getMetricKeyWithoutAttribution(metric),
        label: metric.label,
      })),
    [selectedAttributions],
  )

  // Check if any row is empty
  const emptyRows =
    new Set(draftItems.map((item) => item.rowId).filter((item) => item)).size <
    attributionMetricRows.length

  const noData = attributionMetricRows.length === 0

  const activeAttributions = useMemo(() => {
    const labelSet = new Set()
    const labels = selectedAttributions.reduce<string[]>(
      (acc, { attributionId }) => {
        const label =
          normalizedAttributionModels[attributionId ?? DEFAULT_ATTRIBUTION.id]
            ?.label ?? ''

        if (label && !labelSet.has(label)) {
          acc.push(label)
          labelSet.add(label)
        }

        return acc
      },
      [],
    )

    if (labels.length > 0) {
      return labels.sort()
    }

    return [DEFAULT_ATTRIBUTION.label]
  }, [selectedAttributions, normalizedAttributionModels])

  // Set initial selected items per row
  useEffect(() => {
    if (attributionColumns.length <= 0 || attributionMetricRows.length <= 0) {
      setDraftItems([])
    } else {
      setDraftItems(
        selectedAttributions.map((attribution) => ({
          rowId: attribution.groupKey ?? attribution.key,
          columnId: attribution.attributionId ?? '',
        })),
      )
    }
  }, [
    selectedAttributions,
    attributionColumns.length,
    attributionMetricRows.length,
  ])

  const onApply = () => {
    onClose()

    // if the new selectedAttributions is invalid
    if (emptyRows) {
      setDraftItems(
        selectedAttributions.map((attribution) => {
          return {
            rowId: attribution.groupKey ?? attribution.key,
            columnId: attribution.attributionId ?? '',
          }
        }),
      )

      return
    }

    const selected = draftItems
      .map((item) => {
        return `${item.rowId}:${item.columnId}`
      })
      .sort()

    const selectedAttributionsIds = selectedAttributions
      .map((m) => m.key)
      .sort()

    if (isEqual(selectedAttributionsIds, selected)) {
      return
    }

    const updatedMetrics = [
      ...metrics
        .filter((metric) => !(metric as AttributedMetric).attributionId)
        .map((metric) => metric.key),
      ...selected,
    ]

    setMetrics(updatedMetrics)

    trackEvent({
      eventName: 'Report Attribution Changed',
      eventProperties: {
        oldAttributionIds: selectedAttributions.map(
          (attribution) => attribution.id,
        ),
        newAttributionIds: selectedAttributionsIds,
      },
    })
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onApply}
      size="small"
      placement="left"
      isLazy
    >
      <PopoverTrigger>
        <ReportDetailRow
          title="Attribution"
          label={activeAttributions.join(', ')}
          iconName="ChartPieIcon"
          infoDetails={infoDetails}
        />
      </PopoverTrigger>

      <Portal>
        <MenuOverlay isOpen={isOpen} onClick={onClose} />
        <PopoverContent w="unset">
          <PopoverBody>
            {noData ? (
              <Typography p={2} fontSize="sm" fontWeight={500}>
                Attribution is not applicable for any of the selected metrics
              </Typography>
            ) : (
              <>
                <MultiCheckboxSelect
                  leftColumnHeader="Metric"
                  rows={attributionMetricRows}
                  columns={attributionColumns}
                  selectedItems={draftItems}
                  setSelectedItems={setDraftItems}
                />

                {emptyRows && (
                  <Box mt={6}>
                    <Alert
                      content="Select at least one attribution model per row"
                      status="error"
                    />
                  </Box>
                )}
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
