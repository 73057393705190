import { TooltipMetricRow } from 'features/dashboard/components/ChartTooltip/shared/TooltipMetricRow'
import { calculateEpRoas } from 'features/optimizations/utils/calculateEpRoas'
import { METRIC_FORMAT } from 'graphql/statistics/constants'
import { renderToString } from 'react-dom/server'
import { colorTheme } from 'ui/theme/colors'
import { formatMetric } from 'utils/numberFormats'

interface TooltipFormatterProps {
  currency: string | undefined
}

export const getTooltipFormatter = ({ currency }: TooltipFormatterProps) => {
  return function (this: Highcharts.TooltipFormatterContextObject) {
    const spend = Number(this.x)
    const ngp3 = Number(this.y)
    const eproas = calculateEpRoas(ngp3, spend)

    const element = (
      <>
        <div
          style={{
            marginBottom: '8px',
            color: colorTheme.grey[700],
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '14px',
          }}
        >
          {this.series.name}
        </div>

        <div style={{ marginBottom: '12px' }}>
          <TooltipMetricRow
            metricName="Marketing spend"
            value={
              isFinite(spend)
                ? formatMetric(METRIC_FORMAT.CURRENCY, spend, currency)
                : 'N/A'
            }
          />
          <TooltipMetricRow
            metricName="Net gross profit 3"
            value={
              isFinite(ngp3)
                ? formatMetric(METRIC_FORMAT.CURRENCY, ngp3, currency)
                : 'N/A'
            }
          />
          <TooltipMetricRow
            metricName="epROAS"
            value={
              isFinite(eproas)
                ? formatMetric(METRIC_FORMAT.PERCENT, eproas, currency)
                : 'N/A'
            }
          />
        </div>
      </>
    )

    // Used as highcharts requires a string and not an Element
    return renderToString(element)
  }
}
