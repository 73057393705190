import { checkboxAnatomy as parts } from '@chakra-ui/anatomy'
import { defineStyle } from '@chakra-ui/react'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle(() => {
  return {
    control: {
      borderWidth: 1,
      borderColor: 'grey.800',
      _disabled: {
        borderColor: 'grey.300',
      },
      _checked: {
        bg: 'grey.800',
        borderColor: 'grey.800',
        _hover: {
          bg: 'grey.900',
          borderColor: 'grey.900',
        },
      },
    },
  }
})

const sizes = {
  md: definePartsStyle({
    label: defineStyle({
      fontSize: 'sm',
    }),
  }),
}

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'grey',
  },
  sizes,
})
