import { Tag } from 'components/Tag/Tag'
import { type ParameterTypeId } from '../shared/consts'
import { MappingsActionType, useMappingsAtom } from '../shared/state'
import { getMappingsLabel } from '../shared/utils'

interface Props {
  value: string
  type: ParameterTypeId
  isReadOnly?: boolean
}

export const UnhandledMappingsCell = ({ value, type, isReadOnly }: Props) => {
  const [mappingState, setMappingsState] = useMappingsAtom()
  const isSelected = mappingState[type] === value

  const onClick = () => {
    if (isReadOnly) return

    setMappingsState({
      type: MappingsActionType.SetMappingTypeValue,
      payload: {
        type,
        value: isSelected ? undefined : value,
      },
    })
  }

  return (
    <Tag
      variant="selectable"
      size="lg"
      label={getMappingsLabel(value)}
      minW="full"
      maxW="150px"
      aria-disabled={isReadOnly}
      aria-selected={isSelected}
      onClick={onClick}
    />
  )
}
