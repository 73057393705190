import { Flex, Skeleton } from '@chakra-ui/react'
import { WidgetErrorState } from 'components/Error/WidgetErrorState'
import { type HistoricalMissedPotentialAggregated } from 'generated/graphql/graphql'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsPatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import { BaseWidget } from '../../../../components/BaseWidget'
import { getWaterfallChart } from './getWaterfallChart'

HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HighchartsPatternFill(Highcharts)
HighchartsMore(Highcharts) // needed to load in the waterfall chart

interface Props {
  data: HistoricalMissedPotentialAggregated | undefined
  isLoading: boolean
  hasError: boolean
}

export const ProfitBreakdown = ({ data, isLoading, hasError }: Props) => {
  const { currency } = useMerchantInfo()

  return (
    <BaseWidget title="Missed potential">
      <Flex flex={1} minH="272px" overflowX="hidden" w="full" gap={12}>
        {isLoading ? (
          <Skeleton h="full" w="full" />
        ) : hasError || !data ? (
          <WidgetErrorState />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={getWaterfallChart({
              data,
              currency: currency,
            })}
            containerProps={{ style: { width: '100%' } }}
          />
        )}
      </Flex>
    </BaseWidget>
  )
}
