import { type Filter } from 'constants/types'
import { analyticsConfigAtom } from 'features/reports/atoms/reportViewStateAtom'
import {
  getAnalyticsConfigFromStore,
  getValidDimensions,
  getValidMetricProcessors,
  getValidMetrics,
} from 'features/reports/utils/utils'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { normalizedDimensionsAtom } from 'graphql/statistics/useDimensions'
import { normalizedMetricsAtom } from 'graphql/statistics/useMetrics'
import { type SetStateAction, atom, useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { dimensionsStateAtom } from '../useDimensionsState'
import { useIsNewReportFlow } from '../useIsNewReportFlow'
import { metricsStateAtom } from '../useMetricsState'

const focusFiltersStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('filters'),
)

export const filtersStateAtom = atom(
  (get) => {
    const filters = get(focusFiltersStateAtom)
    const { dimensions } = get(dimensionsStateAtom)
    const { metrics } = get(metricsStateAtom)
    const normalizedMetrics = get(normalizedMetricsAtom)
    const normalizedDimensions = get(normalizedDimensionsAtom)

    const validMetricProcessors = getValidMetricProcessors(dimensions)
    const getValidFilters = (filters: Record<string, Filter[]>) => {
      return Object.entries(filters).reduce(
        (acc, [key, filter]) => {
          const dimension = normalizedDimensions[key]

          if (dimension) {
            const validDimensions = getValidDimensions([dimension], metrics)

            if (validDimensions.length === 0) {
              return acc
            }

            acc[key] = filter
          }
          const metric = normalizedMetrics[key]

          if (!metric || !metrics.includes(metric)) {
            return acc
          }

          const validMetrics = getValidMetrics(
            [metric],
            normalizedMetrics,
            validMetricProcessors,
          )

          if (validMetrics.length === 0) {
            return acc
          }

          acc[key] = filter

          return acc
        },
        {} as Record<string, Filter[]>,
      )
    }

    return getValidFilters(filters)
  },
  (_, set, filtersState: SetStateAction<Record<string, Filter[]>>) => {
    set(focusFiltersStateAtom, filtersState)
  },
)

export const useFiltersState = () => useAtomValue(filtersStateAtom)

export const useSetFiltersState = () => {
  const setFiltersStateAtom = useSetAtom(filtersStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setFiltersState = useCallback(
    async (payload: SetStateAction<Record<string, Filter[]>>) => {
      setFiltersStateAtom(payload)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()

        if (typeof payload === 'function') {
          newAnalyticsConfig.filters = payload(newAnalyticsConfig.filters)
        } else {
          newAnalyticsConfig.filters = payload
        }

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [isNewReportFlow, setFiltersStateAtom, createAnalyticsConfig],
  )

  return setFiltersState
}
