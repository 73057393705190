import { type ConversationMessageFieldsFragment } from 'generated/graphql/graphql'
import { v4 as uuid } from 'uuid'

export const getOptimisticMessage = ({
  message,
}: {
  message: string
}): ConversationMessageFieldsFragment => {
  return {
    id: uuid(),
    author: {
      id: 'user',
      name: 'User',
      role: 'User',
      __typename: 'Author' as const,
    },
    items: [
      {
        __typename: 'TextMessageItem' as const,
        id: uuid(),
        text: message,
      },
    ],
    __typename: 'Message' as const,
    createdAt: new Date().toISOString(),
  }
}
