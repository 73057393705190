import {
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { MenuOverlay } from 'components/Dropdown/components/MenuOverlay'
import { Icon } from 'components/Icon/Icon'
import { type SegmentationSetByIdQueryResult } from 'features/productFeed/graphql/useSegmentationSetById'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import type React from 'react'
import { type UseFieldArrayReturn } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { type RuleForm } from './types'
import { getEmptyRule } from './utils'

interface RuleContextMenuProps {
  methods: UseFieldArrayReturn<
    {
      rules: RuleForm[]
    },
    'rules',
    'internal_id'
  >
  index: number
  segmentationSet: SegmentationSetByIdQueryResult
}

export const RuleContextMenu: React.FC<RuleContextMenuProps> = ({
  methods,
  index,
  segmentationSet,
}) => {
  const [trackEvent] = useTrackEvent()
  const rule = methods.fields[index]

  return (
    <Menu size="sm" placement="bottom-end" isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            colorScheme="gray"
            variant="ghost"
            size="sm"
            _groupHover={{ visibility: 'visible' }}
            icon={
              <Icon
                name="HorizontalMenuIcon"
                tooltipLabel="More actions"
                size="small"
              />
            }
          />

          <MenuOverlay isOpen={isOpen} />
          <MenuList onClick={(e) => e.stopPropagation()}>
            <MenuItem
              icon={<Icon name="InsertTopIcon" size="small" />}
              onClick={() => {
                methods.insert(index, getEmptyRule())
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Inserted',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                    rulePosition: 'before',
                  },
                })
              }}
            >
              Insert rule before
            </MenuItem>
            <MenuItem
              icon={<Icon name="InsertBottomIcon" size="small" />}
              onClick={() => {
                methods.insert(index + 1, getEmptyRule())
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Inserted',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                    rulePosition: 'after',
                  },
                })
              }}
            >
              Insert rule after
            </MenuItem>
            <MenuItem
              icon={<Icon name="Copy2Icon" size="small" />}
              onClick={() => {
                methods.insert(index + 1, {
                  ...rule,
                  id: uuid(),
                })
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Duplicated',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                  },
                })
              }}
            >
              Duplicate rule
            </MenuItem>

            <MenuDivider />

            <MenuItem
              icon={<Icon name="ArrowUpIcon" size="small" />}
              isDisabled={index === 0}
              onClick={() => {
                methods.move(index - 1, index)
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Moved',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                    ruleDirection: 'up',
                  },
                })
              }}
            >
              Move up
            </MenuItem>
            <MenuItem
              icon={<Icon name="ArrowDownIcon" size="small" />}
              isDisabled={index === methods.fields.length - 1}
              onClick={() => {
                methods.move(index + 1, index)
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Moved',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                    ruleDirection: 'down',
                  },
                })
              }}
            >
              Move down
            </MenuItem>

            <MenuDivider />

            <MenuItem
              icon={<Icon name="TrashIcon" size="small" />}
              onClick={() => {
                methods.remove(index)
                trackEvent({
                  eventName: 'ProductFeed Segmentation Rule Deleted',
                  eventProperties: {
                    segmentationSetId: segmentationSet.id,
                    segmentationSetName: segmentationSet.name,
                  },
                })
              }}
            >
              Delete rule
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
