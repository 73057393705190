import { Box, Flex, Image } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { Typography } from 'components/Typography'
import { DASHBOARD_ID } from 'features/dashboard/dashboards/consts'
import { selectedDashboardIdAtom } from 'features/dashboard/hooks/useDashboardState'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useSetAtom } from 'jotai'
import { useNavigate } from 'react-router'

export const NotFound: React.FC = () => {
  const navigate = useNavigate()
  const setSelectedDashboardId = useSetAtom(selectedDashboardIdAtom)

  return (
    <Flex flex={1} alignItems="center" justifyContent="center" p={8}>
      <Flex
        maxW="720px"
        alignItems="center"
        flexDir={{ base: 'column-reverse', lg: 'row' }}
        gap={{ base: 8, lg: '100px' }}
      >
        <Box>
          <Typography color="gray.700" fontSize="3xl" lineHeight={9}>
            We looked everywhere...
          </Typography>
          <Typography my={4} fontSize="sm" color="gray.700" lineHeight={5}>
            ...but we can’t seem to find the view that your are looking for.
            Here are some helpful links instead:
          </Typography>

          <Flex flexDir="column" gap={2} alignItems="start">
            <Button
              size="md"
              variant="link"
              onClick={() => navigate(REPORT_PAGES.BASE)}
            >
              Start report
            </Button>
            <Button
              size="md"
              variant="link"
              onClick={() => {
                setSelectedDashboardId(DASHBOARD_ID.OVERVIEW)
                navigate('/')
              }}
            >
              Overview dashboard
            </Button>
            <Button
              size="md"
              variant="link"
              onClick={() => {
                setSelectedDashboardId(DASHBOARD_ID.MARKETING)
                navigate('/')
              }}
            >
              Marketing dashboard
            </Button>
          </Flex>
        </Box>

        <Image src="/images/Binocular.svg" alt="Not found" boxSize="280px" />
      </Flex>
    </Flex>
  )
}
