import { Flex } from '@chakra-ui/react'
import type React from 'react'
import { LeftSidebar } from './components/LeftSidebar/LeftSidebar'
import { PredictionContent } from './components/PredictionContent/PredictionContent'

export const PredictionView: React.FC = () => {
  return (
    <Flex width="full" maxW="full" height="full" maxH="full">
      <LeftSidebar />
      <Flex
        w="full"
        bgColor="white"
        borderTop="1px solid"
        borderColor="gray.200"
        overflow="hidden"
      >
        <PredictionContent />
      </Flex>
    </Flex>
  )
}
