import {
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import type { ResultOf } from '@graphql-typed-document-node/core'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  type SortingState,
} from '@tanstack/react-table'
import { type DropdownOption } from 'components/Dropdown'
import { Icon } from 'components/Icon/Icon'
import { SkeletonTableRows } from 'components/Skeleton/SkeletonTableRows'
import { graphql } from 'generated/graphql'
import {
  type IntegrationFieldsFragmentDoc,
  type MarketingCostsViewQueryQuery,
} from 'generated/graphql/graphql'
import { useMemo, useState } from 'react'
import { EmptyData } from 'shared/EmptyData/EmptyData'
import { costTypes } from '../consts'
import { type MarketingCostDimension, type TaxonomyDataType } from '../types'
import { IntegrationConfigDimension } from './IntegrationConfigDimension'
import { RemoveButton } from './RemoveButton'

type Integration = ResultOf<typeof IntegrationFieldsFragmentDoc>
type ManualInputIntegrationConfigDimensions = {
  channelGroup?: string
  channel?: string
  country?: string
  campaign?: string
}

const normalizedTaxonomyDataTypeOptions = costTypes.reduce(
  (acc, next) => {
    acc[next.id as TaxonomyDataType] = next

    return acc
  },
  {} as Record<TaxonomyDataType, DropdownOption>,
)

const columnHelper = createColumnHelper<Integration>()

const COLUMN_ACTIONS_ID = 'actions'

const columns = [
  columnHelper.accessor('config.dimensions', {
    cell: ({ getValue }) => {
      return (
        <Grid
          gridTemplateColumns="auto 1fr"
          columnGap={4}
          rowGap={1.5}
          minW="300px"
        >
          {Object.entries(
            getValue() as ManualInputIntegrationConfigDimensions,
          ).map(([key, value]) => (
            <IntegrationConfigDimension
              key={`${key}:${value}`}
              dimensionId={key as MarketingCostDimension}
              value={value}
            />
          ))}
        </Grid>
      )
    },
    header: 'Dimensions',
  }),
  columnHelper.accessor('config.calculationType', {
    cell: ({ getValue }) => {
      const value = getValue() as TaxonomyDataType

      return normalizedTaxonomyDataTypeOptions[value]?.name ?? value
    },
    header: 'Cost type',
  }),
  columnHelper.accessor('config.amount', {
    cell: ({ getValue }) => getValue(),
    header: 'Value',
  }),
  columnHelper.accessor('config.currency', {
    cell: ({ getValue }) => getValue(),
    header: 'Currency',
  }),
  columnHelper.accessor('config.startDate', {
    cell: ({ getValue }) => getValue(),
    header: 'Start date',
  }),
  // End date key is not always available
  columnHelper.accessor('config', {
    cell: ({ getValue }) => getValue()?.endDate ?? '-',
    header: 'End date',
  }),
  columnHelper.accessor('id', {
    cell: ({ getValue, row }) => (
      <RemoveButton
        disabled={
          !!row.original.config?.endDate &&
          new Date() > new Date(String(row.original.config?.endDate))
        }
        integrationId={getValue()}
      />
    ),
    id: COLUMN_ACTIONS_ID,
    header: 'Actions',
    enableSorting: false,
  }),
]

export const MarketingCostManualInputs_MerchantSiteFragment = graphql(
  /* GraphQL */ `
    fragment MarketingCostManualInputs_MerchantSite on MerchantSite {
      marketingCostManualInputs {
        ...IntegrationFields
      }
    }
  `,
)

type MarketingCostTableProps = {
  merchantSite: NonNullable<
    MarketingCostsViewQueryQuery['viewer']
  >['merchantSite']
  isReadOnly: boolean
  isLoading: boolean
  isError: boolean
}

export const MarketingCostTable = ({
  merchantSite,
  isReadOnly,
  isLoading,
  isError,
}: MarketingCostTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([])

  const integrations = useMemo(
    () =>
      merchantSite?.marketingCostManualInputs?.filter(
        (integration) => integration.connected,
      ) ?? [],
    [merchantSite],
  )

  const filteredColumns = useMemo(() => {
    return isReadOnly
      ? columns.filter((column) => column.id !== COLUMN_ACTIONS_ID)
      : columns
  }, [isReadOnly])

  const table = useReactTable({
    data: integrations,
    columns: filteredColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  })

  const isEmptyOrError = isError || !integrations || integrations.length === 0

  return (
    <TableContainer>
      <Table>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  cursor={header.column.getCanSort() ? 'pointer' : undefined}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {!header.isPlaceholder && (
                    <Flex alignItems="center">
                      {flexRender(
                        header.column.columnDef.header as string,
                        header.getContext(),
                      )}
                      <Icon
                        visibility={
                          header.column.getIsSorted() ? 'visible' : 'hidden'
                        }
                        name={
                          header.column.getIsSorted() === 'asc'
                            ? 'ArrowUpIcon'
                            : 'ArrowDownIcon'
                        }
                        size="small"
                      />
                    </Flex>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isLoading ? (
            <SkeletonTableRows columns={columns.length} rows={10} />
          ) : isEmptyOrError ? (
            <Tr>
              <Td colSpan={100}>
                <EmptyData label="No integrations found" />
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td key={cell.id} maxW="360px">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
