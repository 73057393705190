import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { Typography } from 'components/Typography'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useRef } from 'react'
import {
  MAPPING_ACTION,
  useUpdateChannelMappings,
} from '../graphql/useUpdateChannelMappings'
import { MAPPING_TABS } from '../MappingsView'

interface Props {
  utmSource: string | undefined
  utmMedium: string | undefined
  utmCampaign: string | undefined
  channelGroup: string
  channel: string
}

export const DeleteMappingButton: React.FC<Props> = ({
  utmSource,
  utmMedium,
  utmCampaign,
  channelGroup,
  channel,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [updateChannelMappings, { loading }] = useUpdateChannelMappings()
  const { frontendId } = useMerchantInfo()
  const cancelRef = useRef(null)
  const toast = useToast()

  const deleteMapping = async () => {
    const deleteMappingParams = {
      action: MAPPING_ACTION.REMOVE,
      frontendId: frontendId as string,
      channelGroup,
      channel,
      mappings: {
        utmSource,
        utmMedium,
        utmCampaign,
      },
    }

    try {
      await updateChannelMappings(deleteMappingParams)
      toast({
        status: 'success',
        description: 'Mapping deleted',
        position: 'bottom',
        isClosable: true,
      })
      onClose()
    } catch (error) {
      toast({
        status: 'error',
        description: 'Failed to delete mapping',
        position: 'bottom',
        isClosable: true,
      })
    }
  }

  return (
    <>
      <ButtonIcon
        name="TrashIcon"
        size="sm"
        title="Delete mapping"
        onClick={onOpen}
        variant="ghost"
        color="gray.800"
        colorScheme="gray"
      />

      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        returnFocusOnClose={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight={500}>
              Remove mapping
            </AlertDialogHeader>

            <AlertDialogBody>
              <Typography>
                This mapping will be removed. Traffic that has already been
                assigned a channel based on the mapping will not be affected.
                The UTM parameters of this mapping will be possible to map again
                under <b>{MAPPING_TABS[0].label}</b>.
              </Typography>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="outline"
                size="md"
                colorScheme="gray"
                mr={2}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                size="md"
                isLoading={loading}
                onClick={deleteMapping}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
