import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import { useMemo } from 'react'
import { FILTER_OPTION } from './ReportListFilter/consts'
import {
  type FilterOptionValue,
  type ReportListFilters,
} from './ReportListFilter/types'
import { nonTeamVisibilityOptions } from './ReportVisibility/ReportVisibility'

const getFilterIdsByOption = (filters: ReportListFilters) => {
  return Object.entries(filters).reduce<Record<FilterOptionValue, string[]>>(
    (acc, [filterOption, values]) => {
      acc[filterOption as FilterOptionValue] = values.map((value) => value.id)

      return acc
    },
    {} as Record<FilterOptionValue, string[]>,
  )
}

const matchesFilter = (
  option: FilterOptionValue,
  options: { id: string }[],
  filters: ReportListFilters,
  filterIdsByOption: Record<FilterOptionValue, string[]>,
) => {
  return (
    filters[option].length === 0 ||
    filterIdsByOption[option].some((id) => options.some((o) => o.id === id))
  )
}

const matchesSearchInput = (report: ReportState, lowerCaseInput: string) => {
  const matchesInput = (value: string) =>
    value.toLowerCase().includes(lowerCaseInput)

  return (
    [report.name, report.description].some(matchesInput) ||
    report.labels?.some((label) => matchesInput(label.name))
  )
}

export const useFilterReports = (
  reports: ReportState[],
  searchInput: string,
  filters: ReportListFilters,
) => {
  return useMemo(() => {
    const hasFilters = Object.values(filters).some(
      (filter) => filter.length > 0,
    )
    const lowerCaseInput = searchInput.toLowerCase()
    const filterIdsByOption = getFilterIdsByOption(filters)

    const matchesFilterHelper = (
      option: FilterOptionValue,
      options: { id: string }[],
    ) => matchesFilter(option, options, filters, filterIdsByOption)

    return reports.filter((report) => {
      const isMatchingFilter =
        !hasFilters ||
        (matchesFilterHelper(
          FILTER_OPTION.VISIBILITY,
          report.visibility && report.visibility.length > 0
            ? report.visibility
            : [nonTeamVisibilityOptions[0]],
        ) &&
          matchesFilterHelper(
            FILTER_OPTION.OWNER,
            report.owner ? [report.owner] : [],
          ) &&
          matchesFilterHelper(FILTER_OPTION.LABELS, report.labels ?? []))

      const isMatchingSearch =
        !searchInput || matchesSearchInput(report, lowerCaseInput)

      return isMatchingFilter && isMatchingSearch
    })
  }, [filters, reports, searchInput])
}
