import { Flex } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import {
  ReportPanelOpenState,
  useReportPanelOpenAtom,
} from 'features/reports/atoms/reportPanelOpenAtom'

export const ReportPanelControl: React.FC = () => {
  const [reportPanelOpen, setReportPanelOpen] = useReportPanelOpenAtom()

  function toggleHandler(state: ReportPanelOpenState) {
    setReportPanelOpen((current) => (current === state ? null : state))
  }

  return (
    <Flex gap={2}>
      <ButtonIcon
        name="GraphSettingsIcon"
        size="sm"
        colorScheme="gray"
        variant="ghost"
        title="Open chart settings"
        onClick={() => toggleHandler(ReportPanelOpenState.CHART_SETTINGS)}
        isSelected={reportPanelOpen === ReportPanelOpenState.CHART_SETTINGS}
      />
      <ButtonIcon
        name="SlidersIcon"
        size="sm"
        colorScheme="gray"
        variant="ghost"
        title="Open report details"
        onClick={() => toggleHandler(ReportPanelOpenState.REPORT_DETAILS)}
        isSelected={reportPanelOpen === ReportPanelOpenState.REPORT_DETAILS}
      />
    </Flex>
  )
}
