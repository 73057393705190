import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)

type Status = 'success' | 'info' | 'warning' | 'error'

const variantBases = {
  info: {
    bg: 'blue.50',
    borderColor: 'blue.500',
  },
  success: {
    bg: 'green.50',
    borderColor: 'green.600',
  },
  warning: {
    bg: 'orange.50',
    borderColor: 'orange.600',
  },
  error: {
    bg: 'red.50',
    borderColor: 'red.500',
  },
} as const

const darkAccentColors = {
  info: 'blue.400',
  success: 'green.400',
  warning: 'orange.400',
  error: 'red.400',
} as const

const baseStyle = definePartsStyle((props) => {
  const status: Status = props.status ?? 'info'

  return {
    container: {
      borderRadius: '0px',
      py: '14px',
      pl: 4,
      pr: 8,
      borderColor: variantBases[status].borderColor,
      backgroundColor: variantBases[status].bg,

      _dark: {
        borderColor: darkAccentColors[status],
        backgroundColor: 'grey.800',
      },

      // Close button color
      '& > button': {
        color: 'grey.900',
        top: 3,
        _dark: {
          color: 'grey.50',
        },
      },
    },
    title: {
      fontWeight: 600,
      fontSize: 'sm',
      lineHeight: 5,
      color: 'grey.900',

      _dark: {
        color: 'grey.50',
      },
    },
    description: {
      fontWeight: 400,
      fontSize: 'sm',
      lineHeight: 5,
      color: 'grey.900',

      _dark: {
        color: 'grey.50',
      },
    },
    icon: {
      alignSelf: 'flex-start',
      width: 5,
      height: 5,
      color: variantBases[status].borderColor,

      _dark: {
        color: darkAccentColors[status],
      },
    },
  }
})

const bordered = definePartsStyle(() => {
  return {
    container: {
      borderWidth: '1px',
      borderStyle: 'solid',
    },
  }
})

export const alertTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { bordered },
})
