import { Flex, Switch } from '@chakra-ui/react'
import { ButtonIcon } from 'components/buttons'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { type Dispatch, type FC, type SetStateAction } from 'react'
import { chartTypes } from 'utils/chart/chartTypes'
import { compareColor } from 'utils/chart/constants'
import { useChartState, useSetChartState } from '../../hooks/useChartState'
import { useDateState } from '../../hooks/useDateState'

type Props = {
  showChart: boolean
  setShowChart: Dispatch<SetStateAction<boolean>>
}

export const ChartQuickAccessSettings: FC<Props> = ({
  showChart,
  setShowChart,
}) => {
  const [trackEvent] = useTrackEvent()
  const { series, color: chartGroup } = useChartState()
  const { isCompare } = useDateState()
  const setChartState = useSetChartState()
  const toggleShowChart = () => setShowChart((prev) => !prev)

  const isDisabled = !isCompare
  const isShowingCompare = chartGroup === compareColor.id

  const toggleCompareDate = () => {
    if (isDisabled) return

    trackEvent({
      eventName: 'Chart Show Comparison Button Toggled',
      eventProperties: {
        isShowingCompare: !isShowingCompare,
      },
    })
    setChartState((prev) => ({
      ...prev,
      color: isShowingCompare ? null : compareColor.id,
    }))
  }

  return (
    <Flex mx={5} my={2} justifyContent="end" alignItems="center" gap={2}>
      {showChart && (
        <Flex gap={2} alignItems="center">
          <Typography
            as="label"
            htmlFor="show-comparison-toggle"
            fontSize="xs"
            lineHeight={5}
            color={isDisabled ? 'grey.300' : 'gray.900'}
          >
            Show comparison
          </Typography>
          <Switch
            size="sm"
            id="show-comparison-toggle"
            colorScheme="primary"
            onChange={toggleCompareDate}
            isChecked={isShowingCompare}
            isDisabled={isDisabled}
          />
        </Flex>
      )}
      <Flex gap={2}>
        {!showChart && (
          <Icon
            name={chartTypes[series[0]?.type]?.graphWideIconName}
            width={24}
            height={8}
          />
        )}

        <ButtonIcon
          name={showChart ? 'ChevronUpIcon' : 'ChevronDownIcon'}
          colorScheme="grey"
          size="sm"
          variant="ghost"
          title="Toggle chart"
          onClick={toggleShowChart}
        />
      </Flex>
    </Flex>
  )
}
