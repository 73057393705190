import { Thead, Tr, Th } from '@chakra-ui/react'
import { flexRender, type HeaderGroup } from '@tanstack/react-table'
import { sharedCellStyles } from './consts'

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerGroups: HeaderGroup<any>[]
}

export const MappingThead = ({ headerGroups }: Props) => (
  <Thead>
    {headerGroups.map((headerGroup) => (
      <Tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <Th key={header.id} {...sharedCellStyles}>
            {!header.isPlaceholder &&
              flexRender(
                header.column.columnDef.header as string,
                header.getContext(),
              )}
          </Th>
        ))}
      </Tr>
    ))}
  </Thead>
)
