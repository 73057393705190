import {
  AuthenticateWithRedirectCallback,
  ClerkLoaded,
  ClerkLoading,
  useAuth,
  useOrganizationList,
  useUser,
} from '@clerk/clerk-react'
import { REPORT_PAGES } from 'features/reports/routePages'
import { useEffect } from 'react'
import {
  Route,
  Routes as RoutesWrapper,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { LoaderPage } from 'shared'
import { UnAuthedIntercomProvider } from 'shared/IntercomProvider/IntercomProvider'
import PasswordReset from './views/PasswordReset/PasswordReset'
import { SignIn } from './views/SignIn/SignIn'
import { SignUp } from './views/SignUp/SignUp'

export const Routes = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isLoaded: isUserLoaded, isSignedIn } = useUser()
  const { isLoaded: isAuthLoaded, orgId } = useAuth()
  const isUserSignedIn = isUserLoaded && isSignedIn
  const isUserWithActiveOrg = isAuthLoaded && orgId != null
  const {
    isLoaded: isOrgListLoaded,
    setActive: setActiveOrg,
    userMemberships,
  } = useOrganizationList({ userMemberships: true })

  useEffect(() => {
    if (!isUserSignedIn) return

    const isOrgReadyToSet =
      isOrgListLoaded && orgId == null && userMemberships.data.length === 1

    if (isUserWithActiveOrg) {
      const from = searchParams.get('from')
      const search = searchParams.get('q') ?? ''
      const to = from ?? REPORT_PAGES.BASE

      navigate(to + search)
    } else if (isOrgListLoaded && isOrgReadyToSet) {
      setActiveOrg({ organization: userMemberships.data[0].organization.id })
    }
  }, [
    isUserWithActiveOrg,
    isOrgListLoaded,
    isUserSignedIn,
    navigate,
    searchParams,
    setActiveOrg,
    userMemberships,
    orgId,
  ])

  return (
    <>
      <ClerkLoading>
        <LoaderPage />
      </ClerkLoading>
      <ClerkLoaded>
        <UnAuthedIntercomProvider>
          <RoutesWrapper>
            <Route path="/login" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<PasswordReset />} />
            <Route path="/sso-callback" element={<SSOCallback />} />
            <Route path="/loading" element={<LoaderPage />} />
          </RoutesWrapper>
        </UnAuthedIntercomProvider>
      </ClerkLoaded>
    </>
  )
}

function SSOCallback() {
  // Handle the redirect flow by rendering the
  // prebuilt AuthenticateWithRedirectCallback component.
  // This is the final step in the custom OAuth flow
  return <AuthenticateWithRedirectCallback />
}
