import { Flex } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { UnAuthedIntercomProvider } from './IntercomProvider/IntercomProvider'

export const UnexpectedError = () => {
  return (
    <UnAuthedIntercomProvider>
      <Flex
        flex={1}
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Flex maxW="492px" flexDir="column" alignItems="center">
          <Icon name="ExclamationCircleIcon" color="red.500" customSize={64} />
          <Typography
            fontSize="2xl"
            lineHeight={8}
            fontWeight="700"
            color="grey.900"
            my={4}
          >
            Something went wrong
          </Typography>
          <Typography
            fontSize="md"
            lineHeight={6}
            fontWeight="400"
            color="grey.900"
            textAlign="center"
          >
            An error has occurred and we are working to fix the problem! Please
            try again in a while or reach out to us.
          </Typography>
        </Flex>
      </Flex>
    </UnAuthedIntercomProvider>
  )
}
