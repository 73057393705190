import { Box, Flex } from '@chakra-ui/react'
import type React from 'react'

interface ColorPickerProps {
  colors: string[]
  selectedColor: string
  setSelectedColor: (color: string) => void
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  colors,
  selectedColor,
  setSelectedColor,
}) => {
  return (
    <Flex mt={1} flexWrap="wrap" mb={6}>
      {colors.map((color) => {
        const isSelected = color === selectedColor

        return (
          <Flex
            as="button"
            type="button"
            justifyContent="center"
            alignItems="center"
            flexShrink={0}
            width={8}
            height={8}
            key={color}
            aria-selected={isSelected}
            onClick={() => setSelectedColor(color)}
            _selected={{ bg: 'gray.200' }}
            _hover={{ bg: isSelected ? 'gray.200' : 'gray.100' }}
          >
            <Box bg={color} w={4} h={4} />
          </Flex>
        )
      })}
    </Flex>
  )
}
