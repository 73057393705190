export const orgRoleKeys = {
  ADMIN: 'org:admin',
  MEMBER: 'org:member',
  GUEST: 'org:guest',
} as const

export type OrgRole = (typeof orgRoleKeys)[keyof typeof orgRoleKeys]

export const orgRolesMap = {
  'org:admin': { key: 'org:admin', label: 'Admin' },
  'org:member': { key: 'org:member', label: 'Member' },
  'org:guest': { key: 'org:guest', label: 'Guest' },
} satisfies Record<OrgRole, { key: OrgRole; label: string }>

export const orgRolesArray = Object.values(orgRolesMap)
