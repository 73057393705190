import { Dropdown } from 'components/Dropdown'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useEffect, useMemo } from 'react'

type Props = {
  merchantSiteId: string | undefined
  setMerchantSiteId: (siteId: string) => void
}

export const MerchantSiteSelector = ({
  merchantSiteId,
  setMerchantSiteId,
}: Props) => {
  const { sites = [] } = useMerchantInfo()

  const siteOptions = useMemo(
    () => sites.map((site) => ({ id: site.frontendId, name: site.domain })),
    [sites],
  )

  const hasSites = siteOptions.length > 1

  useEffect(
    () => setMerchantSiteId(siteOptions[0].id),
    [siteOptions, setMerchantSiteId],
  )

  if (!hasSites) return null

  return (
    <Dropdown
      isLoading={!merchantSiteId}
      value={merchantSiteId}
      options={siteOptions}
      callback={(option) => setMerchantSiteId(option.id)}
      size="sm"
      variant="outline"
      matchWidth={false}
      buttonProps={{ size: 'sm', variant: 'outline' }}
    />
  )
}
