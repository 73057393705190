import { Typography } from 'components/Typography'
import { type FC } from 'react'

type Props = {
  error: string
  isDarkMode?: boolean
}

export const ErrorMessage: FC<Props> = ({ error, isDarkMode }) => (
  <Typography
    mt={1}
    role="alert"
    title="error-message"
    aria-label="error-message"
    color={isDarkMode ? 'red.300' : 'red.500'}
    fontSize="xs"
    lineHeight={4}
  >
    {error}
  </Typography>
)
