export const GEO_LIFT_ROUTE_FRAGMENTS = {
  GEOLIFT: 'geo-lift',
  EXPERIMENTS: 'experiments',
  GUIDE: 'guide',
} as const

const BASE = `/${GEO_LIFT_ROUTE_FRAGMENTS.GEOLIFT}` as const

export const GEO_LIFT_PAGES = {
  BASE,
  EXPERIMENTS: `${BASE}/${GEO_LIFT_ROUTE_FRAGMENTS.EXPERIMENTS}`,
  GUIDE: `${BASE}/${GEO_LIFT_ROUTE_FRAGMENTS.GUIDE}`,
} as const
