import { type Dimension as GraphDimension } from 'generated/graphql/graphql'
import { atom, useAtomValue } from 'jotai'
import { keyBy } from 'lodash-es'

export type Dimension = GraphDimension

export type NormalizedDimensions = Record<Dimension['id'], Dimension>

export const dimensionsQueryAtom = atom<Dimension[] | null>(null)

export const normalizedDimensionsAtom = atom<NormalizedDimensions>((get) => {
  const data = get(dimensionsQueryAtom)

  return keyBy(data?.filter(({ isHidden }) => !isHidden) ?? [], 'id')
})

export const useNormalizedDimensions = (): NormalizedDimensions => {
  return useAtomValue(normalizedDimensionsAtom)
}
