import { type DisplayFormat } from 'constants/displayFormats'
import { type Filter } from 'constants/types'
import { type SortDirection } from 'ag-grid-community'
import {
  type AnalyticsConfigFieldsFragment,
  type OwnerFieldsFragment,
  type ReportWithOwnerFieldsFragment,
  type MerchantFieldsFragment,
  type TeamFieldsFragment,
} from 'generated/graphql/graphql'
import { type ChartTypeId } from 'utils/chart/chartTypes'

export type ExtendedSortDirection = SortDirection | 'ascCompare' | 'descCompare'

export type TableState = {
  id: string
  sort: ExtendedSortDirection
  isPinned: boolean
}[]

export enum ChartSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type ChartSorting = { key: string; order: ChartSortOrder }

export interface ChartState {
  color: string | null | undefined
  xAxis: string
  series: ChartSerie[]
  chartSorting: ChartSorting
}

export interface ChartSerie {
  key: string
  type: ChartTypeId
}

export type CompareUnit = Extract<
  DisplayFormat,
  'percentageDiff' | 'absoluteDiff'
>

type EnhancedAnalyticsConfigTypes = {
  filters: Record<string, Filter[]>
  tableState: TableState
  chart: ChartState
  compareUnit: CompareUnit
}

export type ReportVisibility = TeamFieldsFragment | MerchantFieldsFragment

export type AnalyticsConfig = Omit<
  AnalyticsConfigFieldsFragment,
  'filters' | 'tableState' | 'chart' | 'compareUnit'
> &
  EnhancedAnalyticsConfigTypes

export type ReportResult = Omit<
  ReportWithOwnerFieldsFragment,
  'visibility' | 'owner' | 'analyticsConfig'
> & {
  owner: OwnerFieldsFragment
  visibility: ReportVisibility[]
  analyticsConfig: AnalyticsConfig
}
