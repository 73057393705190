import { Box, Flex } from '@chakra-ui/react'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import { ProfitDescription } from '../ProfitDescription'

import { MultiChannelGraph } from './MultiChannelGraph'
import { MultiChannelGraphControlPanel } from './MultiChannelGraphControlPanel'

interface MultiChannelSaturationCurveProps {
  curveData: SaturationCurveData
  showValuesPerDay: boolean
}

export const MultiChannelSaturationCurve: React.FC<
  MultiChannelSaturationCurveProps
> = ({ curveData, showValuesPerDay }) => {
  const {
    optimalSpend,
    optimalProfit,
    actualProfit,
    actualSpend,
    saturationSpend,
    saturationProfit,
  } = curveData.reduce(
    (prev, curr) => ({
      optimalSpend: prev.optimalSpend + curr.optimalPoint[0],
      optimalProfit: prev.optimalProfit + curr.optimalPoint[1],
      actualProfit: prev.actualProfit + curr.actualPoint[1],
      actualSpend: prev.actualSpend + curr.actualPoint[0],
      saturationSpend: prev.saturationSpend + curr.saturationPoint[0],
      saturationProfit: prev.saturationProfit + curr.saturationPoint[1],
    }),
    {
      optimalSpend: 0,
      optimalProfit: 0,
      actualSpend: 0,
      actualProfit: 0,
      saturationSpend: 0,
      saturationProfit: 0,
    },
  )

  return (
    <Flex width="full" gap={6}>
      <ProfitDescription
        selectedChannel="all channels"
        optimalSpend={optimalSpend}
        optimalProfit={optimalProfit}
        actualProfit={actualProfit}
        actualSpend={actualSpend}
        showValuesPerDay={showValuesPerDay}
        saturationProfit={saturationProfit}
        saturationSpend={saturationSpend}
      />
      <Flex width="full" flexDir="column" overflow="auto">
        {/* We set a high margin to avoid the component jumping when toggling between multi and single channel */}
        <Box mb={14} minW={400}>
          <MultiChannelGraphControlPanel curveData={curveData} />
        </Box>

        <MultiChannelGraph curveData={curveData} />
      </Flex>
    </Flex>
  )
}
