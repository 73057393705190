import {
  type ValueFormatterParams,
  type ColDef,
  type SortDirection,
} from 'ag-grid-community'
import { getIsPopulatedDateRange } from 'components/Datepicker/utils'
import { useDateState } from 'features/reports/hooks/useDateState'
import {
  type CompareUnit,
  type ExtendedSortDirection,
  type TableState,
} from 'graphql/reports/types'
import {
  type MetricFormat,
  type NormalizedStatistic,
  type Statistic,
} from 'graphql/statistics/types'
import {
  useNormalizedAttributionModels,
  type NormalizedAttributionModels,
} from 'graphql/statistics/useAttributionModels'
import {
  type NormalizedDimensions,
  type Dimension,
} from 'graphql/statistics/useDimensions'
import {
  type NormalizedMetrics,
  type AttributedMetric,
  type Metric,
} from 'graphql/statistics/useMetrics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import isEqual from 'lodash-es/isEqual'
import { useEffect, useState } from 'react'
import { calcDifference } from 'utils/calcDifference'
import { formatMetricLabel } from 'utils/formatMetricLabel'
import { stringToNumber } from 'utils/stringToNumber'
import { dimensionsCellRendererSelector } from './cell/DimensionCell'
import { metricsCellRendererSelector } from './cell/MetricCell'
import { type AnalyticsCell } from './types'

const dimensionSortingOrder: SortDirection[] = ['asc', 'desc', null]
const dimensionsComparator = (
  valueA: AnalyticsCell | null | undefined,
  valueB: AnalyticsCell | null | undefined,
) => {
  if (!valueA) return -1
  if (!valueB) return 1

  return String(valueA.formattedValue)
    .toLowerCase()
    .localeCompare(String(valueB.formattedValue).toLowerCase())
}

const mapDimensionToColumnDefs = (
  dimension: Dimension,
): ColDef<NormalizedStatistic, AnalyticsCell> => {
  return {
    field: dimension.id,
    valueFormatter: ({
      value,
    }: ValueFormatterParams<NormalizedStatistic, AnalyticsCell>) =>
      value?.formattedValue ?? '',
    sortingOrder: dimensionSortingOrder,
    cellRendererSelector: dimensionsCellRendererSelector,
    comparator: dimensionsComparator,
    headerName: dimension.label,
    headerComponentParams: {
      dimension: dimension,
    },
  }
}

const metricSortingOrder: SortDirection[] = ['desc', 'asc', null]

interface GetMetricColumnDefsParams {
  metric: Metric
  aggregatedData: NormalizedStatistic[]
  isCompare: boolean
  currency?: string
  isMultiMetric: boolean
  attributionModels?: NormalizedAttributionModels
  isLoading: boolean
  compareUnit: CompareUnit
}

// Sort variable is used to determine if the sort is a compare sort. We use a global variable since AG-grid caches the comparator function and doesn't update it when the sort variable changes leading to flickering in some scenarios.
let sortVariable: ExtendedSortDirection = null

type MetricComparator = {
  valueA: Statistic | null | undefined
  valueB: Statistic | null | undefined
  compareUnit: CompareUnit
  currency?: string
  metricFormat: MetricFormat
}

const metricsComparator = ({
  valueA,
  valueB,
  compareUnit,
  metricFormat,
}: MetricComparator) => {
  const isCompareSort = ['descCompare', 'ascCompare'].includes(
    sortVariable ?? '',
  )

  const aActual = stringToNumber(valueA?.value)
  const aCompare = stringToNumber(valueA?.comparisonValue)
  const bActual = stringToNumber(valueB?.value)
  const bCompare = stringToNumber(valueB?.comparisonValue)

  let aSelected = 0
  let bSelected = 0

  if (!isCompareSort) {
    aSelected = aActual
    bSelected = bActual
  } else {
    const { difference: aDiff = 0 } = calcDifference({
      value: aActual,
      compareValue: aCompare,
      format: metricFormat,
      displayFormat: compareUnit,
    })

    const { difference: bDiff = 0 } = calcDifference({
      value: bActual,
      compareValue: bCompare,
      format: metricFormat,
      displayFormat: compareUnit,
    })

    aSelected = aDiff
    bSelected = bDiff
  }

  return aSelected < bSelected ? -1 : 1
}

const getMetricColumnDefs = ({
  metric,
  isCompare,
  currency,
  attributionModels,
  aggregatedData,
  isMultiMetric,
  isLoading,
  compareUnit,
}: GetMetricColumnDefsParams): ColDef<NormalizedStatistic, AnalyticsCell> => {
  const attributionLabel =
    attributionModels?.[(metric as AttributedMetric).attributionId ?? '']?.label

  return {
    field: metric.key,
    valueFormatter: ({
      value,
    }: ValueFormatterParams<NormalizedStatistic, AnalyticsCell>) =>
      value?.formattedValue ?? '',
    cellRendererSelector: metricsCellRendererSelector,
    cellRendererParams: { metric, isCompare, compareUnit },
    sortingOrder: metricSortingOrder,
    comparator: (
      valueA: Statistic | null | undefined,
      valueB: Statistic | null | undefined,
    ) =>
      metricsComparator({
        valueA,
        valueB,
        compareUnit,
        metricFormat: metric.format,
      }),
    headerName: formatMetricLabel(metric, currency),
    headerComponentParams: {
      metric,
      isCompare,
      aggregatedData,
      attributionLabel: isMultiMetric ? attributionLabel : undefined,
      isLoading,
      compareUnit,
    },
  }
}

const getMetricGroups = (metrics: Metric[]) => {
  const metricGroupsMap = metrics.reduce<Record<string, Metric[]>>(
    (acc, next) => {
      const { groupKey } = next

      ;(acc[groupKey] ??= []).push(next)

      return acc
    },
    {},
  )

  return metricGroupsMap
}

interface UseTableColumnsGridProps {
  normalizedDimensions: NormalizedDimensions
  normalizedMetrics: NormalizedMetrics
  aggregatedData: NormalizedStatistic[]
  isLoading: boolean
  tableState: TableState
  compareUnit: CompareUnit
}

export const useTableColumns = ({
  normalizedDimensions,
  normalizedMetrics,
  aggregatedData,
  isLoading,
  tableState,
  compareUnit,
}: UseTableColumnsGridProps) => {
  const { currency } = useMerchantInfo()
  const { compareDateRange } = useDateState()
  const isCompare = getIsPopulatedDateRange(compareDateRange)
  const normalizedAttributionModels = useNormalizedAttributionModels()

  const [columns, setColumns] = useState<
    ColDef<NormalizedStatistic, AnalyticsCell>[]
  >([])

  useEffect(() => {
    const metricGroupsMap = getMetricGroups(
      tableState.map((column) => normalizedMetrics[column.id]).filter(Boolean),
    )

    sortVariable = tableState.filter(({ sort }) => sort)?.[0]?.sort ?? null
    const arr: ColDef<NormalizedStatistic, AnalyticsCell>[] = tableState
      .map((column) => {
        const dimensionRow = normalizedDimensions[column.id]

        if (dimensionRow) {
          return mapDimensionToColumnDefs(dimensionRow)
        }

        const metric = normalizedMetrics[column.id]

        if (metric) {
          return getMetricColumnDefs({
            metric,
            aggregatedData,
            currency,
            isMultiMetric: metricGroupsMap[metric.groupKey]?.length > 1,
            isCompare,
            isLoading,
            attributionModels: normalizedAttributionModels,
            compareUnit,
          })
        }
      })
      .filter(Boolean) as ColDef<NormalizedStatistic, AnalyticsCell>[]

    setColumns((prev) => {
      // Updating column definitions without any changes crash in some scenarios
      if (!isEqual(arr, prev)) {
        return arr
      }

      return prev
    })
  }, [
    normalizedDimensions,
    normalizedMetrics,
    aggregatedData,
    currency,
    tableState,
    isCompare,
    normalizedAttributionModels,
    isLoading,
    compareUnit,
  ])

  return columns
}
