import {
  Box,
  type BoxProps,
  Flex,
  type FlexProps,
  type PropsOf,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { type ButtonProps } from 'components/buttons/Button'
import { Typography } from 'components/Typography'
import { LayoutGroup, motion } from 'framer-motion'
import { forwardRef, useId } from 'react'
import { colorToRgba } from 'ui/theme/colors'

type Item<Id extends string> = {
  id: Id
  label: string
  matchWith?: string
}

type Props<Id extends string> = FlexProps & {
  items: Item<Id>[]
  selected?: Id
  setSelected: (selected: Id) => void
  buttonProps?: ButtonProps
  sliderBoxProps?: PropsOf<typeof MotionBox>
}

// Bit of a hacky solution to avoid TS errors
const ForwardedBox = forwardRef<HTMLDivElement, BoxProps>((props, ref) => (
  <Box ref={ref} {...props} />
))

ForwardedBox.displayName = 'ForwardedBox'

const MotionBox = motion.create(ForwardedBox)

export const ToggleWithLabels = <Id extends string>({
  items,
  selected = items[0].id,
  setSelected,
  buttonProps,
  sliderBoxProps,
  ...rest
}: Props<Id>) => {
  const animationId = useId()

  return (
    <Flex w="fit-content" borderRadius="none" bg="grey.200" {...rest}>
      <LayoutGroup>
        {items.map((item) => (
          <Button
            key={item.id}
            onClick={() => setSelected(item.id)}
            colorScheme="grey"
            variant="ghost"
            isSelected={selected === item.id}
            boxSizing="border-box"
            color="gray.700"
            _hover={{
              bg: 'transparent',
              color: 'gray.900',
            }}
            size="sm"
            {...buttonProps}
          >
            {(item.matchWith
              ? selected.startsWith(item.matchWith)
              : selected === item.id) && (
              <MotionBox
                layoutId={animationId} // this is needed so the animation does not jump between pages
                position="absolute"
                inset={0}
                bg="white"
                border="solid 1px"
                borderColor={colorToRgba('grey.900', 0.16)}
                {...sliderBoxProps}
                transition={{
                  type: 'spring',
                  stiffness: 350,
                  damping: 30,
                }}
              />
            )}
            <Typography zIndex={1} fontWeight={500} color="inherit">
              {item.label}
            </Typography>
          </Button>
        ))}
      </LayoutGroup>
    </Flex>
  )
}
