import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMemo } from 'react'
import { TAXONOMY_TYPE } from '../shared/consts'
import { ManualInputTable } from '../shared/ManualInput/ManualInputTable'
import { productColumns } from './ProductColumns'

const types = [TAXONOMY_TYPE.RETURN_PERCENTAGE]

export const ReturnPercentView = () => {
  const flags = useFeatureFlags()
  const filteredColumns = useMemo(() => {
    return flags.allowSettingsWrite
      ? productColumns
      : productColumns.filter((column) => column.id !== 'actions')
  }, [flags.allowSettingsWrite])

  return (
    <ManualInputTable
      columns={filteredColumns}
      title="Return percent"
      description="Input return percentages on different levels."
      types={types}
    />
  )
}
