import { uniqBy } from 'lodash-es'
import { useMemo, type FC } from 'react'
import { ReportLabelsPicker } from '../../ReportLabelsPicker/ReportLabelsPicker'
import { FILTER_OPTION } from '../consts'
import { type ReportListFilterPickerChildProps } from '../types'

export const FilterOptionLabels: FC<ReportListFilterPickerChildProps> = ({
  customMenuButton,
  reports,
  isOpenByDefault,
  selectedIds,
  setFilters,
  setSelectedFilterOption,
}) => {
  const uniqueOptions = useMemo(() => {
    return uniqBy(
      reports.flatMap((report) => report.labels ?? []),
      'id',
    )
  }, [reports])

  return (
    <ReportLabelsPicker
      matchWidth={true}
      customMenuButton={customMenuButton}
      reportLabels={uniqueOptions}
      selectedLabelIds={selectedIds}
      isOpenByDefault={isOpenByDefault}
      onLabelsChanged={(labels) => setFilters(FILTER_OPTION.LABELS, labels)}
      onClose={() => setSelectedFilterOption(undefined)}
    />
  )
}
