import { useAuth, useOrganization, useUser } from '@clerk/clerk-react'
import { useMemo } from 'react'

export const useOrganisationMembers = () => {
  const { memberships, isLoaded: membershipsLoaded } = useOrganization({
    memberships: { pageSize: 500 },
  })
  const { user, isLoaded: userLoaded } = useUser()
  const { userId, isLoaded: authLoaded } = useAuth()
  const isLoading = !membershipsLoaded || !userLoaded || !authLoaded

  const members = useMemo(
    () =>
      user?.primaryEmailAddress?.emailAddress.endsWith('@dema.ai')
        ? memberships?.data
        : memberships?.data?.filter(
            (member) =>
              !member.publicUserData.identifier?.endsWith('@dema.ai') ||
              member.publicUserData.userId === userId,
          ),
    [memberships?.data, user?.primaryEmailAddress?.emailAddress, userId],
  )

  const normalizedMembers = useMemo(
    () =>
      members?.reduce<Record<string, (typeof members)[0]>>((acc, member) => {
        const userId = member.publicUserData.userId

        if (userId) {
          acc[userId] = member
        }

        return acc
      }, {}),
    [members],
  )

  return { members, normalizedMembers, isLoading }
}
