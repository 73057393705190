import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Button } from 'components/buttons/Button'
import Input from 'components/Input/Input'
import { Label } from 'components/Label/Label'
import { ColorPicker } from 'components/Pickers/ColorPicker'
import { GenericPickerModal } from 'components/Pickers/GenericPickerModal'
import { Typography } from 'components/Typography'
import { type ReportLabel } from 'generated/graphql/graphql'
import {
  useDefaultDisclosure,
  type DefaultDisclosure,
} from 'hooks/useDefaultDisclosure'
import { type FC, useState, type ReactElement, useRef } from 'react'
import { colorTheme } from 'ui/theme/colors'

export type UpdateReportLabel = Pick<Partial<ReportLabel>, 'id'> &
  Omit<ReportLabel, 'id'>

const labelColors: string[] = [
  colorTheme.gray[500],
  colorTheme.purple[500],
  colorTheme.magenta[500],
  colorTheme.red[400],
  colorTheme.orange[500],
  colorTheme.yellow[600],
  colorTheme.green[500],
  colorTheme.dema[600],
  colorTheme.cyan[600],
  colorTheme.blue[600],
  colorTheme.black,
]

const defaultLabel: UpdateReportLabel = {
  color: labelColors[0],
  name: '',
  reportCount: 0,
}

type ReportLabelPickerProps = {
  currentLabel: ReportLabel | undefined
  modalButton: ReactElement
  isEditMode?: boolean
  onApply: (label: UpdateReportLabel) => void
  disclosure?: DefaultDisclosure
}

export const ReportLabelPicker: FC<ReportLabelPickerProps> = ({
  currentLabel,
  modalButton,
  isEditMode,
  onApply,
  disclosure,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [label, setLabel] = useState<UpdateReportLabel>(
    currentLabel ?? defaultLabel,
  )
  const { isOpen, onOpen, onClose } = useDefaultDisclosure(disclosure)

  return (
    <GenericPickerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      modalButton={modalButton}
      setToDefaultState={() => {
        if (!currentLabel) {
          setLabel(defaultLabel)
        }
      }}
      initialFocusRef={inputRef}
    >
      <ModalHeader>{isEditMode ? 'Edit label' : 'Add label'}</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <Typography mb={4}>Choose a color and name for the label</Typography>
        <Label label="Color" />
        <Box mt={1} mb={6}>
          <ColorPicker
            colors={labelColors}
            selectedColor={label.color}
            setSelectedColor={(color) =>
              setLabel((label) => ({ ...label, color }))
            }
          />
        </Box>

        <Input
          ref={inputRef}
          label="Label name"
          title="Label name"
          placeholder="E.g Marketing"
          value={label.name}
          maxLength={50}
          onChange={(e) =>
            setLabel((label) => ({ ...label, name: e.target.value }))
          }
        />
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="gray" variant="outline" mr={2} onClick={onClose}>
          Cancel
        </Button>
        <Button
          isDisabled={!label.name || !label.color}
          variant="solid"
          type="submit"
          colorScheme="primary"
          onClick={() => {
            onApply(label)
            onClose()
          }}
        >
          {isEditMode ? 'Save' : 'Add label'}
        </Button>
      </ModalFooter>
    </GenericPickerModal>
  )
}
