import { type DropdownGroup } from 'components/Dropdown'
import { type Dimension } from 'generated/graphql/graphql'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { groupBy } from 'lodash-es'
import { useMemo } from 'react'

export const useDimensionGroups = ({
  dimensions,
  showDeselect,
}: {
  dimensions?: Dimension[]
  showDeselect?: boolean
} = {}) => {
  const normalizedDimensions = useNormalizedDimensions()
  const groups = useMemo(() => {
    const allDimensions = dimensions ?? Object.values(normalizedDimensions)
    // Group only base dimensions
    const groupedDimensions = groupBy(allDimensions, 'groupId')

    return Object.values(groupedDimensions).map((group) => ({
      name: group[0].groupLabel,
      showDeselect: !!showDeselect,
      items: group.map((dim) => ({ ...dim, name: dim.label })),
    })) satisfies DropdownGroup[]
  }, [dimensions, normalizedDimensions, showDeselect])

  return groups
}
