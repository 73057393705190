import { Skeleton } from '@chakra-ui/react'
import { type CellRendererSelectorFunc } from 'ag-grid-community'
import { Typography } from 'components/Typography'
import {
  type NormalizedStatistic,
  type Statistic,
} from 'graphql/statistics/types'
import { calcDifference } from 'utils/calcDifference'
import { stringToNumber } from 'utils/stringToNumber'
import { type MetricCellRendererParams } from '../types'
import { CellWrapper } from './CellWrapper'

const MetricCellRenderer = ({
  value,
  metric,
  isCompare,
  data,
  compareUnit,
}: MetricCellRendererParams) => {
  const { formattedValue, formattedDifference, color } = calcDifference({
    value: stringToNumber(value?.value),
    compareValue: stringToNumber(data?.[metric.key]?.comparisonValue),
    format: metric.format,
    isOppositeDiffColor: !!metric.isReversePositive,
    neutralColor: 'grey.600',
    includePrefix: true,
    displayFormat: compareUnit,
  })

  return (
    <CellWrapper>
      <Typography
        textAlign="end"
        lineHeight={4}
        noOfLines={1}
        color="grey.900"
        fontSize="xs"
        whiteSpace="nowrap"
        display="block"
      >
        {formattedValue ?? '-'}
      </Typography>
      {isCompare && (
        <Typography
          noOfLines={1}
          lineHeight="18px"
          fontSize="2xs"
          fontWeight={400}
          color={color}
          textAlign="end"
        >
          {formattedDifference ?? 'N/A'}
        </Typography>
      )}
    </CellWrapper>
  )
}

const MetricSkeletonLoader = () => {
  return (
    <CellWrapper>
      <Skeleton w="80px" h="14px" alignSelf="flex-end" />
      <Skeleton w="50px" h="12px" mt="4px" alignSelf="flex-end" />
    </CellWrapper>
  )
}

export const metricsCellRendererSelector: CellRendererSelectorFunc<
  NormalizedStatistic,
  Statistic
> = (params) => {
  if (params.data?.['loading']) {
    return {
      component: MetricSkeletonLoader,
    }
  }

  return {
    component: MetricCellRenderer,
  }
}
