import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const CREATE_ORGANIZATION_INVITATION = graphql(/* GraphQL */ `
  mutation CreateOrganizationInvitation(
    $params: CreateOrganizationInvitationInput!
  ) {
    createOrganizationInvitation(params: $params) {
      __typename
      ... on OrganizationInvitation {
        id
        status
      }
      ... on CreateOrganizationInvitationError {
        message
      }
    }
  }
`)

export const useCreateOrganizationInvitation = () =>
  useMutation(CREATE_ORGANIZATION_INVITATION)
