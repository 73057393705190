import { envConfigSchema } from 'envConfig'

const envVars = envConfigSchema.parse(import.meta.env)

type EnvVarWithoutVITE = {
  [K in keyof typeof envVars as K extends `VITE_${infer R}`
    ? R
    : K]: (typeof envVars)[K]
}

const updatedEnvs = Object.fromEntries(
  Object.entries(envVars).map(([key, value]) => [
    key.replace(/^VITE_/, ''),
    value,
  ]),
) as EnvVarWithoutVITE

export const config = {
  ...updatedEnvs,
  APP_ENV: import.meta.env.MODE,
  GRAPHQL_URL: `${updatedEnvs.API_URL}graphql`,
  APP_VERSION: '1.5.1',
  CLERK_KEY: updatedEnvs.CLERK_PUBLISHABLE_KEY,
} as const
