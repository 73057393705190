import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { AnalyticsChart } from './AnalyticsChart'
import { ChartQuickAccessSettings } from './ChartQuickAccessSettings'

export const AnalyticsChartContainer = () => {
  const [showChart, setShowChart] = useState(true)

  return (
    <>
      <ChartQuickAccessSettings
        showChart={showChart}
        setShowChart={setShowChart}
      />
      {showChart && (
        <Box mx={4} maxW="full">
          <AnalyticsChart />
        </Box>
      )}
    </>
  )
}
