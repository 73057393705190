import {
  MenuItem as ChakraMenuItem,
  forwardRef,
  type As,
  type MenuItemOptionProps,
  type MenuItemProps,
} from '@chakra-ui/react'
import { type useCombobox } from 'downshift'
import { type ReactElement } from 'react'
import { type DropdownOption } from '../types'
import { MenuItemText } from './MenuItemText'

interface Props {
  option: DropdownOption
  isSelected?: boolean
  isHighlighted?: boolean
  itemProps?: ReturnType<ReturnType<typeof useCombobox>['getItemProps']>
  itemStyles?: MenuItemProps & MenuItemOptionProps
  isOption?: boolean
  leftItem?: ReactElement
  rightItem?: ReactElement
  onClick?: MenuItemProps['onClick']
}

export const MenuItem = forwardRef<Props, As>(
  (
    {
      leftItem,
      rightItem,
      isSelected,
      isOption,
      option,
      itemProps,
      itemStyles,
      onClick,
    },
    ref,
  ) => {
    return (
      <ChakraMenuItem
        ref={ref}
        onClick={onClick}
        value={option.id.toString()}
        isDisabled={option.disabled}
        {...itemProps}
        {...itemStyles}
        aria-disabled={option.disabled}
        aria-selected={isSelected}
      >
        <MenuItemText
          option={option}
          isDisabled={option.disabled}
          rightItem={rightItem}
          leftItem={leftItem}
          isOption={isOption}
          isSelected={isSelected}
        />
      </ChakraMenuItem>
    )
  },
)

MenuItem.displayName = 'MenuItem'
