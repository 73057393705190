import { Box, Flex } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/buttons'
import { Form } from 'components/Form'
import { usePredictionAtom } from 'features/optimizations/views/Prediction/atoms/atom'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { validationSchema } from './schema'
import { TargetSection } from './TargetSection'
import { type SidebarFormState } from './types'
import { getFormValuesFromState, getStateValueFromForm } from './utils'

export const LeftSidebar = () => {
  const [state, setState] = usePredictionAtom()
  const methods = useForm<SidebarFormState>({
    resolver: zodResolver(validationSchema),
    defaultValues: getFormValuesFromState(state),
  })
  const [trackEvent] = useTrackEvent()

  const onSubmit: SubmitHandler<SidebarFormState> = (formState) => {
    const newState = getStateValueFromForm(formState)

    trackEvent({
      eventName: 'Optimizations Future Analysis Scenario Ran',
      eventProperties: {
        scenarioState: newState,
      },
    })
    setState(newState)
  }

  return (
    <Form<SidebarFormState> methods={methods} onSubmit={onSubmit}>
      <Flex
        flexShrink={0}
        direction="column"
        bg="gray.50"
        borderRight="solid 1px"
        borderRightColor="gray.200"
        h="full"
        overflow="hidden"
        borderTop="1px solid"
        borderColor="gray.200"
      >
        <TargetSection />

        <Box
          borderTop="solid 1px"
          px={6}
          py={4}
          bg="gray.50"
          flexShrink={0}
          borderTopColor="gray.200"
          position="sticky"
          bottom={0}
          zIndex={1}
        >
          <Button
            colorScheme="primary"
            w="full"
            justifyContent="left"
            h="12"
            type="submit"
          >
            Run scenario
          </Button>
        </Box>
      </Flex>
    </Form>
  )
}
