import { useUser } from '@clerk/clerk-react'
import { useUserReports } from 'graphql/reports/useUserReports'
import { useMemo } from 'react'
import {
  useNewReportState,
  useSetAnalyticsConfigAtom,
  useSetReportStateAtom,
} from '../hooks/useReportState'
import { REPORT_PAGE_TITLES } from '../routePages'
import { ReportListTable } from './shared/ReportListTable'

export const UserReportsView = () => {
  const { userReports, query } = useUserReports()
  const { user } = useUser()
  const setReportState = useSetReportStateAtom()
  const setAnalyticsConfigAtom = useSetAnalyticsConfigAtom()
  const newReportState = useNewReportState()

  const privateReports = useMemo(
    () =>
      userReports.filter(
        (report) => report.owner.id === user?.publicMetadata.dema_id,
      ),
    [userReports, user?.publicMetadata.dema_id],
  )

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.USER}
      reports={privateReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setReportState(newReportState)
        setAnalyticsConfigAtom(undefined)
      }}
    />
  )
}
