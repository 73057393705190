import { Navigate, type RouteObject } from 'react-router-dom'
import { OPTIMIZATIONS_ROUTE_FRAGMENTS } from './routePages'
import { HistoricalOverviewView } from './views/Historical/HistoricalOverviewView'
import { WeeklyAnalysisView } from './views/Historical/WeeklyAnalysisView'
import { YearlyOverviewView } from './views/Historical/YearlyOverviewView'
import { PredictionView } from './views/Prediction/PredictionView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <HistoricalOverviewView />,
  },
  {
    path: `:${OPTIMIZATIONS_ROUTE_FRAGMENTS.MARKET}` as const,
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={OPTIMIZATIONS_ROUTE_FRAGMENTS.WEEKLY_ANALYSIS}
            replace
          />
        ),
      },
      {
        path: OPTIMIZATIONS_ROUTE_FRAGMENTS.WEEKLY_ANALYSIS,
        element: <WeeklyAnalysisView />,
      },
      {
        path: OPTIMIZATIONS_ROUTE_FRAGMENTS.YEARLY_ANALYSIS,
        element: <YearlyOverviewView />,
      },
      {
        path: OPTIMIZATIONS_ROUTE_FRAGMENTS.PREDICTION,
        element: <PredictionView />,
      },
    ],
  },
]
