import {
  ListItem as ChakraListItem,
  forwardRef,
  type ListItemProps,
} from '@chakra-ui/react'
import { mergeRefs } from '@chakra-ui/react-utils'
import { type useCombobox } from 'downshift'
import { type ForwardedRef, type ReactElement } from 'react'
import { MenuItemText } from '../../components/MenuItemText'
import { type DropdownOption } from '../../types'

interface Props<TItem extends DropdownOption> {
  option: TItem
  isSelected?: boolean
  isHighlighted?: boolean
  itemProps?: ReturnType<ReturnType<typeof useCombobox>['getItemProps']>
  itemStyles?: ListItemProps
  isOption?: boolean
  leftItem?: ReactElement
  rightItem?: ReactElement
}

export const ComboBoxItem = forwardRef(
  <TItem extends DropdownOption>(
    {
      leftItem,
      rightItem,
      isSelected,
      isHighlighted,
      option,
      itemProps,
      itemStyles,
      isOption,
      ...rest // This is needed for using some HOC which adds additional props. For example, using Chakra's Tooltip in itemWrapper.
    }: Props<TItem>,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const itemRef = itemProps?.ref ? mergeRefs(ref, itemProps.ref) : ref

    return (
      <ChakraListItem
        alignItems="center"
        aria-disabled={option.disabled}
        bg={isHighlighted ? 'grey.100' : ''}
        cursor="pointer"
        display="flex"
        _selected={{
          bg: isHighlighted ? 'grey.300' : 'grey.200',
        }}
        value={option.id.toString()}
        // Order matters here. Spreading rest before itemProps can make Downshift to stop working and make the component to stop working.
        {...rest}
        {...itemProps}
        {...itemStyles}
        ref={itemRef}
      >
        <MenuItemText
          option={option}
          leftItem={leftItem}
          isDisabled={option.disabled}
          rightItem={rightItem}
          isOption={isOption}
          isSelected={isSelected}
        />
      </ChakraListItem>
    )
  },
)

ComboBoxItem.displayName = 'ComboBoxItem'
