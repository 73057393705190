export const PRODUCT_FEED_ROUTE_FRAGMENTS = {
  PRODUCT_FEED: 'product-feed',
  PRODUCT_LABELS: 'labels',
  SEGMENTATION_SETS: 'sets',
  SEGMENTATION_RULES: 'rules',
} as const

const BASE = `/${PRODUCT_FEED_ROUTE_FRAGMENTS.PRODUCT_FEED}` as const

export const PRODUCT_FEED_PAGES = {
  PRODUCT_LABELS: `${BASE}/${PRODUCT_FEED_ROUTE_FRAGMENTS.PRODUCT_LABELS}`,
  SEGMENTATION_SETS: `${BASE}/${PRODUCT_FEED_ROUTE_FRAGMENTS.SEGMENTATION_SETS}`,
  SEGMENTATION_RULES_BY_ID: (segmentationSetId: string) =>
    `${BASE}/${PRODUCT_FEED_ROUTE_FRAGMENTS.SEGMENTATION_SETS}/${segmentationSetId}/${PRODUCT_FEED_ROUTE_FRAGMENTS.SEGMENTATION_RULES}` as const,
} as const
