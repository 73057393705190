import { Box, Flex } from '@chakra-ui/react'
import { type SaturationCurveData } from 'features/optimizations/graphql/useHistoricalAnalysisQuery'
import {
  getChannelId,
  getChannelName,
} from 'features/optimizations/utils/transformChannel'
import { ProfitDescription } from '../ProfitDescription'

import { SingleChannelControlPanel } from './SingleChannelControlPanel'
import { SingleChannelGraph } from './SingleChannelGraph'
import { getSelectedCurveData, useSingleChannelState } from './state'

interface SingleChannelSaturationCurveProps {
  curveData: SaturationCurveData
  showValuesPerDay: boolean
}

export const SingleChannelSaturationCurve: React.FC<
  SingleChannelSaturationCurveProps
> = ({ curveData, showValuesPerDay }) => {
  const [state, dispatch] = useSingleChannelState(curveData)

  if (!state) return null

  const selectedCurve = getSelectedCurveData(curveData, state.channel)
  const selectedChannelName = getChannelName(selectedCurve.channel)

  return (
    <Flex w="full" gap={6}>
      <ProfitDescription
        selectedChannel={selectedChannelName}
        optimalSpend={selectedCurve.optimalPoint[0]}
        optimalProfit={selectedCurve.optimalPoint[1]}
        actualProfit={selectedCurve.actualPoint[1]}
        actualSpend={selectedCurve.actualPoint[0]}
        saturationProfit={selectedCurve.saturationPoint[1]}
        saturationSpend={selectedCurve.saturationPoint[0]}
        showValuesPerDay={showValuesPerDay}
        isSingleChannel
      />
      <Flex width="full" flexDir="column" overflow="auto">
        <Box mb={4} minW={400}>
          <SingleChannelControlPanel
            state={state}
            dispatch={dispatch}
            selectedCurveData={selectedCurve}
            channels={curveData.map(({ channel }) => ({
              id: getChannelId(channel),
              name: getChannelName(channel),
            }))}
          />
        </Box>

        <SingleChannelGraph
          selectedCurveData={selectedCurve}
          selectedChannel={selectedChannelName}
        />
      </Flex>
    </Flex>
  )
}
