import type { PropsWithChildren } from 'react'
import {
  FormProvider,
  type FieldError,
  type FieldValues,
} from 'react-hook-form'
import type { Props } from './types'

export const getFormErrorMessage = (error?: FieldError, label?: string) => {
  let errorMessage: string | undefined = undefined

  if (error) {
    const fieldName = label ?? 'Field'

    if (error.message) {
      errorMessage = error.message
    } else if (error.type.includes('required') || error.ref?.value === '') {
      errorMessage = `${fieldName} is required`
    } else {
      errorMessage = `${fieldName} is invalid`
    }
  }

  return errorMessage
}

export const Form = <T extends FieldValues>({
  children,
  onSubmit,
  methods,
  className,
  ...rest
}: PropsWithChildren<Props<T>>) => {
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        {...rest}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  )
}
