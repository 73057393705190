import { type useCombobox } from 'downshift'
import { type Dispatch, type SetStateAction } from 'react'
import {
  type ComboBoxOption,
  type ComboBoxProps,
  type DropdownOption,
  type DropdownSize,
} from '../../types'
import { hasGroupOptions } from '../utils'
import { GroupedListItems } from './GroupedListItems'
import { SimpleListItems } from './SimpleListItems'

export type ComboBoxItemsProps<TItem extends DropdownOption> = Pick<
  ComboBoxProps<TItem>,
  'selected' | 'itemWrapper'
> & {
  size: DropdownSize
  filteredOptions: ComboBoxOption<TItem>[]
  isOption?: boolean
  hasSelectDeselect?: boolean
  setSelected?: Dispatch<SetStateAction<string[]>>
  highlightedIndex?: number
  getItemProps: ReturnType<typeof useCombobox>['getItemProps']
}
export const ComboBoxItems = <TItem extends DropdownOption>({
  size,
  filteredOptions,
  selected,
  isOption,
  highlightedIndex,
  getItemProps,
  hasSelectDeselect,
  setSelected,
  itemWrapper,
}: ComboBoxItemsProps<TItem>) => {
  if (hasGroupOptions(filteredOptions)) {
    return (
      <GroupedListItems
        size={size}
        items={filteredOptions}
        selected={selected}
        isOption={isOption}
        highlightedIndex={highlightedIndex}
        getItemProps={getItemProps}
        hasSelectDeselect={hasSelectDeselect}
        setSelected={setSelected}
        itemWrapper={itemWrapper}
      />
    )
  }

  return (
    <SimpleListItems
      size={size}
      items={filteredOptions as TItem[]}
      selected={selected}
      isOption={isOption}
      highlightedIndex={highlightedIndex}
      getItemProps={getItemProps}
      itemWrapper={itemWrapper}
    />
  )
}
