import { MenuDivider, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'components/Typography'
import { useDateState } from 'features/reports/hooks/useDateState'
import { type HeaderMenuProps } from '../types'

export const MetricsMenu = ({
  setSortOrder,
  openFilterPopup,
}: HeaderMenuProps) => {
  const { isCompare } = useDateState()

  return (
    <MenuList>
      <MenuItem
        icon={<Icon name="ArrowDownIcon" size="small" />}
        onClick={() => setSortOrder('desc')}
      >
        <Typography>Sort 9 → 1</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="ArrowUpIcon" size="small" />}
        onClick={() => setSortOrder('asc')}
      >
        <Typography>Sort 1 → 9</Typography>
      </MenuItem>
      <MenuItem
        icon={<Icon name="FilterIcon" size="small" />}
        onClick={() => openFilterPopup()}
      >
        <Typography>Filter</Typography>
      </MenuItem>

      {isCompare && (
        <>
          <MenuDivider />
          <MenuGroup title="Comparison">
            <MenuItem
              icon={<Icon name="ArrowDownIcon" size="small" />}
              onClick={() => setSortOrder('descCompare')}
            >
              <Typography>Sort 9 → 1</Typography>
            </MenuItem>
            <MenuItem
              icon={<Icon name="ArrowUpIcon" size="small" />}
              onClick={() => setSortOrder('ascCompare')}
            >
              <Typography>Sort 1 → 9</Typography>
            </MenuItem>
          </MenuGroup>
        </>
      )}
    </MenuList>
  )
}
