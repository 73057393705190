import { TARGETS, type TargetType } from 'features/optimizations/consts'
import {
  CHANNEL_ID_SEPARATOR,
  getChannelId,
} from 'features/optimizations/utils/transformChannel'
import { type PredictionStore, type PredictionState } from '../../atoms/atom'
import { type SidebarFormState } from './types'

const defaultTargetState = {
  [TARGETS.BUDGET]: undefined,
  [TARGETS.ROAS]: undefined,
  [TARGETS.EP_ROAS]: undefined,
}

export const getFormValuesFromState = (
  state: PredictionState,
): SidebarFormState => {
  const { target, targetValue, channelBounds } = state

  return {
    channelBounds: channelBounds.reduce<Record<string, [number, number]>>(
      (acc, { channel, range }) => {
        const channelId = getChannelId(channel)

        acc[channelId] = range

        return acc
      },
      {},
    ),
    target: {
      ...defaultTargetState,
      [target]: targetValue ? String(targetValue) : undefined,
    } as Record<TargetType, string | undefined>,
    selectedTarget: target,
  }
}

export const getStateValueFromForm = (
  formState: SidebarFormState,
): PredictionStore => {
  const { target, channelBounds, selectedTarget } = formState

  const targetValue = Number(target[selectedTarget])

  return {
    target: selectedTarget,
    targetValue: isFinite(targetValue) ? targetValue : 0,
    channelBounds: Object.entries(channelBounds).map(([channelKey, range]) => {
      const [channelGroup, channel, funnelCampaign] =
        channelKey.split(CHANNEL_ID_SEPARATOR)

      return {
        channel: {
          channel,
          funnelCampaign,
          channelGroup,
        },
        range,
      }
    }),
  }
}
