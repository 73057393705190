import { Flex } from '@chakra-ui/react'
import { Button, ButtonIcon } from 'components/buttons'
import { Input } from 'components/Input'
import ResizeTextarea from 'react-textarea-autosize'

interface MessageInputProps {
  setConversationId: React.Dispatch<React.SetStateAction<string | null>>
  message: string
  setMessage: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  sendMessage: (message: string) => void
}

export const MessageInput: React.FC<MessageInputProps> = ({
  setConversationId,
  message,
  setMessage,
  isLoading,
  sendMessage,
}) => {
  const isValidMessage = message.trim() !== ''
  const isDisabled = isLoading || !isValidMessage

  const handleSendMessage = async () => {
    if (isDisabled) return

    sendMessage(message)
  }

  return (
    <Flex gap={2} w="full">
      <Input
        minH="unset"
        w="100%"
        resize="none"
        minRows={1}
        maxRows={15}
        as={ResizeTextarea}
        placeholder="Write a question to Copilot"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        wrapperProps={{ flex: 1 }}
        action={
          <ButtonIcon
            name="ArrowUpIcon"
            title={
              isLoading
                ? 'Wait for response'
                : isValidMessage
                  ? 'Send message'
                  : ''
            }
            size="sm"
            variant={isValidMessage ? 'solid' : 'ghost'}
            isDisabled={isDisabled}
            onClick={handleSendMessage}
          />
        }
        actionWidth={32}
        onKeyDown={(e) => {
          // dont send message on shift+enter
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSendMessage()
          }
        }}
      />
      <Button
        flexShrink={0}
        variant="outline"
        colorScheme="gray"
        isDisabled={isLoading}
        onClick={() => {
          setConversationId(null)
        }}
      >
        Clear chat
      </Button>
    </Flex>
  )
}
