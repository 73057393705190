import { graphql } from 'generated/graphql'

export const TextMessageItemFragment = graphql(/* GraphQL */ `
  fragment TextMessageItemFields on TextMessageItem {
    id
    text
    __typename
  }
`)

export const ReportMessageItemFragment = graphql(/* GraphQL */ `
  fragment ReportMessageItemFields on ReportMessageItem {
    id
    report {
      id
      name
      startDate
      endDate
      dynamicDate
      compareStartDate
      compareEndDate
      compareDynamicDate
      metrics
      dimensions
      filters
      __typename
    }
    __typename
  }
`)

export const ConversationMessageFieldsFragment = graphql(/* GraphQL */ `
  fragment ConversationMessageFields on Message {
    id
    author {
      id
      name
      role
      __typename
    }
    items {
      ...ReportMessageItemFields
      ...TextMessageItemFields
      __typename
    }
    createdAt
    __typename
  }
`)

export const ConversationFieldsFragment = graphql(/* GraphQL */ `
  fragment ConversationFields on Conversation {
    id
    title
    messages {
      ...ConversationMessageFields
    }
    __typename
  }
`)
