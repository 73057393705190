import { Box, Button, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import type { FC } from 'react'

type ConnectorProps = {
  name: string
  logo: string
  onClick: () => void
}

export const Connector: FC<ConnectorProps> = ({ name, logo, onClick }) => {
  return (
    <Button
      bg="none"
      borderRadius="lg"
      px={0}
      py={6}
      height="unset"
      cursor="pointer"
      display="flex"
      flexDir="column"
      alignItems="center"
      border="solid 1px"
      borderColor="primary.500"
      _hover={{
        borderColor: 'primary.50',
        bg: 'primary.50',
      }}
      onClick={onClick}
    >
      <Box height="14" width="14">
        <Image
          src={logo}
          alt={'Logo for ' + name}
          width="full"
          height="full"
          objectFit="contain"
        />
      </Box>
      <Typography
        mt={2}
        fontSize="sm"
        lineHeight={5}
        fontWeight="400"
        color="grey.950"
      >
        {name}
      </Typography>
    </Button>
  )
}
