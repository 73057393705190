import { useMutation } from '@apollo/client'
import { graphql } from 'generated/graphql'

const DELETE_SEGMENTATION_SET_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteSegmentationSet($id: ID!) {
    deleteSegmentationSet(id: $id) {
      id
      __typename
    }
  }
`)

export const useDeleteSegmentationSet = () => {
  const [deleteSegmentationSet] = useMutation(DELETE_SEGMENTATION_SET_MUTATION)

  return deleteSegmentationSet
}
